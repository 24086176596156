/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik';
import React from 'react';

import LanguageTexts from '../../../common/language';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import ImageUpload from '../../../components/ImageUpload';

type TestimonialProps = {
  index: number;
  onRemove: (index: number) => void;
  onRemoveTestiPic: (id: string) => void;
  onImageSelect: (file: File | null) => void;
  errors: string[];
  commenterPicUrl?: string | null;
  id?: string | null;
  disabled?: boolean;
};

const Testimonial: React.FC<TestimonialProps> = ({
  index,
  onRemove,
  onRemoveTestiPic,
  onImageSelect,
  errors,
  commenterPicUrl,
  id,
  disabled,
}: TestimonialProps): JSX.Element => {
  const {
    instituteWebsiteInformation: instituteWebsiteInformationTxt,
  } = LanguageTexts;

  function handleClick() {
    onRemove(index);
  }

  return (
    <div className="border p-3 mb-3 common-border-color testimonial-parent">
      <div className="col-12 text-right">
        <button
          type="button"
          className="btn cancel-btn"
          onClick={handleClick}
          disabled={disabled}
        >
          {instituteWebsiteInformationTxt.btnRemove}&nbsp;
          <span className="fa fa-times" />
        </button>
      </div>
      <div className="testimonial-child">
        <div className="row">
          <div className="col-lg-6 col-12">
            <ImageUpload
              id={`testi-image${index}`}
              label={instituteWebsiteInformationTxt['testimonial.commenterPic']}
              onFileSelect={onImageSelect}
              url={commenterPicUrl}
              maxFileSize={250}
              fileResolution="250 x 250"
              disabled={disabled}
              onRemoveUrl={() => (id ? onRemoveTestiPic(id) : null)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <AppInputLableWithError
                errors={errors}
                langTxt={instituteWebsiteInformationTxt}
                id="testimonial.commenterName"
                required
                errId={`${index}.commenterName`}
              />
              <Field
                name={`testimonial.${index}.commenterName`}
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-lg-4 col-12 mb-3 mb-lg-0">
            <AppInputLableWithError
              errors={errors}
              langTxt={instituteWebsiteInformationTxt}
              id="testimonial.commenterContact"
              errId={`${index}.commenterContact`}
            />
            <Field
              name={`testimonial.${index}.commenterContact`}
              type="text"
              className="form-control"
            />
          </div>
          <div className="col-lg-4 col-12">
            <AppInputLableWithError
              errors={errors}
              langTxt={instituteWebsiteInformationTxt}
              id="testimonial.commenterDescription"
              errId={`${index}.commenterDescription`}
            />
            <Field
              name={`testimonial.${index}.commenterDescription`}
              type="text"
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <AppInputLableWithError
                errors={errors}
                langTxt={instituteWebsiteInformationTxt}
                id="testimonial.comments"
                required
                errId={`${index}.comments`}
              />
              <Field
                name={`testimonial.${index}.comments`}
                as="textarea"
                className="form-control"
                rows={5}
              />
              <div className="row">
                <div className="col-12 text-right">
                  <p className="small common-text-color short-desc mt-1">
                    {instituteWebsiteInformationTxt.maxCharNote}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Testimonial.defaultProps = {
  commenterPicUrl: null,
  id: null,
  disabled: false,
};

export default Testimonial;

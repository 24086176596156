/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, WebsiteMediaInput } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  removeInstituteWebsiteMedia,
  resetRemoveInstituteWebsiteMedia,
  resetSaveWebsiteMediaSuccess,
  saveWebsiteMedia,
} from '../institute-setup.slice';
import MediaUploadForm from './MediaUploadForm';

type MediaUploadProps = {
  infoLoaded: boolean;
};

const MediaUpload: React.FC<MediaUploadProps> = ({
  infoLoaded,
}: MediaUploadProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    websiteMedia,
    instituteTypes,
    websiteDefaultMainImageUrl,
    loading: infoLoading,
    saveWebsiteMedia: { errors, loading, success },
    removeInstituteWebsiteMedia: {
      loading: removeInstituteWebsiteMediaLoading,
      success: removeInstituteWebsiteMediaSuccess,
    },
  } = useSelector((state: RootState) => state.instituteSetup);

  useEffect(() => {
    if (success) {
      dispatch(resetSaveWebsiteMediaSuccess());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (removeInstituteWebsiteMediaSuccess) {
      dispatch(resetRemoveInstituteWebsiteMedia());
    }
  }, [removeInstituteWebsiteMediaSuccess, dispatch]);

  function onSubmit(input: WebsiteMediaInput[], defaultMainImage: string) {
    dispatch(saveWebsiteMedia({ input, defaultMainImage }));
  }

  function onRemoveWebsiteMedia(id: string) {
    dispatch(removeInstituteWebsiteMedia(id));
  }

  const websiteMediaInit =
    websiteMedia && websiteMedia.length > 0 ? cloneDeep(websiteMedia) : [];

  return infoLoaded &&
    !infoLoading &&
    !success &&
    !removeInstituteWebsiteMediaSuccess ? (
    <MediaUploadForm
      initialValues={websiteMediaInit}
      initDefaultMainImage={websiteDefaultMainImageUrl || ''}
      instituteTypes={instituteTypes}
      onSubmit={onSubmit}
      onRemoveMedia={onRemoveWebsiteMedia}
      errors={errors}
      loading={loading || removeInstituteWebsiteMediaLoading}
    />
  ) : (
    <AppLoader />
  );
};

export default MediaUpload;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginInput, RootState } from '../../common/types';
import { login } from './login.slice';
import LoginForm from './LoginForm';

const LoginPage: React.FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { errors, loading } = useSelector((state: RootState) => state.login);

  function onSubmit(input: LoginInput) {
    dispatch(login(input));
  }

  return (
    <div className="limiter">
      <div className="container-login-register">
        <div className="wrap-login-register-parent">
          <div className="wrap-login-register-child signin-form-section">
            <div className="login100-form validate-signin-form">
              <LoginForm
                onSubmit={onSubmit}
                errors={errors}
                loading={loading}
              />
            </div>
          </div>
          <div
            className="login-register-side-image"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        </div>
      </div>
    </div>
  );
};

LoginPage.defaultProps = {};

export default LoginPage;

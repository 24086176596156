/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppRoutePath,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateAssignmentSubmissionInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadAssignmentSubmission,
  resetLoadAssignmentSubmission,
} from './assignments.slice';
import AssignmentSubmissionForm from './AssignmentSubmissionForm';

type AssignmentSubmissionRecordPageParams = {
  onSubmit: (data: CreateAssignmentSubmissionInput) => void;
  errors: string[];
  loading: boolean;
};

const AssignmentSubmissionPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: AssignmentSubmissionRecordPageParams): JSX.Element => {
  const { assignmentId, studentId } = useParams<{
    assignmentId: string;
    studentId: string;
  }>();

  const { assignments: assignmentsTxt } = LanguageTexts;

  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: { role: selectedRole },
  } = useSelector((state: RootState) => state.app);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const [
    assignmentSubmissionLoadRequested,
    setAssignmentSubmissionLoadRequested,
  ] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadAssignmentSubmission: {
      loading: loadAssignmentSubmissionLoading,
      errors: loadAssignmentSubmissionErrors,
      success: loadAssignmentSubmissionSuccess,
    },
    assignmentSubmission,
    student,
    assignment,
  } = useSelector((state: RootState) => state.assignments);

  useEffect(() => {
    if (!assignmentSubmissionLoadRequested) {
      dispatch(loadAssignmentSubmission({ assignmentId, studentId }));
      setAssignmentSubmissionLoadRequested(true);
    }
  }, [assignmentSubmissionLoadRequested, assignmentId, studentId, dispatch]);

  useEffect(() => {
    if (loadAssignmentSubmissionErrors.length > 0) {
      dispatch(resetLoadAssignmentSubmission());
      history.push(AppRoutePath.Assignments);
    } else if (loadAssignmentSubmissionSuccess) {
      dispatch(resetLoadAssignmentSubmission());
    }
  }, [
    loadAssignmentSubmissionErrors,
    loadAssignmentSubmissionSuccess,
    dispatch,
    history,
  ]);

  if (
    !assignmentSubmissionLoadRequested ||
    loadAssignmentSubmissionLoading ||
    !assignment ||
    !student
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(input: any) {
    onSubmitParent({
      ...input,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentSubmissionInit: any = {
    _id: assignmentSubmission?._id || undefined,
    assignmentId,
    studentId,
    submittedAs: currentUser?.role.includes(UserRole.Student)
      ? UserRole.Student
      : UserRole.Teacher,
    // eslint-disable-next-line no-nested-ternary
    submissionStatus: currentUser?.role.includes(UserRole.Student)
      ? assignmentSubmission
        ? AssignmentSubmissionStatus.Resubmitted
        : AssignmentSubmissionStatus.Submitted
      : undefined,
    comment: '',
    assignmentSubmissionFormat: undefined,
    assignmentUrl: '',
    assignmentFile: undefined,
    grade: '',
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5 assignment-submission">
      <div className="form">
        <table className="table table-condensed table-bordered">
          <tbody>
            <tr>
              <td className="bg-light">
                <h1 className="primary-heading mb-4">
                  {assignmentsTxt.homework}
                </h1>
                <div className="row">
                  <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                    <p className="font-weight-bold">
                      {currentUser?.role.includes(UserRole.Student)
                        ? assignmentsTxt.teacherName
                        : assignmentsTxt.studentName}{' '}
                      :
                    </p>
                  </div>
                  <div className="col-lg-9 col-12">
                    <p>
                      {currentUser?.role.includes(UserRole.Student)
                        ? assignment.createdByUser.name
                        : student?.name}
                    </p>
                  </div>
                </div>
                <div className="my-3" />
                <div className="row">
                  <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                    <p className="font-weight-bold">
                      {assignmentsTxt.dueDate} :
                    </p>
                  </div>
                  <div className="col-lg-9 col-12">
                    <p>
                      {assignment
                        ? moment(
                            setTimeToZero(assignment.assignmentDueDate),
                          ).format('L')
                        : ''}
                    </p>
                  </div>
                </div>
                <div className="my-3" />
                <div className="row">
                  <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                    <p className="font-weight-bold">
                      {assignmentsTxt.description} :
                    </p>
                  </div>
                  <div className="col-lg-9 col-12">
                    <p>{assignment?.description}</p>
                  </div>
                </div>
                <div className="my-3" />
                {assignment?.assignmentUrl || assignment?.assignmentFileUrl ? (
                  <div className="row">
                    <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                      <p className="font-weight-bold">
                        {assignmentsTxt.fileOrUrl} :
                      </p>
                    </div>
                    <div className="col-lg-9 col-12">
                      <p>
                        <a
                          href={
                            assignment?.assignmentUrl ||
                            assignment?.assignmentFileUrl
                          }
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {assignmentsTxt.view}&nbsp;
                          <span className="fa fa-download" />
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>

        {assignmentSubmission
          ? assignmentSubmission.submissionHistory.map(
              ({
                submittedAs,
                assignmentUrl,
                assignmentFileUrl,
                submittedAt,
                submissionStatus,
                comment,
                grade,
              }) => {
                if (submittedAs === UserRole.Student) {
                  return (
                    <>
                      <div className="my-3" />
                      <h2 className="secondary-heading mb-4">
                        {`${submissionStatus} ${assignmentsTxt.by} ${student.name}`}
                      </h2>
                      <div className="row">
                        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                          <p className="font-weight-bold">
                            {assignmentsTxt.submissionFile} :
                          </p>
                        </div>
                        <div className="col-lg-9 col-12">
                          <p>
                            <a
                              href={assignmentUrl || assignmentFileUrl}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {assignmentsTxt.view}&nbsp;
                              <span className="fa fa-download" />
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="my-3" />
                      <div className="row">
                        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                          <p className="font-weight-bold">
                            {assignmentsTxt.submissionDate} :
                          </p>
                        </div>
                        <div className="col-lg-9 col-12">
                          <p>{moment(submittedAt).format('L')}</p>
                        </div>
                      </div>
                      <hr className="my-3" />
                    </>
                  );
                }

                return (
                  <>
                    <h2 className="secondary-heading mb-4">
                      {`${assignmentsTxt.remark} ${assignmentsTxt.by} ${assignment.createdByUser.name}`}
                    </h2>
                    <div className="row">
                      <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                        <p className="font-weight-bold">
                          {assignmentsTxt.remark} :
                        </p>
                      </div>
                      <div className="col-lg-9 col-12">
                        <p>{submissionStatus}</p>
                      </div>
                    </div>
                    <div className="my-3" />
                    <div className="row">
                      <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                        <p className="font-weight-bold">
                          {assignmentsTxt.remarkDate} :
                        </p>
                      </div>
                      <div className="col-lg-9 col-12">
                        <p>{moment(submittedAt).format('L')}</p>
                      </div>
                    </div>
                    <div className="my-3" />
                    <div className="row">
                      <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                        <p className="font-weight-bold">
                          {assignmentsTxt.remarkComment} :
                        </p>
                      </div>
                      <div className="col-lg-9 col-12">
                        <p>{comment}</p>
                      </div>
                    </div>
                    <div className="my-3" />
                    {grade ? (
                      <>
                        <div className="row">
                          <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                            <p className="font-weight-bold">
                              {assignmentsTxt.grade} :
                            </p>
                          </div>
                          <div className="col-lg-9 col-12">
                            <p>{grade}</p>
                          </div>
                        </div>
                        <div className="my-3" />
                      </>
                    ) : null}
                    {assignmentUrl || assignmentFileUrl ? (
                      <>
                        <div className="row">
                          <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                            <p className="font-weight-bold">
                              {assignmentsTxt.resubmissionFile} :
                            </p>
                          </div>
                          <div className="col-lg-9 col-12">
                            <p>
                              <a
                                href={assignmentUrl || assignmentFileUrl}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {assignmentsTxt.view}&nbsp;
                                <span className="fa fa-download" />
                              </a>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <hr className="my-3" />
                  </>
                );
              },
            )
          : null}

        {currentUser &&
        ((userRoles.includes(UserRole.Teacher) && assignmentSubmission) ||
          userRoles.includes(UserRole.Student)) ? (
          <AssignmentSubmissionForm
            onSubmit={onSubmit}
            initialValues={assignmentSubmissionInit}
            errors={errors}
            loading={loading}
            userRoles={userRoles}
          />
        ) : null}
      </div>
    </section>
  );
};

export default AssignmentSubmissionPage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { InstitutePaymentInfoInput, RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  resetSaveInstitutePaymentInfo,
  saveInstitutePaymentInfo,
} from '../institute-setup.slice';
import PaymentInformationDetail from './PaymentInformationDetail';
import PaymentInformationForm from './PaymentInformationForm';

type PaymentInformationProps = {
  infoLoaded: boolean;
};

const PaymentInformation: React.FC<PaymentInformationProps> = ({
  infoLoaded,
}: PaymentInformationProps): JSX.Element => {
  const { institutePaymentInfo: institutePaymentInfoTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    loading: infoLoading,
    institutePaymentInfo,
    instituteAddress,
    saveInstitutePaymentInfo: { errors, loading, success },
  } = useSelector((state: RootState) => state.instituteSetup);

  const [editMode, setEditMode] = useState(false);

  function onSubmit(data: InstitutePaymentInfoInput) {
    dispatch(saveInstitutePaymentInfo(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetSaveInstitutePaymentInfo());
      setEditMode(false);
    }
  }, [success, dispatch]);

  if (!infoLoaded || infoLoading) {
    return <AppLoader />;
  }

  if (!instituteAddress) {
    return <p>{institutePaymentInfoTxt.missingAddress}</p>;
  }

  if (institutePaymentInfo && !editMode) {
    return (
      <>
        <h3 className="tertiary-heading mb-4">
          {institutePaymentInfoTxt.paymentInfoTooltip}
        </h3>
        <PaymentInformationDetail
          onEdit={() => setEditMode(true)}
          loading={loading}
          data={{ ...institutePaymentInfo }}
        />
      </>
    );
  }

  return !success ? (
    <>
      <h3 className="tertiary-heading mb-4">
        {institutePaymentInfoTxt.paymentInfoTooltip}
      </h3>
      <PaymentInformationForm
        address={instituteAddress}
        onSubmit={onSubmit}
        onCancel={() => setEditMode(false)}
        errors={errors}
        loading={loading}
        showCancel={!!institutePaymentInfo}
      />
    </>
  ) : (
    <AppLoader />
  );
};

export default PaymentInformation;

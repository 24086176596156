import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InstituteWebsiteInfoInput, RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  removeInstituteAboutImage,
  removeTestimonialImage,
  resetInstituteWebsiteInfoSuccess,
  resetRemoveTestimonialImage,
  saveInstituteWebsiteInfo,
} from '../institute-setup.slice';
import WebsiteInformationForm from './WebsiteInformationForm';

type WebsiteInformationProps = {
  infoLoaded: boolean;
};

const WebsiteInformation: React.FC<WebsiteInformationProps> = ({
  infoLoaded,
}: WebsiteInformationProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    instituteWebsiteInfo,
    loading: infoLoading,
    saveInstituteWebsiteInfo: { errors, loading, success },
    removeTestimonialImage: {
      loading: removeTestimonialImageLoading,
      success: removeTestimonialImageSuccess,
    },
  } = useSelector((state: RootState) => state.instituteSetup);

  function onSubmit(data: InstituteWebsiteInfoInput) {
    dispatch(saveInstituteWebsiteInfo(data));
  }

  function onRemoveAboutImage() {
    dispatch(removeInstituteAboutImage());
  }

  function onRemoveTestimonialImage(id: string) {
    dispatch(removeTestimonialImage(id));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetInstituteWebsiteInfoSuccess());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (removeTestimonialImageSuccess) {
      dispatch(resetRemoveTestimonialImage());
    }
  }, [removeTestimonialImageSuccess, dispatch]);

  const instituteWebsiteInfoInit = instituteWebsiteInfo
    ? cloneDeep(instituteWebsiteInfo)
    : {
        heading: '',
        description: [''],
        testimonial: [],
      };

  return infoLoaded &&
    !infoLoading &&
    !success &&
    !removeTestimonialImageSuccess ? (
    <WebsiteInformationForm
      initialValues={instituteWebsiteInfoInit}
      onSubmit={onSubmit}
      errors={errors}
      loading={loading || removeTestimonialImageLoading}
      onRemoveAboutImage={onRemoveAboutImage}
      onRemoveTestiPic={onRemoveTestimonialImage}
    />
  ) : (
    <AppLoader />
  );
};

export default WebsiteInformation;

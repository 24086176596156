/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';

import { InstituteWebsiteMediaType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { WebsiteMediaInput } from '../../../common/types';

type MediaVideosProps = {
  initialValues: WebsiteMediaInput[];
  onChange: (data: WebsiteMediaInput[]) => void;
  disabled?: boolean;
};

const MediaVideos: React.FC<MediaVideosProps> = ({
  initialValues,
  onChange,
  disabled,
}: MediaVideosProps): JSX.Element => {
  const { websiteMedia: websiteMediaTxt } = LanguageTexts;
  const [videos, setVideos] = useState<WebsiteMediaInput[]>(initialValues);

  const initVideos = useMemo(() => {
    return videos.length === 0
      ? [
          {
            mediaUrl: '',
            mediaType: InstituteWebsiteMediaType.YoutubeUrl,
          },
        ]
      : videos;
  }, [videos]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const newVideos = [...initVideos];
    newVideos[index] = { ...newVideos[index], mediaUrl: e.target.value };
    setVideos(newVideos);
    onChange(newVideos.filter(({ mediaUrl }) => !!mediaUrl));
  }

  function reomveVideoUrl(index: number) {
    if (disabled) return;
    const newVideos = initVideos.filter((vid, i) => index !== i);
    setVideos(newVideos);
    onChange(newVideos.filter(({ mediaUrl }) => !!mediaUrl));
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <label className="text-secondary small">
            {websiteMediaTxt.utubeUrl}
          </label>
          {initVideos.map(({ mediaUrl }, index) => {
            return (
              <div
                key={`utube${index}`}
                className={index > 0 ? 'youtube-url-more' : 'youtube-url'}
              >
                <div className="row d-flex justify-content-start align-items-center mt-3">
                  <div className="col-10">
                    <div className="input-group">
                      <input
                        name="videoUrl"
                        type="text"
                        className="form-control"
                        aria-label="Recipient's username with two button addons"
                        aria-describedby="button-addon4"
                        value={mediaUrl}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  </div>
                  <div className="col-1 d-flex justify-content-center">
                    {index > 0 ? (
                      <button
                        type="button"
                        className="btn cancel-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          if (disabled) return;
                          reomveVideoUrl(index);
                        }}
                        disabled={disabled}
                      >
                        <i className="fa fa-times font-weight-bold" />
                      </button>
                    ) : (
                      ' '
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn add-more-btn"
            onClick={() =>
              setVideos([
                ...initVideos,
                {
                  mediaUrl: '',
                  mediaType: InstituteWebsiteMediaType.YoutubeUrl,
                },
              ])
            }
            disabled={disabled}
          >
            <span className="fa fa-plus-circle" />
            &nbsp;{websiteMediaTxt.addVideos}
          </button>
        </div>
      </div>
    </>
  );
};

MediaVideos.defaultProps = {
  disabled: false,
};

export default MediaVideos;

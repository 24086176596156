import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadCourses } from './institute-courses.slice';

const InstituteCourseListPage = (): JSX.Element => {
  const { courses: coursesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { courses, loading } = useSelector((state: RootState) => state.courses);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: coursesTxt.course,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link
              to={`${AppRoutePath.CoursesUpdate.replace(':courseId', _id)}`}
            >
              {name}
            </Link>
          );
        },
      },
    ],
    [coursesTxt],
  );

  const data = React.useMemo(
    () =>
      courses?.map(({ ...restClass }) => {
        return {
          ...restClass,
        };
      }),
    [courses],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadCourses({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{coursesTxt.courses}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default InstituteCourseListPage;

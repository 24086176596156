import { Field, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import AppInputLableWithError from '../../../components/AppInputLableWithError';

type BankAccountVerifyProps = {
  onSubmit: (data: {
    amount1: number | undefined;
    amount2: number | undefined;
  }) => void;
  errors: string[];
  loading: boolean;
};

const BankAccountVerifyForm: React.FC<BankAccountVerifyProps> = ({
  onSubmit,
  errors,
  loading,
}: BankAccountVerifyProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const initialValues = {
    amount1: undefined,
    amount2: undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);

        return null;
      }}
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(errorKeys, errors, profileTxt);

        return (
          <Form>
            <div className="form bank-verify-form">
              <div className="row">
                <div className="col-md-6 col-12 mb-md-0 mb-3">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={profileTxt}
                    id="input.amount1"
                    required
                  />
                  <Field
                    name="amount1"
                    type="number"
                    className="form-control"
                    placeholder="0.00"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={profileTxt}
                    id="input.amount2"
                    required
                  />
                  <Field
                    name="amount2"
                    type="number"
                    className="form-control"
                    placeholder="0.00"
                  />
                </div>
              </div>

              <div className="my-3" />

              <div className="row">
                <div className="col-12">
                  {formattedErrors.map((error) => (
                    <p
                      className="txt2"
                      style={{ fontWeight: 'bold' }}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {profileTxt.btnSave}&nbsp;
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankAccountVerifyForm;

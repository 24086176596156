/* eslint-disable jsx-a11y/label-has-associated-control */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { UserCreditCardInput } from '../../../common/types';

const cardStyle = {
  style: {
    base: {
      color: 'rgba(108, 117, 125, 0.8)',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(108, 117, 125, 0.8)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

type CreditCardFormProps = {
  onSubmit: (data: UserCreditCardInput) => void;
  onCancel: () => void;
  errors: string[];
  loading: boolean;
  showCancel: boolean;
  isPayMode?: boolean;
};

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  onSubmit,
  onCancel,
  errors,
  loading,
  showCancel,
  isPayMode,
}: CreditCardFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const stripe = useStripe();
  const elements = useElements();
  const initialValues: UserCreditCardInput = {
    paymentMethodToken: undefined,
    autoPayment: true,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!stripe || !elements) {
            return null;
          }

          const cardEle = elements.getElement(CardElement) || undefined;
          let paymentMethodToken;

          if (cardEle) {
            const { error, token } = await stripe.createToken(cardEle);

            setErrors({ paymentMethodToken: error?.message || undefined });

            paymentMethodToken = token?.id || undefined;
          } else {
            setErrors({
              paymentMethodToken: LanguageTexts.app.invalidCard,
            });
          }

          if (paymentMethodToken) {
            onSubmit({ ...values, paymentMethodToken });
            setSubmitting(false);
          }

          return null;
        }}
      >
        {({ values }) => {
          const errorKeys = getLangAndErrKeys(values);

          const formattedErrors = formatErrors(errorKeys, errors, profileTxt);

          return (
            <Form>
              <div className="form">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <CardElement
                      options={cardStyle}
                      className="credit-card-element"
                    />
                  </div>
                </div>
                <div className="my-3" />
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <p>{profileTxt.autoSaveCard}</p>
                  </div>
                </div>
                <div className="my-3" />
                <div className="row">
                  <div className="col-12">
                    {formattedErrors.map((error) => (
                      <p
                        className="txt2"
                        style={{ fontWeight: 'bold' }}
                        key={error}
                      >
                        {error}
                      </p>
                    ))}
                    <ErrorMessage
                      name="paymentMethodToken"
                      render={(msg) => (
                        <p className="txt2" style={{ fontWeight: 'bold' }}>
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>
                <div className="my-5" />
                <div className="row">
                  <div className="col-lg-6 col-12" />
                  <div className="col-lg-6 col-12 text-right">
                    {showCancel ? (
                      <button
                        type="button"
                        className="btn cancel-btn mr-2"
                        disabled={loading}
                        onClick={() => onCancel()}
                      >
                        {profileTxt.btnCancel}&nbsp;
                      </button>
                    ) : null}
                    &nbsp;
                    <button
                      type="submit"
                      className="btn save-btn"
                      disabled={loading}
                    >
                      {isPayMode
                        ? profileTxt.payAndSaveCardBtn
                        : profileTxt.updateCardBtn}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

CreditCardForm.defaultProps = {
  isPayMode: false,
};

export default CreditCardForm;

import 'react-day-picker/lib/style.css';

import moment from 'moment-timezone';
import React, { FC } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { setTimeToZero } from '../../../common/helper';

const todayStyle = `.DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  color: #212529;
  font-weight: 400;
}
`;

type BatchDatePickerProps = {
  value: moment.Moment | undefined;
  onChange: (newValue: moment.Moment | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDays: any[];
  timezone: string;
  disabled?: boolean;
};

const BatchDatePicker: FC<BatchDatePickerProps> = ({
  value,
  timezone,
  onChange,
  disabledDays,
  disabled,
}: BatchDatePickerProps): JSX.Element => {
  const today = moment(
    setTimeToZero(
      moment
        .tz(undefined, timezone)
        .add('1', 'd')
        .format(moment.HTML5_FMT.DATE),
    ),
  ).toDate();

  return (
    <>
      <style>{todayStyle}</style>
      <DayPickerInput
        value={value?.format('L') || undefined}
        onDayChange={(dayInit, { selected }) => {
          if (!selected) {
            if (!dayInit) {
              onChange(undefined);
              return;
            }
            try {
              const day = moment.tz(
                setTimeToZero(moment(dayInit).format(moment.HTML5_FMT.DATE)),
                timezone,
              );
              onChange(day);
            } catch (e) {
              onChange(undefined);
            }
          }
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        format="L"
        placeholder={moment.tz(undefined, timezone).format('L')}
        inputProps={{
          className: `form-control ${!disabled ? 'fc-readonly' : ''}`,
          disabled,
          readOnly: true,
        }}
        dayPickerProps={{
          disabledDays,
          initialMonth: today,
        }}
      />
    </>
  );
};

BatchDatePicker.defaultProps = {
  disabled: false,
};

export default BatchDatePicker;

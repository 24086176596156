/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateClassInput,
  GetClassDepsQuery,
  GetClassesQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addClass,
  addClassError,
  addClassSuccess,
  loadClass,
  loadClassDeps,
  loadClassDepsError,
  loadClassDepsSuccess,
  loadClassError,
  loadClasses,
  loadClassesError,
  loadClassesSuccess,
  loadClassSuccess,
  removeClassImage,
  removeClassImageError,
  removeClassImageSuccess,
} from './institute-classes.slice';

export function* addClassAsync(action: PayloadAction<CreateClassInput>) {
  const { data, errors } = yield call(ApiService.addClass, action.payload);

  if (errors) {
    yield put(addClassError(errors));
  } else {
    yield put(addClassSuccess(!!data));
  }
}

export function* loadClassAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadClassFormDetails, action.payload);

  if (!data) {
    yield put(loadClassError(['404']));
  } else {
    yield put(loadClassSuccess(data));
  }
}

export function* loadClassesAsync(action: PayloadAction<GetClassesQuery>) {
  const { data } = yield call(ApiService.getClassesForTable, action.payload);

  if (!data) {
    yield put(loadClassesError(['500']));
  } else {
    yield put(loadClassesSuccess(data));
  }
}

export function* loadClassDepsAsync(action: PayloadAction<GetClassDepsQuery>) {
  const { data } = yield call(ApiService.getClassDeps, action.payload);

  if (!data) {
    yield put(loadClassDepsError(['500']));
  } else {
    yield put(loadClassDepsSuccess(data));
  }
}

export function* removeClassImageAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.removeClassImage,
    action.payload,
  );

  if (errors) {
    yield put(removeClassImageError(errors));
  } else {
    yield put(removeClassImageSuccess({ success: data, id: action.payload }));
  }
}

export function* watchClassesAsync() {
  yield takeEvery(addClass.toString(), addClassAsync);
  yield takeEvery(loadClass.toString(), loadClassAsync);
  yield takeEvery(loadClasses.toString(), loadClassesAsync);
  yield takeEvery(loadClassDeps.toString(), loadClassDepsAsync);
  yield takeEvery(removeClassImage.toString(), removeClassImageAsync);
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import Select from 'react-select';

import { BatchScheduleType, BatchStatus } from '../../common/constants';
import {
  formatErrors,
  getLangAndErrKeys,
  isPastDate,
  isWeekDayExistBetweenDates,
  setTimeToZero,
} from '../../common/helper';
import LanguageTexts from '../../common/language';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import BatchCustomSchedule from './components/BatchCustomSchedule';
import BatchDatePicker from './components/BatchDatePicker';
import BatchFeesSchedule from './components/BatchFeesSchedule';
import BatchWeeklySchedule from './components/BatchWeeklySchedule';

type BatchCustomScheduleProps = {
  _id?: string;
  day: moment.Moment;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
};

type BatchWeeklyScheduleProps = {
  _id?: string;
  day: string;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
};

type BatchFeesScheduleProps = {
  _id?: string;
  feesAmount: number | undefined;
  dueDate: moment.Moment | undefined;
};

export type CreateBatchProps = {
  _id?: string;
  centerId: string;
  classId: string;
  name: string;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  scheduleWeekly: BatchWeeklyScheduleProps[];
  scheduleCustom: BatchCustomScheduleProps[];
  scheduleType: BatchScheduleType;
  feesSchedule: BatchFeesScheduleProps[];
  status: BatchStatus;
};

type BatchFormProps = {
  onSubmit: (data: CreateBatchProps) => void;
  initialValues: CreateBatchProps;
  errors: string[];
  loading: boolean;
  timezone: string;
  currencySymbol: string;
  classes: { name: string; _id: string }[];
};

const BatchForm: React.FC<BatchFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
  timezone,
  currencySymbol,
  classes,
}: BatchFormProps): JSX.Element => {
  const { batches: batchsTxt } = LanguageTexts;
  const classOptions = classes.map(({ _id, name }) => {
    return { value: _id, label: name };
  });

  const [selectedClass, setSelectedClass] = useState(
    classOptions.find(({ value }) => value === initialValues.classId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onClassChange(val: any) {
    setSelectedClass(val);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit({
          ...values,
          classId: selectedClass?.value || '',
        });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue }) => {
        const {
          scheduleType,
          scheduleCustom,
          scheduleWeekly,
          ...restValues
        } = values;
        const errorKeys = getLangAndErrKeys({
          ...restValues,
          startDate: undefined,
          endDate: undefined,
          schedule:
            scheduleType === BatchScheduleType.Custom
              ? scheduleCustom
              : scheduleWeekly,
        });
        errorKeys.push({ langKey: 'input.schedule', errKey: 'input.schedule' });

        const formattedErrors = formatErrors(errorKeys, errors, batchsTxt);

        const startDate = values.startDate
          ? moment(
              setTimeToZero(values.startDate.format(moment.HTML5_FMT.DATE)),
            ).toDate()
          : undefined;

        const batchExpired = !!(
          timezone &&
          values.startDate &&
          values.endDate &&
          isPastDate(values.startDate, timezone) &&
          isPastDate(values.endDate, timezone)
        );

        function resetDateFields(
          newStartDate: moment.Moment,
          newEndDate: moment.Moment,
        ) {
          setFieldValue(
            'scheduleCustom',
            values.scheduleCustom.filter(({ day }) => {
              return (
                day.isSameOrAfter(newStartDate) &&
                day.isSameOrBefore(newEndDate)
              );
            }),
          );
          setFieldValue(
            'scheduleWeekly',
            values.scheduleWeekly.filter(({ day }) => {
              return isWeekDayExistBetweenDates(+day, newStartDate, newEndDate);
            }),
          );
          setFieldValue(
            'feesSchedule',
            values.feesSchedule.map(({ dueDate, ...rest }) => {
              return {
                ...rest,
                dueDate:
                  dueDate && dueDate.isSameOrBefore(newEndDate)
                    ? dueDate
                    : undefined,
              };
            }),
          );
        }

        return (
          <Form>
            <div className="class-parent">
              <div className="class-child">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={batchsTxt}
                        id="input.classId"
                        required
                      />
                      <Select
                        value={selectedClass}
                        options={classOptions}
                        onChange={onClassChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={batchsTxt}
                        id="input.name"
                        required
                      />
                      <Field name="name" type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={batchsTxt}
                        id="input.startDate"
                        required
                      />
                      <BatchDatePicker
                        value={values.startDate}
                        onChange={(newValue) => {
                          setFieldValue('startDate', newValue);

                          if (newValue && values.endDate) {
                            if (values.endDate.isBefore(newValue)) {
                              setFieldValue('endDate', undefined);
                            }

                            resetDateFields(newValue, values.endDate);
                          }
                        }}
                        disabledDays={[]}
                        timezone={timezone}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={batchsTxt}
                        id="input.endDate"
                        required
                      />
                      <BatchDatePicker
                        value={values.endDate}
                        onChange={(newValue) => {
                          setFieldValue('endDate', newValue);

                          if (values.startDate && newValue) {
                            resetDateFields(values.startDate, newValue);
                          }
                        }}
                        disabledDays={
                          startDate
                            ? [
                                {
                                  before: startDate,
                                },
                              ]
                            : []
                        }
                        timezone={timezone}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={batchsTxt}
                      id="input.scheduleType"
                      required
                    />
                    <select
                      name="scheduleType"
                      className="custom-select select-schedule"
                      value={values.scheduleType}
                      onChange={handleChange}
                      disabled={batchExpired}
                    >
                      <option value={BatchScheduleType.Weekly}>
                        {BatchScheduleType.Weekly}
                      </option>
                      <option value={BatchScheduleType.Custom}>
                        {BatchScheduleType.Custom}
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="schedule">
                      {values.scheduleType === BatchScheduleType.Custom ? (
                        <FieldArray name="scheduleCustom">
                          {() => (
                            <BatchCustomSchedule
                              schedule={values.scheduleCustom}
                              onChange={(schedule) =>
                                setFieldValue('scheduleCustom', schedule)
                              }
                              startDate={values.startDate}
                              endDate={values.endDate}
                              errors={errors}
                              timezone={timezone}
                            />
                          )}
                        </FieldArray>
                      ) : (
                        <FieldArray name="scheduleWeekly">
                          {() => (
                            <BatchWeeklySchedule
                              schedule={values.scheduleWeekly}
                              onChange={(schedule) =>
                                setFieldValue('scheduleWeekly', schedule)
                              }
                              startDate={values.startDate}
                              endDate={values.endDate}
                              errors={errors}
                              timezone={timezone}
                            />
                          )}
                        </FieldArray>
                      )}
                    </div>
                  </div>
                </div>
                <div className="my-3" />
                <p className="text-center mb-3">
                  <b>{batchsTxt.feesScheduleNote}</b>
                </p>
                <div className="my-3" />
                <FieldArray name="feesSchedule">
                  {({ remove, push, replace }) => (
                    <div className="border rounded-lg p-3">
                      {values.feesSchedule.map((fSchedule, index) => (
                        <BatchFeesSchedule
                          // eslint-disable-next-line react/no-array-index-key
                          key={`feesschedule${index}`}
                          onRemove={(i) => remove(i)}
                          index={index}
                          errors={errors}
                          disabled={loading}
                          id={fSchedule._id}
                          dueDate={fSchedule.dueDate}
                          startDate={values.startDate}
                          endDate={values.endDate}
                          onDateChange={(dueDate) => {
                            replace(index, {
                              ...fSchedule,
                              dueDate,
                            });
                          }}
                          timezone={timezone}
                          currencySymbol={currencySymbol}
                        />
                      ))}
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn add-more-btn"
                            onClick={() =>
                              push({
                                feesAmount: 0,
                                dueDate: undefined,
                              })
                            }
                            disabled={loading || batchExpired}
                          >
                            {batchsTxt.btnAddMore}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="my-3" />
                <div className="row">
                  <div className="col-12">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={batchsTxt}
                      id="input.status"
                      required
                    />
                    <select
                      name="status"
                      className="custom-select class-status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value={BatchStatus.Active}>
                        {BatchStatus.Active}
                      </option>
                      <option value={BatchStatus.Cancelled}>
                        {BatchStatus.Cancelled}
                      </option>
                      <option value={BatchStatus.Completed}>
                        {BatchStatus.Completed}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-3" />
            <div className="row">
              <div className="col-lg-12 col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="my-3" />
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn save-btn"
                >
                  {batchsTxt.btnAdd}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

BatchForm.defaultProps = {};

export default BatchForm;

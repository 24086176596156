/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ReceivePaymentForInstituteInput,
  SendPaymentForInstituteInput,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  loadOwners,
  loadOwnersError,
  loadOwnersSuccess,
  receivePaymentForInstitute,
  receivePaymentForInstituteError,
  receivePaymentForInstituteSuccess,
  sendPaymentForInstitute,
  sendPaymentForInstituteError,
  sendPaymentForInstituteSuccess,
} from './institutes.slice';

export function* loadOwnersAsync() {
  const { data } = yield call(ApiService.getInstiuteOwnersForTable);

  if (!data) {
    yield put(loadOwnersError(['500']));
  } else {
    yield put(loadOwnersSuccess(data));
  }
}

export function* receivePaymentForInstituteAsync(
  action: PayloadAction<ReceivePaymentForInstituteInput>,
) {
  const { data, errors } = yield call(
    ApiService.receivePaymentForInstitute,
    action.payload,
  );

  if (errors) {
    yield put(receivePaymentForInstituteError(errors));
  } else {
    yield put(receivePaymentForInstituteSuccess(!!data));
  }
}

export function* sendPaymentForInstituteAsync(
  action: PayloadAction<SendPaymentForInstituteInput>,
) {
  const { data, errors } = yield call(
    ApiService.sendPaymentForInstitute,
    action.payload,
  );

  if (errors) {
    yield put(sendPaymentForInstituteError(errors));
  } else {
    yield put(sendPaymentForInstituteSuccess(!!data));
  }
}

export function* watchInstitutesAsync() {
  yield takeEvery(loadOwners.toString(), loadOwnersAsync);
  yield takeEvery(
    receivePaymentForInstitute.toString(),
    receivePaymentForInstituteAsync,
  );
  yield takeEvery(
    sendPaymentForInstitute.toString(),
    sendPaymentForInstituteAsync,
  );
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateUserInput,
  GetUserDepsQuery,
  GetUsersQuery,
  UserDepsResponse,
  UserModel,
  UsersState,
} from '../../common/types';

const initialState: UsersState = {
  loading: false,
  success: null,
  errors: [],
  users: null,
  addUser: {
    loading: false,
    success: null,
    errors: [],
  },
  loadUser: {
    loading: false,
    success: null,
    errors: [],
  },
  loadUserDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  parents: null,
  centers: null,
  batches: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUsers(state, action: PayloadAction<GetUsersQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadUsersSuccess(state, action: PayloadAction<UserModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.users = action.payload;
    },
    loadUsersError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadUsers(state) {
      state.loadUser.success = null;
      state.loadUser.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addUser(state, action: PayloadAction<CreateUserInput>) {
      state.addUser.loading = true;
      state.addUser.errors = [];
    },
    addUserSuccess(state, action: PayloadAction<boolean>) {
      state.addUser.loading = false;
      state.addUser.success = action.payload;
    },
    addUserError(state, action: PayloadAction<string[]>) {
      state.addUser.loading = false;
      state.addUser.errors = action.payload;
    },
    resetAddUserSuccess(state) {
      state.addUser.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUser(state, action: PayloadAction<string>) {
      state.loadUser.loading = true;
      state.loadUser.errors = [];
    },
    loadUserSuccess(state, action: PayloadAction<UserModel>) {
      state.loadUser.loading = false;
      state.loadUser.success = action.payload;
    },
    loadUserError(state, action: PayloadAction<string[]>) {
      state.loadUser.loading = false;
      state.loadUser.errors = action.payload;
    },
    resetLoadUser(state) {
      state.loadUser.success = null;
      state.loadUser.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUserDeps(state, action: PayloadAction<GetUserDepsQuery>) {
      state.loadUserDeps.loading = true;
      state.loadUserDeps.errors = [];
    },
    loadUserDepsSuccess(state, action: PayloadAction<UserDepsResponse>) {
      state.loadUserDeps.loading = false;
      state.loadUserDeps.success = !!action.payload;
      state.parents = action.payload.parents;
      state.centers = action.payload.centers;
      state.batches = action.payload.batches;
    },
    loadUserDepsError(state, action: PayloadAction<string[]>) {
      state.loadUserDeps.loading = false;
      state.loadUserDeps.errors = action.payload;
    },
    resetLoadUserDeps(state) {
      state.loadUserDeps.success = null;
      state.loadUserDeps.errors = [];
    },
  },
});

export const {
  addUser,
  addUserSuccess,
  addUserError,
  resetAddUserSuccess,
  loadUser,
  loadUserSuccess,
  loadUserError,
  resetLoadUser,
  loadUsers,
  loadUsersSuccess,
  loadUsersError,
  resetLoadUsers,
  loadUserDeps,
  loadUserDepsSuccess,
  loadUserDepsError,
  resetLoadUserDeps,
} = usersSlice.actions;

export const { name: usersSliceName, reducer: usersSliceReducer } = usersSlice;

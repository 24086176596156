import React from 'react';

import LanguageTexts from '../../common/language';
import AssignmentTable from './AssignmentTable';

const AssignmentListPage = (): JSX.Element => {
  const { assignments: assignmentsTxt } = LanguageTexts;

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{assignmentsTxt.assignments}</h1>
      <AssignmentTable />
    </section>
  );
};

export default AssignmentListPage;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { ChangePasswordInput } from '../../../common/types';
import AppInputLableWithError from '../../../components/AppInputLableWithError';

type ChangePasswordFormProps = {
  onSubmit: (data: ChangePasswordInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: ChangePasswordInput;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: ChangePasswordFormProps): JSX.Element => {
  // const { profile: profileTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(
    errorKeys,
    errors,
    LanguageTexts.profile,
  );

  return (
    <>
      <Formik
        initialValues={initVals}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <div className="form">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    id="input.newPassword"
                    errors={errors}
                    langTxt={LanguageTexts.profile}
                    required
                  />
                  <Field
                    type="password"
                    className="form-control"
                    name="newPassword"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    id="input.oldPassword"
                    errors={errors}
                    langTxt={LanguageTexts.profile}
                    required
                  />
                  <Field
                    type="password"
                    className="form-control"
                    name="oldPassword"
                  />
                </div>
              </div>
            </div>
            <div className="my-5" />
            <div className="row">
              <div className="col-lg-12 col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12" />
              <div className="col-lg-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {LanguageTexts.profile.changePasswordBtn}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ChangePasswordForm;

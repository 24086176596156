/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { resetSelectedFilter } from '../../app/app.slice';
import { AppRoutePath, TOKEN_KEY } from '../../common/constants';
import { LoginInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  login,
  loginError,
  loginSuccess,
  logout,
  logoutSuccess,
  whoami,
  whoamiError,
  whoamiSuccess,
} from './login.slice';

export function* loginAsync(action: PayloadAction<LoginInput>) {
  const { data, errors } = yield call(ApiService.login, action.payload);

  if (errors) {
    yield put(loginError(errors));
  } else {
    yield call(
      [localStorage, localStorage.setItem],
      TOKEN_KEY,
      data.accessToken,
    );
    yield put(loginSuccess(data));
    yield put(push(AppRoutePath.Dashboard));
  }
}

export function* whoamiAsync() {
  const { data, errors } = yield call(ApiService.whoami);

  if (errors) {
    yield put(whoamiError(errors));
  } else {
    yield put(whoamiSuccess(data));
  }
}

export function* logoutAsync() {
  yield call([localStorage, localStorage.removeItem], TOKEN_KEY);
  yield put(logoutSuccess());
  yield put(resetSelectedFilter());
  window.location.replace('/');
}

export function* watchLoginAsync() {
  yield takeLatest(login.toString(), loginAsync);
  yield takeLatest(whoami.toString(), whoamiAsync);
  yield takeLatest(logout.toString(), logoutAsync);
}

import { Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';

import { AttendanceStatus } from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { UserModel } from '../../common/types';

type AttendanceFormData = {
  _id?: string;
  batchId: string;
  attendanceDate: moment.Moment;
  students: { _id?: string; user: UserModel; attendanceStatus: string }[];
};

type AttendanceProps = {
  onSubmit: (data: AttendanceFormData) => void;
  loading: boolean;
  errors: string[];
  initialValues: AttendanceFormData;
};

const AttendanceForm: React.FC<AttendanceProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
}: AttendanceProps): JSX.Element => {
  const { attendances: attendancesTxt, app: appTxt } = LanguageTexts;

  const { ...initVal } = initialValues;

  return (
    <Formik
      initialValues={initVal}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        onSubmit(input);

        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
        });
        const formattedErrors = formatErrors(errorKeys, errors, attendancesTxt);

        return (
          <Form>
            <table className="table table-condensed table-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>
                    <p>
                      {attendancesTxt.date} :&nbsp;
                      <span className="common-text-color font-weight-bold">
                        {initVal.attendanceDate.format('L')}
                      </span>
                    </p>
                  </th>
                </tr>
                <tr>
                  <th className="text-secondary bg-light text-center">
                    {attendancesTxt.studentName}
                  </th>
                  <th className="text-secondary bg-light text-center">
                    {attendancesTxt.attendance}
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="students">
                  {() => {
                    return values.students.map((student, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr className="text-center" key={`studentItem${index}`}>
                        <td style={{ verticalAlign: 'middle' }}>
                          {student.user.name}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Field
                            as="select"
                            className="custom-select attendance-type"
                            name={`students.${index}.attendanceStatus`}
                          >
                            <option value="">{appTxt.choose}</option>
                            <option value={AttendanceStatus.Present}>
                              {AttendanceStatus.Present}
                            </option>
                            <option value={AttendanceStatus.Absent}>
                              {AttendanceStatus.Absent}
                            </option>
                            <option value={AttendanceStatus.Excused}>
                              {AttendanceStatus.Excused}
                            </option>
                          </Field>
                        </td>
                      </tr>
                    ));
                  }}
                </FieldArray>
              </tbody>
            </table>
            <div className="my-md-3" />

            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12" />
              <div className="col-md-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {attendancesTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AttendanceForm;

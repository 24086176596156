import { Form, Formik } from 'formik';
import { flattenDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetSelectedFilter, setSelectedFilter } from '../../app/app.slice';
import { USER_ROLES_PERMISSIONS, UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import { loadBatches } from '../../features/batches/batches.slice';
import { loadCenters } from '../../features/institute-centers/institute-centers.slice';

const FilterBox = (): JSX.Element => {
  const { app: appTxt } = LanguageTexts;
  const [loadRequested, setLoadRequested] = useState(false);
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.app);
  const { centers } = useSelector((state: RootState) => state.centers);
  const { batches } = useSelector((state: RootState) => state.batches);
  const { user: currentUser } = useSelector((state: RootState) => state.login);

  const initValues = {
    role: filter.role || '',
    centerId: filter.centerId || '',
    batchId: filter.batchId || '',
    childrenId: filter.childrenId || '',
  };

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadCenters({
          domain: currentUser?.domain || '',
        }),
      );
      dispatch(
        loadBatches({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  const filteredCenters = useMemo(() => {
    let centerIds: string[] = [];

    if (currentUser?.role.indexOf(UserRole.CenterHead) !== -1) {
      centerIds = currentUser?.centerIds || [];
    }

    return centers?.filter(({ _id }) => {
      if (centerIds.length > 0) {
        return centerIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, centers]);

  const filteredBatches = useMemo(() => {
    let batchIds: string[] = [];

    if (
      currentUser?.role.indexOf(UserRole.Teacher) !== -1 ||
      currentUser?.role.indexOf(UserRole.Student) !== -1
    ) {
      batchIds = currentUser?.batches?.map(({ batch: { _id } }) => _id) || [];
    } else if (currentUser?.role.indexOf(UserRole.Parent) !== -1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userBatchIds: any =
        currentUser?.children?.map(({ batches: studentBatches }) => {
          return studentBatches?.map(({ batch: { _id } }) => _id) || [];
        }) || [];

      batchIds = flattenDeep(userBatchIds);
      batchIds = batchIds.length === 0 ? [''] : batchIds;
    }

    return batches?.filter(({ _id }) => {
      if (batchIds.length > 0) {
        return batchIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, batches]);

  const children = currentUser?.children || [];

  return (
    <div>
      <Formik
        initialValues={initValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);

          if (currentUser?.role.indexOf(UserRole.CenterHead) !== -1) {
            if (currentUser?.role.indexOf(UserRole.Teacher) !== -1) {
              dispatch(
                setSelectedFilter({
                  role: null,
                  centerId: values.centerId,
                  batchId: values.batchId,
                  childrenId: null,
                }),
              );
            } else {
              dispatch(
                setSelectedFilter({
                  role: null,
                  centerId: values.centerId,
                  batchId: null,
                  childrenId: null,
                }),
              );
            }
          } else if (
            currentUser?.role.indexOf(UserRole.Teacher) !== -1 ||
            currentUser?.role.indexOf(UserRole.Student) !== -1
          ) {
            dispatch(
              setSelectedFilter({
                role: null,
                centerId: null,
                batchId: values.batchId,
                childrenId: null,
              }),
            );
          } else if (currentUser?.role.indexOf(UserRole.Parent) !== -1) {
            dispatch(
              setSelectedFilter({
                role: null,
                centerId: null,
                batchId: values.batchId,
                childrenId: values.childrenId,
              }),
            );
          } else if (!values.role) {
            dispatch(resetSelectedFilter());
          } else if (values.role === UserRole.CenterHead) {
            dispatch(
              setSelectedFilter({
                role: UserRole.CenterHead,
                centerId: values.centerId,
                batchId: null,
                childrenId: null,
              }),
            );
          } else if (values.role === UserRole.Teacher) {
            dispatch(
              setSelectedFilter({
                role: UserRole.Teacher,
                centerId: null,
                batchId: values.batchId,
                childrenId: null,
              }),
            );
          } else if (values.role === UserRole.Owner) {
            dispatch(
              setSelectedFilter({
                role: UserRole.Owner,
                centerId: null,
                batchId: null,
                childrenId: null,
              }),
            );
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => {
          const disabled = !!(
            (currentUser?.role.indexOf(UserRole.Owner) !== -1 &&
              (!values.role ||
                (values.role === UserRole.CenterHead && !values.centerId) ||
                (values.role === UserRole.Teacher && !values.batchId))) ||
            (currentUser?.role.indexOf(UserRole.CenterHead) !== -1 &&
              !values.centerId) ||
            (currentUser?.role.indexOf(UserRole.Teacher) !== -1 &&
              !values.batchId) ||
            (currentUser?.role.indexOf(UserRole.Parent) !== -1 &&
              (!values.batchId || !values.childrenId))
          );

          const showCenters =
            (currentUser?.role.indexOf(UserRole.Owner) !== -1 &&
              values.role === UserRole.CenterHead) ||
            currentUser?.role.indexOf(UserRole.CenterHead) !== -1;

          const showBatches =
            (currentUser?.role.indexOf(UserRole.Owner) !== -1 &&
              values.role === UserRole.Teacher) ||
            currentUser?.role.indexOf(UserRole.Teacher) !== -1 ||
            currentUser?.role.indexOf(UserRole.Student) !== -1 ||
            currentUser?.role.indexOf(UserRole.Parent) !== -1;

          const showChildren =
            currentUser?.role.indexOf(UserRole.Parent) !== -1;

          let allowedBatchIds: (string | undefined)[] = [];

          if (values.childrenId) {
            const selectedChild = children.find(
              ({ _id }) => _id === values.childrenId,
            );

            allowedBatchIds =
              selectedChild?.batches
                ?.map(({ batch: { _id } = {} }) => _id)
                .filter((id) => id) || [];
          }

          return (
            <Form>
              {currentUser?.role.indexOf(UserRole.Owner) !== -1 ? (
                <select
                  name="role"
                  className="custom-select custom-select-sm choose-role-menu"
                  value={values.role}
                  onChange={handleChange}
                >
                  <option value="">{appTxt.chooseRole}</option>
                  <option value={UserRole.Owner}>
                    {appTxt.instituteOwner}
                  </option>
                  <option value={UserRole.CenterHead}>
                    {USER_ROLES_PERMISSIONS.find(
                      ({ key }) => key === UserRole.CenterHead,
                    )?.title || ''}
                  </option>
                  <option value={UserRole.Teacher}>
                    {USER_ROLES_PERMISSIONS.find(
                      ({ key }) => key === UserRole.Teacher,
                    )?.title || ''}
                  </option>
                </select>
              ) : null}

              {showCenters ? (
                <select
                  name="centerId"
                  className="custom-select custom-select-sm choose-role-menu"
                  value={values.centerId}
                  onChange={handleChange}
                >
                  <option value="">{appTxt.chooseCenter}</option>
                  {filteredCenters
                    ? filteredCenters.map(({ _id, name }) => {
                        return (
                          <option key={_id} value={_id}>
                            {name}
                          </option>
                        );
                      })
                    : null}
                </select>
              ) : null}

              {showChildren ? (
                <select
                  name="childrenId"
                  className="custom-select custom-select-sm choose-role-menu"
                  value={values.childrenId}
                  onChange={(e) => {
                    setFieldValue('batchId', null);
                    handleChange(e);
                  }}
                >
                  <option value="">{appTxt.choose}</option>
                  {children
                    ? children.map(({ _id, name }) => {
                        return (
                          <option key={_id} value={_id}>
                            {name}
                          </option>
                        );
                      })
                    : null}
                </select>
              ) : null}

              {showBatches ? (
                <select
                  name="batchId"
                  className="custom-select custom-select-sm choose-role-menu"
                  value={values.batchId}
                  onChange={handleChange}
                >
                  <option value="">{appTxt.chooseBatch}</option>
                  {filteredBatches
                    ? filteredBatches
                        .filter(({ _id }) => {
                          if (allowedBatchIds && allowedBatchIds.length > 0) {
                            return allowedBatchIds.indexOf(_id) !== -1;
                          }

                          return true;
                        })
                        .map(
                          ({ _id, name, class: { name: className } = {} }) => {
                            return (
                              <option key={_id} value={_id}>
                                {className} - {name}
                              </option>
                            );
                          },
                        )
                    : null}
                </select>
              ) : null}

              <button
                type="submit"
                className="btn btn-sm btn-outline-light btn-block choose-role"
                disabled={disabled}
              >
                {appTxt.btnSubmit}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FilterBox;

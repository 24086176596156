import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadAttendances } from './attendances.slice';

const AttendanceListPage = (): JSX.Element => {
  const { attendances: attendancesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { attendances, loading } = useSelector(
    (state: RootState) => state.attendances,
  );

  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const [loadRequested, setLoadRequested] = useState(false);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const columns = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conColumns: any = [];

    if (
      userRoles.includes(UserRole.Owner) ||
      userRoles.includes(UserRole.Teacher) ||
      userRoles.includes(UserRole.CenterHead)
    ) {
      conColumns.push({
        Header: attendancesTxt.studentName,
        accessor: 'student.user.name',
      });
    }

    if (userRoles.includes(UserRole.Owner)) {
      conColumns.push({
        Header: attendancesTxt.center,
        accessor: 'batch.center.name',
      });
    }

    if (
      userRoles.includes(UserRole.Owner) ||
      userRoles.includes(UserRole.CenterHead)
    ) {
      conColumns.push({
        Header: attendancesTxt.class,
        accessor: 'batchTxt',
      });
    }

    conColumns.push({
      Header: attendancesTxt.date,
      accessor: 'dateTxt',
    });

    conColumns.push({
      Header: attendancesTxt.attendance,
      accessor: 'student.attendanceStatus',
    });

    return conColumns;
  }, [attendancesTxt, userRoles]);

  const data = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aStudents: any[] = [];

    attendances?.forEach(({ attendanceDate, students, ...restAttendance }) => {
      const dateTxt = moment(setTimeToZero(attendanceDate)).format('L');
      const batchTxt = `${restAttendance.batch?.class?.name || ''} (${
        restAttendance.batch?.name || ''
      })`;

      students.forEach((student) => {
        aStudents.push({
          ...restAttendance,
          dateTxt,
          batchTxt,
          student,
        });
      });
    });

    return aStudents.filter(
      ({
        student: { userId },
        batch: {
          _id: batchId,
          center: { _id: centerId },
        },
      }) => {
        const aCenterIds = [centerId];
        const aBatchIds = [batchId];

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (selectedChildren) {
          childrenCheck = selectedChildren === userId;
        } else if (userRoles.includes(UserRole.Student)) {
          childrenCheck = currentUser?._id === userId;
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (selectedChildren || userRoles.includes(UserRole.Student)) {
            return batchCheck && childrenCheck;
          }

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      },
    );
  }, [
    attendances,
    selectedBatch,
    selectedCenter,
    selectedChildren,
    currentUser,
    userRoles,
  ]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadAttendances({}));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{attendancesTxt.attendances}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default AttendanceListPage;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { BankAccountModel, RootState } from '../../../common/types';
import {
  loadPaymentMethods,
  resetVerifyBankAccount,
  verifyBankAccount,
} from '../profile.slice';
import BankAccountVerifyForm from './BankAccountVerifyForm';

type BankAccountDetailProps = {
  data: BankAccountModel;
  handleSetDefaultPaymentMethod?: (id: string) => void;
  handleDeletePaymentMethod?: (id: string) => void;
  selected?: boolean;
  showPaymentSelectedIcon?: boolean;
};

const BankAccountDetail: React.FC<BankAccountDetailProps> = ({
  data,
  handleSetDefaultPaymentMethod,
  handleDeletePaymentMethod,
  selected,
  showPaymentSelectedIcon,
}: BankAccountDetailProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const [showModel, setShowModel] = useState(false);

  const dispatch = useDispatch();
  const {
    verifyBankAccount: {
      errors: verifyBankAccountErrors,
      loading: verifyBankAccountLoading,
      success: verifyBankAccountSuccess,
    },
    setDefaultPaymentMethod: { loading: setDefaultPaymentMethodLoading },
    deletePaymentMethod: { loading: deletePaymentMethodLoading },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(input: {
    amount1: number | undefined;
    amount2: number | undefined;
  }) {
    dispatch(verifyBankAccount({ ...input, bankAccountId: data.id }));
  }

  useEffect(() => {
    if (verifyBankAccountSuccess) {
      dispatch(resetVerifyBankAccount());
      dispatch(loadPaymentMethods());
      setShowModel(false);
    }
  }, [verifyBankAccountSuccess, dispatch]);

  function handleModelClose() {
    setShowModel(false);
  }

  return (
    <div className="my-4">
      <div className="card mb-4 payment-info-detail">
        <div className="card-header">
          <div className="row justify-content-center align-items-center">
            <div className="col-6">
              <h4 className="quaternary-heading">{profileTxt.ach}</h4>
            </div>
            <div className="col-6 text-right">
              {data.isDefault ? (
                <p>
                  <b>{profileTxt.defaultPaymentText}</b>
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.accountHolderName}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>{data.name}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.bankName}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>{data.bankName}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.accountNumber}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>**** {data.accountNumber}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.routingNumber}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>{data.routingNumber}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">
                {profileTxt.verificationStatus}
              </p>
            </div>
            {data.status === 'new' ? (
              <div className="col-lg-9 col-12">
                <button
                  type="button"
                  className="btn pending-verify-btn"
                  onClick={() => setShowModel(true)}
                >
                  {profileTxt.pendingVerification}
                </button>
                <Modal show={showModel} size="lg" onHide={handleModelClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h2 className="secondary-heading">
                        {profileTxt.verifyBankAccount}
                      </h2>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      <b>{profileTxt.note}: </b>
                      {profileTxt.verifyNote}
                    </p>
                    <div className="my-4" />
                    {!verifyBankAccountSuccess ? (
                      <BankAccountVerifyForm
                        errors={verifyBankAccountErrors}
                        loading={verifyBankAccountLoading}
                        onSubmit={onSubmit}
                      />
                    ) : null}
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              <div className="col-lg-9 col-12">
                <p>{profileTxt.verified}</p>
              </div>
            )}
          </div>
          <div className="my-4" />
          <div className="row">
            <div className="col-6">
              {!data.isDefault && handleSetDefaultPaymentMethod ? (
                <button
                  type="button"
                  className="btn save-btn"
                  disabled={setDefaultPaymentMethodLoading}
                  onClick={() => handleSetDefaultPaymentMethod(data.id)}
                >
                  {profileTxt.setDefault}&nbsp;
                </button>
              ) : showPaymentSelectedIcon ? null : selected ? (
                <>
                  <i className="fas fa-check-circle text-success fa-1x" />
                  <span className="text-secondary ml-2">
                    {profileTxt.selectedPaymentMethodText}
                  </span>
                </>
              ) : null}
            </div>
            <div className="col-6 text-right">
              {!data.isDefault && handleDeletePaymentMethod ? (
                <button
                  type="button"
                  className="btn cancel-btn"
                  disabled={deletePaymentMethodLoading}
                  onClick={() => handleDeletePaymentMethod(data.id)}
                >
                  {profileTxt.delete}&nbsp;
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BankAccountDetail.defaultProps = {
  handleSetDefaultPaymentMethod: undefined,
  handleDeletePaymentMethod: undefined,
  selected: false,
  showPaymentSelectedIcon: false,
};

export default BankAccountDetail;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { PaymentMethodType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import BankAccountPage from '../bank-account/BankAccountPage';
import CreditCardPage from '../credit-card/CreditCardPage';

type PaymentMethodFormProps = {
  initialValues: { paymentMethodType: PaymentMethodType };
  infoLoaded: boolean;
  onCancel: () => void;
};

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  initialValues,
  infoLoaded,
  onCancel,
}: PaymentMethodFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        return (
          <>
            <div className="card mb-4 payment-info-detail">
              <div className="card-header">
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          type="radio"
                          name="paymentMethodType"
                          id={PaymentMethodType.Card}
                          className="custom-control-input cash-mode"
                          value={PaymentMethodType.Card}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={PaymentMethodType.Card}
                        >
                          {profileTxt.creditCard}
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          type="radio"
                          name="paymentMethodType"
                          id={PaymentMethodType.ACH}
                          className="custom-control-input cash-mode"
                          value={PaymentMethodType.ACH}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={PaymentMethodType.ACH}
                        >
                          {profileTxt.ach}
                        </label>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="card-body border-0 bg-white">
                {values.paymentMethodType === PaymentMethodType.Card ? (
                  <CreditCardPage infoLoaded={infoLoaded} onCancel={onCancel} />
                ) : null}

                {values.paymentMethodType === PaymentMethodType.ACH ? (
                  <BankAccountPage
                    infoLoaded={infoLoaded}
                    onCancel={onCancel}
                  />
                ) : null}
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default PaymentMethodForm;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateUserInput,
  GetUserDepsQuery,
  GetUsersQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addUser,
  addUserError,
  addUserSuccess,
  loadUser,
  loadUserDeps,
  loadUserDepsError,
  loadUserDepsSuccess,
  loadUserError,
  loadUsers,
  loadUsersError,
  loadUsersSuccess,
  loadUserSuccess,
} from './users.slice';

export function* addUserAsync(action: PayloadAction<CreateUserInput>) {
  const { data, errors } = yield call(ApiService.addUser, action.payload);

  if (errors) {
    yield put(addUserError(errors));
  } else {
    yield put(addUserSuccess(!!data));
  }
}

export function* loadUserAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadUserFormDetails, action.payload);

  if (!data) {
    yield put(loadUserError(['404']));
  } else {
    yield put(loadUserSuccess(data));
  }
}

export function* loadUsersAsync(action: PayloadAction<GetUsersQuery>) {
  const { data } = yield call(ApiService.getUsersForTable, action.payload);

  if (!data) {
    yield put(loadUsersError(['500']));
  } else {
    yield put(loadUsersSuccess(data));
  }
}

export function* loadUserDepsAsync(action: PayloadAction<GetUserDepsQuery>) {
  const { data } = yield call(ApiService.getUserDeps, action.payload);

  if (!data) {
    yield put(loadUserDepsError(['500']));
  } else {
    yield put(loadUserDepsSuccess(data));
  }
}

export function* watchUsersAsync() {
  yield takeEvery(addUser.toString(), addUserAsync);
  yield takeEvery(loadUser.toString(), loadUserAsync);
  yield takeEvery(loadUsers.toString(), loadUsersAsync);
  yield takeEvery(loadUserDeps.toString(), loadUserDepsAsync);
}

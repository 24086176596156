/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState, UserBatchModel } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadCenterHeadContactInfo,
  loadTeacherContactInfo,
} from './contact-us.slice';

const ContactUsPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    filter: { batchId: selectedBatch, childrenId: selectedChildren },
  } = useSelector((state: RootState) => state.app);
  const { centerHeadContactInfo, teacherContactInfo, loading } = useSelector(
    (state: RootState) => state.contacts,
  );
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState<boolean>();

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadCenterHeadContactInfo({
          role: [UserRole.CenterHead],
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadTeacherContactInfo({
          role: [UserRole.Teacher],
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (!loadRequested || loading) {
    return <AppLoader />;
  }

  let batchIds: string[] = [];
  let centerIds: string[] = [];

  if (currentUser) {
    let batches: UserBatchModel[] = [];

    if (currentUser.role.includes(UserRole.Student)) {
      batches = currentUser.batches || [];
    } else if (currentUser.role.includes(UserRole.Parent)) {
      if (selectedChildren) {
        const student = currentUser.children?.find(
          ({ _id }) => _id === selectedChildren,
        );

        batches = student?.batches || [];
      }
    }

    if (selectedBatch) {
      const userBatch =
        batches.find(({ batch }) => batch._id === selectedBatch)?.batch || null;

      if (userBatch) {
        batchIds = [userBatch._id];

        const userCenter = userBatch.center;

        if (userCenter) {
          centerIds = [userCenter._id];
        }
      }
    }
  }

  const batchTeachers =
    teacherContactInfo?.filter(({ batches }) => {
      const tBatchIds =
        batches?.map(({ batch: { _id } = {} }) => _id).filter((id) => !!id) ||
        [];
      const batchCount = tBatchIds.filter(
        (bId) => bId && batchIds.indexOf(bId) !== -1,
      ).length;

      return batchCount > 0;
    }) || [];

  const centerHeads =
    centerHeadContactInfo?.filter(({ centerIds: tCenterIds }) => {
      const centerCount =
        tCenterIds?.filter((tId) => centerIds.indexOf(tId) !== -1).length || 0;

      return centerCount > 0;
    }) || [];

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">{LanguageTexts.contact.header}</h1>
      <div className="row contact-us">
        {centerHeads.length > 0 || batchTeachers.length > 0 ? (
          <>
            {centerHeads?.map(
              ({ name, email, phone, gender, profilePicUrl }, i) => {
                return (
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="card border contact-card">
                      <img
                        className="border shadow-sm"
                        src={
                          profilePicUrl !== null
                            ? profilePicUrl
                            : gender === null
                            ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/user.png`
                            : gender === 'Male'
                            ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/man.png`
                            : `${process.env.REACT_APP_IMAGE_PATH}website/profile/woman.png`
                        }
                        alt={name}
                      />
                      <div className="card-body">
                        <h3 className="tertiary-heading text-center mb-2">
                          {name}
                        </h3>
                        <p className="text-center role">
                          <b>{LanguageTexts.contact.centerHead}</b>
                        </p>
                        <div className="my-4" />
                        <p className="mb-2">
                          <i className="fa fa-envelope mr-2" />
                          <a href={`mailto:${email}`}>{email}</a>
                        </p>
                        <p>
                          <i className="fa fa-phone mr-2" />
                          <a href={`tel:${phone}`}>{phone}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
            {batchTeachers?.map(
              ({ name, email, phone, gender, profilePicUrl }, i) => {
                return (
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="card border contact-card">
                      <img
                        className="border shadow-sm"
                        src={
                          profilePicUrl !== null
                            ? profilePicUrl
                            : gender === null
                            ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/user.png`
                            : gender === 'Male'
                            ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/man.png`
                            : `${process.env.REACT_APP_IMAGE_PATH}website/profile/woman.png`
                        }
                        alt={name}
                      />
                      <div className="card-body">
                        <h3 className="tertiary-heading text-center mb-2">
                          {name}
                        </h3>
                        <p className="text-center role">
                          <b>{LanguageTexts.contact.teacher}</b>
                        </p>
                        <div className="my-4" />
                        <p className="mb-2">
                          <i className="fa fa-envelope mr-2" />
                          <a href={`mailto:${email}`}>{email}</a>
                        </p>
                        <p>
                          <i className="fa fa-phone mr-2" />
                          <a href={`tel:${phone}`}>{phone}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </>
        ) : (
          <>
            <div className="col-12">
              <h3 className="tertiary-heading text-center">
                {LanguageTexts.contact.noContacts}
              </h3>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ContactUsPage;

import React from 'react';

import LanguageTexts from '../../common/language';

const PrivacyPolicyPage: React.FC = (): JSX.Element => {
  return (
    <div className="content app-content privacy-container">
      <section className="bg-white rounded shadow px-4 py-5">
        <h1 className="primary-heading mb-4">
          {LanguageTexts.privacyPolicy.header}
        </h1>
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: LanguageTexts.privacyPolicy.privacyPara1.replace(
              '{url}',
              `<a href=${LanguageTexts.app.appHomePageLink} target="_blank" className="underline-link">${LanguageTexts.app.appHomePageLink}</a>`,
            ),
          }}
        />
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara2}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara3}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara4}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara5}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara6}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara7}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara8}</p>
        <div className="my-3" />
        <p>{LanguageTexts.privacyPolicy.privacyPara9}</p>
        <div className="my-3" />
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;

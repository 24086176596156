/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadCenters } from './institute-centers.slice';

const InstituteCenterListPage = (): JSX.Element => {
  const { centers: centersTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { centers, loading } = useSelector((state: RootState) => state.centers);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: centersTxt.center,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link
              to={`${AppRoutePath.CentersUpdate.replace(':centerId', _id)}`}
            >
              {name}
            </Link>
          );
        },
      },
      {
        Header: centersTxt.centerHead,
        accessor: 'centerHeadTitle',
      },
      {
        Header: centersTxt.city,
        accessor: 'city',
      },
      {
        Header: centersTxt.email,
        accessor: 'email',
      },
      {
        Header: centersTxt.contact,
        accessor: 'phone',
      },
    ],
    [centersTxt],
  );

  const data = React.useMemo(
    () =>
      centers?.map(({ _id: centerId, centerHead, ...restCenter }) => {
        return {
          _id: centerId,
          ...restCenter,
          city: restCenter.address.city?.cityName || '',
          centerHeadTitle: centerHead?.map(({ name }) => name).join(', ') || '',
        };
      }),
    [centers],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadCenters({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{centersTxt.centers}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default InstituteCenterListPage;

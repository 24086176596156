/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call } from 'redux-saga/effects';

import { watchAnnouncementsAsync } from '../features/announcements/announcements.saga';
import { watchAssignmentsAsync } from '../features/assignments/assignments.saga';
import { watchAttendancesAsync } from '../features/attendances/attendances.saga';
import { watchBatchesAsync } from '../features/batches/batches.saga';
import { watchContactUsAsync } from '../features/contact-us/contact-us.saga';
import { watchForgotPasswordAsync } from '../features/forgot-password/forgot-password.saga';
import { watchPreRegistrationInfoAsync } from '../features/home/home-page.saga';
import { watchCentersAsync } from '../features/institute-centers/institute-centers.saga';
import { watchCoursesAsync } from '../features/institute-courses/institute-courses.saga';
import { watchClassesAsync } from '../features/institute-classes/institute-classes.saga';
import { watchInstituteHomeAsync } from '../features/institute-home/institute-home.saga';
import { watchInstituteSetupAsync } from '../features/institute-setup/institute-setup.saga';
import { watchInstitutesAsync } from '../features/institutes/institutes.saga';
import { watchItSettingAsync } from '../features/it-setting/it-setting.saga';
import { watchLoginAsync } from '../features/login/login.saga';
import { watchNotificationAsync } from '../features/notification/notification.saga';
import { watchProfileAsync } from '../features/profile/profile.saga';
import { watchRegisterAsync } from '../features/registration/registration.saga';
import { watchUserInvoicesAsync } from '../features/user-invoices/user-invoices.saga';
import { watchUsersAsync } from '../features/users/users.saga';
import { watchAppAsync } from './app.saga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchPreRegistrationInfoAsync),
    call(watchRegisterAsync),
    call(watchLoginAsync),
    call(watchForgotPasswordAsync),
    call(watchInstituteSetupAsync),
    call(watchItSettingAsync),
    call(watchCentersAsync),
    call(watchCoursesAsync),
    call(watchClassesAsync),
    call(watchUsersAsync),
    call(watchInstituteHomeAsync),
    call(watchBatchesAsync),
    call(watchProfileAsync),
    call(watchContactUsAsync),
    call(watchUserInvoicesAsync),
    call(watchInstitutesAsync),
    call(watchAppAsync),
    call(watchAnnouncementsAsync),
    call(watchAttendancesAsync),
    call(watchAssignmentsAsync),
    call(watchNotificationAsync),
  ]);
}

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import FsLightbox from 'fslightbox-react';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion as ClassAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  InstituteWebsiteMediaType,
  LightBoxSlideType,
  UserRole,
} from '../../common/constants';
import {
  getCurrentYear,
  getSubdomain,
  getUrlParameterByName,
} from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AccordionHeader from '../../components/AccordionHeader';
import AppLoader from '../../components/AppLoader';
import {
  loadInstituteWebsiteBasicInfoDetails,
  loadInstituteWebsiteCenterDetails,
  loadInstituteWebsiteClassDetails,
  loadInstituteWebsiteCourseDetails,
  loadInstituteWebsiteDetails,
  loadInstituteWebsiteTeacherDetails,
} from './institute-home.slice';

const InstituteHomePage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    website,
    websiteBasicInfo,
    websiteCenterInfo,
    websiteCourseInfo,
    websiteClassInfo,
    websiteTeacherInfo,
    loading,
  } = useSelector((state: RootState) => state.instituteHome);
  const [loadRequested, setLoadRequested] = useState<boolean>();
  const subdomain = getSubdomain();

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadInstituteWebsiteDetails(subdomain));
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadInstituteWebsiteBasicInfoDetails(subdomain));
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadInstituteWebsiteCenterDetails({
          domain: subdomain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadInstituteWebsiteCourseDetails(subdomain));
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadInstituteWebsiteClassDetails({
          domain: subdomain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadInstituteWebsiteTeacherDetails({
          role: [UserRole.Teacher],
          domain: subdomain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  function setMainImage() {
    let mainImageSrc;
    if (
      websiteBasicInfo?.instituteTypeId.toString() ===
      `5f5b21862d85fa2764e1410c`
    ) {
      mainImageSrc = `${process.env.REACT_APP_IMAGE_PATH}website/institute-main-images/education/main-image.jpg`;
    } else if (
      websiteBasicInfo?.instituteTypeId.toString() ===
      `5f5b21862d85fa2764e1410a`
    ) {
      mainImageSrc = `${process.env.REACT_APP_IMAGE_PATH}website/institute-main-images/dance/main-image.jpg`;
    } else if (
      websiteBasicInfo?.instituteTypeId.toString() ===
      `5f5b21862d85fa2764e1410b`
    ) {
      mainImageSrc = `${process.env.REACT_APP_IMAGE_PATH}website/institute-main-images/sports/main-image.jpg`;
    } else if (
      websiteBasicInfo?.instituteTypeId.toString() ===
      `5f92ca01523d934234d6433d`
    ) {
      mainImageSrc = `${process.env.REACT_APP_IMAGE_PATH}website/institute-main-images/yoga/main-image.jpg`;
    } else if (
      websiteBasicInfo?.instituteTypeId.toString() ===
      `5fe97ea615b7d91b30197197`
    ) {
      mainImageSrc = `${process.env.REACT_APP_IMAGE_PATH}website/institute-main-images/other/main-image.jpg`;
    }
    return mainImageSrc;
  }

  function testimonialBgColor() {
    let testimonialClass;
    if (
      (website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.GalleryImage;
        }).length > 0) ||
      (website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.YoutubeUrl;
        }).length > 0)
    ) {
      testimonialClass = `testi bg-light p-4`;
    } else {
      testimonialClass = `testi p-4`;
    }

    return testimonialClass;
  }

  function contactUsBgColor() {
    let contactUsClass;
    if (website?.testimonial.length) {
      if (
        (website &&
          website.media.filter((media) => {
            return media.mediaType === InstituteWebsiteMediaType.GalleryImage;
          }).length > 0) ||
        (website &&
          website.media.filter((media) => {
            return media.mediaType === InstituteWebsiteMediaType.YoutubeUrl;
          }).length > 0)
      ) {
        contactUsClass = `contact p-4`;
      }
    } else if (!website?.testimonial.length) {
      if (
        website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.GalleryImage;
        }).length === 0 &&
        website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.YoutubeUrl;
        }).length === 0
      ) {
        contactUsClass = `contact p-4`;
      }
    } else {
      contactUsClass = `contact bg-light p-4`;
    }

    return contactUsClass;
  }

  const mediaGalleryItems = useMemo(() => {
    const galleryItems: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: any;
      url: string;
      previewUrl: string;
      slide: number;
    }[] = [];

    website?.media.forEach(({ mediaType, mediaUrl }) => {
      switch (mediaType) {
        case InstituteWebsiteMediaType.GalleryImage: {
          galleryItems.push({
            type: LightBoxSlideType.Image,
            url: mediaUrl,
            previewUrl: mediaUrl,
            slide: galleryItems.length + 1,
          });
          break;
        }
        case InstituteWebsiteMediaType.YoutubeUrl: {
          const videoId = getUrlParameterByName('v', mediaUrl);
          const previewUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

          galleryItems.push({
            type: LightBoxSlideType.Youtube,
            url: mediaUrl,
            previewUrl,
            slide: galleryItems.length + 1,
          });

          break;
        }
        default:
          break;
      }
    });

    return galleryItems;
  }, [website]);

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function renderMarkers(map: any, maps: any) {
    const geocoder = new maps.Geocoder();
    const centers = websiteCenterInfo || [];

    for (let i = 0; i < centers.length; i += 1) {
      const center = centers[i];
      let address = center.address.address1;

      if (center.address.address2) {
        address += `, ${center.address.address2}`;
      }

      address += `, ${center.address.zipcode}, <br />${center.address.city?.cityName}, ${center.address.state?.stateName}, ${center.address.country?.name}`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      geocoder.geocode({ address }, (results: any, status: any) => {
        if (status === 'OK') {
          const { location } = results[0].geometry;

          if (i === 0) {
            map.setCenter(location);
          }

          const marker = new maps.Marker({
            position: location,
            map,
            title: center.name,
          });

          const infowindow = new maps.InfoWindow({
            content: `
              <h3>${center.name}</h3>
              <p>${address}</p>
            `,
          });

          marker.addListener('click', () => {
            infowindow.open(map, marker);
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(
            `Geocode was not successful for the following reason: ${status}`,
          );
        }
      });
    }
  }

  if (!loadRequested || loading) {
    return <AppLoader />;
  }

  return (
    <div className="institute-home-page">
      {/* Header */}

      <section className="top-header institute-page-top-header" id="top">
        <img
          src={
            website?.defaultMainImageUrl
              ? website?.defaultMainImageUrl
              : setMainImage()
          }
          alt=""
          className="img-fluid"
        />
        <div className="header">
          <div className="container">
            <img
              className="logo bg-white"
              src={
                websiteBasicInfo?.logoUrl
                  ? websiteBasicInfo?.logoUrl
                  : `${process.env.REACT_APP_IMAGE_PATH}website/logo/default-logo.jpg`
              }
              alt="logo"
            />
            <nav className="py-3">
              <label htmlFor="drop" className="toggle">
                <span className="fa fa-bars" />
              </label>
              <input type="checkbox" id="drop" />
              <ul className="menu ml-auto mt-2">
                <li>
                  <a href="#about">
                    {LanguageTexts.instituteHomePage.menuAbout}
                  </a>
                </li>
                <li>
                  <a href="#courses">
                    {LanguageTexts.instituteHomePage.menuCourses}
                  </a>
                </li>
                <li>
                  <a href="#trainers">
                    {LanguageTexts.instituteHomePage.menuTrainers}
                  </a>
                </li>
                {(website &&
                  website.media.filter((media) => {
                    return (
                      media.mediaType === InstituteWebsiteMediaType.GalleryImage
                    );
                  }).length > 0) ||
                (website &&
                  website.media.filter((media) => {
                    return (
                      media.mediaType === InstituteWebsiteMediaType.YoutubeUrl
                    );
                  }).length > 0) ? (
                  <>
                    <li>
                      <a href="#image-gallery">
                        {LanguageTexts.instituteHomePage.menuGallery}
                      </a>
                    </li>
                  </>
                ) : null}
                {website?.testimonial.length ? (
                  <>
                    <li>
                      <a href="#testimonial">
                        {LanguageTexts.instituteHomePage.menuTestimonial}
                      </a>
                    </li>
                  </>
                ) : null}
                <li>
                  <a href="#contact">
                    {LanguageTexts.instituteHomePage.menuContact}
                  </a>
                </li>
                <li className="last-grid ml-2">
                  <Link to="/login" className="save-btn">
                    <span className="fa fa-user-lock text-white" />{' '}
                    {LanguageTexts.instituteHomePage.loginBtn}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      {/* //Header */}

      {/* About Section */}

      <section className="about p-4" id="about">
        <h3 className="heading mt-5">
          {LanguageTexts.instituteHomePage.aboutUs}
        </h3>
        <div className="container my-5">
          <div className="row">
            <div
              className={
                website?.aboutImageUrl
                  ? `col-lg-6 order-2 order-lg-1`
                  : `col-12`
              }
            >
              <h4 className="text-secondary mb-3">{website?.heading}</h4>
              {website?.description.map((description, i) => (
                <p className="mb-3" key={`desc${i}`}>
                  {description}
                </p>
              ))}
            </div>
            {website?.aboutImageUrl ? (
              <>
                <div className="col-lg-6 order-1 order-lg-2 pb-5">
                  <img
                    className="img-fluid rounded common-shadow about-image"
                    src={website?.aboutImageUrl}
                    alt="about-us"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>

      {/* //About Section */}

      {/* Centers */}

      <section className="centers bg-light p-4" id="centers">
        <h3 className="heading mt-5">
          {LanguageTexts.instituteHomePage.centers}
        </h3>

        <div className="row">
          <div className="col-md-12 text-center">
            <div className="center-map">
              {websiteCenterInfo?.length ? (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                    language: 'en',
                    region: 'US',
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{ lat: 39.06175, lng: -77.1796 }}
                  defaultZoom={10}
                  onGoogleApiLoaded={({ map, maps }) =>
                    renderMarkers(map, maps)
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>

      {/* //Centers */}

      {/* Courses */}

      <section className="courses p-4" id="courses">
        <h3 className="heading mt-5">
          {LanguageTexts.instituteHomePage.courses}
        </h3>

        <Accordion defaultActiveKey="0" className="courses-accordion">
          {websiteCourseInfo?.map((course, i) => (
            <Card key={`cours${i}`}>
              <Card.Header>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey={`${i}`}
                >
                  <AccordionHeader title={course.name} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`${i}`}>
                <Card.Body>
                  <div className="container my-5">
                    <div className="row">
                      {course.imageUrl ? (
                        <>
                          <div className="col-md-6 mb-5">
                            <img
                              className="img-fluid rounded common-shadow"
                              src={course.imageUrl}
                              alt={course.name}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className={course.imageUrl ? `col-md-6` : `col-12`}>
                        {course.description.map((description, j) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <p key={`desc${j}`} className="mb-3">
                            {description}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="my-4" />
                    {websiteClassInfo &&
                    websiteClassInfo.filter(({ courseId }) => {
                      return courseId === course._id;
                    }).length > 0 ? (
                      <>
                        <h4 className="text-center text-secondary subheading">
                          {LanguageTexts.instituteHomePage.classes}
                        </h4>
                        <div className="my-4" />
                        <ClassAccordion
                          preExpanded={['0']}
                          className="classes-accordion"
                        >
                          {websiteClassInfo?.map(
                            ({ courseId, name, description }, k) => {
                              return course._id === courseId ? (
                                <React.Fragment key={`ac${k}`}>
                                  <AccordionItem uuid={`${k}`}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        <p>{name}</p>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {description?.map((classDesc, l) => (
                                        <p
                                          key={`classDescr${l}`}
                                          className="mb-3"
                                        >
                                          {classDesc}
                                        </p>
                                      ))}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </React.Fragment>
                              ) : (
                                ''
                              );
                            },
                          )}
                        </ClassAccordion>
                      </>
                    ) : null}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </section>

      {/* //Courses */}

      {/* Trainers */}

      <section className="trainers bg-light p-4" id="trainers">
        <h3 className="heading mt-5">
          {LanguageTexts.instituteHomePage.trainers}
        </h3>

        <div className="row">
          {websiteTeacherInfo?.map((teacher, i) => {
            return (
              <div
                key={`trainer${i}`}
                className="col-lg-6 col-12 mb-4 trainers-info"
              >
                <div className="bg-white shadow p-3">
                  <div className="row">
                    <div className="col-lg-3 col-12 mb-lg-0 mb-4">
                      <img
                        width={120}
                        height={120}
                        className="rounded-circle mr-3"
                        src={
                          teacher.profilePicUrl !== null
                            ? teacher.profilePicUrl
                            : teacher.gender === 'Male'
                            ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/man.png`
                            : `${process.env.REACT_APP_IMAGE_PATH}website/profile/woman.png`
                        }
                        alt={teacher.name}
                      />
                    </div>
                    <div className="col-lg-9 col-12">
                      <h4 className="font-weight-bold mb-3">{teacher.name}</h4>
                      <p className="mb-3">{teacher.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* //Trainers */}

      {/* Image/Video Gallery */}

      {(website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.GalleryImage;
        }).length > 0) ||
      (website &&
        website.media.filter((media) => {
          return media.mediaType === InstituteWebsiteMediaType.YoutubeUrl;
        }).length > 0) ? (
        <>
          <section className="gallery p-4" id="image-gallery">
            <h3 className="heading mt-5">
              {LanguageTexts.instituteHomePage.gallery}
            </h3>

            <div className="image-gallery">
              {mediaGalleryItems.map(({ url, previewUrl, slide, type }) => {
                return (
                  <div key={`mg${slide}`} className="image-gallery-item">
                    <a
                      href={url}
                      onClick={(e) => {
                        e.preventDefault();
                        openLightboxOnSlide(slide);
                      }}
                    >
                      <img
                        src={previewUrl}
                        className="img-fluid"
                        alt="gallery-img"
                      />
                      {type === LightBoxSlideType.Youtube ? (
                        <i className="far fa-play-circle gallery-video-icon" />
                      ) : null}
                    </a>
                  </div>
                );
              })}
            </div>
          </section>
        </>
      ) : null}

      {/* //Image/Video Gallery */}

      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={mediaGalleryItems.map(({ url }) => url)}
        types={mediaGalleryItems.map(({ type }) => type)}
      />

      {/* Testimonial */}

      {website?.testimonial.length && website?.testimonial.length > 1 ? (
        <>
          <section className={testimonialBgColor()} id="testimonial">
            <h3 className="heading mt-5">
              {LanguageTexts.instituteHomePage.testimonial}
            </h3>

            <div className="container my-5 container-carousel">
              <Carousel controls indicators={false} interval={10000}>
                {website?.testimonial.map((testi, i) => (
                  <Carousel.Item key={`test${i}`}>
                    <div className="item carousel-item active">
                      {testi.commenterPicUrl ? (
                        <div className="img-box">
                          <img src={testi.commenterPicUrl} alt="" />
                        </div>
                      ) : (
                        <div className="img-box">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}website/testimonial/man.png`}
                            alt=""
                          />
                        </div>
                      )}
                      <p className="testimonial text-center">
                        {testi.comments}
                      </p>
                      <p className="overview font-weight-bold">
                        {testi.commenterName}
                      </p>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </section>
        </>
      ) : website?.testimonial.length ? (
        <>
          <section className={testimonialBgColor()} id="testimonial">
            <h3 className="heading mt-5">
              {LanguageTexts.instituteHomePage.testimonial}
            </h3>

            <div className="container my-5 container-carousel">
              <Carousel controls={false} indicators={false} interval={10000}>
                {website?.testimonial.map((testi, i) => (
                  <Carousel.Item key={`test${i}`}>
                    <div className="item carousel-item active">
                      {testi.commenterPicUrl ? (
                        <div className="img-box">
                          <img src={testi.commenterPicUrl} alt="" />
                        </div>
                      ) : (
                        <div className="img-box">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}website/testimonial/man.png`}
                            alt=""
                          />
                        </div>
                      )}
                      <p className="testimonial text-center">
                        {testi.comments}
                      </p>
                      <p className="overview font-weight-bold">
                        {testi.commenterName}
                      </p>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </section>
        </>
      ) : null}

      {/* //Testimonial */}

      {/* Contact Us */}

      <section className={contactUsBgColor()} id="contact">
        <h3 className="heading mt-5">
          {LanguageTexts.instituteHomePage.contactUs}
        </h3>

        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 text-center mb-3">
              <h5 className="common-text-color mb-4">
                <a
                  href={`mailto:${websiteBasicInfo?.email}`}
                  className="common-text-color"
                >
                  {websiteBasicInfo?.email}
                </a>
              </h5>
              <h5 className="text-secondary mb-4">{websiteBasicInfo?.phone}</h5>
              <h5 className="text-secondary mb-2">
                {`${websiteBasicInfo?.address.address1} ${', '} ${
                  websiteBasicInfo?.address.zipcode
                } ${', '}`}
              </h5>
              <h5 className="text-secondary mb-2">
                {`${websiteBasicInfo?.address.city?.cityName} ${', '} ${
                  websiteBasicInfo?.address.state?.stateName
                } ${', '} ${websiteBasicInfo?.address.country?.name}`}
              </h5>
            </div>
          </div>
        </div>
      </section>

      {/* //Contact Us Chain Bridge Rd, Fairfax, VA, USA */}

      {/* Copyright */}

      <div className="copyright">
        <div className="container py-4">
          <div className="row">
            <div className="col-lg-6 col-12">
              <p>
                {getCurrentYear(LanguageTexts.instituteHomePage.copyrightText)}{' '}
                <a
                  href={`${LanguageTexts.app.appHomePageLink}`}
                  target="_blank"
                >
                  {LanguageTexts.instituteHomePage.copyrightLinkText}
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-12 text-right">
              {websiteBasicInfo?.socialMedia.facebook ? (
                <a
                  href={`${websiteBasicInfo?.socialMedia.facebook}`}
                  target="_blank"
                >
                  <span className="fab fa-facebook-f text-white mr-3" />
                </a>
              ) : null}
              {websiteBasicInfo?.socialMedia.instagram ? (
                <>
                  <a
                    href={`${websiteBasicInfo?.socialMedia.instagram}`}
                    target="_blank"
                  >
                    <span className="fab fa-instagram text-white mr-3" />
                  </a>
                </>
              ) : null}
              {websiteBasicInfo?.socialMedia.youtube ? (
                <>
                  <a
                    href={`${websiteBasicInfo?.socialMedia.youtube}`}
                    target="_blank"
                  >
                    <span className="fab fa-youtube text-white mr-3" />
                  </a>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* //Copyright */}
      <div className="move-top text-right">
        <a href="#top" className="move-top font-weight-bold">
          <span className="fa fa-angle-up mb-3" aria-hidden="true" />
        </a>
      </div>
    </div>
  );
};

export default InstituteHomePage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadClasses } from './institute-classes.slice';

const InstituteClassListPage = (): JSX.Element => {
  const { classes: classesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { classes, loading } = useSelector((state: RootState) => state.classes);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: classesTxt.course,
        accessor: 'courseTitle',
      },
      {
        Header: classesTxt.class,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link to={`${AppRoutePath.ClassesUpdate.replace(':classId', _id)}`}>
              {name}
            </Link>
          );
        },
      },
    ],
    [classesTxt],
  );

  const data = React.useMemo(
    () =>
      classes?.map(({ course: courseInfo, ...restClass }) => {
        return {
          courseTitle: courseInfo?.name || '',
          ...restClass,
        };
      }),
    [classes],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadClasses({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{classesTxt.classes}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default InstituteClassListPage;

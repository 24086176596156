/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { RegistrationInput } from '../../common/types';
import ApiService from '../../services/api';
import { register, registerError, registerSuccess } from './registration.slice';

export function* registerAsync(action: PayloadAction<RegistrationInput>) {
  const { data, errors } = yield call(ApiService.register, action.payload);

  if (errors) {
    yield put(registerError(errors));
  } else {
    yield put(registerSuccess(!!data));
  }
}

export function* watchRegisterAsync() {
  yield takeEvery(register.toString(), registerAsync);
}

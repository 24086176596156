import moment from 'moment-timezone';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { PaymentMethodType, PaymentMode } from '../../common/constants';
import { calculateProcessingFees, setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState, UserInvoiceModel } from '../../common/types';

type UserInvoiceDetailProps = {
  invoice: UserInvoiceModel;
  paymentMode: PaymentMode;
  paymentMethod?: PaymentMethodType;
};

const UserInvoiceDetail: FC<UserInvoiceDetailProps> = ({
  invoice,
  paymentMode,
  paymentMethod,
}: UserInvoiceDetailProps): JSX.Element => {
  const { userInvoices: userInvoicesTxt } = LanguageTexts;
  const timezone = invoice.batch.center?.address.city?.timeZone || '';
  const dueDate = timezone
    ? moment.tz(setTimeToZero(invoice.dueDate), timezone).format('L')
    : '';
  const { stripe } = useSelector((state: RootState) => state.itSetting);

  let processingFee = 0;
  let totalAmount = 0;

  switch (paymentMethod) {
    case PaymentMethodType.Card: {
      const processingFeesPercent = stripe?.processingFees.percentage || 0;
      const processingFeesCent = stripe?.processingFees.cent || 0;

      processingFee =
        calculateProcessingFees(
          invoice.amountDue,
          processingFeesPercent,
          processingFeesCent,
        ) / 100;

      totalAmount = invoice.amountDue + processingFee;
      break;
    }

    case PaymentMethodType.ACH: {
      const processingFeesPercent = stripe?.processingFees.ach.percentage || 0;
      const processingFeesCent = stripe?.processingFees.ach.cent || 0;

      processingFee =
        calculateProcessingFees(
          invoice.amountDue,
          processingFeesPercent,
          processingFeesCent,
        ) / 100;

      totalAmount = invoice.amountDue + processingFee;
      break;
    }

    default:
      break;
  }

  return (
    <div className="payment-info-detail">
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">{userInvoicesTxt.studentName}</p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{invoice.user.name}</p>
        </div>
      </div>

      <div className="my-3" />

      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">{userInvoicesTxt.class}</p>
        </div>
        <div className="col-lg-9 col-12">
          <p>
            {invoice.batch.class?.name} ({invoice.batch.name})
          </p>
        </div>
      </div>

      <div className="my-3" />

      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">{userInvoicesTxt.dueDate}</p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{dueDate}</p>
        </div>
      </div>

      <div className="my-3" />

      {paymentMode === PaymentMode.Online ? (
        <>
          <div className="row">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{userInvoicesTxt.feesAmount}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>
                {invoice.currencySymbol}
                {invoice.amountDue.toFixed(2)}
              </p>
            </div>
          </div>

          {paymentMethod ? (
            <>
              <div className="my-3" />

              <div className="row">
                <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                  <p className="font-weight-bold">
                    {userInvoicesTxt.processingfeesAmount}
                  </p>
                </div>
                <div className="col-lg-9 col-12">
                  <p className="processing-fees-amount">
                    {invoice.currencySymbol}
                    {processingFee.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="my-3" />

              <div className="row">
                <div className="col-lg-3 col-12 mb-lg-0 mb-2">
                  <p className="font-weight-bold">
                    {userInvoicesTxt.totalfeesAmount}
                  </p>
                </div>
                <div className="col-lg-9 col-12">
                  <p className="total-fees-amount">
                    {invoice.currencySymbol}
                    {totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

UserInvoiceDetail.defaultProps = {
  paymentMethod: undefined,
};

export default UserInvoiceDetail;

import 'react-day-picker/lib/style.css';

import moment from 'moment-timezone';
import React, { FC } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';

import { setTimeToStart } from '../../../common/helper';

const todayStyle = `.DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  color: #212529;
  font-weight: 400;
}
`;

type AttendanceCalendarProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDays: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attendanceSubmitted: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attendancePending: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attendanceFuture: any[];
  selectedDay: moment.Moment | undefined;
  timezone: string;
  initialMonth: moment.Moment;
  onChange: (newValues: moment.Moment) => void;
};

const AttendanceCalendar: FC<AttendanceCalendarProps> = ({
  disabledDays,
  attendanceSubmitted,
  attendancePending,
  attendanceFuture,
  selectedDay,
  timezone,
  initialMonth,
  onChange,
}: AttendanceCalendarProps): JSX.Element => {
  const today = moment(
    setTimeToStart(
      moment.tz(undefined, timezone).format(moment.HTML5_FMT.DATE),
    ),
  ).toDate();

  function handleDayClick(
    dayInit: Date,
    { disabled: dayDisabled }: DayModifiers,
  ) {
    if (dayDisabled) {
      return;
    }

    const day = moment(
      setTimeToStart(moment(dayInit).format(moment.HTML5_FMT.DATE)),
    );

    onChange(day);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modifiers: any = {
    attendanceSubmitted,
    attendancePending,
    attendanceFuture,
    today: [today],
  };

  if (selectedDay) {
    modifiers.selectedDay = [selectedDay.toDate()];
  }

  const modifiersStyles = {
    attendanceSubmitted: {
      color: 'white',
      backgroundColor: '#28a745',
    },
    attendancePending: {
      color: 'white',
      backgroundColor: '#dc3545',
    },
    attendanceFuture: {
      color: 'white',
      backgroundColor: '#33cccc',
    },
    today: {
      color: 'white',
      backgroundColor: '#6c757d',
      fontWeight: 'bold',
    },
    selectedDay: {
      fontWeight: 'bold',
    },
  };

  return (
    <>
      <style>{todayStyle}</style>
      <DayPicker
        initialMonth={initialMonth.toDate()}
        disabledDays={disabledDays}
        selectedDays={
          selectedDay
            ? [
                moment(
                  setTimeToStart(selectedDay.format(moment.HTML5_FMT.DATE)),
                ).toDate(),
              ]
            : []
        }
        onDayClick={handleDayClick}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        showOutsideDays
        enableOutsideDaysClick={false}
      />
    </>
  );
};

export default AttendanceCalendar;

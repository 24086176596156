/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  AppRoutePath,
  BatchScheduleType,
  UserRole,
} from '../../common/constants';
import { setTimeToEnd, setTimeToStart } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  CreateAssignmentInput,
  RootState,
  UserModel,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AssignmentForm from './AssignmentForm';
import {
  loadAssignmentDeps,
  loadAssignments,
  resetAddAssignmentSuccess,
  resetLoadAssignmentDeps,
  resetLoadAssignments,
} from './assignments.slice';
import AssignmentCalendar from './components/AssignmentCalendar';

type AssignmentRecordPageParams = {
  onSubmit: (data: CreateAssignmentInput) => void;
  errors: string[];
  loading: boolean;
};

const AssignmentRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: AssignmentRecordPageParams): JSX.Element => {
  const { assignments: assignmentsTxt, app: appTxt } = LanguageTexts;

  const {
    filter: { batchId: selectedBatch, role: selectedRole },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [assignmentDepsLoadRequested, setAssignmentDepsRequested] = useState<
    boolean
  >();
  const [assignmentsLoadRequested, setAssignmentsRequested] = useState<
    boolean
  >();
  const [assignmentDate, setAssignmentDate] = useState<moment.Moment>();
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading: loadAssignmentsLoading,
    errors: loadAssignmentsErrors,
    success: loadAssignmentsSuccess,
    loadAssignmentDeps: {
      loading: loadAssignmentDepsLoading,
      errors: loadAssignmentDepsErrors,
      success: loadAssignmentDepsSuccess,
    },
    assignments,
    students,
    batch,
  } = useSelector((state: RootState) => state.assignments);

  useEffect(() => {
    if (!assignmentsLoadRequested && selectedBatch) {
      dispatch(loadAssignments({ batchIds: [selectedBatch] }));
      setAssignmentsRequested(true);
    }
  }, [assignmentsLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAssignmentsErrors.length > 0) {
      dispatch(resetLoadAssignments());
      history.push(AppRoutePath.Assignments);
    } else if (loadAssignmentsSuccess) {
      dispatch(resetLoadAssignments());
    }
  }, [loadAssignmentsErrors, loadAssignmentsSuccess, dispatch, history]);

  // load assignment deps
  useEffect(() => {
    if (!assignmentDepsLoadRequested) {
      dispatch(
        loadAssignmentDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Student],
          batchId: selectedBatch || '',
        }),
      );
      setAssignmentDepsRequested(true);
    }
  }, [currentUser, assignmentDepsLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAssignmentDepsErrors.length > 0) {
      dispatch(resetLoadAssignmentDeps());
      history.push(AppRoutePath.Assignments);
    } else if (loadAssignmentDepsSuccess) {
      dispatch(resetLoadAssignmentDeps());
    }
  }, [loadAssignmentDepsSuccess, loadAssignmentDepsErrors, dispatch, history]);

  if (
    !assignmentsLoadRequested ||
    loadAssignmentsLoading ||
    !assignmentDepsLoadRequested ||
    loadAssignmentDepsLoading
  ) {
    return <AppLoader />;
  }

  if (!batch || !assignments) {
    return <p>{appTxt.notFound}</p>;
  }

  const timezone = batch.center?.address.city?.timeZone || '';
  const startDate = moment(setTimeToStart(batch.startDate));
  const endDate = moment(setTimeToEnd(batch.endDate));
  const today = moment(
    setTimeToEnd(moment.tz(undefined, timezone).format(moment.HTML5_FMT.DATE)),
  );

  let filteredStudents: UserModel[] = [];

  if (students) {
    filteredStudents = students.filter(({ batches: sBatches }) => {
      return (
        !!sBatches?.find(({ batchId }) => selectedBatch === batchId) || false
      );
    });
  }

  const disabledDays = [
    {
      before: startDate.toDate(),
      after: endDate.toDate(),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentPendingDays: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentSubmittedDays: any[] = assignments.map(
    ({ assignmentDate: atDate }) => moment(setTimeToStart(atDate)).toDate(),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentFutureDays: any[] = [];

  if (batch.scheduleType === BatchScheduleType.Weekly) {
    const daysOfWeek = batch.schedule.map(({ day }) => parseInt(day, 10));

    const initDate = startDate.clone();

    while (initDate.isSameOrBefore(endDate)) {
      const curDate = initDate.clone();

      if (daysOfWeek.includes(curDate.weekday())) {
        if (curDate.isAfter(today)) {
          assignmentFutureDays.push(curDate.toDate());
        } else {
          const assignment = assignments.find(
            ({ assignmentDate: atDate }) =>
              curDate.format(moment.HTML5_FMT.DATE) === atDate,
          );

          if (!assignment) {
            assignmentPendingDays.push(curDate.toDate());
          }
        }
      }

      initDate.add(1, 'day');
    }
  } else if (batch.scheduleType === BatchScheduleType.Custom) {
    batch.schedule.forEach(({ day }) => {
      const curDate = moment(setTimeToStart(day));

      if (curDate.isAfter(today)) {
        assignmentFutureDays.push(curDate.toDate());
      } else {
        const assignment = assignments.find(
          ({ assignmentDate: atDate }) =>
            curDate.format(moment.HTML5_FMT.DATE) === atDate,
        );

        if (!assignment) {
          assignmentPendingDays.push(curDate.toDate());
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let assignmentInfo: any;

  if (assignments && assignmentDate) {
    const assignment = assignments.find(
      ({ assignmentDate: atDate }) =>
        assignmentDate.format(moment.HTML5_FMT.DATE) === atDate,
    );
    assignmentInfo = assignment
      ? {
          ...assignment,
          assignmentDate: moment(setTimeToStart(assignment.assignmentDate)),
          assignmentDueDate: moment(
            setTimeToStart(assignment.assignmentDueDate),
          ),
        }
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assignmentInfoInit: any = assignmentInfo || {
    batchId: batch._id,
    assignmentDate,
    assignmentDueDate: undefined,
    students: [],
    studentsExcluded: [],
    description: '',
    assignmentSubmissionFormat: undefined,
    assignmentUrl: '',
    assignmentFileUrl: '',
    assignmentFile: undefined,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(input: any) {
    onSubmitParent({
      _id: input._id,
      assignmentDate: input.assignmentDate.format(moment.HTML5_FMT.DATE),
      assignmentDueDate:
        input.assignmentDueDate?.format(moment.HTML5_FMT.DATE) || '',
      batchId: input.batchId,
      students: input.students,
      studentsExcluded: input.studentsExcluded,
      assignmentFile: input.assignmentFile,
      assignmentSubmissionFormat: input.assignmentSubmissionFormat,
      assignmentUrl: input.assignmentUrl,
      assignmentFileUrl: input.assignmentFileUrl,
      description: input.description,
    });
  }

  function handleOnChange(newVal: moment.Moment) {
    dispatch(resetAddAssignmentSuccess());
    setAssignmentDate(undefined);
    setTimeout(() => setAssignmentDate(newVal));
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 new-assignment">
      <h1 className="primary-heading mb-4">{assignmentsTxt.newAssignment}</h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="row">
        <div className="col-xl-6 col-12 mb-xl-0 mb-4">
          <AssignmentCalendar
            disabledDays={disabledDays}
            selectedDay={assignmentDate}
            initialMonth={startDate}
            assignmentPending={assignmentPendingDays}
            assignmentFuture={assignmentFutureDays}
            assignmentSubmitted={assignmentSubmittedDays}
            onChange={handleOnChange}
            timezone={timezone}
          />
          <div className="my-4">
            <p className="d-flex align-items-center">
              <span className="indication submitted-form mr-2" />
              {assignmentsTxt.indicatesCreated}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication pending-form mr-2" />
              {assignmentsTxt.indicatesNotCreated}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication future-schedule mr-2" />
              {assignmentsTxt.indicatesNextSchedule}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication current-day mr-2" />
              {assignmentsTxt.indicatesCurrentDay}
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-12">
          <div className="form">
            {assignmentDate && currentUser ? (
              <AssignmentForm
                onSubmit={onSubmit}
                initialValues={assignmentInfoInit}
                filteredStudents={filteredStudents}
                errors={errors}
                loading={loading}
                userRoles={userRoles}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AssignmentRecordPage;

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { FILTER_KEY, TOKEN_KEY, UserRole } from '../common/constants';
import { getSubdomain } from '../common/helper';
import LanguageTexts from '../common/language';
import { RootState, SelectedFilterInput } from '../common/types';
import AppLoader from '../components/AppLoader';
import {
  loadInstituteWebsiteBasicInfoDetails,
  loadInstituteWebsiteCenterDetails,
  loadInstituteWebsiteCourseDetails,
} from '../features/institute-home/institute-home.slice';
import { itSetting } from '../features/it-setting/it-setting.slice';
import { whoami, whoamiSuccess } from '../features/login/login.slice';
import { setSelectedFilter } from './app.slice';
import RootRoutes from './rootRoutes';

const promiseStripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

function App(): JSX.Element {
  const accessToken = localStorage.getItem(TOKEN_KEY);
  const dispatch = useDispatch();
  const { app: appTxt } = LanguageTexts;
  const {
    user,
    userCheckDone,
    whoamiState: { loading: whoAmILoading },
  } = useSelector((state: RootState) => state.login);
  const {
    loaded: itSettingLoaded,
    loading: itSettingLoading,
    reservedSubdomain,
  } = useSelector((state: RootState) => state.itSetting);
  const { loading: appLoading, filter } = useSelector(
    (state: RootState) => state.app,
  );
  const {
    websiteBasicInfo,
    websiteCourseInfo,
    websiteCenterInfo,
  } = useSelector((state: RootState) => state.instituteHome);

  const [filterLoaded, setFilterLoaded] = useState(false);
  const [websiteInfoLoadRequested, setWebsiteInfoLoadRequested] = useState(
    false,
  );

  useEffect(() => {
    if (!accessToken) {
      dispatch(whoamiSuccess(null));
    } else if (accessToken && !user) {
      dispatch(whoami());
    }
  }, [accessToken, user, dispatch]);

  useEffect(() => {
    if (!itSettingLoaded && !itSettingLoading) {
      dispatch(itSetting());
    }
  }, [itSettingLoaded, itSettingLoading, dispatch]);

  useEffect(() => {
    function handleSidemenuShowHide() {
      if (window.innerWidth <= 992) {
        document.body.classList.add('small-device');
      } else {
        document.body.classList.remove('small-device');
      }
    }
    window.addEventListener('resize', handleSidemenuShowHide);
  });

  // restore filters from local storage
  useEffect(() => {
    if (!accessToken) {
      setFilterLoaded(true);
    } else if (user && !filterLoaded) {
      const filtersData = localStorage.getItem(FILTER_KEY);
      const filters = filtersData ? JSON.parse(filtersData) : null;

      if (filters) {
        dispatch(
          setSelectedFilter({
            ...filters,
          }),
        );
      }

      setFilterLoaded(true);
    }
  }, [user, accessToken, filterLoaded, dispatch]);

  // init filter based on user role if empty
  useEffect(() => {
    if (
      user &&
      filterLoaded &&
      user.role.indexOf(UserRole.Admin) === -1 &&
      user.role.indexOf(UserRole.Owner) === -1 &&
      !filter.role &&
      !filter.batchId &&
      !filter.centerId &&
      !filter.childrenId
    ) {
      const initFilters: SelectedFilterInput = {
        batchId: null,
        centerId: null,
        childrenId: null,
        role: null,
      };

      if (user.role.indexOf(UserRole.CenterHead) !== -1) {
        initFilters.centerId = user.centerIds?.[0] || null;

        if (user.role.indexOf(UserRole.Teacher) !== -1) {
          initFilters.batchId = user.batches?.[0].batch._id || null;
        }
      } else if (
        user.role.indexOf(UserRole.Teacher) !== -1 ||
        user.role.indexOf(UserRole.Student) !== -1
      ) {
        initFilters.batchId = user.batches?.[0].batch._id || null;
      } else if (user.role.indexOf(UserRole.Parent) !== -1) {
        const firstChild = user.children?.[0] || null;

        if (firstChild) {
          initFilters.childrenId = firstChild._id;
          initFilters.batchId = firstChild.batches?.[0].batch._id || null;
        } else {
          initFilters.childrenId = ' ';
          initFilters.batchId = ' ';
        }
      }

      dispatch(
        setSelectedFilter({
          ...initFilters,
        }),
      );
    }
  }, [filter, user, filterLoaded, dispatch]);

  const subdomain = getSubdomain();
  const isRootDomain = !!(
    reservedSubdomain && reservedSubdomain.indexOf(subdomain) !== -1
  );

  useEffect(() => {
    if (itSettingLoaded && !isRootDomain && !websiteInfoLoadRequested) {
      dispatch(loadInstituteWebsiteBasicInfoDetails(subdomain));
      dispatch(loadInstituteWebsiteCourseDetails(subdomain));
      dispatch(
        loadInstituteWebsiteCenterDetails({
          domain: subdomain,
        }),
      );
      setWebsiteInfoLoadRequested(true);
    }
  }, [
    itSettingLoaded,
    websiteInfoLoadRequested,
    isRootDomain,
    subdomain,
    dispatch,
  ]);

  const { metaTitle, metaDescription, metaKeywords } = useMemo(() => {
    let metaTitleData = appTxt.metaTitle;
    let metaDescriptionData = appTxt.metaDescription;
    let metaKeywordsData = appTxt.metaKeywords;

    if (!isRootDomain) {
      if (websiteBasicInfo) {
        const instituteAddress = websiteBasicInfo.address;

        const address = `${instituteAddress.city?.cityName}, ${instituteAddress.state?.stateName}, ${instituteAddress.country?.name}`;

        metaTitleData = `${websiteBasicInfo.instituteType.name} ${appTxt.institute} ${appTxt.of} ${address} | ${websiteBasicInfo.name}`;
        metaDescriptionData = `${websiteBasicInfo.instituteType.name} ${appTxt.institute} ${appTxt.of} ${address}`;
        metaKeywordsData = `${websiteBasicInfo.instituteType.name} ${appTxt.classesFor}`;

        if (websiteCourseInfo && websiteCourseInfo.length > 0) {
          const coursesTxt = websiteCourseInfo
            .map(({ name }) => name)
            .join(', ');

          metaDescriptionData += ` ${appTxt.specializedIn} ${coursesTxt}.`;
          metaKeywordsData += `, ${coursesTxt}`;
        }

        if (websiteCenterInfo && websiteCenterInfo.length > 0) {
          const centerAddressesTxt = websiteCenterInfo
            .map(
              (center) =>
                `${center.address.city?.cityName}, ${center.address.state?.stateName}`,
            )
            .join(', ');

          metaDescriptionData += ` ${appTxt.conductingClasses} ${centerAddressesTxt}.`;
        }
      }
    }

    return {
      metaTitle: metaTitleData,
      metaDescription: metaDescriptionData,
      metaKeywords: metaKeywordsData,
    };
  }, [
    appTxt,
    isRootDomain,
    websiteBasicInfo,
    websiteCourseInfo,
    websiteCenterInfo,
  ]);

  if (
    appLoading ||
    whoAmILoading ||
    !userCheckDone ||
    !itSettingLoaded ||
    !filterLoaded
  ) {
    return <AppLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <Elements stripe={promiseStripe}>
        <RootRoutes />
        <ToastContainer />
      </Elements>
    </>
  );
}

export default App;

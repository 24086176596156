import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import Select from 'react-select';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  BatchModel,
  CenterModel,
  CreateAnnouncementInput,
  UserModel,
} from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import ExpiryDatePicker from './components/ExpiryDatePicker';

type AnnouncementProps = {
  onSubmit: (data: CreateAnnouncementInput) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateAnnouncementInput;
  centers: CenterModel[];
  batches: BatchModel[];
  students: UserModel[];
};

const AnnouncementForm: React.FC<AnnouncementProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  centers,
  batches,
  students,
}: AnnouncementProps): JSX.Element => {
  const { announcements: announcementsTxt } = LanguageTexts;

  // drp maps
  const mapCenterOption = ({ _id, name }: CenterModel) => {
    return { value: _id, label: name };
  };
  const mapBatchOption = ({
    _id,
    name,
    centerId,
    class: classObj,
  }: BatchModel) => {
    return { value: _id, label: `${classObj?.name || ''} - ${name}`, centerId };
  };
  const mapStudentOption = ({ _id, name, batches: stuBatches }: UserModel) => {
    return {
      value: _id,
      label: name,
      batchIds: stuBatches?.map(({ batchId }) => batchId) || [],
    };
  };

  // drp state
  const [selectedCenters, setSelectedCenters] = useState(
    centers
      .filter(({ _id }) => initialValues.centerIds.indexOf(_id) !== -1)
      .map(mapCenterOption),
  );

  const [selectedBatches, setSelectedBatches] = useState(
    batches
      .filter(({ _id }) => initialValues.batchIds.includes(_id))
      .map(mapBatchOption),
  );
  const [selectedStudents, setSelectedStudents] = useState(
    students
      .filter(({ _id }) => {
        return initialValues.userIds
          ? initialValues.userIds.includes(_id)
          : false;
      })
      .map(mapStudentOption),
  );

  // drp options
  const centersOptions = centers.map(({ _id, name }) => {
    return { value: _id, label: name };
  });
  const batchesOptions = batches.map(mapBatchOption).filter(({ centerId }) => {
    return selectedCenters
      ? !!selectedCenters.find(({ value }) => value === centerId)
      : false;
  });
  const studentsOptions = students
    .map(mapStudentOption)
    .filter(({ batchIds }) =>
      selectedBatches
        ? !!selectedBatches.find(({ value }) => batchIds.includes(value))
        : false,
    );

  // drp listeners
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCenterChange(val: any) {
    setSelectedCenters(val);
    setSelectedBatches([]);
    setSelectedStudents([]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onBatchChange(val: any) {
    setSelectedBatches(val);
    setSelectedStudents([]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onStudentChange(val: any) {
    setSelectedStudents(val);
  }

  const { ...initVals } = initialValues;

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        input.centerIds = selectedCenters?.map(({ value }) => value) || [];
        input.batchIds = selectedBatches?.map(({ value }) => value) || [];
        input.userIds = selectedStudents?.map(({ value }) => value) || [];

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
          centerIds: undefined,
          batchIds: undefined,
        });
        errorKeys.push({ langKey: 'input.batchIds', errKey: 'input.batchIds' });
        errorKeys.push({
          langKey: 'input.centerIds',
          errKey: 'input.centerIds',
        });
        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          LanguageTexts.announcements,
        );

        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12 my-lg-0 my-3">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={announcementsTxt}
                  id="input.centerIds"
                  required
                />
                <Select
                  closeMenuOnSelect={false}
                  value={selectedCenters}
                  options={centersOptions}
                  onChange={onCenterChange}
                  isMulti
                />
              </div>
              <div className="col-lg-6 col-12">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={announcementsTxt}
                  id="input.batchIds"
                  required
                />
                <Select
                  closeMenuOnSelect={false}
                  value={selectedBatches}
                  options={batchesOptions}
                  onChange={onBatchChange}
                  isMulti
                />
              </div>
            </div>
            <div className="my-3" />
            <div className="row">
              <div className="col-lg-6 col-12 mb-lg-0 mb-3">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={announcementsTxt}
                  id="input.userIds"
                />
                <Select
                  closeMenuOnSelect={false}
                  value={selectedStudents}
                  options={studentsOptions}
                  onChange={onStudentChange}
                  placeholder={
                    selectedStudents && selectedStudents.length > 0
                      ? ''
                      : announcementsTxt.allStudents
                  }
                  isMulti
                />
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={announcementsTxt}
                    id="input.heading"
                    required
                  />
                  <Field name="heading" type="text" className="form-control" />
                </div>
              </div>
            </div>
            <div className="my-3" />
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={announcementsTxt}
                    id="input.description"
                    required
                  />
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    rows={5}
                  />
                  <div className="row">
                    <div className="col-12 text-right">
                      <p className="small common-text-color short-desc mt-1">
                        {announcementsTxt.maxCharNote}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={announcementsTxt}
                    id="input.expiresAt"
                    required
                  />
                  <ExpiryDatePicker
                    value={
                      values.expiresAt ? moment(values.expiresAt) : undefined
                    }
                    onChange={(newValue) => {
                      setFieldValue(
                        'expiresAt',
                        newValue?.toISOString() || undefined,
                      );
                    }}
                    disabledDays={[]}
                  />
                </div>
              </div>
            </div>
            <div className="my-3" />
            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12" />
              <div className="col-lg-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {announcementsTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AnnouncementForm;

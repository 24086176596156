import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { AppRoutePath, PublicRoutePath } from '../common/constants';
import { getPermissionsForRoute, getSubdomain } from '../common/helper';
import { AppRoutePermissions, RootState } from '../common/types';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import AnnouncementsPage from '../features/announcements/AnnouncementsPage';
import AssignmentsPage from '../features/assignments/AssignmentsPage';
import AttendancesPage from '../features/attendances/AttendancesPage';
import BatchesPage from '../features/batches/BatchesPage';
import ContactUsPage from '../features/contact-us/ContactUsPage';
import DashboardPage from '../features/dashboard/DashboardPage';
import ForgotPasswordPage from '../features/forgot-password/ForgotPasswordPage';
import HomePage from '../features/home/HomePage';
import InstituteCentersPage from '../features/institute-centers/InstituteCentersPage';
import InstituteCoursesPage from '../features/institute-courses/InstituteCoursesPage';
import InstituteClassesPage from '../features/institute-classes/InstituteClassesPage';
import InstituteHomePage from '../features/institute-home/InstituteHomePage';
import InstituteSetupPage from '../features/institute-setup/InstituteSetupPage';
import InstitutesPage from '../features/institutes/InstitutesPage';
import LoginPage from '../features/login/LoginPage';
import PrivacyPolicyPage from '../features/privacy-policy/PrivacyPolicyPage';
import ProfilePage from '../features/profile/ProfilePage';
import RegistrationPage from '../features/registration/RegistrationPage';
import UserInvoicesPage from '../features/user-invoices/UserInvoicesPage';
import UsersPage from '../features/users/UsersPage';
import history from './history';

function RootRoutes(): JSX.Element {
  const { routePermissions, reservedSubdomain } = useSelector(
    (state: RootState) => state.itSetting,
  );

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  const subdomain = getSubdomain();
  const isRootDomain = !!(
    reservedSubdomain && reservedSubdomain.indexOf(subdomain) !== -1
  );

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PublicRoute path={PublicRoutePath.Register}>
          <RegistrationPage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.Login}>
          <LoginPage />
        </PublicRoute>
        <PublicRoute path={PublicRoutePath.ForgotPass}>
          <ForgotPasswordPage />
        </PublicRoute>
        <Route path={PublicRoutePath.PrivacyPolicy}>
          <PrivacyPolicyPage />
        </Route>
        <PrivateRoute
          path={AppRoutePath.Dashboard}
          permissions={rPermission(AppRoutePath.Dashboard)}
        >
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.InstituteSetup}
          permissions={rPermission(AppRoutePath.InstituteSetup)}
        >
          <InstituteSetupPage />
        </PrivateRoute>
        <Route path={AppRoutePath.Centers}>
          <InstituteCentersPage />
        </Route>
        <Route path={AppRoutePath.Courses}>
          <InstituteCoursesPage />
        </Route>
        <Route path={AppRoutePath.Classes}>
          <InstituteClassesPage />
        </Route>
        <PrivateRoute
          path={AppRoutePath.ContactUs}
          permissions={rPermission(AppRoutePath.ContactUs)}
        >
          <ContactUsPage />
        </PrivateRoute>
        <Route path={AppRoutePath.Users}>
          <UsersPage />
        </Route>
        <Route path={AppRoutePath.Batches}>
          <BatchesPage />
        </Route>
        <Route path={AppRoutePath.UserInvoices}>
          <UserInvoicesPage />
        </Route>
        <Route path={AppRoutePath.Institutes}>
          <InstitutesPage />
        </Route>
        <Route path={AppRoutePath.Announcements}>
          <AnnouncementsPage />
        </Route>
        <Route path={AppRoutePath.Attendances}>
          <AttendancesPage />
        </Route>
        <Route path={AppRoutePath.Assignments}>
          <AssignmentsPage />
        </Route>
        <PrivateRoute
          path={AppRoutePath.Profile}
          permissions={rPermission(AppRoutePath.Profile)}
        >
          <ProfilePage />
        </PrivateRoute>
        <PublicRoute path="/">
          {isRootDomain ? <HomePage /> : <InstituteHomePage />}
        </PublicRoute>
      </Switch>
    </ConnectedRouter>
  );
}

export default RootRoutes;

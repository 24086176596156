/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageTexts from '../common/language';

type ImageUploadProps = {
  id: string;
  label: string;
  onFileSelect: (image: File | null) => void;
  onRemoveUrl?: undefined | (() => void);
  url?: string | null;
  required?: boolean;
  maxFileSize?: number | null;
  fileResolution?: string | null;
  disabled?: boolean;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  id,
  label,
  onFileSelect,
  onRemoveUrl,
  url,
  required,
  maxFileSize,
  fileResolution,
  disabled,
}: ImageUploadProps): JSX.Element => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

  function removePreviewImage(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    setPreviewImage(null);
    onFileSelect(null);
  }

  function removeImageUrl(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    if (onRemoveUrl) {
      onRemoveUrl();
    }
  }

  function onFileChange({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && files.length > 0) {
      setMaxSizeErr(null);

      const file = files[0];
      const sizeInKB = Math.round(file.size / 1024);

      if (maxFileSize && sizeInKB > maxFileSize) {
        setMaxSizeErr(
          LanguageTexts.app.maxSizeErr.replace('{size}', `${maxFileSize}KB`),
        );
        return;
      }

      const reader = new FileReader();

      reader.onload = function onLoad() {
        setPreviewImage(reader.result ? reader.result.toString() : null);
        onFileSelect(file);
      };

      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      {previewImage || url ? (
        <div className="row">
          <div className="col-lg-5 col-12 uploaded-image">
            <img
              src={previewImage || url || ''}
              alt=""
              className="shadow rounded-sm"
            />
            {previewImage ? (
              <Link
                className="remove-pic-btn"
                onClick={removePreviewImage}
                to="/"
              >
                x
              </Link>
            ) : null}
            {onRemoveUrl && !previewImage && url ? (
              <Link className="remove-pic-btn" onClick={removeImageUrl} to="/">
                x
              </Link>
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="upload-image-field">
        <div className="form-group">
          <label
            className={maxSizeErr ? '' : 'text-secondary small'}
            style={maxSizeErr ? { color: 'red' } : {}}
          >
            {label}
            {required ? (
              <sup className="common-text-color require-field">
                <span className="fa fa-star-of-life" />
              </sup>
            ) : null}
          </label>
          {maxSizeErr ? <p>{maxSizeErr}</p> : null}
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                accept="image/*"
                className="custom-file-input"
                id={id}
                aria-describedby="inputGroupFileAddon04"
                onChange={onFileChange}
                disabled={disabled}
              />
              <label htmlFor={id} className="custom-file-label" />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {fileResolution ? (
                <p className="small common-text-color short-desc mt-1">
                  {LanguageTexts.app.resolution} {fileResolution}
                </p>
              ) : null}
            </div>
            <div className="col-6 text-right">
              {maxFileSize ? (
                <p className="small common-text-color short-desc mt-1">
                  {LanguageTexts.app.maxSize} {maxFileSize}KB
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ImageUpload.defaultProps = {
  url: null,
  maxFileSize: null,
  fileResolution: null,
  required: false,
  disabled: false,
  onRemoveUrl: undefined,
};

export default ImageUpload;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseState, RegistrationInput } from '../../common/types';

const initialState: BaseState = {
  loading: false,
  success: null,
  errors: [],
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    register(state, action: PayloadAction<RegistrationInput>) {
      state.loading = true;
    },
    registerSuccess(state, action: PayloadAction<boolean>) {
      state.loading = false;
      state.success = action.payload;
    },
    registerError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  register,
  registerSuccess,
  registerError,
} = registrationSlice.actions;

export const {
  name: registrationSliceName,
  reducer: registrationSliceReducer,
} = registrationSlice;

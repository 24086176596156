/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import {
  CityModel,
  InstituteBasicInfoInput,
  InstituteTypeModel,
} from '../../../common/types';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import ImageUpload from '../../../components/ImageUpload';

type BasicInformationProps = {
  onSubmit: (data: InstituteBasicInfoInput) => void;
  onRemoveLogo: () => void;
  errors: string[];
  loading: boolean;
  initialValues: InstituteBasicInfoInput;
  instituteTypes: InstituteTypeModel[];
  cities: CityModel[];
};

const BasicInformationForm: React.FC<BasicInformationProps> = ({
  onSubmit,
  onRemoveLogo,
  errors,
  loading,
  initialValues,
  instituteTypes,
  cities,
}: BasicInformationProps): JSX.Element => {
  const {
    instituteBasicInformation: instituteBasicInformationTxt,
  } = LanguageTexts;

  const { app: appTxt } = LanguageTexts;

  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
  const { logoUrl, ...initVals } = initialValues;

  const cityOptions = cities.map((city) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id,
      cityName,
      state: { stateName },
      country: { name: countryName },
    } = city;
    return {
      value: _id,
      label: `${cityName}, ${stateName}, ${countryName}`,
      cityObj: city,
    };
  });

  const [selectedCity, setSelectedCity] = useState(
    cityOptions.find(({ value }) => value === initialValues.address.cityId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCityChange(val: any) {
    setSelectedCity(val);
  }

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };
        if (selectedLogo) {
          input.logo = selectedLogo;
        }

        input.address.cityId = selectedCity?.cityObj?._id || '';
        input.address.stateId = selectedCity?.cityObj?.state?._id || '';
        input.address.countryId = selectedCity?.cityObj?.country?._id || '';

        onSubmit(input);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          LanguageTexts.instituteBasicInformation,
        );

        return (
          <Form>
            <div className="form">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <ImageUpload
                    id="basic-info-logo"
                    label={instituteBasicInformationTxt['input.logo']}
                    onFileSelect={(file) => {
                      setSelectedLogo(file);
                    }}
                    url={logoUrl}
                    maxFileSize={250}
                    fileResolution={appTxt.resolutionSize}
                    disabled={loading}
                    onRemoveUrl={() => onRemoveLogo()}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="input.name"
                      required
                    />
                    <Field name="name" type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={instituteBasicInformationTxt}
                    id="input.instituteTypeId"
                    required
                  />
                  <select
                    name="instituteTypeId"
                    value={values.instituteTypeId}
                    onChange={handleChange}
                    className="custom-select select-institute-type"
                  >
                    <option value="">
                      {instituteBasicInformationTxt['input.instituteTypeId']}
                    </option>
                    {instituteTypes.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="input.email"
                      required
                    />
                    <Field
                      name="email"
                      type="text"
                      className="form-control tolowercase-text"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="input.phone"
                      required
                    />
                    <Field name="phone" type="text" className="form-control" />
                    <p className="small common-text-color short-desc mt-1">
                      {appTxt.phoneNote}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="address.address1"
                      required
                    />
                    <Field
                      name="address.address1"
                      type="text"
                      className="form-control"
                    />
                    <p className="small common-text-color short-desc mt-1">
                      {instituteBasicInformationTxt.address1Note}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="address.address2"
                    />
                    <Field
                      name="address.address2"
                      type="text"
                      className="form-control"
                    />
                    <p className="small common-text-color short-desc mt-1">
                      {instituteBasicInformationTxt.address2Note}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="address.csc"
                      errId="address.cityId"
                      required
                    />
                    <Select
                      value={selectedCity}
                      options={cityOptions}
                      onChange={onCityChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="address.zipcode"
                      required
                    />
                    <Field
                      name="address.zipcode"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="socialMedia.facebook"
                    />
                    <Field
                      name="socialMedia.facebook"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="socialMedia.instagram"
                    />
                    <Field
                      name="socialMedia.instagram"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteBasicInformationTxt}
                      id="socialMedia.youtube"
                    />
                    <Field
                      name="socialMedia.youtube"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="my-lg-5 my-3" />
              <div className="row">
                <div className="col-12">
                  {formattedErrors.map((error) => (
                    <p
                      className="txt2"
                      style={{ fontWeight: 'bold' }}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12" />
                <div className="col-md-6 col-12 text-right">
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {instituteBasicInformationTxt.btnSave}&nbsp;
                    <span className="fa fa-chevron-circle-right text-white" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BasicInformationForm;

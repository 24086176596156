export type ILanguageTexts = {
  app: Record<string, string>;
  registration: Record<string, string>;
  login: Record<string, string>;
  forgotPassword: Record<string, string>;
  auth: Record<string, string>;
  homePage: Record<string, string>;
  instituteHomePage: Record<string, string>;
  instituteSetup: Record<string, string>;
  centers: Record<string, string>;
  courses: Record<string, string>;
  classes: Record<string, string>;
  instituteBasicInformation: Record<string, string>;
  instituteWebsiteInformation: Record<string, string>;
  instituteCourses: Record<string, string>;
  websiteMedia: Record<string, string>;
  users: Record<string, string>;
  batches: Record<string, string>;
  institutePaymentInfo: Record<string, string>;
  profile: Record<string, string>;
  contact: Record<string, string>;
  userInvoices: Record<string, string>;
  institutes: Record<string, string>;
  announcements: Record<string, string>;
  attendances: Record<string, string>;
  dashboard: Record<string, string>;
  assignments: Record<string, string>;
  notification: Record<string, string>;
  contactUs: Record<string, string>;
  topHeader: Record<string, string>;
  privacyPolicy: Record<string, string>;
  layoutAdmin: Record<string, string>;
};

const LanguageTexts: ILanguageTexts = {
  app: {
    loading: 'Loading...',
    each: 'Each',
    maxSize: 'Max. Size',
    resolution: 'Recommended file size - ',
    resolutionSize: '500 x 300',
    maxSizeErr: 'Max allowed image size is {size}',
    mandatoryNote: '* Fields are mandatory',
    invalidCard: 'Please provide valid card details.',
    invalidDate: '$property must be a valid date',
    confirmText: 'Are you sure to do this?',
    yes: 'Yes',
    no: 'No',
    chooseRole: 'Choose Role',
    instituteOwner: 'Institute Owner',
    chooseCenter: 'Choose Center',
    chooseBatch: 'Choose Batch',
    choose: 'Choose',
    readMore: 'Read More',
    btnSubmit: 'Submit',
    notFound: 'Not Found',
    noData: 'No Data Found',
    phoneNote: 'For Ex. 5417540000',
    InvalidValue: '$property contains invalid value',
    metaTitle: 'Institute management application | Impeccable Tracker',
    metaDescription:
      'Web and mobile application software for Institute management. Manage classes, schedules, attendance, payments easily though Impeccable Tracker.',
    metaKeywords:
      'Class management, Class schedule, class management, attendee tracking, assignment review, online fees collection, sports class management, yoga class management, Music class management',
    institute: 'Institute',
    of: 'of',
    classesFor: 'classes for kids and adults',
    specializedIn: 'specialized in',
    conductingClasses: 'Currently conducting classes in',
    contactEmail: 'contact@impeccabletracker.com',
    appHomePageLink: 'https://www.impeccabletracker.com/',
    tableSearchPlaceholderText: 'Type here to search...',
    tablePageNumberText: 'pageIndex of length',
  },
  registration: {
    'input.name': 'Owner Name',
    'input.email': 'Email',
    'input.password': 'Password',
    'input.phone': 'Phone Number',
    'input.domain': 'Institute Name',
    'input.ccName': 'Full name on the card',
    'input.ccNumber': 'Credit Card Number',
    'input.ccCvv': 'CVV No',
    'input.ccExp': 'Card Expiration (MM/YY)',
    'input.autoPayment': 'This card will be saved for autopayments.',
    successMsg:
      'Your registration is complete. We will contact you within 24-48 hours for next steps.',
    header: 'Create Account',
    defaultDomain: 'yourinstitutename',
    https: 'https://',
    domainUrl: '.impeccabletracker.com',
    checkAvailable: 'Check Availability',
    domainAvailable: 'Institute Name is available',
    domainNotAvailable: 'Institute Name is not available',
    payment: 'Payment',
    paymentInfoMsg:
      'You will be paying <b>{price}</b> for each active student <b>every month</b>.',
    autopaymentMsg: 'This card will be saved for autopayments.',
    submitBtn: 'Pay & Create an Account',
  },
  login: {
    'input.email': 'Email/Username',
    'input.password': 'Password',
    header: 'Login to continue',
    homeBtn: 'Home',
    forgotPass: 'Forgot Password?',
    submitBtn: 'Sign In',
  },
  forgotPassword: {
    'input.email': 'Email/Username',
    header: 'Forgot Password?',
    subHeader: 'Password will send to you at your email address',
    homeBtn: 'Home',
    forgotPass: 'Forgot Password?',
    submitBtn: 'Submit',
    successMsg:
      "New password is emailed to your account. If you don't see it in your inbox it might be in your spam folder.",
    backToLoginLink: 'Back To Login',
  },
  auth: {
    noaccess: 'You do not have access to this page!',
    renewtoaccess: 'Please renew your subscription to access this page!',
  },
  homePage: {
    'input.email': 'Email',
    appTitle: 'Impeccable Tracker',
    menuAbout: 'About',
    menuFeatures: 'Features',
    menuHowItWorks: 'How It Works?',
    menuTestimonial: 'Testimonial',
    menuRegister: 'Register',
    menuContact: 'Contact',
    loginBtn: 'Login',
    mainSlide1: 'Complete Institute Management',
    mainSlide2: 'Manage Students Activities',
    mainSlide3: 'Seamless Payment Solution',
    mainSlide4: 'Role Based Access',
    aboutUs: 'About Us',
    aboutUsText1:
      'We have a cloud based solution for managing any type of institute. Our solution offers an online web and mobile app based, where institutes can register their classes and enroll students. Parents can see children’s progress. Teachers can manage the classes.',
    aboutUsText2:
      'We also offers an online payment gateway allowing fees to be collected using a credit card. Application also accepts fees in cash, check and online payment services.',
    features: 'Features',
    setupClass: 'Setup a class',
    setupClassInfo1: 'Schedule a class',
    setupClassInfo2: 'Setup the fees',
    setupClassInfo3: 'Assign students',
    setupClassInfo4: 'Select location of the class',
    roleBaseAccess: 'Role based access',
    role1: 'Institute Owner',
    role2: 'Center Head',
    role3: 'Teacher',
    role4: 'Parent',
    role5: 'Student',
    classManagement: 'Class management',
    classManagementInfo1: 'Announcements',
    classManagementInfo2: 'Attendance',
    classManagementInfo3: 'Assignments',
    classManagementInfo4: 'Payments',
    assignments: 'Assignments',
    assignmentsInfo1: 'View assignments',
    assignmentsInfo2: 'Submission',
    assignmentsInfo3: 'Allow corrections',
    assignmentsInfo4: 'Review comments',
    announcements: 'Announcements',
    announcementsInfo1: 'Easy way to send quick note to students',
    announcementsInfo2: 'Automatically expire the announcement',
    announcementsInfo3: 'Send the notifications',
    announcementsInfo4: 'One view for all the announcements',
    fees: 'Fees',
    feesInfo1: 'Accept payment methods like cash, cheque, credit cards',
    feesInfo2: 'Sends the payment reminders',
    feesInfo3: 'No late fees',
    feesInfo4: 'Completely secure solution',
    howItWorks: 'How It Works',
    testimonial: 'Testimonial',
    testimonialText1:
      'Phasellus vitae suscipit justo. Mauris pharetra feugiat ante id lacinia. Etiam faucibus mauris id tempor egestas. Duis luctus turpis at accumsan tincidunt. Phasellus risus risus, volutpat vel tellus ac, tincidunt fringilla massa. Etiam hendrerit dolor eget rutrum.',
    testimonialCommenter1: 'Michael Holz',
    testimonialCommenter1Info: 'Seo Analyst at OsCorp Tech.',
    testimonialText2:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nameu sem tempor, varius quam at, luctus dui. Mauris magna metus,dapibus nec turpis vel, semper malesuada ante. Vestibulum idacnisl bibendum scelerisque non non purus. Suspendisse variusnibh non aliquet.',
    testimonialCommenter2: 'Paula Wilson',
    testimonialCommenter2Info: 'Media Analyst at SkyNet Inc.',
    testimonialText3:
      'Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget nisi a mi suscipit tincidunt. Utmtc tempus dictum risus. Pellentesque viverra sagittis quam at mattis. Suspendisse potenti. Aliquam sit amet gravida nibh, facilisis gravida odio. Phasellus auctor velit.',
    testimonialCommenter3: 'Anna Moreno',
    testimonialCommenter3Info: 'Web Developer at Circle Ltd.',
    registerInstitute: 'Register Your Institute',
    paymentInfoMsg: '{price} <small>per user/month</small>',
    getRegisteredBtn: 'Get Registered',
    registerAgreeTermsText: 'By registering, you agree to our',
    registerAgreeTermsLinkText: 'Terms & Conditions.',
    contactUs: 'Contact Us',
    contactUsBtn: 'Submit',
    addressInfo: '6d, Canal Street, TT 3356, New York',
    contactNumber: '+1(212) 456 789',
    quickLinks: 'Quick Links',
    quickLinks1: 'About',
    quickLinks2: 'Contact',
    quickLinks3: 'Login',
    quickLinks4: 'Register',
    copyrightText: '© {current_year} All Rights Reserved | ',
    privacyPolicyText: 'Privacy Policy',
  },
  instituteHomePage: {
    menuAbout: 'About',
    menuCourses: 'Courses',
    menuTrainers: 'Our Trainers',
    menuGallery: 'Gallery',
    menuTestimonial: 'Testimonial',
    menuContact: 'Contact',
    loginBtn: 'Login',
    aboutUs: 'About Us',
    readMoreBtn: 'Read More',
    centers: 'Centers',
    courses: 'Courses',
    classes: 'Classes',
    trainers: 'Our Trainers',
    noTrainers: 'No Trainers',
    gallery: 'Gallery',
    noGallery: 'No Images/Videos',
    testimonial: 'Testimonial',
    noTestimonial: 'No Testimonial',
    contactUs: 'Contact Us',
    copyrightText: '© {current_year} All Rights Reserved | Designed by ',
    copyrightLinkText: 'Impeccable Tracker',
  },
  instituteSetup: {
    header: 'Institute Setup',
    tabHeader1: 'Basic',
    tabHeader2: 'Website',
    tabHeader3: 'Courses',
    tabHeader4: 'Media',
    tabHeader5: 'Payment',
  },
  instituteBasicInformation: {
    'input.logo': 'Logo',
    'input.name': 'Institute Name',
    'input.instituteTypeId': 'Institute Type',
    'input.email': 'Email',
    'input.phone': 'Phone Number',
    'address.address1': 'Address 1',
    'address.address2': 'Address 2',
    'address.csc': 'City, State, Country',
    'address.cityId': 'City',
    'address.stateId': 'State',
    'address.countryId': 'Country',
    'address.zipcode': 'Zip',
    'socialMedia.youtube': 'Youtube Channel',
    'socialMedia.facebook': 'Facebook Page',
    'socialMedia.instagram': 'Instagram Page',
    address1Note: 'Street Address, P.O. Box, Company Name, c/o',
    address2Note: 'Apartment, Suite, Unit, Building, Floor etc.',
    btnReset: 'Reset',
    btnSave: 'Save & Continue',
  },
  instituteWebsiteInformation: {
    aboutus: 'About Us',
    aboutusTooltip:
      'This information will be used to build your personalized website.',
    'input.image': 'Image',
    'input.heading': 'Heading',
    'input.description': 'Description',
    testimonial: 'Testimonial',
    'testimonial.commenterPic': 'Commenter Pic',
    'testimonial.commenterName': 'Commenter Name',
    'testimonial.commenterContact': 'Commenter Contact',
    'testimonial.commenterDescription': 'Commenter Description',
    'testimonial.comments': 'Comments',
    descTxt: 'Paragraph',
    maxCharNote: 'Max. 250 Characters',
    recommendedCharNote: 'Recommended 500 Characters',
    addDesc: 'Add More',
    addTestimonial: 'Add More Testimonial',
    btnRemove: 'Remove',
    btnReset: 'Reset',
    btnSave: 'Save & Continue',
  },
  instituteCourses: {
    'input.image': 'Image',
    'input.name': 'Course Name',
    'input.description': 'Description',
    descTxt: 'Paragraph',
    maxCharNote: 'Max. 250 Characters',
    recommendedCharNote: 'Recommended 500 Characters',
    addDesc: 'Add More',
    addCourse: 'Add More Course',
    btnRemove: 'Remove',
    btnReset: 'Reset',
    btnSave: 'Save & Continue',
  },
  websiteMedia: {
    'input.image': 'Image',
    'input.mediaUrl': 'Url',
    videos: 'Videos',
    photos: 'Photos',
    utubeUrl: 'Put Youtube URL Here',
    setMainImg: 'Set Main Image',
    resolutionMainImage: 'Recommended file size - 1200 x 460',
    chooseImg: 'Choose image from here',
    uploadOwn: 'Or upload your own',
    addPhotos: 'Add More Photos',
    addVideos: 'Add More Videos',
    btnUpload: 'Upload',
    btnSave: 'Save & Continue',
  },
  centers: {
    'input.name': 'Center Name',
    'input.email': 'Email',
    'input.phone': 'Phone Number',
    'address.address1': 'Address 1',
    'address.address2': 'Address 2',
    'address.csc': 'City, State, Country',
    'address.cityId': 'City',
    'address.stateId': 'State',
    'address.countryId': 'Country',
    'address.zipcode': 'Zip',
    header: 'Center Setup',
    newCenter: 'New Center',
    updateCenter: 'Update Center',
    centerList: 'Center List',
    address1Note: 'Street Address, P.O. Box, Company Name, c/o',
    address2Note: 'Apartment, Suite, Unit, Building, Floor etc.',
    centers: 'Centers',
    center: 'Center',
    centerHead: 'Center Head',
    city: 'City',
    email: 'Email',
    contact: 'Contact',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
  },
  courses: {
    'input.image': 'Image',
    'input.name': 'Course Name',
    'input.description': 'Description',
    newCourse: 'New Course',
    updateCourse: 'Update Course',
    course: 'Course',
    courses: 'Courses',
    description: 'Description',
    descTxt: 'Paragraph',
    maxCharNote: 'Max. 250 Characters',
    recommendedCharNote: 'Recommended 500 Characters',
    addDesc: 'Add More',
    addCourse: 'Add More Course',
    btnRemove: 'Remove',
    btnReset: 'Reset',
    btnSave: 'Save',
  },
  classes: {
    'input.image': 'Image',
    'input.course': 'Select Course',
    'input.courseId': 'Course',
    'input.name': 'Class Name',
    'input.description': 'Description',
    header: 'Class Setup',
    newClass: 'New Class',
    updateClass: 'Update Class',
    classList: 'Class List',
    descTxt: 'Text',
    maxCharNote: 'Max. 250 Characters',
    addDesc: 'Add More',
    classes: 'Classes',
    course: 'Course',
    class: 'Class',
    btnRemove: 'Remove',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
  },
  users: {
    'input.firstName': 'First Name',
    'input.lastName': 'Last Name',
    'input.email': 'Email',
    'input.gender': 'Gender',
    'input.phone': 'Phone Number',
    'input.status': 'Status',
    'input.description': 'Description',
    'input.parentId': 'Parents Name',
    'input.username': 'Username',
    'input.assignParent': 'Assign a parent',
    'input.centerIds': 'Center(s)',
    'input.classIds': 'Class(es)',
    'input.batches': 'Batch(es)',
    'batches.enrollmentStatus': 'Enrollment Status',
    'batches.enrollmentDate': 'Enrollment Date',
    'batches.inactiveDate': 'Inactive Date',
    dateFormat: '(mm/dd/yyyy)',
    newUser: 'New User',
    updateUser: 'Update User',
    role: 'Role',
    addUserAs: 'Add an user as',
    addParentQue: 'Do you want to add a parent for this user?',
    yes: 'Yes',
    no: 'No',
    male: 'Male',
    female: 'Female',
    checkAvailable: 'Check Availabilty',
    select: 'Select',
    active: 'Active',
    inactive: 'Inactive',
    maxCharNote: 'Max. 500 Characters',
    btnAdd: 'Save',
    users: 'Users',
    name: 'Name',
    enStatus: 'Enroll Status',
    enDate: 'Enroll Date',
    classes: 'Classes',
    centers: 'Centers',
    batches: 'Batches',
  },
  batches: {
    'input.name': 'Batch Name',
    'input.centerId': 'Center',
    'input.classId': 'Class',
    'input.teacherIds': 'Teachers',
    'input.startDate': 'Start Date',
    'input.endDate': 'End Date',
    'input.schedule': 'Schedule',
    'input.scheduleType': 'Schedule',
    'input.status': 'Status',
    'feesSchedule.feesAmount': 'Fees Amount',
    'feesSchedule.dueDate': 'Fees Due Date',
    'schedule.startTime': 'Start Time',
    'schedule.endTime': 'End Time',
    newBatch: 'New Batch',
    feesScheduleNote:
      'Below is the fees schedule for this batch. Please review and modify if needed.',
    selectDays: 'Select Days',
    updateBatch: 'Update Batch',
    btnAdd: 'Save',
    btnAddMore: 'Add More',
    btnRemove: 'Remove',
    batches: 'Batches',
    batch: 'Batch',
    class: 'Class',
    center: 'Center',
    status: 'Status',
    doubleDecimal: '.00',
  },
  institutePaymentInfo: {
    'input.firstName': 'First Name',
    'input.lastName': 'Last Name',
    'input.ssn': 'SSN',
    'input.dob': 'Date of birth',
    'input.accountNumber': 'Account number',
    'input.routingNumber': 'Routing number',
    lastFour: 'last four digits',
    dateFormat: '(mm/dd/yyyy)',
    missingAddress: 'Please update your address details in basic information',
    paymentInfoTooltip:
      'Fees paid by students will be deposited in this account.',
    btnSave: 'Save',
    btnCancel: 'Cancel',
    btnEdit: 'Change Account',
    btnEditCard: 'Change Card',
    accountDetails: 'Account Details',
    accountHolderName: 'Account Holder Name',
    dateOfBirth: 'Date of Birth',
    bankName: 'Bank Name',
    accountNumber: 'Account Number',
    routingNumber: 'Routing Number',
    cardDetails: 'Card Details',
    cardType: 'Card Type',
    cardNumber: 'Card Number',
    cardExpiry: 'Card Expiry',
  },
  profile: {
    'input.profileImage': 'Upload Profile Photo',
    'input.newPassword': 'New Password',
    'input.oldPassword': 'Old Password',
    'input.updateCard': 'Update Card Details',
    'input.autoPayment': 'This card will be saved for autopayments.',
    'input.name': 'Account Holder Name',
    'input.accountNumber': 'Account number',
    'input.routingNumber': 'Routing number',
    'input.amount1': 'Amount 1',
    'input.amount2': 'Amount 2',
    header: 'My Profile',
    tabHeader1: 'Upload Profile Photo',
    tabHeader2: 'Change Password',
    tabHeader3: 'Payment Information',
    tabHeader4: 'Cancel Subscription',
    tabHeader5: 'Renew Subscription',
    accountHolderName: 'Account Holder Name',
    bankName: 'Bank Name',
    accountNumber: 'Account Number',
    routingNumber: 'Routing Number',
    uploadProfilePhotoBtn: 'Upload',
    newPassword: 'New Password',
    changePasswordBtn: 'Change Password',
    passwordSuccess: 'Your password changed successfully.',
    autoSaveCard: 'This card will be saved for autopayments.',
    updateCardBtn: 'Save Card',
    payAndSaveCardBtn: 'Save Card & Pay',
    cancelSubscriptionMsg1:
      'We are sorry to hear you want to cancel your subscription. Is there anything we can help you with? Please email us at ',
    cancelSubscriptionMsg2:
      'This will cancel your subscription on the day it expires and disable auto-renew.',
    cancelSubscriptionMsg3:
      'Cancelling subscription will make all the students inactive.',
    cancelSubscriptionBtn: 'Cancel Subscription',
    btnCancel: 'Cancel',
    btnEdit: 'Edit',
    btnSave: 'Save',
    btnVerify: 'Verify',
    btnAddPaymentMethod: 'Add Payment Method',
    renewSubscriptionMsg1: 'Renew Subscription',
    renewSubscriptionBtn: 'Renew Subscription',
    verificationStatus: 'Status',
    creditCard: 'Credit Card',
    ach: 'ACH',
    paymentMethod: 'Payment Method',
    cardType: 'Card Type',
    cardNumber: 'Card Number',
    cardExpiry: 'Card Expiry',
    pendingVerification: 'Pending Verification',
    note: 'NOTE',
    verifyNote: `We will send two different deposits, each under $1, to your
    account. These deposits take 1-2 business days to appear on
    your statement. The statement has a description that includes
    AMTS followed by this two amounts. You will need to enter
    these amounts to verify your account. On submit this amounts
    will be deducted from your bank account.`,
    setDefault: 'Set as Default',
    close: 'Close',
    verifyBankAccount: 'Verify Bank Account',
    verified: 'Verified',
    setDefaultPaymentMethodSuccess:
      'Default payment method changed successfully.',
    defaultPaymentText: 'Default Payment',
    selectedPaymentMethodText: 'Selected',
    delete: 'Delete',
    deletePaymentMethod: 'Delete Payment method',
    deletePaymentMethodSuccess: 'Payment method deleted successfully.',
  },
  contact: {
    header: 'Contact Us',
    centerHead: 'Center Head',
    teacher: 'Teacher',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    noCenterHead: 'No center head',
    noTeacher: 'No teacher',
    noContacts: 'No Contact Details',
  },
  userInvoices: {
    fees: 'Fees',
    instituteName: 'Institute Name',
    studentName: 'Student Name',
    class: 'Class',
    center: 'Center',
    amount: 'Amount',
    amountDue: 'Amount Due',
    processingFees: 'Processing Fees',
    amountPaid: 'Amount Paid',
    dueDate: 'Due Date',
    createdDate: 'Invoice Date',
    feesStatus: 'Fees Status',
    paymentMode: 'Payment Mode',
    received: 'Received',
    paid: 'Paid',
    pending: 'Pending',
    failed: 'Payment Failed',
    processing: 'Payment Processing',
    feesAmount: 'Fees',
    notes: 'Notes',
    processingfeesAmount: 'Processing Fees',
    totalfeesAmount: 'Total Charges',
    status: 'Status',
    newFees: 'Pay Fees',
    offlineFees: 'Receive Offline Fees',
    btnPay: 'Pay',
    'input.feesAmount': 'Amount',
    'input.paymentMethodType': 'Payment Mode',
    'input.notes': 'Notes',
    'input.chequeBankName': 'Bank name',
    'input.chequeNumber': 'Cheque Number',
    onlinePaymentService: 'Online payment service',
    onlinePaymentServiceExp: 'eg: Paypal, Venmo etc.',
    notPaid: 'Waived Off',
    btnSubmit: 'Submit',
    paymentMethod: 'Payment Method',
    paymentMethodInstruction: 'Click and select payment method to pay',
  },
  institutes: {
    institutes: 'Institutes',
    instituteName: 'Institute Name',
    subscriptionAmountDue: 'Subscription Amount',
    subscriptionInvoiceDate: 'Subscription Payment Date',
    subscriptionPaymentStatus: 'Subscription Payment Status',
    subscriptionPending: 'Subscription Pending',
    paymentReceiveSuccess: 'Payment received successfully.',
    paymentReceive: 'Receive Payment',
    payoutAmountDue: 'Payout Amount',
    payoutInvoiceDate: 'Payout Date',
    payoutPaymentStatus: 'Payout Payment Status',
    payoutPending: 'Payout Pending',
    payoutNA: 'Payout Not Generated',
    subscriptionNA: 'Subscription Not Generated',
    paymentSend: 'Mark As Paid',
    paymentSendSuccess: 'Marked as paid successfully.',
  },
  announcements: {
    announcements: 'Announcements',
    'input.heading': 'Heading',
    'input.description': 'Description',
    'input.batchIds': 'Batches',
    'input.centerIds': 'Centers',
    'input.userIds': 'Announce to',
    'input.expiresAt': 'Expiry Date',
    allStudents: 'All Students',
    heading: 'Heading',
    description: 'Description',
    center: 'Center',
    class: 'Class',
    student: 'Student',
    date: 'Date',
    header: 'Announcement',
    newAnnouncement: 'New Announcement',
    updateAnnouncement: 'Update Announcement',
    announcementList: 'Announcement List',
    maxCharNote: 'Max. 250 Characters',
    btnSave: 'Save',
  },
  attendances: {
    attendances: 'Attendances',
    'students.attendanceStatus': 'Status',
    batch: 'Batch',
    center: 'Center',
    class: 'Class',
    student: 'Student',
    studentName: 'Student Name',
    date: 'Date',
    attendance: 'Attendance',
    header: 'Attendance',
    newAttendance: 'Record Attendance',
    updateAttendance: 'Update Attendance',
    indicatesSubmitted: 'indicates attendance submitted',
    indicatesNotSubmitted: 'indicates attendance not submitted',
    indicatesNextSchedule: 'indicates next schedules',
    indicatesCurrentDay: 'indicates current day',
    attendanceList: 'Attendance List',
    maxCharNote: 'Max. 250 Characters',
    btnSave: 'Save',
    errorFutureDates: 'You can not record attendance for future dates.',
  },
  dashboard: {
    announcements: 'Announcements',
    assignments: 'Assignments',
    classSchedule: 'Class Schedule',
    scheduleDetails: 'Schedule for',
    viewAll: 'View All',
    close: 'Close',
    center: 'Center',
    class: 'Class',
    timing: 'Timing',
    to: 'to',
    indicatesNextSchedule: 'indicates next schedules',
    indicatesCurrentDay: 'indicates current day',
  },
  assignments: {
    'input.assignmentDueDate': 'Submit By Date',
    'input.students': 'Submit To',
    'input.studentsExcluded': 'Excused Students',
    'input.description': 'Description',
    'input.assignmentSubmissionFormat': 'Assignment Submission Format',
    'input.assignmentUrl': 'URL',
    'input.assignmentFile': 'File',
    'input.comment': 'Comment',
    'input.submissionStatus': 'Remark',
    'input.grade': 'Grade',
    allStudents: 'All Students',
    assignments: 'Assignments',
    batch: 'Batch',
    center: 'Center',
    class: 'Class',
    student: 'Student',
    studentName: 'Student Name',
    teacherName: 'Teacher Name',
    date: 'Date',
    dueDate: 'Due Date',
    description: 'Description',
    assignment: 'Assignment',
    header: 'Assignment',
    newAssignment: 'New Assignment',
    updateAssignment: 'Update Assignment',
    indicatesCreated: 'indicates assignment created',
    indicatesNotCreated: 'indicates assignment not created',
    indicatesNextSchedule: 'indicates next schedules',
    indicatesCurrentDay: 'indicates current day',
    assignmentList: 'Assignment List',
    maxCharNote: 'Max. 250 Characters',
    submissionStatus: 'Submission Status',
    btnSave: 'Submit',
    none: 'None',
    homework: 'Homework',
    comment: 'Comment',
    completed: 'Completed',
    resubmit: 'Resubmit',
    remark: 'Remark',
    remarkDate: 'Remark Date',
    remarkComment: 'Remark Comment',
    view: 'View',
    by: 'By',
    submissionFile: 'Submission File/URL',
    resubmissionFile: 'Resubmission File/URL',
    submissionDate: 'Submission Date',
    fileOrUrl: 'File/URL',
    remarkFormHeading: 'Give Remark',
    grade: 'Grade',
  },
  notification: {
    feesDue:
      'Fees due is {currencySymbol}{amountDue}. Kindly pay before due date.',
    batchScheduleChange: 'Batch schedule has been changed.',
    newAssignment: 'New assignment posted.',
    assignmentStatusChange: 'Assignment status changed to {status}.',
  },
  contactUs: {
    'input.name': 'Name',
    'input.email': 'Email',
    'input.phone': 'Phone Number',
    'input.message': 'Message',
    name: 'Your Name',
    email: 'Your Email',
    phone: 'Phone Number',
    message: 'Your Message Here...',
    btnSubmit: 'Submit',
    successMessage:
      'Your contact request is submitted successfully. We will get back to you soon!',
  },
  topHeader: {
    myProfile: 'My Profile',
    logout: 'Logout',
  },
  privacyPolicy: {
    header: 'Privacy Policy',
    contactLinkText: 'https://dev.impeccabletracker.com',
    privacyPara1:
      "Your privacy is important to us. It is Star Spinel's policy to respect your privacy regarding any information we may collect from you across our website, {url}, and other sites we own and operate.",
    privacyPara2:
      'We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.',
    privacyPara3:
      'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.',
    privacyPara4:
      'We don’t share any personally identifying information publicly or with third-parties, except when required to by law.',
    privacyPara5:
      'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.',
    privacyPara6:
      'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
    privacyPara7:
      'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.',
    privacyPara8: 'This policy is effective as of 8 January 2021.',
    privacyPara9: 'Privacy Policy created with GetTerms.',
  },
  layoutAdmin: {
    copyrightText: '© {current_year} All Rights Reserved | ',
    privacyPolicyText: 'Privacy Policy',
  },
};

export default LanguageTexts;

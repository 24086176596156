import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateCourseInput } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import ImageUpload from '../../components/ImageUpload';
import AboutUsDescription from '../institute-setup/website-information/AboutUsDescription';

type InstituteCourseProps = {
  onSubmit: (data: CreateCourseInput) => void;
  onRemoveCoursePic: (id: string) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateCourseInput;
};

const InstituteCourseForm: React.FC<InstituteCourseProps> = ({
  onSubmit,
  onRemoveCoursePic,
  loading,
  errors,
  initialValues,
}: InstituteCourseProps): JSX.Element => {
  const { courses: coursesTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { imageUrl, ...initVals } = initialValues;

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        if (selectedImage) {
          input.image = selectedImage;
        }

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);
        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          LanguageTexts.courses,
        );
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <ImageUpload
                  id="website-info"
                  label={coursesTxt['input.image']}
                  onFileSelect={(file) => {
                    setSelectedImage(file);
                  }}
                  url={imageUrl}
                  maxFileSize={1024}
                  fileResolution={appTxt.resolutionSize}
                  disabled={loading}
                  onRemoveUrl={() =>
                    values._id ? onRemoveCoursePic(values._id) : null
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={coursesTxt}
                    id="input.name"
                    required
                  />
                  <Field name="name" type="text" className="form-control" />
                </div>
              </div>
            </div>

            <div className="my-3" />
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={coursesTxt}
                    id="input.description"
                    required
                    errId="description"
                  />
                  <FieldArray name="description">
                    {({ remove, push }) => (
                      <>
                        {values.description.map((description, index) => (
                          <AboutUsDescription
                            // eslint-disable-next-line react/no-array-index-key
                            key={`desc${index}`}
                            index={index}
                            onRemove={(i) => remove(i)}
                            disabled={loading}
                          />
                        ))}
                        <div className="my-3" />
                        <div className="row">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn add-more-btn"
                              onClick={() => push('')}
                              disabled={loading}
                            >
                              <span className="fa fa-plus-circle" />
                              &nbsp;{coursesTxt.addDesc}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12" />
              <div className="col-lg-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {coursesTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InstituteCourseForm;

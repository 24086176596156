/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { isNumeric } from '../../../common/helper';

type MediaMainImageRadioProps = {
  _id: string;
  mediaUrl: string;
  defaultImage: string;
  onDefaultImgChange: (imageUrl: string) => void;
  onImageClick: (_id: string) => void;
  disabled?: boolean;
};

const MediaMainImageRadio: React.FC<MediaMainImageRadioProps> = ({
  _id,
  mediaUrl,
  defaultImage,
  onDefaultImgChange,
  disabled,
  onImageClick,
}: MediaMainImageRadioProps): JSX.Element => {
  const rValue = isNumeric(_id) ? _id : mediaUrl;

  return (
    <div className="col-sm-3 col-6 mb-3 d-flex flex-column align-items-center">
      <a
        href={mediaUrl}
        onClick={(e) => {
          e.preventDefault();
          onImageClick(_id);
        }}
      >
        <img src={mediaUrl} className="img-fluid rounded shadow-sm" alt="" />
      </a>

      <div className="custom-control custom-radio mt-1 mb-3">
        <input
          type="radio"
          value={rValue}
          id={`img-radio${_id}`}
          name="main-image"
          className="custom-control-input"
          onChange={(e) => {
            onDefaultImgChange(e.target.value);
          }}
          checked={rValue === defaultImage}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={`img-radio${_id}`} />
      </div>
    </div>
  );
};

MediaMainImageRadio.defaultProps = {
  disabled: false,
};

export default MediaMainImageRadio;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { FILTER_KEY } from '../common/constants';
import { SelectedFilterInput } from '../common/types';
import {
  resetSelectedFilter,
  resetSelectedFilterSuccess,
  setSelectedFilter,
  setSelectedFilterSuccess,
} from './app.slice';

export function* setSelectedFilterAsync(
  action: PayloadAction<SelectedFilterInput>,
) {
  yield call(
    [localStorage, localStorage.setItem],
    FILTER_KEY,
    JSON.stringify(action.payload) || '',
  );
  yield put(setSelectedFilterSuccess(action.payload));
}

export function* resetSelectedFilterAsync() {
  yield call([localStorage, localStorage.removeItem], FILTER_KEY);
  yield put(resetSelectedFilterSuccess());
}

export function* watchAppAsync() {
  yield takeLatest(setSelectedFilter.toString(), setSelectedFilterAsync);
  yield takeLatest(resetSelectedFilter.toString(), resetSelectedFilterAsync);
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AppRoutePath,
  BatchScheduleType,
  UserRole,
} from '../../common/constants';
import { setTimeToEnd, setTimeToStart } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  CreateAttendanceInput,
  RootState,
  UserModel,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AttendanceForm from './AttendanceForm';
import {
  loadAttendanceDeps,
  loadAttendances,
  resetAddAttendanceSuccess,
  resetLoadAttendanceDeps,
  resetLoadAttendances,
} from './attendances.slice';
import AttendanceCalendar from './components/AttendanceCalendar';

type AttendanceRecordPageParams = {
  onSubmit: (data: CreateAttendanceInput) => void;
  errors: string[];
  loading: boolean;
};

const AttendanceRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: AttendanceRecordPageParams): JSX.Element => {
  const { attendances: attendancesTxt, app: appTxt } = LanguageTexts;

  const {
    filter: { batchId: selectedBatch },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [attendanceDepsLoadRequested, setAttendanceDepsRequested] = useState<
    boolean
  >();
  const [attendancesLoadRequested, setAttendancesRequested] = useState<
    boolean
  >();
  const [attendanceDate, setAttendanceDate] = useState<moment.Moment>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading: loadAttendancesLoading,
    errors: loadAttendancesErrors,
    success: loadAttendancesSuccess,
    loadAttendanceDeps: {
      loading: loadAttendanceDepsLoading,
      errors: loadAttendanceDepsErrors,
      success: loadAttendanceDepsSuccess,
    },
    attendances,
    students,
    batch,
  } = useSelector((state: RootState) => state.attendances);

  useEffect(() => {
    if (!attendancesLoadRequested && selectedBatch) {
      dispatch(loadAttendances({ batchIds: [selectedBatch] }));
      setAttendancesRequested(true);
    }
  }, [attendancesLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAttendancesErrors.length > 0) {
      dispatch(resetLoadAttendances());
      history.push(AppRoutePath.Attendances);
    } else if (loadAttendancesSuccess) {
      dispatch(resetLoadAttendances());
    }
  }, [loadAttendancesErrors, loadAttendancesSuccess, dispatch, history]);

  // load attendance deps
  useEffect(() => {
    if (!attendanceDepsLoadRequested) {
      dispatch(
        loadAttendanceDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Student],
          batchId: selectedBatch || '',
        }),
      );
      setAttendanceDepsRequested(true);
    }
  }, [currentUser, attendanceDepsLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAttendanceDepsErrors.length > 0) {
      dispatch(resetLoadAttendanceDeps());
      history.push(AppRoutePath.Attendances);
    } else if (loadAttendanceDepsSuccess) {
      dispatch(resetLoadAttendanceDeps());
    }
  }, [loadAttendanceDepsSuccess, loadAttendanceDepsErrors, dispatch, history]);

  if (
    !attendancesLoadRequested ||
    loadAttendancesLoading ||
    !attendanceDepsLoadRequested ||
    loadAttendanceDepsLoading
  ) {
    return <AppLoader />;
  }

  if (!batch || !attendances) {
    return <p>{appTxt.notFound}</p>;
  }

  const timezone = batch.center?.address.city?.timeZone || '';
  const startDate = moment(setTimeToStart(batch.startDate));
  const endDate = moment(setTimeToEnd(batch.endDate));
  const today = moment(
    setTimeToEnd(moment.tz(undefined, timezone).format(moment.HTML5_FMT.DATE)),
  );

  let filteredStudents: UserModel[] = [];

  if (students) {
    filteredStudents = students.filter(({ batches: sBatches }) => {
      return (
        !!sBatches?.find(({ batchId }) => selectedBatch === batchId) || false
      );
    });
  }

  const disabledDays = [
    {
      before: startDate.toDate(),
      after: endDate.toDate(),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attendancePendingDays: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attendanceSubmittedDays: any[] = attendances.map(
    ({ attendanceDate: atDate }) => moment(setTimeToStart(atDate)).toDate(),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attendanceFutureDays: any[] = [];

  if (batch.scheduleType === BatchScheduleType.Weekly) {
    const daysOfWeek = batch.schedule.map(({ day }) => parseInt(day, 10));

    const initDate = startDate.clone();

    while (initDate.isSameOrBefore(endDate)) {
      const curDate = initDate.clone();

      if (daysOfWeek.includes(curDate.weekday())) {
        if (curDate.isAfter(today)) {
          attendanceFutureDays.push(curDate.toDate());
        } else {
          const attendance = attendances.find(
            ({ attendanceDate: atDate }) =>
              curDate.format(moment.HTML5_FMT.DATE) === atDate,
          );

          if (!attendance) {
            attendancePendingDays.push(curDate.toDate());
          }
        }
      }

      initDate.add(1, 'day');
    }
  } else if (batch.scheduleType === BatchScheduleType.Custom) {
    batch.schedule.forEach(({ day }) => {
      const curDate = moment(setTimeToStart(day));

      if (curDate.isAfter(today)) {
        attendanceFutureDays.push(curDate.toDate());
      } else {
        const attendance = attendances.find(
          ({ attendanceDate: atDate }) =>
            curDate.format(moment.HTML5_FMT.DATE) === atDate,
        );

        if (!attendance) {
          attendancePendingDays.push(curDate.toDate());
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let attendanceInfo: any;

  if (attendances && attendanceDate) {
    const attendance = attendances.find(
      ({ attendanceDate: atDate }) =>
        attendanceDate.format(moment.HTML5_FMT.DATE) === atDate,
    );
    attendanceInfo = attendance
      ? {
          ...attendance,
          attendanceDate: moment(setTimeToStart(attendance.attendanceDate)),
        }
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attendanceInfoInit: any = attendanceInfo || {
    batchId: batch._id,
    attendanceDate,
    students: filteredStudents.map((user) => {
      return {
        user,
        attendanceStatus: '',
      };
    }),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(input: any) {
    onSubmitParent({
      _id: input._id,
      attendanceDate: input.attendanceDate.format(moment.HTML5_FMT.DATE),
      batchId: input.batchId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      students: input.students.map(({ _id, user, attendanceStatus }: any) => {
        return { _id, userId: user._id, attendanceStatus };
      }),
    });
  }

  function handleOnChange(newVal: moment.Moment) {
    if (!newVal.isAfter(today)) {
      dispatch(resetAddAttendanceSuccess());
      setAttendanceDate(newVal);
    } else {
      toast.error(attendancesTxt.errorFutureDates);
    }
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 record-attendance">
      <h1 className="primary-heading mb-4">{attendancesTxt.newAttendance}</h1>

      <div className="row">
        <div className="col-xl-6 col-12 mb-xl-0 mb-4">
          <AttendanceCalendar
            disabledDays={disabledDays}
            selectedDay={attendanceDate}
            initialMonth={startDate}
            attendancePending={attendancePendingDays}
            attendanceFuture={attendanceFutureDays}
            attendanceSubmitted={attendanceSubmittedDays}
            onChange={handleOnChange}
            timezone={timezone}
          />
          <div className="my-4">
            <p className="d-flex align-items-center">
              <span className="indication submitted-form mr-2" />
              {attendancesTxt.indicatesSubmitted}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication pending-form mr-2" />
              {attendancesTxt.indicatesNotSubmitted}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication future-schedule mr-2" />
              {attendancesTxt.indicatesNextSchedule}
            </p>
            <p className="d-flex align-items-center">
              <span className="indication current-day mr-2" />
              {attendancesTxt.indicatesCurrentDay}
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-12">
          <div className="form">
            {attendanceDate ? (
              <AttendanceForm
                onSubmit={onSubmit}
                initialValues={attendanceInfoInit}
                errors={errors}
                loading={loading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AttendanceRecordPage;

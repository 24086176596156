import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateCenterInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import { addCenter, resetAddCenterSuccess } from './institute-centers.slice';
import InstituteCenterListPage from './InstituteCenterListPage';
import InstituteCenterRecordPage from './InstituteCenterRecordPage';

const InstituteCentersPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addCenter: {
      loading: addCenterLoading,
      success: addCenterSuccess,
      errors: addCenterErrors,
    },
  } = useSelector((state: RootState) => state.centers);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateCenterInput) {
    dispatch(
      addCenter({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addCenterSuccess) {
      dispatch(resetAddCenterSuccess());
      history.push(AppRoutePath.Centers);
    }
  }, [addCenterSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.CentersUpdate}
          permissions={rPermission(AppRoutePath.CentersUpdate)}
        >
          {!addCenterSuccess ? (
            <InstituteCenterRecordPage
              key="updatecenterform"
              errors={addCenterErrors}
              loading={addCenterLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.CentersCreate}
          permissions={rPermission(AppRoutePath.CentersCreate)}
        >
          {!addCenterSuccess ? (
            <InstituteCenterRecordPage
              key="createcenterform"
              errors={addCenterErrors}
              loading={addCenterLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <InstituteCenterListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default InstituteCentersPage;

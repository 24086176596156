/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BatchDepsResponse,
  BatchesState,
  BatchModel,
  CreateBatchInput,
  GetBatchDepsQuery,
  GetBatchesQuery,
} from '../../common/types';

const initialState: BatchesState = {
  loading: false,
  success: null,
  errors: [],
  batches: null,
  addBatch: {
    loading: false,
    success: null,
    errors: [],
  },
  loadBatch: {
    loading: false,
    success: null,
    errors: [],
  },
  loadBatchDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  centers: null,
  classes: null,
};

const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadBatches(state, action: PayloadAction<GetBatchesQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadBatchesSuccess(state, action: PayloadAction<BatchModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.batches = action.payload;
    },
    loadBatchesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadBatches(state) {
      state.loadBatch.success = null;
      state.loadBatch.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addBatch(state, action: PayloadAction<CreateBatchInput>) {
      state.addBatch.loading = true;
      state.addBatch.errors = [];
    },
    addBatchSuccess(state, action: PayloadAction<boolean>) {
      state.addBatch.loading = false;
      state.addBatch.success = action.payload;
    },
    addBatchError(state, action: PayloadAction<string[]>) {
      state.addBatch.loading = false;
      state.addBatch.errors = action.payload;
    },
    resetAddBatch(state) {
      state.addBatch.success = null;
      state.addBatch.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadBatch(state, action: PayloadAction<string>) {
      state.loadBatch.loading = true;
      state.loadBatch.errors = [];
    },
    loadBatchSuccess(state, action: PayloadAction<BatchModel>) {
      state.loadBatch.loading = false;
      state.loadBatch.success = action.payload;
    },
    loadBatchError(state, action: PayloadAction<string[]>) {
      state.loadBatch.loading = false;
      state.loadBatch.errors = action.payload;
    },
    resetLoadBatch(state) {
      state.loadBatch.success = null;
      state.loadBatch.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadBatchDeps(state, action: PayloadAction<GetBatchDepsQuery>) {
      state.loadBatchDeps.loading = true;
      state.loadBatchDeps.errors = [];
    },
    loadBatchDepsSuccess(state, action: PayloadAction<BatchDepsResponse>) {
      state.loadBatchDeps.loading = false;
      state.loadBatchDeps.success = !!action.payload;
      state.centers = action.payload.centers;
      state.classes = action.payload.classes;
    },
    loadBatchDepsError(state, action: PayloadAction<string[]>) {
      state.loadBatchDeps.loading = false;
      state.loadBatchDeps.errors = action.payload;
    },
    resetLoadBatchDeps(state) {
      state.loadBatchDeps.success = null;
      state.loadBatchDeps.errors = [];
    },
  },
});

export const {
  addBatch,
  addBatchSuccess,
  addBatchError,
  resetAddBatch,
  loadBatch,
  loadBatchSuccess,
  loadBatchError,
  resetLoadBatch,
  loadBatches,
  loadBatchesSuccess,
  loadBatchesError,
  resetLoadBatches,
  loadBatchDeps,
  loadBatchDepsSuccess,
  loadBatchDepsError,
  resetLoadBatchDeps,
} = batchesSlice.actions;

export const {
  name: batchesSliceName,
  reducer: batchesSliceReducer,
} = batchesSlice;

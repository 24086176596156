/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GetCentersQuery,
  GetClassesQuery,
  GetUsersQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  loadInstituteWebsiteBasicInfoDetails,
  loadInstituteWebsiteBasicInfoDetailsError,
  loadInstituteWebsiteBasicInfoDetailsSuccess,
  loadInstituteWebsiteCenterDetails,
  loadInstituteWebsiteCenterDetailsError,
  loadInstituteWebsiteCenterDetailsSuccess,
  loadInstituteWebsiteClassDetails,
  loadInstituteWebsiteClassDetailsError,
  loadInstituteWebsiteClassDetailsSuccess,
  loadInstituteWebsiteCourseDetails,
  loadInstituteWebsiteCourseDetailsError,
  loadInstituteWebsiteCourseDetailsSuccess,
  loadInstituteWebsiteDetails,
  loadInstituteWebsiteDetailsError,
  loadInstituteWebsiteDetailsSuccess,
  loadInstituteWebsiteTeacherDetails,
  loadInstituteWebsiteTeacherDetailsError,
  loadInstituteWebsiteTeacherDetailsSuccess,
} from './institute-home.slice';

export function* loadInstituteWebsiteDetailsAsync(
  action: PayloadAction<string>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteDetailsError(['500']));
  }
}

export function* loadInstituteWebsiteBasicInfoDetailsAsync(
  action: PayloadAction<string>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteBasicInfoDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteBasicInfoDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteBasicInfoDetailsError(['500']));
  }
}

export function* loadInstituteWebsiteCenterDetailsAsync(
  action: PayloadAction<GetCentersQuery>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteCenterDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteCenterDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteCenterDetailsError(['500']));
  }
}

export function* loadInstituteWebsiteCourseDetailsAsync(
  action: PayloadAction<string>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteCourseDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteCourseDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteCourseDetailsError(['500']));
  }
}

export function* loadInstituteWebsiteClassDetailsAsync(
  action: PayloadAction<GetClassesQuery>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteClassDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteClassDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteClassDetailsError(['500']));
  }
}

export function* loadInstituteWebsiteTeacherDetailsAsync(
  action: PayloadAction<GetUsersQuery>,
) {
  const { data } = yield call(
    ApiService.loadInstituteWebsiteTeacherDetails,
    action.payload,
  );

  if (data) {
    yield put(loadInstituteWebsiteTeacherDetailsSuccess(data));
  } else {
    yield put(loadInstituteWebsiteTeacherDetailsError(['500']));
  }
}

export function* watchInstituteHomeAsync() {
  yield takeEvery(
    loadInstituteWebsiteDetails.toString(),
    loadInstituteWebsiteDetailsAsync,
  );
  yield takeEvery(
    loadInstituteWebsiteBasicInfoDetails.toString(),
    loadInstituteWebsiteBasicInfoDetailsAsync,
  );
  yield takeEvery(
    loadInstituteWebsiteCenterDetails.toString(),
    loadInstituteWebsiteCenterDetailsAsync,
  );
  yield takeEvery(
    loadInstituteWebsiteCourseDetails.toString(),
    loadInstituteWebsiteCourseDetailsAsync,
  );
  yield takeEvery(
    loadInstituteWebsiteClassDetails.toString(),
    loadInstituteWebsiteClassDetailsAsync,
  );
  yield takeEvery(
    loadInstituteWebsiteTeacherDetails.toString(),
    loadInstituteWebsiteTeacherDetailsAsync,
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_CURRENCY_SYMBOL } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RegistrationInput, RootState } from '../../common/types';
import { register } from './registration.slice';
import RegistrationForm from './RegistrationForm';

const RegistrationPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { errors, success, loading } = useSelector(
    (state: RootState) => state.registration,
  );
  const { subscriptionPlanFirst } = useSelector(
    (state: RootState) => state.itSetting,
  );
  const currencySymbol = DEFAULT_CURRENCY_SYMBOL;

  function onSubmit(data: RegistrationInput) {
    dispatch(register(data));
  }

  return (
    <div className="limiter">
      <div className="container-login-register">
        {success ? (
          <div className="wrap-login-register-parent">
            <div className="wrap-login-register-child signin-form-section">
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="text-secondary">
                    {LanguageTexts.registration.successMsg}
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="login-register-side-image"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </div>
        ) : (
          <div className="wrap-login-register-parent">
            <div className="wrap-login-register-child signin-form-section">
              <div className="login100-form validate-signin-form">
                {subscriptionPlanFirst ? (
                  <RegistrationForm
                    onSubmit={onSubmit}
                    errors={errors}
                    loading={loading}
                    subscriptionPlan={subscriptionPlanFirst}
                    currencySymbol={currencySymbol}
                  />
                ) : null}
              </div>
            </div>
            <div
              className="login-register-side-image"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

RegistrationPage.defaultProps = {};

export default RegistrationPage;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { CreditCardModel, RootState } from '../../../common/types';

type CreditCardDetailProps = {
  data: CreditCardModel;
  handleSetDefaultPaymentMethod?: (id: string) => void;
  handleDeletePaymentMethod?: (id: string) => void;
  selected?: boolean;
  showPaymentSelectedIcon?: boolean;
};

const CreditCardDetail: React.FC<CreditCardDetailProps> = ({
  data,
  handleSetDefaultPaymentMethod,
  handleDeletePaymentMethod,
  selected,
  showPaymentSelectedIcon,
}: CreditCardDetailProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const {
    setDefaultPaymentMethod: { loading: setDefaultPaymentMethodLoading },
    deletePaymentMethod: { loading: deletePaymentMethodLoading },
  } = useSelector((state: RootState) => state.profile);

  return (
    <div className="my-4">
      <div className="card mb-4 payment-info-detail">
        <div className="card-header">
          <div className="row justify-content-center align-items-center">
            <div className="col-6">
              <h4 className="quaternary-heading">{profileTxt.creditCard}</h4>
            </div>
            <div className="col-6 text-right">
              {data.isDefault ? (
                <p>
                  <b>{profileTxt.defaultPaymentText}</b>
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.cardType}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>{data.brand}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.cardNumber}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>**** {data.last4}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-3 col-12 mb-lg-0 mb-2">
              <p className="font-weight-bold">{profileTxt.cardExpiry}</p>
            </div>
            <div className="col-lg-9 col-12">
              <p>
                {data.expMonth}/{data.expYear}
              </p>
            </div>
          </div>
          <div className="my-4" />
          <div className="row">
            <div className="col-6">
              {!data.isDefault && handleSetDefaultPaymentMethod ? (
                <button
                  type="button"
                  className="btn save-btn"
                  disabled={setDefaultPaymentMethodLoading}
                  onClick={() => handleSetDefaultPaymentMethod(data.id)}
                >
                  {profileTxt.setDefault}&nbsp;
                </button>
              ) : showPaymentSelectedIcon ? null : selected ? (
                <>
                  <i className="fas fa-check-circle text-success fa-1x" />
                  <span className="text-secondary ml-2">
                    {profileTxt.selectedPaymentMethodText}
                  </span>
                </>
              ) : null}
            </div>
            <div className="col-6 text-right">
              {!data.isDefault && handleDeletePaymentMethod ? (
                <button
                  type="button"
                  className="btn cancel-btn"
                  disabled={deletePaymentMethodLoading}
                  onClick={() => handleDeletePaymentMethod(data.id)}
                >
                  {profileTxt.delete}&nbsp;
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreditCardDetail.defaultProps = {
  handleSetDefaultPaymentMethod: undefined,
  handleDeletePaymentMethod: undefined,
  selected: false,
  showPaymentSelectedIcon: false,
};

export default CreditCardDetail;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react';

import { WEEK_DAYS } from '../../../common/constants';
import { isDayAllowed } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import BatchTimePicker from './BatchTimePicker';

type BatchScheduleProps = {
  day: string;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
};

type BatchWeeklyScheduleProps = {
  schedule: BatchScheduleProps[];
  onChange: (schedule: BatchScheduleProps[]) => void;
  errors: string[];
  disabled?: boolean;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  timezone: string;
};

const BatchWeeklySchedule: React.FC<BatchWeeklyScheduleProps> = ({
  schedule: scheduleInit,
  onChange,
  errors,
  disabled,
  startDate,
  endDate,
  timezone,
}: BatchWeeklyScheduleProps): JSX.Element => {
  const { batches: batchsTxt } = LanguageTexts;
  const headerStyle = { cursor: 'pointer' };
  const disabledStyle = { opacity: '0.5' };

  const schedule = useMemo(() => {
    return scheduleInit.sort((a, b) => +a.day - +b.day);
  }, [scheduleInit]);

  function isDaySelected(day: string): boolean {
    return !!schedule.find(({ day: sDay }) => day === sDay);
  }

  const weekDays = useMemo(() => {
    return WEEK_DAYS.map(({ value, ...rest }) => {
      return {
        ...rest,
        value,
        dayAllowed:
          startDate && endDate
            ? isDayAllowed(value, startDate, endDate, timezone)
            : false,
      };
    });
  }, [startDate, endDate, timezone]);

  const scheduleDisplay = useMemo(() => {
    return schedule.map(({ day, ...rest }) => {
      return {
        ...rest,
        day,
        dayAllowed:
          startDate && endDate
            ? isDayAllowed(day, startDate, endDate, timezone)
            : false,
      };
    });
  }, [schedule, startDate, endDate, timezone]);

  function toggleSelectedDay(day: string) {
    const dayObj = schedule.find(({ day: sDay }) => day === sDay);
    let newSchedule;

    if (dayObj) {
      newSchedule = schedule.filter(({ day: sDay }) => day !== sDay);
    } else {
      newSchedule = [
        ...schedule,
        { day, startTime: undefined, endTime: undefined },
      ];
    }

    onChange(newSchedule.sort((a, b) => +a.day - +b.day));
  }

  function onTimeChange(index: number, type: string, time: moment.Moment) {
    const newSchedule = [...schedule];
    const sItem = { ...newSchedule[index] };

    // reset end time if before start time
    if (
      (type === 'startTime' &&
        time &&
        sItem.endTime &&
        sItem.endTime.isSameOrBefore(time)) ||
      (type === 'endTime' &&
        time &&
        sItem.startTime &&
        time.isSameOrBefore(sItem.startTime))
    ) {
      sItem.endTime = undefined;

      if (type === 'startTime') {
        sItem.startTime = time;
      }

      newSchedule[index] = sItem;
    } else {
      newSchedule[index] = {
        ...sItem,
        [type]: time,
      };
    }

    onChange(newSchedule);
  }

  return (
    <div className="weekly-schedule">
      <label className="text-secondary small">
        {batchsTxt.selectDays}&nbsp;
        <sup className="common-text-color require-field">
          <span className="fa fa-star-of-life" />
        </sup>
      </label>
      <div className="my-2" />
      <table className="table table-condensed table-bordered">
        <tbody>
          <tr className="text-center">
            {weekDays.map(({ label, value, dayAllowed }) => {
              return (
                <th
                  key={`wdheader${value}`}
                  className={
                    isDaySelected(value)
                      ? 'text-center weekly-selected-day text-white'
                      : 'text-center bg-light text-secondary'
                  }
                  style={dayAllowed ? headerStyle : disabledStyle}
                  onClick={() => (dayAllowed ? toggleSelectedDay(value) : null)}
                >
                  {label}
                </th>
              );
            })}
          </tr>
        </tbody>
      </table>

      {scheduleDisplay.map(({ day, startTime, endTime, dayAllowed }, index) => {
        const dayLabel = WEEK_DAYS.find(({ value }) => value === day)?.label;

        return (
          <div key={`wdday${day}`}>
            <div className="my-3" />
            <div className="form-group row">
              <AppInputLableWithError
                errors={errors}
                langTxt={batchsTxt}
                id="schedule.startTime"
                required
                errId={`${index}.startTime`}
                className="col-lg-3 col-12 col-form-label small"
              />
              <div className="col-lg-9 col-12">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <b>{dayLabel}</b>
                    </span>
                  </div>
                  <BatchTimePicker
                    value={startTime}
                    disabled={disabled || !dayAllowed}
                    onChange={(newVal) =>
                      onTimeChange(index, 'startTime', newVal)
                    }
                    timezone={timezone}
                  />
                </div>
              </div>
            </div>
            <div className="my-3" />
            <div className="form-group row">
              <AppInputLableWithError
                errors={errors}
                langTxt={batchsTxt}
                id="schedule.endTime"
                required
                errId={`${index}.endTime`}
                className="col-lg-3 col-12 col-form-label small"
              />
              <div className="col-lg-9 col-12">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <b>{dayLabel}</b>
                    </span>
                  </div>
                  <BatchTimePicker
                    value={endTime}
                    disabled={disabled || !dayAllowed}
                    onChange={(newVal) =>
                      onTimeChange(index, 'endTime', newVal)
                    }
                    timezone={timezone}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

BatchWeeklySchedule.defaultProps = {
  disabled: false,
};

export default BatchWeeklySchedule;

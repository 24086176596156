/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  InstituteBasicInfoInput,
  InstituteCourseInput,
  InstitutePaymentInfoInput,
  InstituteWebsiteInfoInput,
  WebsiteMediaInput,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  loadInstituteSetupInfo,
  loadInstituteSetupInfoError,
  loadInstituteSetupInfoSuccess,
  removeCourseImage,
  removeCourseImageError,
  removeCourseImageSuccess,
  removeInstituteAboutImage,
  removeInstituteAboutImageError,
  removeInstituteAboutImageSuccess,
  removeInstituteLogo,
  removeInstituteLogoError,
  removeInstituteLogoSuccess,
  removeInstituteWebsiteMedia,
  removeInstituteWebsiteMediaError,
  removeInstituteWebsiteMediaSuccess,
  removeTestimonialImage,
  removeTestimonialImageError,
  removeTestimonialImageSuccess,
  saveInstituteBasicInfo,
  saveInstituteBasicInfoError,
  saveInstituteBasicInfoSuccess,
  saveInstituteCourses,
  saveInstituteCoursesError,
  saveInstituteCoursesSuccess,
  saveInstitutePaymentInfo,
  saveInstitutePaymentInfoError,
  saveInstitutePaymentInfoSuccess,
  saveInstituteWebsiteInfo,
  saveInstituteWebsiteInfoError,
  saveInstituteWebsiteInfoSuccess,
  saveWebsiteMedia,
  saveWebsiteMediaError,
  saveWebsiteMediaSuccess,
  setActiveTab,
} from './institute-setup.slice';

export function* loadInstituteSetupInfoAsync() {
  const { data, errors } = yield call(ApiService.loadInstituteSetupInfo);

  if (errors) {
    yield put(loadInstituteSetupInfoError(errors));
  } else {
    yield put(loadInstituteSetupInfoSuccess(data));
  }
}

export function* saveInstituteBasicInfoAsync(
  action: PayloadAction<InstituteBasicInfoInput>,
) {
  const { data, errors } = yield call(
    ApiService.saveInstituteBasicInfo,
    action.payload,
  );

  if (errors) {
    yield put(saveInstituteBasicInfoError(errors));
  } else {
    yield put(saveInstituteBasicInfoSuccess(data));
    yield put(setActiveTab('1'));
  }
}

export function* saveInstituteWebsiteInfoAsync(
  action: PayloadAction<InstituteWebsiteInfoInput>,
) {
  const { data, errors } = yield call(
    ApiService.saveInstituteWebsiteInfo,
    action.payload,
  );

  if (errors) {
    yield put(saveInstituteWebsiteInfoError(errors));
  } else {
    yield put(saveInstituteWebsiteInfoSuccess(data));
    yield put(setActiveTab('2'));
  }
}

export function* saveInstituteCoursesAsync(
  action: PayloadAction<InstituteCourseInput[]>,
) {
  const { data, errors } = yield call(
    ApiService.saveInstituteCourses,
    action.payload,
  );

  if (errors) {
    yield put(saveInstituteCoursesError(errors));
  } else {
    yield put(saveInstituteCoursesSuccess(data));
    yield put(setActiveTab('3'));
  }
}

export function* saveWebsiteMediaAsync(
  action: PayloadAction<{
    input: WebsiteMediaInput[];
    defaultMainImage: string;
  }>,
) {
  const { data, errors } = yield call(
    ApiService.saveWebsiteMedia,
    action.payload,
  );

  if (errors) {
    yield put(saveWebsiteMediaError(errors));
  } else {
    yield put(saveWebsiteMediaSuccess(data));
    yield put(setActiveTab('4'));
  }
}

export function* removeInstituteLogoAsync() {
  const { data, errors } = yield call(ApiService.removeInstituteLogo);

  if (errors) {
    yield put(removeInstituteLogoError(errors));
  } else {
    yield put(removeInstituteLogoSuccess(data));
  }
}

export function* removeInstituteAboutImageAsync() {
  const { data, errors } = yield call(ApiService.removeInstituteAboutImage);

  if (errors) {
    yield put(removeInstituteAboutImageError(errors));
  } else {
    yield put(removeInstituteAboutImageSuccess(data));
  }
}

export function* removeTestimonialImageAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.removeTestimonialImage,
    action.payload,
  );

  if (errors) {
    yield put(removeTestimonialImageError(errors));
  } else {
    yield put(
      removeTestimonialImageSuccess({ success: data, id: action.payload }),
    );
  }
}

export function* removeCourseImageAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.removeCourseImage,
    action.payload,
  );

  if (errors) {
    yield put(removeCourseImageError(errors));
  } else {
    yield put(removeCourseImageSuccess({ success: data, id: action.payload }));
  }
}

export function* removeInstituteWebsiteMediaAsync(
  action: PayloadAction<string>,
) {
  const { data, errors } = yield call(
    ApiService.removeInstituteWebsiteMedia,
    action.payload,
  );

  if (errors) {
    yield put(removeInstituteWebsiteMediaError(errors));
  } else {
    yield put(
      removeInstituteWebsiteMediaSuccess({ success: data, id: action.payload }),
    );
  }
}

export function* saveInstitutePaymentInfoAsync(
  action: PayloadAction<InstitutePaymentInfoInput>,
) {
  const { data, errors } = yield call(
    ApiService.saveInstitutePaymentInfo,
    action.payload,
  );

  if (errors) {
    yield put(saveInstitutePaymentInfoError(errors));
  } else {
    yield put(saveInstitutePaymentInfoSuccess(data));
  }
}

export function* watchInstituteSetupAsync() {
  yield takeEvery(
    loadInstituteSetupInfo.toString(),
    loadInstituteSetupInfoAsync,
  );
  yield takeEvery(
    saveInstituteBasicInfo.toString(),
    saveInstituteBasicInfoAsync,
  );
  yield takeEvery(
    saveInstituteWebsiteInfo.toString(),
    saveInstituteWebsiteInfoAsync,
  );
  yield takeEvery(saveInstituteCourses.toString(), saveInstituteCoursesAsync);
  yield takeEvery(saveWebsiteMedia.toString(), saveWebsiteMediaAsync);
  yield takeEvery(removeInstituteLogo.toString(), removeInstituteLogoAsync);
  yield takeEvery(
    removeInstituteAboutImage.toString(),
    removeInstituteAboutImageAsync,
  );
  yield takeEvery(
    removeTestimonialImage.toString(),
    removeTestimonialImageAsync,
  );
  yield takeEvery(removeCourseImage.toString(), removeCourseImageAsync);
  yield takeEvery(
    removeInstituteWebsiteMedia.toString(),
    removeInstituteWebsiteMediaAsync,
  );
  yield takeEvery(
    saveInstitutePaymentInfo.toString(),
    saveInstitutePaymentInfoAsync,
  );
}

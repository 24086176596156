import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateAttendanceInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import AttendanceListPage from './AttendanceListPage';
import AttendanceRecordPage from './AttendanceRecordPage';
import { addAttendance, resetAddAttendanceSuccess } from './attendances.slice';

const AttendancesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addAttendance: {
      loading: addAttendanceLoading,
      success: addAttendanceSuccess,
      errors: addAttendanceErrors,
    },
  } = useSelector((state: RootState) => state.attendances);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateAttendanceInput) {
    dispatch(
      addAttendance({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addAttendanceSuccess) {
      dispatch(resetAddAttendanceSuccess());
    }
  }, [addAttendanceSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.AttendancesCreate}
          permissions={rPermission(AppRoutePath.AttendancesCreate)}
        >
          {!addAttendanceSuccess ? (
            <AttendanceRecordPage
              key="createattendanceform"
              errors={addAttendanceErrors}
              loading={addAttendanceLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <AttendanceListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default AttendancesPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import matchSorter from 'match-sorter';
import React, { useMemo, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import LanguageTexts from '../common/language';

// Define a default UI for filtering
function GlobalFilter({
  // preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((newValue) => {
    setGlobalFilter(newValue || undefined);
  }, 200);
  const { app: appTxt } = LanguageTexts;

  return (
    <span>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${appTxt.tableSearchPlaceholderText}`}
        className="form-control form-control-sm"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
      className="form-control form-control-sm"
    />
  );
}

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

type AppTableProps = {
  columns: any[];
  data: any[];
  disableFilters?: boolean;
  renderRowSubComponent?: ((data: { row: any }) => JSX.Element) | undefined;
};

const AppTable: React.FC<AppTableProps> = ({
  columns,
  data,
  disableFilters,
  renderRowSubComponent,
}: AppTableProps): JSX.Element => {
  const { app: appTxt } = LanguageTexts;

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  // Use the state and functions returned from useTable to build your UI
  const tableProps: any = {
    columns,
    data,
    disableFilters,
    initialState: { pageIndex: 0, pageSize: 10 },
    defaultColumn, // Be sure to pass the defaultColumn option
    filterTypes,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },

    // for fiters
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  }: any = useTable(
    tableProps,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  // Render the UI for your table
  return (
    <>
      <BTable size="md" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    <p>
                      <b>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span
                          className={
                            // eslint-disable-next-line no-nested-ternary
                            column.isSorted
                              ? column.isSortedDesc
                                ? `fa fa-caret-down ml-2`
                                : `fa fa-caret-up ml-2`
                              : ''
                          }
                        />
                      </b>
                    </p>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
          {!disableFilters ? (
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: 'left',
                }}
                className="search-filter"
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          ) : null}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            const { key: rKey, ...restRowProps } = row.getRowProps();

            return (
              <React.Fragment key={rKey}>
                <tr {...restRowProps}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {renderRowSubComponent && row.isExpanded ? (
                  <tr {...restRowProps}>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
          {page.length === 0 ? (
            <tr>
              <td colSpan={visibleColumns.length}>
                <p style={{ textAlign: 'center' }}>{appTxt.noData}</p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </BTable>
      {!disableFilters ? (
        <div className="row">
          <div className="col-3">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="custom-select show-rows"
            >
              {[5, 10, 20, 50, 100].map((pageSizeItem) => (
                <option key={pageSizeItem} value={pageSizeItem}>
                  Show {pageSizeItem}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6" />
          <div className="col-3">
            <div className="d-flex justify-content-end list-table-pagination">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <i className="fa fa-angle-left" />
              </button>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: appTxt.tablePageNumberText
                    .replace('pageIndex', `${pageIndex + 1}`)
                    .replace('length', `${pageOptions.length}`),
                }}
              />
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <i className="fa fa-angle-right" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* {!disableFilters ? (
        <div className="row">
          <div className="col-3">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="custom-select show-rows"
            >
              {[10, 20, 50, 100].map((pageSizeItem) => (
                <option key={pageSizeItem} value={pageSizeItem}>
                  Show {pageSizeItem}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6 d-flex flex-column align-items-center justify-content-center">
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: appTxt.tablePageNumberText
                  .replace('pageIndex', `${pageIndex + 1}`)
                  .replace('length', `${pageOptions.length}`),
              }}
              className="d-md-block d-none"
            />
          </div>
          <div className="col-3">
            <Pagination className="d-flex justify-content-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </div>
        </div>
      ) : null} */}
      {/* {!disableFilters ? (
        <div className="row">
          <div className="col-4">&nbsp;</div>
          <div className="col-2">
            <Pagination>
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              &nbsp;
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              &nbsp;
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              &nbsp;
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </div>
          <div className="col-4">
            <span>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                | Go to page:{' '}
                <input
                  type="number"
                  value={pageIndex + 1}
                  onChange={(e) => {
                    const pageTo = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;

                    if (pageTo >= 0 && pageTo < pageOptions.length) {
                      gotoPage(pageTo);
                    }
                  }}
                  className="form-control form-control-sm"
                  style={{ display: 'inline', width: '100px' }}
                />
              </span>
            </span>
          </div>
          <div className="col-2">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="custom-select"
            >
              {[50, 100].map((pageSizeItem) => (
                <option key={pageSizeItem} value={pageSizeItem}>
                  Show {pageSizeItem}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : null} */}
    </>
  );
};

AppTable.defaultProps = {
  disableFilters: false,
  renderRowSubComponent: undefined,
};

export default AppTable;

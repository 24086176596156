/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { RootState, UploadProfilePhotoInput } from '../../../common/types';
import ImageUpload from '../../../components/ImageUpload';
import { loadProfileInfo } from '../profile.slice';

type UploadProfilePhotoFormProps = {
  onSubmit: (data: UploadProfilePhotoInput) => void;
  onRemoveProfilePhoto: (id: string) => void;
  loading: boolean;
  initialValues: UploadProfilePhotoInput;
};

const UploadProfilePhotoForm: React.FC<UploadProfilePhotoFormProps> = ({
  onSubmit,
  onRemoveProfilePhoto,
  loading,
  initialValues,
}: UploadProfilePhotoFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { profile: profileTxt } = LanguageTexts;

  const { app: appTxt } = LanguageTexts;

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { user } = useSelector((state: RootState) => state.login);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadProfileInfo());
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded]);

  console.log('Profile Pic', user?.profilePicUrl);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        if (selectedImage) {
          input.profilePic = selectedImage;
        }

        onSubmit(input);
        setSubmitting(false);
      }}
    >
      {() => {
        return (
          <Form>
            <div className="form">
              <div className="row">
                <div className="col-md-6 col-12">
                  <ImageUpload
                    id="upload-profile-photo"
                    label={profileTxt['input.profileImage']}
                    onFileSelect={(file) => {
                      setSelectedImage(file);
                    }}
                    url={user?.profilePicUrl}
                    maxFileSize={250}
                    fileResolution={appTxt.resolutionSize}
                    disabled={loading}
                    onRemoveUrl={() =>
                      user?._id ? onRemoveProfilePhoto(user?._id) : null
                    }
                  />
                </div>
              </div>
              <div className="my-md-3" />
              <div className="row">
                <div className="col-md-6 col-12" />
                <div className="col-md-6 col-12 text-right">
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {profileTxt.uploadProfilePhotoBtn}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UploadProfilePhotoForm;

/* eslint-disable jsx-a11y/label-has-associated-control */

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppRoutePath,
  USER_ROLES_PERMISSIONS,
  UserRole,
  UserStatus,
} from '../../common/constants';
import { rolesHasPermission } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateUserInput, RootState, UserModel } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import UserForm from './UserForm';
import {
  loadUser,
  loadUserDeps,
  resetLoadUser,
  resetLoadUserDeps,
} from './users.slice';

type UserRecordPageParams = {
  onSubmit: (data: CreateUserInput) => void;
  errors: string[];
  loading: boolean;
};

const UserRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: UserRecordPageParams): JSX.Element => {
  const { userId } = useParams<{ userId: string }>();
  const { users: usersTxt, app: appTxt } = LanguageTexts;

  const [role, setRole] = useState<UserRole>();
  const [canModify, setCanModify] = useState<boolean>(true);
  const [addParent, setAddParent] = useState<string>();
  const [userLoaded, setUserLoaded] = useState<boolean>();
  const [user, setUser] = useState<UserModel>();
  const [userDepsLoadRequested, setUserDepsRequested] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: { role: selectedRole },
  } = useSelector((state: RootState) => state.app);
  const {
    loadUser: {
      loading: loadUserLoading,
      errors: loadUserErrors,
      success: loadUserSuccess,
    },
    loadUserDeps: {
      loading: loadUserDepsLoading,
      errors: loadUserDepsErrors,
      success: loadUserDepsSuccess,
    },
    parents,
    centers,
    batches,
  } = useSelector((state: RootState) => state.users);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const viewPermissions = USER_ROLES_PERMISSIONS.filter(
    ({ permissionView }) => {
      return rolesHasPermission(userRoles, permissionView);
    },
  );

  const modifyPermissions = USER_ROLES_PERMISSIONS.filter(
    ({ permissionModify }) => {
      return rolesHasPermission(userRoles, permissionModify);
    },
  );

  // load user
  useEffect(() => {
    if (userId && !userLoaded) {
      dispatch(loadUser(userId));
      setUserLoaded(true);
    }
  }, [userLoaded, userId, dispatch]);

  useEffect(() => {
    if (loadUserErrors.length > 0) {
      dispatch(resetLoadUser());
      history.push(AppRoutePath.Users);
    } else if (loadUserSuccess) {
      setUser(loadUserSuccess);
      dispatch(resetLoadUser());

      const userRole = viewPermissions.find(({ roles }) => {
        const filteredRoles = roles.filter((roleVal) => {
          return loadUserSuccess.role.includes(roleVal);
        });

        return (
          filteredRoles.length === roles.length &&
          filteredRoles.length === loadUserSuccess.role.length
        );
      });

      const canModifyRole = modifyPermissions.find(({ roles }) => {
        const filteredRoles = roles.filter((roleVal) => {
          return loadUserSuccess.role.includes(roleVal);
        });

        return (
          filteredRoles.length === roles.length &&
          filteredRoles.length === loadUserSuccess.role.length
        );
      });

      setCanModify(!!canModifyRole);

      if (userRole) {
        setRole(userRole.key);

        if (userRole.key === UserRole.Student) {
          setAddParent('no');
        }
      } else {
        history.push(AppRoutePath.Users);
      }
    }
  }, [
    viewPermissions,
    modifyPermissions,
    loadUserErrors,
    loadUserSuccess,
    dispatch,
    history,
  ]);

  // load user deps
  useEffect(() => {
    if (!userDepsLoadRequested) {
      dispatch(
        loadUserDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Parent],
        }),
      );
      setUserDepsRequested(true);
    }
  }, [currentUser, userDepsLoadRequested, dispatch]);

  useEffect(() => {
    if (loadUserDepsErrors.length > 0) {
      dispatch(resetLoadUserDeps());
      history.push(AppRoutePath.Users);
    } else if (loadUserDepsSuccess) {
      dispatch(resetLoadUserDeps());
    }
  }, [loadUserDepsSuccess, loadUserDepsErrors, dispatch, history]);

  function handleRoleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val: any = e.target.value;
    setRole(undefined);
    setTimeout(() => {
      setRole(val);
    }, 0);
  }

  function onSubmit({ ...restInput }: CreateUserInput) {
    onSubmitParent({
      ...restInput,
      role: modifyPermissions.find(({ key }) => key === role)?.roles || [],
    });
  }

  const filteredCenters = useMemo(() => {
    let centerIds: string[] = [];

    if (userRoles.indexOf(UserRole.CenterHead) !== -1) {
      centerIds = currentUser?.centerIds || [];
    }

    return centers?.filter(({ _id }) => {
      if (centerIds.length > 0) {
        return centerIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, centers, userRoles]);

  const filteredBatches = useMemo(() => {
    let batchIds: string[] = [];

    if (userRoles.indexOf(UserRole.CenterHead) !== -1) {
      if (userRoles.indexOf(UserRole.Teacher) !== -1) {
        batchIds = currentUser?.batches?.map(({ batch: { _id } }) => _id) || [];
      }

      batchIds = [
        ...batchIds,
        ...(batches
          ?.filter(
            ({ centerId }) => currentUser?.centerIds?.indexOf(centerId) !== -1,
          )
          .map(({ _id }) => _id) || []),
      ];
    } else if (userRoles.indexOf(UserRole.Teacher) !== -1) {
      batchIds = currentUser?.batches?.map(({ batch: { _id } }) => _id) || [];
    }

    return batches?.filter(({ _id }) => {
      if (batchIds.length > 0) {
        return batchIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, batches, userRoles]);

  if (
    (userId && (!userLoaded || loadUserLoading || user === undefined)) ||
    !userDepsLoadRequested ||
    loadUserDepsLoading
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let userInfo: any;

  if (user) {
    const {
      name: fullName,
      batches: userBatches,
      username,
      email,
      ...restUser
    } = user;
    const [firstName, lastName] = fullName ? fullName.split(' ') : [];
    userInfo = {
      ...restUser,
      firstName,
      lastName,
      email: email || '',
      username: username || '',
      assignParent: !!restUser.parentId,
      batches: userBatches?.map(
        ({ enrollmentDate, inactiveDate, ...restBatch }) => {
          return {
            ...restBatch,
            enrollmentDate: enrollmentDate
              ? moment(enrollmentDate).format('L')
              : undefined,
            inactiveDate: inactiveDate
              ? moment(inactiveDate).format('L')
              : undefined,
          };
        },
      ),
    };
  }

  const initVals: CreateUserInput = userInfo || {
    status: UserStatus.Active,
    firstName: '',
    lastName: '',
    email: '',
    gender: undefined,
    phone: '',
    description: '',
    parentId: '',
    username: '',
    assignParent: false,
    role: [],
    centerIds: undefined,
    batches: undefined,
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {userId ? usersTxt.updateUser : usersTxt.newUser}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="text-secondary small">
                {usersTxt.role}
                <sup className="common-text-color require-field">
                  <span className="fa fa-star-of-life" />
                </sup>
              </label>
              <select
                name="selectSm"
                id="selectSm"
                className="custom-select select-user-role"
                value={role}
                onChange={handleRoleChange}
                disabled={!!userId}
              >
                <option value="">{usersTxt.addUserAs}</option>
                {viewPermissions.map(({ key, title }) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="my-3" />
        {role === UserRole.Student && !addParent ? (
          <div className="row">
            <div className="col-12 text-center">
              <p className="mb-1">{usersTxt.addParentQue}</p>
              <button
                className="btn is-parent-assigned-btn mr-2"
                type="button"
                onClick={() => {
                  setRole(UserRole.Parent);
                }}
              >
                {usersTxt.yes}
              </button>
              <button
                className="btn is-parent-assigned-btn"
                type="button"
                onClick={() => setAddParent('no')}
              >
                {usersTxt.no}
              </button>
            </div>
          </div>
        ) : null}
        {role &&
        (role !== UserRole.Student ||
          (role === UserRole.Student && addParent === 'no')) &&
        parents !== undefined ? (
          <UserForm
            parents={parents || []}
            centers={filteredCenters || []}
            batches={filteredBatches || []}
            initialValues={initVals}
            role={role}
            onSubmit={onSubmit}
            loading={loading}
            errors={errors}
            canModify={canModify}
          />
        ) : null}
      </div>
    </section>
  );
};

export default UserRecordPage;

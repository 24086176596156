import 'rc-time-picker/assets/index.css';

import moment from 'moment-timezone';
import TimePicker from 'rc-time-picker';
import React, { FC } from 'react';

type BatchTimePickerProps = {
  value: moment.Moment | undefined;
  timezone: string;
  onChange: (newValue: moment.Moment) => void;
  disabled?: boolean;
};

const BatchTimePicker: FC<BatchTimePickerProps> = ({
  value,
  disabled,
  timezone,
  onChange,
}: BatchTimePickerProps) => {
  return (
    <TimePicker
      value={value || undefined}
      defaultOpenValue={moment
        .tz(undefined, timezone)
        .set({ hour: 23, minute: 59, second: 59 })}
      disabled={disabled}
      showSecond={false}
      use12Hours
      onChange={(newValue) =>
        onChange(newValue.set({ second: 0, millisecond: 0 }).clone())
      }
      minuteStep={15}
      inputReadOnly
    />
  );
};

BatchTimePicker.defaultProps = {
  disabled: false,
};

export default BatchTimePicker;

/* eslint-disable jsx-a11y/label-has-associated-control */
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CenterModel, CreateCenterInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadCenter,
  loadCenterDeps,
  resetLoadCenter,
  resetLoadCenterDeps,
} from './institute-centers.slice';
import InstituteCenterForm from './InstituteCenterForm';

type InstituteCenterRecordPageParams = {
  onSubmit: (data: CreateCenterInput) => void;
  errors: string[];
  loading: boolean;
};

const InstituteCenterRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: InstituteCenterRecordPageParams): JSX.Element => {
  const { centerId } = useParams<{ centerId: string }>();
  const { centers: centersTxt, app: appTxt } = LanguageTexts;

  const [centerLoaded, setCenterLoaded] = useState<boolean>();
  const [centers, setCenters] = useState<CenterModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadCenter: {
      loading: loadCenterLoading,
      errors: loadCenterErrors,
      success: loadCenterSuccess,
    },
    loadCenterDeps: {
      loading: loadCenterDepsLoading,
      errors: loadCenterDepsErrors,
      success: loadCenterDepsSuccess,
    },
    cities,
  } = useSelector((state: RootState) => state.centers);

  const [centerDepsLoadRequested, setCenterDepsRequested] = useState<boolean>();

  useEffect(() => {
    if (centerId && !centerLoaded) {
      dispatch(loadCenter(centerId));
      setCenterLoaded(true);
    }
  }, [centerLoaded, centerId, dispatch]);

  useEffect(() => {
    if (loadCenterErrors.length > 0) {
      dispatch(resetLoadCenter());
      history.push(AppRoutePath.Centers);
    } else if (loadCenterSuccess) {
      setCenters(loadCenterSuccess);
      dispatch(resetLoadCenter());
    }
  }, [loadCenterErrors, loadCenterSuccess, dispatch, history]);

  // load center deps
  useEffect(() => {
    if (!centerDepsLoadRequested) {
      dispatch(loadCenterDeps());
      setCenterDepsRequested(true);
    }
  }, [centerDepsLoadRequested, dispatch]);

  useEffect(() => {
    if (loadCenterDepsErrors.length > 0) {
      dispatch(resetLoadCenterDeps());
      history.push(AppRoutePath.Centers);
    } else if (loadCenterDepsSuccess) {
      dispatch(resetLoadCenterDeps());
    }
  }, [loadCenterDepsSuccess, loadCenterDepsErrors, dispatch, history]);

  function onSubmit({ ...restInput }: CreateCenterInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  if (
    (centerId && (!centerLoaded || loadCenterLoading)) ||
    !centerDepsLoadRequested ||
    loadCenterDepsLoading
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let centerInfo: any;

  if (centers) {
    centerInfo = cloneDeep(centers);
  }

  const centerInfoInit: CreateCenterInput = centerInfo || {
    email: '',
    name: '',
    phone: '',
    address: {
      address1: '',
      address2: '',
      zipcode: '',
      cityId: '',
      stateId: '',
      countryId: '',
    },
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {centerId ? centersTxt.updateCenter : centersTxt.newCenter}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <InstituteCenterForm
          onSubmit={onSubmit}
          cities={cities || []}
          initialValues={centerInfoInit}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default InstituteCenterRecordPage;

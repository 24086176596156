/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AssignmentSubmissionFormat,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import FileUpload from '../../components/FileUpload';

type AssignmentSubmissionFormData = {
  _id?: string;
  assignmentId: string;
  studentId: string;
  submittedAs: string;
  submissionStatus: string;
  comment: string;
  grade: string;
  assignmentSubmissionFormat: string;
  assignmentUrl: string;
  assignmentFile: File | undefined;
};

type AssignmentSubmissionProps = {
  onSubmit: (data: AssignmentSubmissionFormData) => void;
  loading: boolean;
  errors: string[];
  initialValues: AssignmentSubmissionFormData;
  userRoles: UserRole[];
};

const AssignmentSubmissionForm: React.FC<AssignmentSubmissionProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  userRoles,
}: AssignmentSubmissionProps): JSX.Element => {
  const { assignments: assignmentsTxt } = LanguageTexts;
  const { assignmentSubmission } = useSelector(
    (state: RootState) => state.assignments,
  );

  const [
    selectedAssignmentSubmissionFile,
    setAssignmentSubmissionFile,
  ] = useState<File | null>(null);

  const { ...initVal } = initialValues;

  return (
    <Formik
      initialValues={initVal}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        switch (input.assignmentSubmissionFormat) {
          case AssignmentSubmissionFormat.File:
            if (selectedAssignmentSubmissionFile) {
              input.assignmentFile = selectedAssignmentSubmissionFile;
            }
            input.assignmentUrl = '';
            break;
          case AssignmentSubmissionFormat.Url:
            input.assignmentFile = undefined;
            break;
          default:
            break;
        }

        onSubmit(input);

        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
          assignmentFile: null,
        });
        const formattedErrors = formatErrors(errorKeys, errors, assignmentsTxt);

        return (
          <Form>
            <div
              className={
                userRoles.includes(UserRole.Teacher)
                  ? `border common-border-color p-4 mb-4`
                  : ``
              }
            >
              {userRoles.includes(UserRole.Teacher) ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="secondary-heading mb-4">
                        {assignmentsTxt.remarkFormHeading}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={assignmentsTxt}
                        id="input.submissionStatus"
                        required
                      />
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          type="radio"
                          name="submissionStatus"
                          className="custom-control-input"
                          value={AssignmentSubmissionStatus.Completed}
                          id="completed-submissionStatus"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="completed-submissionStatus"
                        >
                          {assignmentsTxt.completed}
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          type="radio"
                          name="submissionStatus"
                          className="custom-control-input"
                          value={AssignmentSubmissionStatus.Resubmit}
                          id="resubmit-submissionStatus"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="resubmit-submissionStatus"
                        >
                          {assignmentsTxt.resubmit}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="my-3" />
                </>
              ) : null}

              <div
                className={
                  userRoles.includes(UserRole.Teacher) ? `bg-white` : ``
                }
              >
                {userRoles.includes(UserRole.Teacher) ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <AppInputLableWithError
                            errors={errors}
                            langTxt={assignmentsTxt}
                            id="input.comment"
                            required
                          />
                          <Field
                            name="comment"
                            as="textarea"
                            className="form-control"
                            rows={5}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="my-3" />
                  </>
                ) : null}

                {userRoles.includes(UserRole.Teacher) ? (
                  <>
                    <div className="row">
                      <div className="col-2">
                        <div className="form-group">
                          <AppInputLableWithError
                            errors={errors}
                            langTxt={assignmentsTxt}
                            id="input.grade"
                          />
                          <Field name="grade" className="form-control" />
                        </div>
                      </div>
                    </div>

                    <div className="my-3" />
                  </>
                ) : null}

                <div
                  className={
                    userRoles.includes(UserRole.Student) &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Submitted &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Resubmitted &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Completed
                      ? `border common-border-color p-4 mb-4`
                      : ``
                  }
                >
                  {(userRoles.includes(UserRole.Student) &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Submitted &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Resubmitted &&
                    assignmentSubmission?.submissionStatus !==
                      AssignmentSubmissionStatus.Completed) ||
                  userRoles.includes(UserRole.Teacher) ? (
                    <>
                      <div
                        className={
                          userRoles.includes(UserRole.Student) &&
                          assignmentSubmission?.submissionStatus !==
                            AssignmentSubmissionStatus.Submitted &&
                          assignmentSubmission?.submissionStatus !==
                            AssignmentSubmissionStatus.Resubmitted &&
                          assignmentSubmission?.submissionStatus !==
                            AssignmentSubmissionStatus.Completed
                            ? `text-secondary`
                            : ``
                        }
                      >
                        {userRoles.includes(UserRole.Student) &&
                        assignmentSubmission?.submissionStatus !==
                          AssignmentSubmissionStatus.Submitted &&
                        assignmentSubmission?.submissionStatus !==
                          AssignmentSubmissionStatus.Resubmitted &&
                        assignmentSubmission?.submissionStatus !==
                          AssignmentSubmissionStatus.Completed ? (
                          <div className="row">
                            <div className="col-12">
                              <h2 className="secondary-heading mb-4">
                                Submit Assignment
                              </h2>
                            </div>
                          </div>
                        ) : null}
                        <div className="row">
                          <div className="col-12">
                            <AppInputLableWithError
                              errors={errors}
                              langTxt={assignmentsTxt}
                              id="input.assignmentSubmissionFormat"
                              required={userRoles.includes(UserRole.Student)}
                            />
                            <div className="my-2" />
                            <div className="custom-control custom-radio custom-control-inline">
                              <Field
                                type="radio"
                                name="assignmentSubmissionFormat"
                                className="custom-control-input"
                                value={AssignmentSubmissionFormat.Url}
                                id="url-assignmentSubmissionFormat"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="url-assignmentSubmissionFormat"
                              >
                                {assignmentsTxt['input.assignmentUrl']}
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <Field
                                type="radio"
                                name="assignmentSubmissionFormat"
                                className="custom-control-input"
                                value={AssignmentSubmissionFormat.File}
                                id="file-assignmentSubmissionFormat"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="file-assignmentSubmissionFormat"
                              >
                                {assignmentsTxt['input.assignmentFile']}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div
                    className={
                      userRoles.includes(UserRole.Student) &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Submitted &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Resubmitted &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Completed
                        ? `bg-white`
                        : ``
                    }
                  >
                    {values.assignmentSubmissionFormat ===
                    AssignmentSubmissionFormat.Url ? (
                      <>
                        <div className="my-4" />
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <AppInputLableWithError
                                errors={errors}
                                langTxt={assignmentsTxt}
                                id="input.assignmentUrl"
                                required={userRoles.includes(UserRole.Student)}
                              />
                              <Field
                                name="assignmentUrl"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {values.assignmentSubmissionFormat ===
                    AssignmentSubmissionFormat.File ? (
                      <>
                        <div className="my-4" />
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <AppInputLableWithError
                                errors={errors}
                                langTxt={assignmentsTxt}
                                id="input.assignmentFile"
                                required={userRoles.includes(UserRole.Student)}
                              />
                              <FileUpload
                                id="assignment-file"
                                label=""
                                onFileSelect={(file) => {
                                  setAssignmentSubmissionFile(file);
                                }}
                                disabled={loading}
                                maxFileSize={1024 * 200}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="row">
                      <div className="col-12">
                        {formattedErrors.map((error) => (
                          <p
                            className="txt2"
                            style={{ fontWeight: 'bold' }}
                            key={error}
                          >
                            {error}
                          </p>
                        ))}
                      </div>
                    </div>
                    {(userRoles.includes(UserRole.Student) &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Submitted &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Resubmitted &&
                      assignmentSubmission?.submissionStatus !==
                        AssignmentSubmissionStatus.Completed) ||
                    userRoles.includes(UserRole.Teacher) ? (
                      <>
                        <div className="my-3" />
                        <div className="row">
                          <div className="col-md-6 col-12" />
                          <div className="col-md-6 col-12 text-right">
                            <button
                              type="submit"
                              className="btn save-btn"
                              disabled={loading}
                            >
                              {assignmentsTxt.btnSave}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignmentSubmissionForm;

import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AppRoutePath,
  InvoiceStatus,
  PaymentStatus,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadUserInvoices } from './user-invoices.slice';

const UserInvoiceListPage = (): JSX.Element => {
  const { userInvoices: userInvoicesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { userInvoices, loading } = useSelector(
    (state: RootState) => state.userInvoices,
  );
  const {
    filter: {
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null,
        id: 'expander',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: ({ row }: any) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <span {...row.getToggleRowExpandedProps()}>
            {row.original.notes ? `${row.isExpanded ? '🞃' : '🞂'}` : ''}
          </span>
        ),
      },
      {
        Header: userInvoicesTxt.studentName,
        accessor: 'user.name',
      },
      {
        Header: userInvoicesTxt.center,
        accessor: 'batch.center.name',
      },
      {
        Header: userInvoicesTxt.class,
        accessor: 'classNameTxt',
      },
      {
        Header: userInvoicesTxt.amountDue,
        accessor: 'amountDue',
        Cell: ({
          row: {
            original: { currencySymbol, amountDue },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return `${currencySymbol}${amountDue}`;
        },
      },
      {
        Header: userInvoicesTxt.processingFees,
        accessor: 'processingFees',
        Cell: ({
          row: {
            original: { currencySymbol, processingFees },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return processingFees ? `${currencySymbol}${processingFees}` : '';
        },
      },
      {
        Header: userInvoicesTxt.amountPaid,
        accessor: 'amountPaid',
        Cell: ({
          row: {
            original: { currencySymbol, amountPaid },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return amountPaid ? `${currencySymbol}${amountPaid}` : '';
        },
      },
      {
        Header: userInvoicesTxt.feesStatus,
        accessor: 'invoiceStatusTxt',
        Cell: ({
          row: {
            original: { _id, invoiceStatus, invoiceStatusTxt, hasPayAccess },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return hasPayAccess &&
            invoiceStatus !== InvoiceStatus.Paid &&
            invoiceStatus !== InvoiceStatus.Void ? (
            <Link
              to={`${AppRoutePath.UserInvoicePay.replace(':invoiceId', _id)}`}
            >
              {invoiceStatusTxt}
            </Link>
          ) : (
            invoiceStatusTxt
          );
        },
      },
      {
        Header: userInvoicesTxt.dueDate,
        accessor: 'dueDate',
      },
      {
        Header: userInvoicesTxt.paymentMode,
        accessor: 'paymentMethodType',
      },
    ],
    [userInvoicesTxt],
  );

  const data = React.useMemo(
    () =>
      userInvoices
        ?.filter(
          ({ userId, batch: { _id, center: { _id: centerId } = {} } }) => {
            let centerCheck = true;
            let batchCheck = true;
            let childrenCheck = true;

            if (selectedChildren) {
              childrenCheck = userId === selectedChildren;
            }

            if (selectedCenter) {
              centerCheck = centerId === selectedCenter;

              if (!selectedBatch) {
                return centerCheck;
              }
            }

            if (selectedBatch) {
              batchCheck = _id === selectedBatch;

              if (selectedChildren) {
                return batchCheck && childrenCheck;
              }

              if (!selectedCenter) {
                return batchCheck;
              }
            }

            return centerCheck || batchCheck;
          },
        )
        .map(({ amountPaid, processingFees, ...restClass }) => {
          const timezone = restClass.batch.center?.address.city?.timeZone || '';
          const dueDate = timezone
            ? moment.tz(setTimeToZero(restClass.dueDate), timezone).format('L')
            : '';
          const classNameTxt = restClass.batch.class
            ? `${restClass.batch.class.name} (${restClass.batch.name})`
            : '';
          const hasPayAccess = true;
          let invoiceStatusTxt = userInvoicesTxt.pending;

          if (
            restClass.paymentStatus === PaymentStatus.Paid ||
            restClass.invoiceStatus === InvoiceStatus.Paid
          ) {
            invoiceStatusTxt = userInvoicesTxt.paid;
          } else if (restClass.paymentStatus === PaymentStatus.Failed) {
            invoiceStatusTxt = userInvoicesTxt.failed;
          } else if (restClass.paymentStatus === PaymentStatus.Pending) {
            invoiceStatusTxt = userInvoicesTxt.processing;
          } else if (restClass.invoiceStatus === InvoiceStatus.Void) {
            invoiceStatusTxt = userInvoicesTxt.notPaid;
          }

          return {
            ...restClass,
            dueDate,
            classNameTxt,
            hasPayAccess,
            invoiceStatusTxt,
            amountPaid: amountPaid ? (amountPaid / 100).toFixed(2) : null,
            processingFees: processingFees
              ? (processingFees / 100).toFixed(2)
              : null,
          };
        }),
    [
      selectedBatch,
      selectedCenter,
      selectedChildren,
      userInvoicesTxt,
      userInvoices,
    ],
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <p>
        <b>{userInvoicesTxt.notes} : </b>
        {row.original.notes}
      </p>
    ),
    [userInvoicesTxt],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadUserInvoices({}));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{userInvoicesTxt.fees}</h1>
      <AppTable
        columns={columns}
        data={data || []}
        renderRowSubComponent={renderRowSubComponent}
      />
    </section>
  );
};

export default UserInvoiceListPage;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { hasError } from '../common/helper';

type LableProps = {
  langTxt: Record<string, string>;
  id: string;
  errors: string[];
  required?: boolean;
  errId?: string;
  className?: string;
  noteKeyId?: string;
};

const AppInputLableWithError: React.FC<LableProps> = ({
  langTxt,
  id,
  errors,
  required,
  errId,
  className,
  noteKeyId,
}: LableProps) => {
  const errorStyle = { color: 'red' };
  const error = hasError(errId || id, errors);

  return (
    <label className={error ? '' : className} style={error ? errorStyle : {}}>
      {langTxt[id]}
      {noteKeyId ? <span>&nbsp;{langTxt[noteKeyId]}</span> : null}
      {required ? (
        <sup className="common-text-color">
          &nbsp;
          <span className="fa fa-star-of-life" />
        </sup>
      ) : null}
    </label>
  );
};

AppInputLableWithError.defaultProps = {
  required: false,
  errId: '',
  className: 'small',
  noteKeyId: undefined,
};

export default AppInputLableWithError;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CoursesState,
  CourseModel,
  CreateCourseInput,
  GetCoursesQuery,
} from '../../common/types';

const initialState: CoursesState = {
  loading: false,
  success: null,
  errors: [],
  courses: null,
  addCourse: {
    loading: false,
    success: null,
    errors: [],
  },
  loadCourse: {
    loading: false,
    success: null,
    errors: [],
  },
  removeCourseImage: {
    loading: false,
    success: null,
    errors: [],
  },
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCourses(state, action: PayloadAction<GetCoursesQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadCoursesSuccess(state, action: PayloadAction<CourseModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.courses = action.payload;
    },
    loadCoursesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadCourses(state) {
      state.loadCourse.success = null;
      state.loadCourse.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addCourse(state, action: PayloadAction<CreateCourseInput>) {
      state.addCourse.loading = true;
      state.addCourse.errors = [];
    },
    addCourseSuccess(state, action: PayloadAction<boolean>) {
      state.addCourse.loading = false;
      state.addCourse.success = action.payload;
    },
    addCourseError(state, action: PayloadAction<string[]>) {
      state.addCourse.loading = false;
      state.addCourse.errors = action.payload;
    },
    resetAddCourseSuccess(state) {
      state.addCourse.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCourse(state, action: PayloadAction<string>) {
      state.loadCourse.loading = true;
      state.loadCourse.errors = [];
    },
    loadCourseSuccess(state, action: PayloadAction<CourseModel>) {
      state.loadCourse.loading = false;
      state.loadCourse.success = action.payload;
    },
    loadCourseError(state, action: PayloadAction<string[]>) {
      state.loadCourse.loading = false;
      state.loadCourse.errors = action.payload;
    },
    resetLoadCourse(state) {
      state.loadCourse.success = null;
      state.loadCourse.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeCourseImage(state, action: PayloadAction<string>) {
      state.removeCourseImage.loading = true;
      state.removeCourseImage.errors = [];
    },
    removeCourseImageSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeCourseImage.loading = false;
      state.removeCourseImage.success = action.payload.success;
    },
    removeCourseImageError(state, action: PayloadAction<string[]>) {
      state.removeCourseImage.loading = false;
      state.removeCourseImage.errors = action.payload;
    },
    resetRemoveCourseImage(state) {
      state.removeCourseImage.success = null;
      state.removeCourseImage.errors = [];
    },
  },
});

export const {
  loadCourses,
  loadCoursesSuccess,
  loadCoursesError,
  resetLoadCourses,
  addCourse,
  addCourseSuccess,
  addCourseError,
  resetAddCourseSuccess,
  loadCourse,
  loadCourseSuccess,
  loadCourseError,
  resetLoadCourse,
  removeCourseImage,
  removeCourseImageSuccess,
  removeCourseImageError,
  resetRemoveCourseImage,
} = coursesSlice.actions;

export const {
  name: coursesSliceName,
  reducer: coursesSliceReducer,
} = coursesSlice;

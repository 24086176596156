import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AppRoutePath,
  USER_ROLES_PERMISSIONS,
  UserRole,
} from '../../common/constants';
import { rolesHasPermission } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadUsers } from './users.slice';

const UserListPage = (): JSX.Element => {
  const { users: usersTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { loading, users } = useSelector((state: RootState) => state.users);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
    },
  } = useSelector((state: RootState) => state.app);
  const [loadRequested, setLoadRequested] = useState(false);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const viewPermissions = USER_ROLES_PERMISSIONS.filter(
    ({ permissionView }) => {
      return rolesHasPermission(userRoles, permissionView);
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let viewRoles: any[] = [];

  viewPermissions.forEach(({ roles: newRoles }) => {
    viewRoles = [...viewRoles, ...newRoles];
  });

  const columns = useMemo(
    () => [
      {
        Header: usersTxt.name,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name, canView },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return canView ? (
            <Link to={`${AppRoutePath.UsersUpdate.replace(':userId', _id)}`}>
              {name}
            </Link>
          ) : (
            name
          );
        },
      },
      {
        Header: usersTxt.role,
        accessor: 'roleTitle',
        Cell: ({
          row: {
            original: { roleTitle },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return roleTitle.join(', ');
        },
      },
      {
        Header: usersTxt.centers,
        accessor: 'centersTitle',
      },
      {
        Header: usersTxt.classes,
        accessor: 'classesTitle',
      },
      {
        Header: usersTxt.batches,
        accessor: 'batchesTitle',
      },
      {
        Header: usersTxt.enStatus,
        accessor: 'enrollmentStatus',
      },
      {
        Header: usersTxt.enDate,
        accessor: 'enrollmentDate',
      },
    ],
    [usersTxt],
  );

  const data = useMemo(
    () =>
      users
        ?.filter(({ role, batches, children }) => {
          let centerCheck = true;
          let batchCheck = true;

          if (role.indexOf(UserRole.Parent) !== -1) {
            if (selectedCenter || selectedBatch) {
              if (!children) {
                return true;
              }

              let centerCheckCount = 0;
              let batchCheckCount = 0;

              for (let i = 0; i < children.length; i += 1) {
                const childrenBatches = children[i].batches;

                if (childrenBatches) {
                  if (selectedCenter) {
                    const cCheck = !!childrenBatches.find(
                      ({ batch: { center: { _id: centerId } = {} } }) =>
                        centerId === selectedCenter,
                    );

                    if (!selectedBatch && cCheck) {
                      return true;
                    }
                    if (cCheck) {
                      centerCheckCount += 1;
                    }
                  }

                  if (selectedBatch) {
                    const bCheck = !!childrenBatches.find(
                      ({ batch: { _id } }) => _id === selectedBatch,
                    );

                    if (!selectedCenter && bCheck) {
                      return true;
                    }

                    if (bCheck) {
                      batchCheckCount += 1;
                    }
                  }
                }
              }

              return centerCheckCount > 0 || batchCheckCount > 0;
            }

            return true;
          }

          if (batches) {
            if (selectedCenter) {
              centerCheck = !!batches.find(
                ({ batch: { center: { _id: centerId } = {} } }) =>
                  centerId === selectedCenter,
              );

              if (!selectedBatch) {
                return centerCheck;
              }
            }

            if (selectedBatch) {
              batchCheck = !!batches.find(
                ({ batch: { _id } }) => _id === selectedBatch,
              );

              if (!selectedCenter) {
                return batchCheck;
              }
            }
          }

          return centerCheck || batchCheck;
        })
        .map(({ role, batches, centers, ...restUser }) => {
          let centersTitle;
          let classesTitle;

          const batchesTitle = (
            batches?.map(({ batch: { name } }) => name) || []
          ).join(', ');

          const enrollmentDateTitle = role.includes(UserRole.Student)
            ? (
                batches?.map(({ enrollmentDate }) =>
                  enrollmentDate ? moment(enrollmentDate).format('L') : '',
                ) || []
              ).join(', ')
            : '';

          const enrollmentStatusTitle = role.includes(UserRole.Student)
            ? (
                batches?.map(
                  ({ enrollmentStatus }) => enrollmentStatus || '',
                ) || []
              ).join(', ')
            : '';

          if (role.includes(UserRole.CenterHead)) {
            let centerNames = [...(centers?.map(({ name }) => name) || [])];

            if (role.includes(UserRole.Teacher)) {
              centerNames = [
                ...centerNames,
                ...(batches?.map(
                  ({ batch: { center: { name = '' } = {} } }) => name,
                ) || []),
              ];
              classesTitle = uniq(
                batches?.map(
                  ({ batch: { class: { name = '' } = {} } }) => name,
                ) || [],
              ).join(', ');
            }

            centersTitle = uniq(centerNames).join(', ');
          } else {
            centersTitle = uniq(
              batches?.map(
                ({ batch: { center: { name = '' } = {} } }) => name,
              ) || [],
            ).join(', ');
            classesTitle = uniq(
              batches?.map(
                ({ batch: { class: { name = '' } = {} } }) => name,
              ) || [],
            ).join(', ');
          }

          return {
            ...restUser,
            enrollmentDate: enrollmentDateTitle,
            enrollmentStatus: enrollmentStatusTitle,
            role,
            roleTitle: role.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (roleVal: string) =>
                USER_ROLES_PERMISSIONS.find(({ key }) => key === roleVal)
                  ?.title,
            ),
            centersTitle,
            classesTitle,
            batchesTitle,
            canView: !!viewPermissions.find(({ roles: uRoles }) => {
              const filteredRoles = uRoles.filter((roleVal) => {
                return role.includes(roleVal);
              });

              return (
                filteredRoles.length === uRoles.length &&
                filteredRoles.length === role.length
              );
            }),
          };
        }),
    [selectedCenter, selectedBatch, users, viewPermissions],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadUsers({
          domain: currentUser?.domain || '',
          role: viewRoles,
        }),
      );
      setLoadRequested(true);
    }
  }, [viewRoles, loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{usersTxt.users}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default UserListPage;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatErrors } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  renewInstituteSubscription,
  resetRenewInstituteSubscription,
} from '../profile.slice';

type RenewSubscriptionPageProps = {
  infoLoaded: boolean;
};

const RenewSubscriptionPage: React.FC<RenewSubscriptionPageProps> = ({
  infoLoaded,
}: RenewSubscriptionPageProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;

  const dispatch = useDispatch();
  const {
    loading: infoLoading,
    renewInstituteSubscription: { success, loading, errors },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit() {
    dispatch(renewInstituteSubscription());
  }

  useEffect(() => {
    if (success) {
      dispatch(resetRenewInstituteSubscription());
    }
  }, [success, dispatch]);

  if (!infoLoaded || infoLoading) {
    return <AppLoader />;
  }

  const formattedErrors = formatErrors([], errors, profileTxt);

  return (
    <div className="form">
      <div className="row">
        <div className="col-12">
          {formattedErrors.map((error) => (
            <p className="txt2" style={{ fontWeight: 'bold' }} key={error}>
              {error}
            </p>
          ))}
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-6 col-12">
          <button
            type="button"
            className="btn save-btn"
            onClick={() => onSubmit()}
            disabled={loading}
          >
            {profileTxt.renewSubscriptionBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenewSubscriptionPage;

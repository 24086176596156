/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import Select from 'react-select';

import {
  Gender,
  UserEnrollmentStatus,
  UserRole,
  UserStatus,
} from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateUserInput } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';

type UserFormProps = {
  onSubmit: (data: CreateUserInput) => void;
  initialValues: CreateUserInput;
  errors: string[];
  loading: boolean;
  role: UserRole;
  parents: { name: string; _id: string }[];
  centers: { name: string; _id: string }[];
  batches: { name: string; _id: string }[];
  canModify: boolean;
};

const UserForm: React.FC<UserFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
  role,
  parents,
  centers,
  batches,
  canModify,
}: UserFormProps): JSX.Element => {
  const { users: usersTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const parentOptions = parents.map(({ _id, name }) => {
    return { value: _id, label: name };
  });
  const centerOptions = centers.map(({ _id, name }) => {
    return { value: _id, label: name };
  });
  const batchOptions = batches.map(({ _id, name }) => {
    return { value: _id, label: name };
  });
  const [selectedParent, setSelectedParent] = useState(
    parentOptions.find(({ value }) => value === initialValues.parentId),
  );
  const [selectedCenters, setSelectedCenters] = useState(
    centerOptions.filter(({ value }) =>
      initialValues.centerIds?.includes(value),
    ),
  );
  const [selectedBatches, setSelectedBatches] = useState(
    batchOptions.filter(({ value }) =>
      initialValues.batches?.find(({ batchId }) => batchId === value),
    ),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onParentChange(val: any) {
    setSelectedParent(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCenterChange(val: any) {
    setSelectedCenters(val);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ batches: batchesObj, ...restValues }, { setSubmitting }) => {
        onSubmit({
          ...restValues,
          batches: batchesObj?.map(
            ({ enrollmentDate, inactiveDate, ...restBatch }) => {
              return {
                // eslint-disable-next-line no-nested-ternary
                enrollmentDate: enrollmentDate
                  ? moment(enrollmentDate, 'L', true).format('YYYY-MM-DD')
                  : role === UserRole.Student
                  ? ''
                  : undefined,
                // eslint-disable-next-line no-nested-ternary
                inactiveDate: inactiveDate
                  ? moment(inactiveDate, 'L', true).format('YYYY-MM-DD')
                  : role === UserRole.Student
                  ? ''
                  : undefined,
                ...restBatch,
              };
            },
          ),
          parentId: selectedParent?.value || undefined,
          centerIds: selectedCenters?.map((cId) => cId.value) || [],
        });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
          centerIds: undefined,
        });

        const formattedErrors = formatErrors(
          [...errorKeys, { langKey: 'input.batches', errKey: 'input.batches' }],
          errors,
          usersTxt,
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function onBatchChange(val: any) {
          setSelectedBatches(val);

          const newBatches =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            val?.map(({ value }: any) => {
              const curBatch = values.batches?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ({ batchId }: any) => batchId === value,
              );

              return curBatch
                ? { ...curBatch }
                : {
                    batchId: value,
                    enrollmentStatus:
                      role === UserRole.Student ? '' : undefined,
                    enrollmentDate: role === UserRole.Student ? '' : undefined,
                    inactiveDate: role === UserRole.Student ? '' : undefined,
                  };
            }) || undefined;

          setFieldValue('batches', newBatches);
        }

        return (
          <Form>
            <div className="student">
              <div className="row">
                <div
                  className={
                    role === UserRole.Student
                      ? `col-lg-6 col-12 mb-lg-0 mb-4`
                      : `col-12`
                  }
                >
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={usersTxt}
                    id="input.gender"
                    required
                  />
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="gender"
                      className="custom-control-input"
                      value={Gender.Male}
                      id="male-gender"
                      disabled={!canModify}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="male-gender"
                    >
                      {usersTxt.male}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="gender"
                      className="custom-control-input"
                      value={Gender.Female}
                      id="female-gender"
                      disabled={!canModify}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="female-gender"
                    >
                      {usersTxt.female}
                    </label>
                  </div>
                </div>
                <div className="col-lg-6 col-12 d-flex flex-column align-items-lg-end align-items-start justify-content-lg-end justify-content-center">
                  {role === UserRole.Student ? (
                    <div className="custom-control custom-checkbox">
                      <Field
                        type="checkbox"
                        name="assignParent"
                        className="custom-control-input assign-parent"
                        id="add-parent"
                        disabled={!canModify}
                      />
                      <label
                        className="custom-control-label text-secondary"
                        htmlFor="add-parent"
                      >
                        {usersTxt['input.assignParent']}
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="my-3" />
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={usersTxt}
                      id="input.firstName"
                      required
                    />
                    <Field
                      name="firstName"
                      type="text"
                      className="form-control"
                      disabled={!canModify}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={usersTxt}
                      id="input.lastName"
                      required
                    />
                    <Field
                      name="lastName"
                      type="text"
                      className="form-control"
                      disabled={!canModify}
                    />
                  </div>
                </div>
              </div>
              {role === UserRole.Student && values.assignParent ? (
                <div
                  className="parents-name-username-field"
                  style={{ display: 'block' }}
                >
                  <div className="my-lg-3" />
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="form-group">
                        <AppInputLableWithError
                          errors={errors}
                          langTxt={usersTxt}
                          id="input.parentId"
                          required
                        />
                        <Select
                          value={selectedParent}
                          options={parentOptions}
                          onChange={onParentChange}
                          isDisabled={!canModify}
                          isOptionDisabled={() => !canModify}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={usersTxt}
                        id="input.username"
                        required
                      />
                      <div className="input-group mb-3">
                        <Field
                          name="username"
                          type="text"
                          className="form-control tolowercase-text"
                          disabled={!canModify}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="my-lg-3" />
              {!values.assignParent ? (
                <div className="email-contact-field">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="form-group">
                        <AppInputLableWithError
                          errors={errors}
                          langTxt={usersTxt}
                          id="input.email"
                          required
                        />
                        <Field
                          name="email"
                          type="text"
                          className="form-control tolowercase-text"
                          disabled={!canModify}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-group">
                        <AppInputLableWithError
                          errors={errors}
                          langTxt={usersTxt}
                          id="input.phone"
                          required
                        />
                        <Field
                          name="phone"
                          type="text"
                          className="form-control"
                          disabled={!canModify}
                        />
                        <p className="small common-text-color text-right short-desc mt-1">
                          {appTxt.phoneNote}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {role !== UserRole.Parent ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <AppInputLableWithError
                          errors={errors}
                          langTxt={usersTxt}
                          id="input.status"
                          required
                        />
                        <select
                          name="status"
                          className="custom-select"
                          value={values.status}
                          onChange={handleChange}
                          disabled={!canModify}
                        >
                          <option value="">{usersTxt.select}</option>
                          <option value={UserStatus.Active}>
                            {usersTxt.active}
                          </option>
                          <option value={UserStatus.Pending}>
                            {usersTxt.inactive}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {[UserRole.Teacher, UserRole.CenterHeadTeacher].indexOf(role) !==
              -1 ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <AppInputLableWithError
                          errors={errors}
                          langTxt={usersTxt}
                          id="input.description"
                          required
                        />
                        <Field
                          name="description"
                          as="textarea"
                          className="form-control"
                          disabled={!canModify}
                          row={5}
                        />
                        <div className="row">
                          <div className="col-12 text-right">
                            <p className="small common-text-color short-desc mt-1">
                              {usersTxt.maxCharNote}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                {[UserRole.CenterHeadTeacher, UserRole.CenterHead].indexOf(
                  role,
                ) !== -1 ? (
                  <div className="col-lg-6 col-12 mb-3 mb-lg-0">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={usersTxt}
                        id="input.centerIds"
                        required
                      />
                      <Select
                        value={selectedCenters}
                        options={centerOptions}
                        onChange={onCenterChange}
                        isMulti
                        isDisabled={!canModify}
                        isOptionDisabled={() => !canModify}
                      />
                    </div>
                  </div>
                ) : null}
                {[
                  UserRole.CenterHeadTeacher,
                  UserRole.Teacher,
                  UserRole.Student,
                ].indexOf(role) !== -1 ? (
                  <div className="col-lg-6 col-12">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={usersTxt}
                      id="input.batches"
                      required
                    />
                    <Select
                      value={selectedBatches}
                      options={batchOptions}
                      onChange={onBatchChange}
                      isMulti
                      isDisabled={!canModify}
                      isOptionDisabled={() => !canModify}
                    />
                  </div>
                ) : null}
              </div>

              {[UserRole.Student].indexOf(role) !== -1 ? (
                <>
                  <div className="my-3" />
                  <FieldArray name="batches">
                    {() =>
                      values.batches?.map((batch, index) => {
                        return (
                          <div
                            className="row border p-2 pt-4 my-3"
                            key={`batch${index}`}
                          >
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12 selected-batch-label">
                                  <p>
                                    <b>
                                      {batchOptions.find(
                                        ({ value }) => value === batch.batchId,
                                      )?.label || ''}
                                    </b>
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className={
                                    batch.enrollmentStatus ===
                                    UserEnrollmentStatus.Inactive
                                      ? `col-lg-4 col-12`
                                      : `col-lg-6 col-12`
                                  }
                                >
                                  <div className="form-group">
                                    <AppInputLableWithError
                                      errors={errors}
                                      langTxt={usersTxt}
                                      id="batches.enrollmentStatus"
                                      errId={`${index}.enrollmentStatus`}
                                      required
                                    />
                                    <Field
                                      as="select"
                                      name={`batches.${index}.enrollmentStatus`}
                                      className="custom-select"
                                      disabled={!canModify}
                                    >
                                      <option value="">
                                        {usersTxt.select}
                                      </option>
                                      <option
                                        value={UserEnrollmentStatus.Active}
                                      >
                                        {usersTxt.active}
                                      </option>
                                      <option
                                        value={UserEnrollmentStatus.Inactive}
                                      >
                                        {usersTxt.inactive}
                                      </option>
                                    </Field>
                                  </div>
                                </div>
                                <div
                                  className={
                                    batch.enrollmentStatus ===
                                    UserEnrollmentStatus.Inactive
                                      ? `col-lg-4 col-12`
                                      : `col-lg-6 col-12`
                                  }
                                >
                                  <div className="form-group">
                                    <AppInputLableWithError
                                      errors={errors}
                                      langTxt={usersTxt}
                                      required
                                      id="batches.enrollmentDate"
                                      errId={`${index}.enrollmentDate`}
                                      noteKeyId="dateFormat"
                                    />
                                    <Field
                                      name={`batches.${index}.enrollmentDate`}
                                      type="text"
                                      className="form-control"
                                      disabled={!canModify}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                  {batch.enrollmentStatus ===
                                  UserEnrollmentStatus.Inactive ? (
                                    <div className="form-group">
                                      <AppInputLableWithError
                                        errors={errors}
                                        langTxt={usersTxt}
                                        required
                                        id="batches.inactiveDate"
                                        errId={`${index}.inactiveDate`}
                                        noteKeyId="dateFormat"
                                      />
                                      <Field
                                        name={`batches.${index}.inactiveDate`}
                                        type="text"
                                        className="form-control"
                                        disabled={!canModify}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }) || null
                    }
                  </FieldArray>
                </>
              ) : null}

              <div className="my-lg-5 my-3" />
              <div className="row">
                <div className="col-12">
                  {formattedErrors.map((error) => (
                    <p
                      className="txt2"
                      style={{ fontWeight: 'bold' }}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="submit"
                    disabled={loading || !canModify}
                    className="btn save-btn"
                  >
                    {usersTxt.btnAdd}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

UserForm.defaultProps = {};

export default UserForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateCourseInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import { addCourse, resetAddCourseSuccess } from './institute-courses.slice';
import InstituteCourseListPage from './InstituteCourseListPage';
import InstituteCourseRecordPage from './InstituteCourseRecordPage';

const InstituteCoursesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addCourse: {
      loading: addCourseLoading,
      success: addCourseSuccess,
      errors: addCourseErrors,
    },
  } = useSelector((state: RootState) => state.courses);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateCourseInput) {
    dispatch(
      addCourse({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addCourseSuccess) {
      dispatch(resetAddCourseSuccess());
      history.push(AppRoutePath.Courses);
    }
  }, [addCourseSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.CoursesUpdate}
          permissions={rPermission(AppRoutePath.CoursesUpdate)}
        >
          {!addCourseSuccess ? (
            <InstituteCourseRecordPage
              key="updatecourseform"
              errors={addCourseErrors}
              loading={addCourseLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.CoursesCreate}
          permissions={rPermission(AppRoutePath.CoursesCreate)}
        >
          {!addCourseSuccess ? (
            <InstituteCourseRecordPage
              key="createcourseform"
              errors={addCourseErrors}
              loading={addCourseLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <InstituteCourseListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default InstituteCoursesPage;

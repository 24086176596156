import 'react-day-picker/lib/style.css';

import moment from 'moment-timezone';
import React, { FC } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

type ExpiryDatePickerProps = {
  value: moment.Moment | undefined;
  onChange: (newValue: moment.Moment | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDays: any[];
  disabled?: boolean;
};

const ExpiryDatePicker: FC<ExpiryDatePickerProps> = ({
  value,
  onChange,
  disabledDays,
  disabled,
}: ExpiryDatePickerProps): JSX.Element => {
  const today = moment().toDate();

  return (
    <>
      <DayPickerInput
        value={value?.toDate() || undefined}
        onDayChange={(dayInit, { selected }) => {
          if (!selected) {
            if (!dayInit) {
              onChange(undefined);
              return;
            }
            try {
              const day = moment(dayInit);
              onChange(day);
            } catch (e) {
              onChange(undefined);
            }
          }
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        format="L"
        placeholder=""
        inputProps={{
          className: `form-control ${!disabled ? 'fc-readonly' : ''}`,
          disabled,
          readOnly: true,
        }}
        dayPickerProps={{
          disabledDays,
          initialMonth: today,
        }}
      />
    </>
  );
};

ExpiryDatePicker.defaultProps = {
  disabled: false,
};

export default ExpiryDatePicker;

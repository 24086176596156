/* eslint-disable import/prefer-default-export */
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import moment from 'moment';

import LanguageTexts from './language';

export function IsValidMomentDate(
  format = 'L',
  validationOptions?: ValidationOptions,
) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (object: Object, propertyName: string): void => {
    registerDecorator({
      name: 'isValidMomentDate',
      target: object.constructor,
      propertyName,
      constraints: [format],
      options: {
        message: LanguageTexts.app.invalidDate,
        ...validationOptions,
      },
      validator: {
        validate(value: string, args: ValidationArguments) {
          const [dateFormat] = args.constraints;
          return moment(value, dateFormat, true).isValid();
        },
      },
    });
  };
}

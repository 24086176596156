/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest } from 'redux-saga/effects';

import ApiService from '../../services/api';
import {
  itSetting,
  itSettingError,
  itSettingSuccess,
} from './it-setting.slice';

export function* itSettingAsync() {
  const { data, errors } = yield call(ApiService.itSetting);

  if (errors) {
    yield put(itSettingError(errors));
  } else {
    yield put(itSettingSuccess(data));
  }
}

export function* watchItSettingAsync() {
  yield takeLatest(itSetting.toString(), itSettingAsync);
}

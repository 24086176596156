/* eslint-disable jsx-a11y/label-has-associated-control */
import GoogleMapReact from 'google-map-react';
import React, { useEffect } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  DEFAULT_CURRENCY_SYMBOL,
  PublicRoutePath,
} from '../../common/constants';
import { getCurrentYear } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  ContactUsInput,
  PreRegistrationInfoInput,
  RootState,
} from '../../common/types';
import ContactUsForm from './ContactUsForm';
import {
  addContactUs,
  addPreRegistrationInfo,
  resetAddContactUsSuccess,
  resetAddPreRegistrationInfoSuccess,
} from './home-page.slice';
import HomePageRegistrationForm from './HomePageRegistrationForm';

const HomePage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    addPreRegistrationInfo: {
      success: preRegistrationInfoSuccess,
      loading: preRegistrationInfoLoading,
      errors: preRegistrationInfoErrors,
    },
    addContactUs: {
      success: addContactUsSuccess,
      loading: addContactUsLoading,
      errors: addContactUsErrors,
    },
  } = useSelector((state: RootState) => state.home);

  const { subscriptionPlanFirst } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const currencySymbol = DEFAULT_CURRENCY_SYMBOL;

  function onSubmit(data: PreRegistrationInfoInput) {
    dispatch(addPreRegistrationInfo(data));
  }

  function onContactUsSubmit(data: ContactUsInput) {
    dispatch(addContactUs(data));
  }

  useEffect(() => {
    if (preRegistrationInfoSuccess) {
      dispatch(resetAddPreRegistrationInfoSuccess());
      history.push(PublicRoutePath.Register);
      window.scrollTo(0, 0);
      document.addEventListener(
        'load',
        // eslint-disable-next-line func-names
        function () {
          document.getElementById('email')?.focus();
        },
        true,
      );
    }
  }, [preRegistrationInfoSuccess, dispatch, history]);

  useEffect(() => {
    if (addContactUsSuccess) {
      toast.success(LanguageTexts.contactUs.successMessage);
      dispatch(resetAddContactUsSuccess());
    }
  }, [addContactUsSuccess, dispatch, history]);

  const windowScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const preRegistrationInfoInit: PreRegistrationInfoInput = {
    email: '',
  };

  const addContactUsInit: ContactUsInput = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function renderMarkers(map: any, maps: any) {
    const geocoder = new maps.Geocoder();
    const address = LanguageTexts.homePage.addressInfo;
    const title = LanguageTexts.homePage.appTitle;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geocoder.geocode({ address }, (results: any, status: any) => {
      if (status === 'OK') {
        const { location } = results[0].geometry;

        map.setCenter(location);

        const marker = new maps.Marker({
          position: location,
          map,
          title,
        });

        const infowindow = new maps.InfoWindow({
          content: `
            <h3>${title}</h3>
            <p>${address}</p>
          `,
        });

        marker.addListener('click', () => {
          infowindow.open(map, marker);
        });
      } else {
        // eslint-disable-next-line no-console
        console.log(
          `Geocode was not successful for the following reason: ${status}`,
        );
      }
    });
  }

  return (
    <div className="app-home-page">
      <section className="top-header" id="top">
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}website/main-image/main-image.jpg`}
          alt=""
          className="img-fluid"
        />
        <div className="header">
          <div className="container">
            <img
              className="logo bg-white"
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo/main-app-logo.jpg`}
              alt="logo"
            />
            <nav className="py-3">
              <label htmlFor="drop" className="toggle">
                <span className="fa fa-bars" />
              </label>
              <input type="checkbox" id="drop" />
              <ul className="menu ml-auto mt-2">
                <li>
                  <a href="#about">{LanguageTexts.homePage.menuAbout}</a>
                </li>
                <li>
                  <a href="#features">{LanguageTexts.homePage.menuFeatures}</a>
                </li>
                {/* <li>
                  <a href="#demo">{LanguageTexts.homePage.menuHowItWorks}</a>
                </li>
                <li>
                  <a href="#testimonial">
                    {LanguageTexts.homePage.menuTestimonial}
                  </a>
                </li> */}
                <li>
                  <a href="#register">{LanguageTexts.homePage.menuRegister}</a>
                </li>
                <li>
                  <a href="#contact">{LanguageTexts.homePage.menuContact}</a>
                </li>
                <li className="last-grid ml-2">
                  <Link to="/login" className="save-btn">
                    <span className="fa fa-user-lock text-white" />{' '}
                    {LanguageTexts.homePage.loginBtn}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="banner">
          <div className="layer">
            <div className="container">
              <div className="banner-text-w3pvt">
                <div className="csslider infinity" id="slider1">
                  <input
                    type="radio"
                    name="slides"
                    id="slides_1"
                    defaultChecked
                  />
                  <input type="radio" name="slides" id="slides_2" />
                  <input type="radio" name="slides" id="slides_3" />
                  <input type="radio" name="slides" id="slides_4" />
                  <ul className="banner_slide_bg">
                    <li>
                      <div className="w3ls_banner_txt text-center">
                        <h3 className="b-w3ltxt text-capitalize mt-md-5">
                          {LanguageTexts.homePage.mainSlide1}
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="w3ls_banner_txt text-center">
                        <h3 className="b-w3ltxt text-capitalize mt-md-5">
                          {LanguageTexts.homePage.mainSlide2}
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="w3ls_banner_txt text-center">
                        <h3 className="b-w3ltxt text-capitalize mt-md-5">
                          {LanguageTexts.homePage.mainSlide3}
                        </h3>
                      </div>
                    </li>
                    <li>
                      <div className="w3ls_banner_txt text-center">
                        <h3 className="b-w3ltxt text-capitalize mt-md-5">
                          {LanguageTexts.homePage.mainSlide4}
                        </h3>
                      </div>
                    </li>
                  </ul>
                  <div className="navigation">
                    <div>
                      <label htmlFor="slides_1" />
                      <label htmlFor="slides_2" />
                      <label htmlFor="slides_3" />
                      <label htmlFor="slides_4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="about p-4" id="about">
        <h3 className="heading mt-5">{LanguageTexts.homePage.aboutUs}</h3>
        <section className="container my-5">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <p>{LanguageTexts.homePage.aboutUsText1}</p>
              <div className="my-3" />
              <p>{LanguageTexts.homePage.aboutUsText2}</p>
            </div>
            <div className="col-md-6 order-1 order-md-2 mb-5">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}website/about/about-us.jpg`}
                alt=""
                className="img-fluid common-shadow"
              />
            </div>
          </div>
        </section>
      </div>
      <section className="features bg-light p-4" id="features">
        <h3 className="heading mt-5">{LanguageTexts.homePage.features}</h3>
        <div className="container my-5">
          <Tabs className="nav-fill" transition={false}>
            <Tab eventKey="institute" title="Institute">
              <div
                className="tab-pane fade show active"
                id="nav-institute"
                role="tabpanel"
                aria-labelledby="nav-institute-tab"
              >
                <div className="nav-institute mt-5">
                  <div className="row">
                    <div className="col-lg-5 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_class.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-school common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.setupClass}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.setupClassInfo1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.setupClassInfo2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.setupClassInfo3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.setupClassInfo4}
                            </li>
                          </ul>
                          {/* <p className="mt-3">
                            {LanguageTexts.homePage.instituteFeature1Info}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-7 order-2 order-lg-1 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-child common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.roleBaseAccess}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.role1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.role2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.role3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.role4}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.role5}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 order-1 order-lg-2 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_user.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-5 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_attendance.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-user common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.classManagement}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.classManagementInfo1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.classManagementInfo2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.classManagementInfo3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.classManagementInfo4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="student" title="Student">
              <div
                className="tab-pane"
                id="nav-student"
                role="tabpanel"
                aria-labelledby="nav-student-tab"
              >
                <div className="nav-student mt-5">
                  <div className="row mt-5">
                    <div className="col-lg-5 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_assignment.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-edit common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.assignments}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.assignmentsInfo1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.assignmentsInfo2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.assignmentsInfo3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.assignmentsInfo4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-7 order-2 order-lg-1 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-bullhorn common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.announcements}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.announcementsInfo1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.announcementsInfo2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.announcementsInfo3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.announcementsInfo4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 order-1 order-lg-2 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_announcement.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-5 rounded-lg common-shadow feature-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/app-features/homepage_features_fee.jpg`}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                      <div className="row inner-heading">
                        <div className="col-lg-2">
                          <span
                            className="fa fa-dollar-sign common-bgfg-color"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="col-lg-10">
                          <h4 className="common-text-color mt-lg-0 mt-2">
                            {LanguageTexts.homePage.fees}
                          </h4>
                          <div className="my-4" />
                          <ul className="feature-list ml-lg-0 ml-3">
                            <li className="text-secondary">
                              {LanguageTexts.homePage.feesInfo1}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.feesInfo2}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.feesInfo3}
                            </li>
                            <li className="text-secondary">
                              {LanguageTexts.homePage.feesInfo4}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
      {/* <section
        className="demo p-4"
        id="demo"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/section-bg-img/demo-bg.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3 className="heading mt-5">{LanguageTexts.homePage.howItWorks}</h3>
        <div className="container my-5">
          <div className="embed-responsive embed-responsive-16by9 mt-4">
            <iframe
              title="youtube video"
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/WwMSioKHt2E"
              allowFullScreen
            />
          </div>
        </div>
      </section> */}

      {/* <section className="testi p-4" id="testimonial">
        <h3 className="heading mt-5">{LanguageTexts.homePage.testimonial}</h3>
        <div className="container my-5">
          <Carousel controls indicators={false} interval={10000}>
            <Carousel.Item>
              <div className="item carousel-item active">
                <div className="img-box">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}website/testimonial/man.png`}
                    alt=""
                  />
                </div>
                <p className="testimonial text-center">
                  {LanguageTexts.homePage.testimonialText1}
                </p>
                <p className="overview">
                  <b>{LanguageTexts.homePage.testimonialCommenter1}</b>
                  {LanguageTexts.homePage.testimonialCommenter1Info}
                </p>
                <div className="star-rating">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-half-alt" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-o" />
                    </li>
                  </ul>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="item carousel-item active">
                <div className="img-box">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}website/testimonial/woman.png`}
                    alt=""
                  />
                </div>
                <p className="testimonial text-center">
                  {LanguageTexts.homePage.testimonialText2}
                </p>
                <p className="overview">
                  <b>{LanguageTexts.homePage.testimonialCommenter2}</b>
                  {LanguageTexts.homePage.testimonialCommenter2Info}
                </p>
                <div className="star-rating">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-half-alt" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-o" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-o" />
                    </li>
                  </ul>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="item carousel-item active">
                <div className="img-box">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}website/testimonial/girl.png`}
                    alt=""
                  />
                </div>
                <p className="testimonial text-center">
                  {LanguageTexts.homePage.testimonialText3}
                </p>
                <p className="overview">
                  <b>{LanguageTexts.homePage.testimonialCommenter3}</b>
                  {LanguageTexts.homePage.testimonialCommenter3Info}
                </p>
                <div className="star-rating m-auto">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star" />
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-o" />
                    </li>
                  </ul>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section> */}

      <section
        className="register p-4"
        id="register"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/section-bg-img/register-bg.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h3 className="heading mt-5">
          {LanguageTexts.homePage.registerInstitute}
        </h3>
        <div className="container my-5 text-center">
          <div className="row">
            <div className="col-lg-6 padding">
              <div>
                <div className="mb-4">
                  <span className="fa fa-hand-holding-usd" />
                  <h3
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: LanguageTexts.homePage.paymentInfoMsg.replace(
                        '{price}',
                        `${currencySymbol}${subscriptionPlanFirst?.pricePerActiveStudent.toString()}`,
                      ),
                    }}
                    className="pricing mt-3"
                  />
                </div>
                <div className="form-style-w3ls">
                  <HomePageRegistrationForm
                    onSubmit={onSubmit}
                    errors={preRegistrationInfoErrors}
                    loading={preRegistrationInfoLoading}
                    initialValues={preRegistrationInfoInit}
                  />
                  <span>
                    {LanguageTexts.homePage.registerAgreeTermsText}{' '}
                    <a href="http://www.impeccabletracker.com/terms.html">
                      {LanguageTexts.homePage.registerAgreeTermsLinkText}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact p-4" id="contact">
        <h3 className="heading mt-5">{LanguageTexts.homePage.contactUs}</h3>
        <div className="container my-5">
          <div className="row contact_information">
            <div className="col-lg-6 order-2 order-lg-1 contact_left">
              <div className="center-map">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY || '',
                    language: 'en',
                    region: 'US',
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{ lat: 39.06175, lng: -77.1796 }}
                  defaultZoom={15}
                  onGoogleApiLoaded={({ map, maps }) =>
                    renderMarkers(map, maps)
                  }
                />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 mt-lg-0 mt-4">
              <div className="contact_right p-lg-5 p-4">
                {!addContactUsSuccess ? (
                  <ContactUsForm
                    onSubmit={onContactUsSubmit}
                    errors={addContactUsErrors}
                    loading={addContactUsLoading}
                    initialValues={addContactUsInit}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="py-md-5 py-3">
        <div className="container py-md-5 py-3">
          <div className="row footer-grids">
            <div className="col-lg-4 col-sm-6 text-center d-flex flex-column justify-content-center align-items-center mb-lg-0 mb-sm-5 mb-4">
              <img
                className="footer-logo"
                src={`${process.env.REACT_APP_IMAGE_PATH}website/logo/main-app-logo.jpg`}
                alt="logo"
              />
            </div>
            <div className="col-lg-5 col-sm-6 mb-lg-0 mb-sm-5 mb-4">
              <h4 className="mb-4">{LanguageTexts.homePage.contactUs}</h4>
              {/* <p>
                <span className="fa mr-2 fa-map-marker text-white" />
                {LanguageTexts.homePage.addressInfo}
              </p> */}
              <p className="phone py-2">
                <span className="fa mr-2 fa-envelope text-white" />
                <a
                  href={`mailto:${LanguageTexts.app.contactEmail}`}
                  className="underline-link"
                >
                  {LanguageTexts.app.contactEmail}
                </a>
              </p>
            </div>
            <div className="col-lg-3 col-sm-6">
              <h4 className="mb-4">{LanguageTexts.homePage.quickLinks}</h4>
              <ul>
                <li>
                  <a href="#about">{LanguageTexts.homePage.quickLinks1}</a>
                </li>
                <li>
                  <a href="#contact">{LanguageTexts.homePage.quickLinks2}</a>
                </li>
                <li>
                  <Link to="/login" onClick={windowScrollTop}>
                    {LanguageTexts.homePage.quickLinks3}
                  </Link>
                </li>
                <li>
                  <Link to="/register" onClick={windowScrollTop}>
                    {LanguageTexts.homePage.quickLinks4}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container py-4">
          <p>
            {getCurrentYear(LanguageTexts.homePage.copyrightText)}{' '}
            <a href="/privacy-policy">
              {LanguageTexts.homePage.privacyPolicyText}
            </a>
          </p>
        </div>
      </div>
      <div className="move-top text-right">
        <a href="#top" className="move-top font-weight-bold">
          <span className="fa fa-angle-up mb-3" aria-hidden="true" />
        </a>
      </div>
    </div>
  );
};

export default HomePage;

/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useMemo } from 'react';

import { isPastDate, setTimeToZero } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import BatchCalendar from './BatchCalendar';
import BatchTimePicker from './BatchTimePicker';

type BatchScheduleProps = {
  day: moment.Moment;
  startTime: moment.Moment | undefined;
  endTime: moment.Moment | undefined;
};

type BatchCustomScheduleProps = {
  schedule: BatchScheduleProps[];
  onChange: (schedule: BatchScheduleProps[]) => void;
  errors: string[];
  disabled?: boolean;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  timezone: string;
};

const BatchCustomSchedule: React.FC<BatchCustomScheduleProps> = ({
  schedule: scheduleInit,
  onChange,
  errors,
  disabled,
  startDate,
  endDate,
  timezone,
}: BatchCustomScheduleProps): JSX.Element => {
  const { batches: batchsTxt } = LanguageTexts;

  const schedule = useMemo(() => {
    return scheduleInit.sort((a, b) => +a.day - +b.day);
  }, [scheduleInit]);

  const selectedDays = schedule.map(({ day }) => day);

  const today = moment(
    setTimeToZero(moment.tz(undefined, timezone).format(moment.HTML5_FMT.DATE)),
  ).toDate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let disabledDays: any[] = [
    today,
    {
      before: today,
      after: today,
    },
  ];

  if (startDate && endDate) {
    disabledDays = [
      today,
      {
        before: today,
      },
      {
        before: moment(
          setTimeToZero(startDate.format(moment.HTML5_FMT.DATE)),
        ).toDate(),
        after: moment(
          setTimeToZero(endDate.format(moment.HTML5_FMT.DATE)),
        ).toDate(),
      },
    ];
  }

  function handleOnChange(newValues: moment.Moment[]) {
    const newSchedule = newValues.map((val) => {
      const newSch = schedule.find(({ day }) => moment(day).isSame(val));

      return newSch
        ? { ...newSch }
        : { day: val, startTime: undefined, endTime: undefined };
    });

    onChange(newSchedule);
  }

  function onTimeChange(index: number, type: string, time: moment.Moment) {
    const newSchedule = [...schedule];
    const sItem = { ...newSchedule[index] };

    // reset end time if before start time
    if (
      (type === 'startTime' &&
        time &&
        sItem.endTime &&
        sItem.endTime.isSameOrBefore(time)) ||
      (type === 'endTime' &&
        time &&
        sItem.startTime &&
        time.isSameOrBefore(sItem.startTime))
    ) {
      sItem.endTime = undefined;

      if (type === 'startTime') {
        sItem.startTime = time;
      }

      newSchedule[index] = sItem;
    } else {
      newSchedule[index] = {
        ...sItem,
        [type]: time,
      };
    }

    onChange(newSchedule);
  }

  return (
    <div className="custom-schedule">
      <label className="text-secondary small">
        {batchsTxt.selectDays}&nbsp;
        <sup className="common-text-color require-field">
          <span className="fa fa-star-of-life" />
        </sup>
      </label>
      <div className="my-2" />

      <div className="batch-custom-schedule">
        <BatchCalendar
          disabledDays={disabledDays}
          selectedDays={selectedDays}
          onChange={handleOnChange}
          timezone={timezone}
        />
      </div>

      {schedule.map(({ day, startTime, endTime }, index) => {
        const dayLabel = day.format('L');

        return (
          <div key={`cdday${day}`}>
            <div className="my-3" />
            <div className="form-group row">
              <AppInputLableWithError
                errors={errors}
                langTxt={batchsTxt}
                id="schedule.startTime"
                required
                errId={`${index}.startTime`}
                className="col-lg-3 col-12 col-form-label small"
              />
              <div className="col-lg-9">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <b>{dayLabel}</b>
                    </span>
                  </div>
                  <BatchTimePicker
                    value={startTime}
                    disabled={disabled || isPastDate(day, timezone)}
                    onChange={(newVal) =>
                      onTimeChange(index, 'startTime', newVal)
                    }
                    timezone={timezone}
                  />
                </div>
              </div>
            </div>
            <div className="my-3" />
            <div className="form-group row">
              <AppInputLableWithError
                errors={errors}
                langTxt={batchsTxt}
                id="schedule.endTime"
                required
                errId={`${index}.endTime`}
                className="col-lg-3 col-12 col-form-label small"
              />
              <div className="col-lg-9">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-secondary">
                      <b>{dayLabel}</b>
                    </span>
                  </div>
                  <BatchTimePicker
                    value={endTime}
                    disabled={disabled || isPastDate(day, timezone)}
                    onChange={(newVal) =>
                      onTimeChange(index, 'endTime', newVal)
                    }
                    timezone={timezone}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

BatchCustomSchedule.defaultProps = {
  disabled: false,
};

export default BatchCustomSchedule;

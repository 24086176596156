import React from 'react';

import LanguageTexts from '../../common/language';
import AnnouncementTable from './AnnouncementTable';

const AnnouncementListPage = (): JSX.Element => {
  const { announcements: announcementsTxt } = LanguageTexts;

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{announcementsTxt.announcements}</h1>
      <AnnouncementTable />
    </section>
  );
};

export default AnnouncementListPage;

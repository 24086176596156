/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { PaymentMethodType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import PaymentMethodForm from './PaymentMethodForm';
import PaymentMethodList from './PaymentMethodList';

type PaymentMethodPageProps = {
  infoLoaded: boolean;
};

const PaymentMethodPage: React.FC<PaymentMethodPageProps> = ({
  infoLoaded,
}: PaymentMethodPageProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const [showForm, setShowForm] = useState(false);
  const {
    paymentMethods,
    loadPaymentMethods: { loading: loadPaymentMethodsLoading },
  } = useSelector((state: RootState) => state.profile);

  return (
    <>
      {showForm ? (
        <PaymentMethodForm
          initialValues={{ paymentMethodType: PaymentMethodType.Card }}
          infoLoaded={infoLoaded}
          onCancel={() => setShowForm(false)}
        />
      ) : (
        <button
          type="button"
          className="btn add-more-btn"
          onClick={() => setShowForm(true)}
        >
          {profileTxt.btnAddPaymentMethod}&nbsp;
        </button>
      )}
      {loadPaymentMethodsLoading ? (
        <AppLoader />
      ) : paymentMethods ? (
        <PaymentMethodList paymentMethods={paymentMethods} />
      ) : null}
    </>
  );
};

export default PaymentMethodPage;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { CreateCenterInput, GetCentersQuery } from '../../common/types';
import ApiService from '../../services/api';
import {
  addCenter,
  addCenterError,
  addCenterSuccess,
  loadCenter,
  loadCenterDeps,
  loadCenterDepsError,
  loadCenterDepsSuccess,
  loadCenterError,
  loadCenters,
  loadCentersError,
  loadCentersSuccess,
  loadCenterSuccess,
} from './institute-centers.slice';

export function* addCenterAsync(action: PayloadAction<CreateCenterInput>) {
  const { data, errors } = yield call(ApiService.addCenter, action.payload);

  if (errors) {
    yield put(addCenterError(errors));
  } else {
    yield put(addCenterSuccess(!!data));
  }
}

export function* loadCenterAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadCenterFormDetails, action.payload);

  if (!data) {
    yield put(loadCenterError(['404']));
  } else {
    yield put(loadCenterSuccess(data));
  }
}

export function* loadCentersAsync(action: PayloadAction<GetCentersQuery>) {
  const { data } = yield call(ApiService.getCentersForTable, action.payload);

  if (!data) {
    yield put(loadCentersError(['500']));
  } else {
    yield put(loadCentersSuccess(data));
  }
}

export function* loadCenterDepsAsync() {
  const { data } = yield call(ApiService.getCenterDeps);

  if (!data) {
    yield put(loadCenterDepsError(['500']));
  } else {
    yield put(loadCenterDepsSuccess(data));
  }
}

export function* watchCentersAsync() {
  yield takeEvery(addCenter.toString(), addCenterAsync);
  yield takeEvery(loadCenter.toString(), loadCenterAsync);
  yield takeEvery(loadCenters.toString(), loadCentersAsync);
  yield takeEvery(loadCenterDeps.toString(), loadCenterDepsAsync);
}

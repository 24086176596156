/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ItSettingsResponse, ItSettingState } from '../../common/types';

const initialState: ItSettingState = {
  loading: false,
  success: null,
  errors: [],
  loaded: false,
  navMenu: null,
  routePermissions: null,
  stripe: null,
  reservedSubdomain: null,
  subscriptionPlanFirst: null,
};

const itSettingSlice = createSlice({
  name: 'itSetting',
  initialState,
  reducers: {
    itSetting(state) {
      state.loading = true;
      state.success = null;
      state.errors = [];
    },
    itSettingSuccess(state, action: PayloadAction<ItSettingsResponse>) {
      state.loading = false;
      state.success = true;
      state.loaded = true;

      state.navMenu = action.payload.navMenu;
      state.routePermissions = action.payload.routePermissions;
      state.stripe = action.payload.stripe;
      state.reservedSubdomain = action.payload.reservedSubdomain;
      state.subscriptionPlanFirst = action.payload.subscriptionPlanFirst;
    },
    itSettingError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
      state.loaded = true;
    },
  },
});

export const {
  itSetting,
  itSettingSuccess,
  itSettingError,
} = itSettingSlice.actions;

export const {
  name: itSettingSliceName,
  reducer: itSettingSliceReducer,
} = itSettingSlice;

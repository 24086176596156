import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import BasicInformation from './basic-information/BasicInformation';
// import InstituteCourses from './institute-courses/InstituteCourses';
import { loadInstituteSetupInfo, setActiveTab } from './institute-setup.slice';
import MediaUpload from './media-upload/MediaUpload';
import PaymentInformation from './payment-information/PaymentInformation';
import WebsiteInformation from './website-information/WebsiteInformation';

const InstituteSetupPage: React.FC = (): JSX.Element => {
  const { instituteSetup: instituteSetupTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state: RootState) => state.instituteSetup.activeTab,
  );

  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadInstituteSetupInfo());
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded]);

  function goToTab(key: string) {
    dispatch(setActiveTab(key));
  }

  return (
    <section className="bg-white rounded shadow py-5 institute-setup-form">
      <h1 className="primary-heading mb-4 ml-4">{instituteSetupTxt.header}</h1>
      <p className="small common-text-color text-right mb-5 mr-4">
        {appTxt.mandatoryNote}
      </p>
      <Tabs
        activeKey={activeTab}
        className="nav-fill"
        onSelect={(key) => key && goToTab(key)}
      >
        <Tab eventKey="0" title={instituteSetupTxt.tabHeader1}>
          <div
            className="tab-pane fade show active pt-4 px-4"
            id="basic-info"
            role="tabpanel"
            aria-labelledby="basic-info-tab"
          >
            <BasicInformation infoLoaded={infoLoaded} />
          </div>
        </Tab>
        <Tab eventKey="1" title={instituteSetupTxt.tabHeader2}>
          <div
            className="tab-pane pt-4 px-4"
            id="website-info"
            role="tabpanel"
            aria-labelledby="website-info-tab"
          >
            <WebsiteInformation infoLoaded={infoLoaded} />
          </div>
        </Tab>
        <Tab eventKey="2" title={instituteSetupTxt.tabHeader4}>
          <div
            className="tab-pane pt-4 px-4"
            id="media"
            role="tabpanel"
            aria-labelledby="media-tab"
          >
            <MediaUpload infoLoaded={infoLoaded} />
          </div>
        </Tab>
        <Tab eventKey="3" title={instituteSetupTxt.tabHeader5}>
          <div
            className="tab-pane pt-4 px-4"
            id="payment"
            role="tabpanel"
            aria-labelledby="payment-tab"
          >
            <PaymentInformation infoLoaded={infoLoaded} />
          </div>
        </Tab>
      </Tabs>
    </section>
  );
};

export default InstituteSetupPage;

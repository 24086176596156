import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InstituteBasicInfoInput, RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  removeInstituteLogo,
  resetInstituteBasicInfoSuccess,
  resetRemoveInstituteLogo,
  saveInstituteBasicInfo,
} from '../institute-setup.slice';
import BasicInformationForm from './BasicInformationForm';

type BasicInformationProps = {
  infoLoaded: boolean;
};

const BasicInformation: React.FC<BasicInformationProps> = ({
  infoLoaded,
}: BasicInformationProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    instituteBasicInfo,
    instituteTypes,
    cities,
    loading: infoLoading,
    saveInstituteBasicInfo: { errors, loading, success },
    removeInstituteLogo: {
      loading: removeInstituteLogoLoading,
      success: removeInstituteLogoSuccess,
    },
  } = useSelector((state: RootState) => state.instituteSetup);

  function onSubmit(data: InstituteBasicInfoInput) {
    dispatch(saveInstituteBasicInfo(data));
  }

  function onRemoveLogo() {
    dispatch(removeInstituteLogo());
  }

  useEffect(() => {
    if (success) {
      dispatch(resetInstituteBasicInfoSuccess());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (removeInstituteLogoSuccess) {
      dispatch(resetRemoveInstituteLogo());
    }
  }, [removeInstituteLogoSuccess, dispatch]);

  const instituteBasicInfoInit = instituteBasicInfo
    ? cloneDeep(instituteBasicInfo)
    : {
        instituteTypeId: '',
        email: '',
        name: '',
        phone: '',
        address: {
          address1: '',
          address2: '',
          zipcode: '',
          cityId: '',
          stateId: '',
          countryId: '',
        },
        socialMedia: {
          youtube: '',
          facebook: '',
          instagram: '',
        },
      };

  return infoLoaded && !infoLoading && !success ? (
    <BasicInformationForm
      initialValues={instituteBasicInfoInit}
      instituteTypes={instituteTypes}
      cities={cities || []}
      onSubmit={onSubmit}
      onRemoveLogo={onRemoveLogo}
      errors={errors}
      loading={loading || removeInstituteLogoLoading}
    />
  ) : (
    <AppLoader />
  );
};

export default BasicInformation;

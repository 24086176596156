import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LanguageTexts from '../../common/language';
import { ForgotPasswordInput, RootState } from '../../common/types';
import { forgotPassword } from './forgot-password.slice';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage: React.FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { errors, success, loading } = useSelector(
    (state: RootState) => state.forgotPassword,
  );

  function onSubmit(data: ForgotPasswordInput) {
    dispatch(forgotPassword(data));
  }

  return (
    <div className="limiter">
      <div className="container-login-register">
        {success ? (
          <div className="wrap-login-register-parent">
            <div className="wrap-login-register-child signin-form-section">
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="text-secondary mb-4">
                    {LanguageTexts.forgotPassword.successMsg}
                  </h5>
                  <Link to="/login" className="common-text-color small">
                    <span
                      className="fa fa-angle-left fa-1x mr-2"
                      aria-hidden="true"
                    />
                    {LanguageTexts.forgotPassword.backToLoginLink}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="login-register-side-image"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </div>
        ) : (
          <div className="wrap-login-register-parent">
            <div className="wrap-login-register-child signin-form-section">
              <div className="login100-form validate-signin-form">
                <ForgotPasswordForm
                  onSubmit={onSubmit}
                  errors={errors}
                  loading={loading}
                />
              </div>
            </div>
            <div
              className="login-register-side-image"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/login/side-image.jpg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ForgotPasswordPage.defaultProps = {};

export default ForgotPasswordPage;

import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import Select from 'react-select';

import { AssignmentSubmissionFormat, UserRole } from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { UserModel } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import FileUpload from '../../components/FileUpload';
import ExpiryDatePicker from '../announcements/components/ExpiryDatePicker';

type AssignmentFormData = {
  _id?: string;
  batchId: string;
  assignmentDate: moment.Moment;
  assignmentDueDate: moment.Moment | undefined;
  students: string[];
  studentsExcluded: string[];
  description: string;
  assignmentSubmissionFormat: string;
  assignmentUrl: string;
  assignmentFileUrl: string;
  assignmentFile: File | undefined;
};

type AssignmentProps = {
  onSubmit: (data: AssignmentFormData) => void;
  loading: boolean;
  errors: string[];
  initialValues: AssignmentFormData;
  filteredStudents: UserModel[];
  userRoles: UserRole[];
};

const AssignmentForm: React.FC<AssignmentProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  filteredStudents,
  userRoles,
}: AssignmentProps): JSX.Element => {
  const { assignments: assignmentsTxt } = LanguageTexts;

  const [selectedAssignmentFile, setAssignmentFile] = useState<File | null>(
    null,
  );

  const mapStudentOption = ({ _id, name }: UserModel) => {
    return {
      value: _id,
      label: name,
    };
  };

  const [selectedStudents, setSelectedStudents] = useState(
    filteredStudents
      .filter(({ _id }) => {
        return initialValues.students
          ? initialValues.students.includes(_id)
          : false;
      })
      .map(mapStudentOption),
  );

  const [selectedStudentsExcluded, setSelectedStudentsExcluded] = useState(
    filteredStudents
      .filter(({ _id }) => {
        return initialValues.studentsExcluded
          ? initialValues.studentsExcluded.includes(_id)
          : false;
      })
      .map(mapStudentOption),
  );

  const studentsOptions = filteredStudents.map(mapStudentOption);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onStudentChange(val: any) {
    setSelectedStudents(val);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onStudentExcludedChange(val: any) {
    setSelectedStudentsExcluded(val);
  }

  const { ...initVal } = initialValues;

  return (
    <Formik
      initialValues={initVal}
      onSubmit={(values, { setSubmitting }) => {
        if (!userRoles.includes(UserRole.Teacher)) {
          return;
        }

        const input = { ...values };

        input.students = selectedStudents?.map(({ value }) => value) || [];
        input.studentsExcluded =
          input.students.length === 0 && selectedStudentsExcluded
            ? selectedStudentsExcluded.map(({ value }) => value)
            : [];

        switch (input.assignmentSubmissionFormat) {
          case AssignmentSubmissionFormat.File:
            if (selectedAssignmentFile) {
              input.assignmentFile = selectedAssignmentFile;
            }
            input.assignmentUrl = '';
            break;
          case AssignmentSubmissionFormat.Url:
            input.assignmentFile = undefined;
            input.assignmentFileUrl = '';
            break;
          default:
            break;
        }

        onSubmit(input);

        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const errorKeys = getLangAndErrKeys({
          ...values,
          assignmentFile: null,
          assignmentDueDate: null,
        });
        const formattedErrors = formatErrors(errorKeys, errors, assignmentsTxt);

        return (
          <Form>
            <div className="row">
              <div className="col-12">
                <p>
                  {assignmentsTxt.date} :&nbsp;
                  <span className="common-text-color font-weight-bold">
                    {initVal.assignmentDate.format('L')}
                  </span>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 my-md-0 my-3">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={assignmentsTxt}
                  id="input.assignmentDueDate"
                  required
                />
                <ExpiryDatePicker
                  value={
                    values.assignmentDueDate
                      ? moment(values.assignmentDueDate)
                      : undefined
                  }
                  onChange={(newValue) => {
                    setFieldValue('assignmentDueDate', newValue || undefined);
                  }}
                  disabledDays={[]}
                />
              </div>
            </div>

            <div className="my-3" />

            <div className="row">
              <div className="col-12 my-md-0 my-3">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={assignmentsTxt}
                  id="input.students"
                />
                <Select
                  closeMenuOnSelect={false}
                  value={selectedStudents}
                  options={studentsOptions}
                  onChange={onStudentChange}
                  placeholder={
                    selectedStudents && selectedStudents.length > 0
                      ? ''
                      : assignmentsTxt.allStudents
                  }
                  isMulti
                />
              </div>
            </div>

            <div className="my-3" />

            {selectedStudents && selectedStudents.length === 0 ? (
              <>
                <div className="row">
                  <div className="col-12 my-md-0 my-3">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={assignmentsTxt}
                      id="input.studentsExcluded"
                    />
                    <Select
                      closeMenuOnSelect={false}
                      value={selectedStudentsExcluded}
                      options={studentsOptions}
                      onChange={onStudentExcludedChange}
                      placeholder={assignmentsTxt.none}
                      isMulti
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="my-3" />

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={assignmentsTxt}
                    id="input.description"
                    required
                  />
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    rows={5}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <AppInputLableWithError
                  errors={errors}
                  langTxt={assignmentsTxt}
                  id="input.assignmentSubmissionFormat"
                />
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    type="radio"
                    name="assignmentSubmissionFormat"
                    className="custom-control-input"
                    value={AssignmentSubmissionFormat.Url}
                    id="url-assignmentSubmissionFormat"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="url-assignmentSubmissionFormat"
                  >
                    {assignmentsTxt['input.assignmentUrl']}
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <Field
                    type="radio"
                    name="assignmentSubmissionFormat"
                    className="custom-control-input"
                    value={AssignmentSubmissionFormat.File}
                    id="file-assignmentSubmissionFormat"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="file-assignmentSubmissionFormat"
                  >
                    {assignmentsTxt['input.assignmentFile']}
                  </label>
                </div>
              </div>
            </div>

            <div className="my-3" />

            {values.assignmentSubmissionFormat ===
            AssignmentSubmissionFormat.Url ? (
              <>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={assignmentsTxt}
                        id="input.assignmentUrl"
                      />
                      <Field
                        name="assignmentUrl"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-3" />
              </>
            ) : null}

            {values.assignmentSubmissionFormat ===
            AssignmentSubmissionFormat.File ? (
              <>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={assignmentsTxt}
                        id="input.assignmentFile"
                      />
                      <FileUpload
                        id="assignment-file"
                        label=""
                        url={initialValues.assignmentFileUrl}
                        onFileSelect={(file) => {
                          setAssignmentFile(file);
                        }}
                        disabled={loading}
                        maxFileSize={1024 * 200}
                      />
                    </div>
                  </div>
                </div>

                <div className="my-3" />
              </>
            ) : null}

            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            {userRoles.includes(UserRole.Teacher) ? (
              <div className="row">
                <div className="col-md-6 col-12" />
                <div className="col-md-6 col-12 text-right">
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {assignmentsTxt.btnSave}
                  </button>
                </div>
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignmentForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateAnnouncementInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import AnnouncementListPage from './AnnouncementListPage';
import AnnouncementRecordPage from './AnnouncementRecordPage';
import {
  addAnnouncement,
  resetAddAnnouncementSuccess,
} from './announcements.slice';

const AnnouncementsPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addAnnouncement: {
      loading: addAnnouncementLoading,
      success: addAnnouncementSuccess,
      errors: addAnnouncementErrors,
    },
  } = useSelector((state: RootState) => state.announcements);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateAnnouncementInput) {
    dispatch(
      addAnnouncement({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addAnnouncementSuccess) {
      dispatch(resetAddAnnouncementSuccess());
      history.push(AppRoutePath.Announcements);
    }
  }, [addAnnouncementSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.AnnouncementsUpdate}
          permissions={rPermission(AppRoutePath.AnnouncementsUpdate)}
        >
          {!addAnnouncementSuccess ? (
            <AnnouncementRecordPage
              key="updateannouncementform"
              errors={addAnnouncementErrors}
              loading={addAnnouncementLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.AnnouncementsCreate}
          permissions={rPermission(AppRoutePath.AnnouncementsCreate)}
        >
          {!addAnnouncementSuccess ? (
            <AnnouncementRecordPage
              key="createannouncementform"
              errors={addAnnouncementErrors}
              loading={addAnnouncementLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <AnnouncementListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default AnnouncementsPage;

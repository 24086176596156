export enum InstituteWebsiteMediaType {
  MainImage = 'main-image',
  GalleryImage = 'gallery-image',
  YoutubeUrl = 'utube-url',
}

export enum LightBoxSlideType {
  Image = 'image',
  Youtube = 'youtube',
}

export enum PublicRoutePath {
  Login = '/login',
  Register = '/register',
  ForgotPass = '/forgot-password',
  PrivacyPolicy = '/privacy-policy',
}

export enum AppRoutePath {
  Dashboard = '/app/dashboard',
  InstituteSetup = '/app/institute-setup',
  InstituteClass = '/app/class-setup',
  Centers = '/app/centers',
  CentersCreate = '/app/centers/create',
  CentersUpdate = '/app/centers/update/:centerId',
  Courses = '/app/courses',
  CoursesCreate = '/app/courses/create',
  CoursesUpdate = '/app/courses/update/:courseId',
  Classes = '/app/classes',
  ClassesCreate = '/app/classes/create',
  ClassesUpdate = '/app/classes/update/:classId',
  ContactUs = '/app/contact-us',
  Users = '/app/users',
  UsersCreate = '/app/users/create',
  UsersUpdate = '/app/users/update/:userId',
  Batches = '/app/batches',
  BatchesCreate = '/app/batches/create',
  BatchesUpdate = '/app/batches/update/:batchId',
  Profile = '/app/profile',
  UserInvoices = '/app/fees',
  UserInvoicePay = '/app/fees/:invoiceId',
  Institutes = '/app/institutes',
  Announcements = '/app/announcements',
  AnnouncementsCreate = '/app/announcements/create',
  AnnouncementsUpdate = '/app/announcements/update/:announcementId',
  Attendances = '/app/attendance',
  AttendancesCreate = '/app/attendance/create',
  Assignments = '/app/assignments',
  AssignmentsCreate = '/app/assignments/create',
  AssignmentsSubmit = '/app/assignments/submission/:assignmentId/:studentId',
}

export enum UserRole {
  Admin = 'Admin',
  Owner = 'Owner',
  CenterHead = 'CenterHead',
  CenterHeadTeacher = 'CenterHeadTeacher',
  Teacher = 'Teacher',
  Parent = 'Parent',
  Student = 'Student',
}

export enum UserStatus {
  Pending = 'Pending',
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum UserEnrollmentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum InstituteStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
}

export enum BatchStatus {
  Active = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum BatchScheduleType {
  Weekly = 'Weekly',
  Custom = 'Custom',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum PaymentMethodType {
  Card = 'Card',
  ACH = 'ACH',
  Cash = 'Cash',
  Cheque = 'Cheque',
  Online = 'Online',
  None = 'None',
}

export enum InvoiceStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  Uncollectible = 'Uncollectible',
  Void = 'Void',
}

export enum InvoiceType {
  StudentFees = 'StudentFees',
  InstituteSubscription = 'InstituteSubscription',
}

export enum PaymentMode {
  Online,
  Offline,
}

export enum PayoutStatus {
  Pending = 'Pending',
  Paid = 'Paid',
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Failed = 'Failed',
}

export enum AttendanceStatus {
  Present = 'Present',
  Absent = 'Absent',
  Excused = 'Excused',
}

export enum AssignmentSubmissionStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Resubmit = 'Resubmit',
  Resubmitted = 'Resubmitted',
  Completed = 'Completed',
}

export enum AssignmentSubmissionFormat {
  File = 'File',
  Url = 'Url',
}

export enum NotificationType {
  FeesDue = 'FeesDue',
  BatchScheduleChange = 'BatchScheduleChange',
  NewAnnouncement = 'NewAnnouncement',
  NewAssignment = 'NewAssignment',
  AssignmentSubmissionStatusChange = 'AssignmentSubmissionStatusChange',
}

export enum WeekDay {
  Sun = '0',
  Mon = '1',
  Tue = '2',
  Wed = '3',
  Thu = '4',
  Fri = '5',
  Sat = '6',
}

export const USER_ROLES_PERMISSIONS = [
  {
    key: UserRole.CenterHead,
    title: 'Center Head',
    roles: [UserRole.CenterHead],
    permissionModify: [UserRole.Owner],
    permissionView: [UserRole.Owner],
  },
  {
    key: UserRole.CenterHeadTeacher,
    title: 'Center Head and Teacher',
    roles: [UserRole.CenterHead, UserRole.Teacher],
    permissionModify: [UserRole.Owner],
    permissionView: [UserRole.Owner],
  },
  {
    key: UserRole.Teacher,
    title: 'Teacher',
    roles: [UserRole.Teacher],
    permissionModify: [UserRole.Owner, UserRole.CenterHead],
    permissionView: [UserRole.Owner, UserRole.CenterHead],
  },
  {
    key: UserRole.Parent,
    title: 'Parent',
    roles: [UserRole.Parent],
    permissionModify: [UserRole.Owner, UserRole.CenterHead, UserRole.Teacher],
    permissionView: [UserRole.Owner, UserRole.CenterHead, UserRole.Teacher],
  },
  {
    key: UserRole.Student,
    title: 'Student',
    roles: [UserRole.Student],
    permissionModify: [UserRole.Owner, UserRole.CenterHead, UserRole.Teacher],
    permissionView: [UserRole.Owner, UserRole.CenterHead, UserRole.Teacher],
  },
];

export const WEEK_DAYS = [
  {
    label: 'Sun',
    value: WeekDay.Sun,
  },
  {
    label: 'Mon',
    value: WeekDay.Mon,
  },
  {
    label: 'Tue',
    value: WeekDay.Tue,
  },
  {
    label: 'Wed',
    value: WeekDay.Wed,
  },
  {
    label: 'Thu',
    value: WeekDay.Thu,
  },
  {
    label: 'Fri',
    value: WeekDay.Fri,
  },
  {
    label: 'Sat',
    value: WeekDay.Sat,
  },
];

export const DEFAULT_CURRENCY_SYMBOL = '$';
export const TOKEN_KEY = 'accessToken';
export const FILTER_KEY = 'selectedFilter';

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AttendanceDepsResponse,
  AttendanceModel,
  AttendancesState,
  CreateAttendanceInput,
  GetAttendanceDepsQuery,
  GetAttendancesQuery,
} from '../../common/types';

const initialState: AttendancesState = {
  loading: false,
  success: null,
  errors: [],
  attendances: null,
  addAttendance: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAttendance: {
    loading: false,
    success: null,
    errors: [],
  },
  loadAttendanceDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  batch: null,
  students: null,
};

const attendancesSlice = createSlice({
  name: 'attendances',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAttendances(state, action: PayloadAction<GetAttendancesQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadAttendancesSuccess(state, action: PayloadAction<AttendanceModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.attendances = action.payload;
    },
    loadAttendancesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadAttendances(state) {
      state.loadAttendance.success = null;
      state.loadAttendance.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addAttendance(state, action: PayloadAction<CreateAttendanceInput>) {
      state.addAttendance.loading = true;
      state.addAttendance.errors = [];
    },
    addAttendanceSuccess(state, action: PayloadAction<boolean>) {
      state.addAttendance.loading = false;
      state.addAttendance.success = action.payload;
    },
    addAttendanceError(state, action: PayloadAction<string[]>) {
      state.addAttendance.loading = false;
      state.addAttendance.errors = action.payload;
    },
    resetAddAttendanceSuccess(state) {
      state.addAttendance.success = null;
      state.addAttendance.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadAttendance(state, action: PayloadAction<string>) {
      state.loadAttendance.loading = true;
      state.loadAttendance.errors = [];
    },
    loadAttendanceSuccess(state, action: PayloadAction<AttendanceModel>) {
      state.loadAttendance.loading = false;
      state.loadAttendance.success = action.payload;
    },
    loadAttendanceError(state, action: PayloadAction<string[]>) {
      state.loadAttendance.loading = false;
      state.loadAttendance.errors = action.payload;
    },
    resetLoadAttendance(state) {
      state.loadAttendance.success = null;
      state.loadAttendance.errors = [];
    },
    loadAttendanceDeps(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetAttendanceDepsQuery>,
    ) {
      state.loadAttendanceDeps.loading = true;
      state.loadAttendanceDeps.errors = [];
    },
    loadAttendanceDepsSuccess(
      state,
      action: PayloadAction<AttendanceDepsResponse>,
    ) {
      state.loadAttendanceDeps.loading = false;
      state.loadAttendanceDeps.success = !!action.payload;
      state.students = action.payload.students;
      state.batch = action.payload.batch;
    },
    loadAttendanceDepsError(state, action: PayloadAction<string[]>) {
      state.loadAttendanceDeps.loading = false;
      state.loadAttendanceDeps.errors = action.payload;
    },
    resetLoadAttendanceDeps(state) {
      state.loadAttendanceDeps.success = null;
      state.loadAttendanceDeps.errors = [];
    },
  },
});

export const {
  loadAttendance,
  loadAttendanceError,
  loadAttendanceSuccess,
  resetLoadAttendance,
  loadAttendances,
  loadAttendancesError,
  loadAttendancesSuccess,
  resetLoadAttendances,
  addAttendance,
  addAttendanceSuccess,
  addAttendanceError,
  resetAddAttendanceSuccess,
  loadAttendanceDeps,
  loadAttendanceDepsSuccess,
  loadAttendanceDepsError,
  resetLoadAttendanceDeps,
} = attendancesSlice.actions;

export const {
  name: attendancesSliceName,
  reducer: attendancesSliceReducer,
} = attendancesSlice;

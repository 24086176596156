import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateAssignmentInput,
  CreateAssignmentSubmissionInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import AssignmentListPage from './AssignmentListPage';
import AssignmentRecordPage from './AssignmentRecordPage';
import {
  addAssignment,
  addAssignmentSubmission,
  resetAddAssignmentSubmissionSuccess,
  resetAddAssignmentSuccess,
} from './assignments.slice';
import AssignmentSubmissionPage from './AssignmentSubmissionPage';

const AssignmentsPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addAssignment: {
      loading: addAssignmentLoading,
      success: addAssignmentSuccess,
      errors: addAssignmentErrors,
    },
    addAssignmentSubmission: {
      loading: addAssignmentSubmissionLoading,
      success: addAssignmentSubmissionSuccess,
      errors: addAssignmentSubmissionErrors,
    },
  } = useSelector((state: RootState) => state.assignments);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateAssignmentInput) {
    dispatch(
      addAssignment({
        ...data,
      }),
    );
  }

  function onAssignmentSubmissionSubmit({
    ...data
  }: CreateAssignmentSubmissionInput) {
    dispatch(
      addAssignmentSubmission({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addAssignmentSuccess) {
      dispatch(resetAddAssignmentSuccess());
    }
  }, [addAssignmentSuccess, dispatch, history]);

  useEffect(() => {
    if (addAssignmentSubmissionSuccess) {
      dispatch(resetAddAssignmentSubmissionSuccess());
    }
  }, [addAssignmentSubmissionSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.AssignmentsSubmit}
          permissions={rPermission(AppRoutePath.AssignmentsSubmit)}
        >
          {!addAssignmentSubmissionSuccess ? (
            <AssignmentSubmissionPage
              key="createassignmentsubmissionform"
              errors={addAssignmentSubmissionErrors}
              loading={addAssignmentSubmissionLoading}
              onSubmit={onAssignmentSubmissionSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.AssignmentsCreate}
          permissions={rPermission(AppRoutePath.AssignmentsCreate)}
        >
          {!addAssignmentSuccess ? (
            <AssignmentRecordPage
              key="createassignmentform"
              errors={addAssignmentErrors}
              loading={addAssignmentLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <AssignmentListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default AssignmentsPage;

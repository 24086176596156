import 'react-day-picker/lib/style.css';

import moment from 'moment-timezone';
import React, { FC } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';

import { setTimeToZero } from '../../../common/helper';

const todayStyle = `.DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  color: #212529;
  font-weight: 400;
}
`;

type BatchCalendarProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDays: any[];
  selectedDays: moment.Moment[];
  timezone: string;
  onChange: (newValues: moment.Moment[]) => void;
};

const BatchCalendar: FC<BatchCalendarProps> = ({
  disabledDays,
  selectedDays,
  timezone,
  onChange,
}: BatchCalendarProps): JSX.Element => {
  const today = moment(
    setTimeToZero(
      moment
        .tz(undefined, timezone)
        .add('1', 'd')
        .format(moment.HTML5_FMT.DATE),
    ),
  ).toDate();

  function handleDayClick(
    dayInit: Date,
    { selected, disabled: dayDisabled }: DayModifiers,
  ) {
    if (dayDisabled) {
      return;
    }

    const day = moment.tz(
      setTimeToZero(moment(dayInit).format(moment.HTML5_FMT.DATE)),
      timezone,
    );
    const newSelectedDays = [...selectedDays];

    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        selectedDay.isSame(day),
      );
      newSelectedDays.splice(selectedIndex, 1);
    } else {
      newSelectedDays.push(day);
    }

    const sDays = newSelectedDays.sort((a, b) => +a - +b);
    onChange(sDays);
  }

  return (
    <>
      <style>{todayStyle}</style>
      <DayPicker
        initialMonth={today}
        disabledDays={disabledDays}
        selectedDays={selectedDays.map((sday) =>
          moment(setTimeToZero(sday.format(moment.HTML5_FMT.DATE))).toDate(),
        )}
        onDayClick={handleDayClick}
      />
    </>
  );
};

export default BatchCalendar;

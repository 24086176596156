/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';

import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { InstituteWebsiteInfoInput } from '../../../common/types';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import ImageUpload from '../../../components/ImageUpload';
import AboutUsDescription from './AboutUsDescription';
import Testimonial from './Testimonial';

type WebsiteInformationProps = {
  onSubmit: (data: InstituteWebsiteInfoInput) => void;
  onRemoveAboutImage: () => void;
  onRemoveTestiPic: (id: string) => void;
  errors: string[];
  loading: boolean;
  initialValues: InstituteWebsiteInfoInput;
};

const WebsiteInformationForm: React.FC<WebsiteInformationProps> = ({
  onSubmit,
  onRemoveAboutImage,
  onRemoveTestiPic,
  errors,
  loading,
  initialValues,
}: WebsiteInformationProps): JSX.Element => {
  const {
    instituteWebsiteInformation: instituteWebsiteInformationTxt,
  } = LanguageTexts;

  const { app: appTxt } = LanguageTexts;

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { aboutImageUrl, ...initVals } = initialValues;

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        if (selectedImage) {
          input.image = selectedImage;
        }

        input.testimonial = input.testimonial.map(
          ({ commenterPicUrl, ...rest }) => rest,
        );

        onSubmit(input);
        setSubmitting(false);
      }}
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          LanguageTexts.instituteWebsiteInformation,
        );

        return (
          <Form>
            <div className="form">
              <h2 className="secondary-heading mb-2">
                {instituteWebsiteInformationTxt.aboutus}
              </h2>
              <p className="small common-text-color mb-4">
                {instituteWebsiteInformationTxt.aboutusTooltip}
              </p>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <ImageUpload
                    id="website-info"
                    label={instituteWebsiteInformationTxt['input.image']}
                    onFileSelect={(file) => {
                      setSelectedImage(file);
                    }}
                    url={aboutImageUrl}
                    maxFileSize={1024}
                    fileResolution={appTxt.resolutionSize}
                    disabled={loading}
                    onRemoveUrl={() => onRemoveAboutImage()}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteWebsiteInformationTxt}
                      id="input.heading"
                      required
                    />
                    <Field
                      name="heading"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={instituteWebsiteInformationTxt}
                      id="input.description"
                      required
                      errId="description"
                    />
                    <FieldArray name="description">
                      {({ remove, push }) => (
                        <>
                          {values.description.map((description, index) => (
                            <AboutUsDescription
                              // eslint-disable-next-line react/no-array-index-key
                              key={`desc${index}`}
                              index={index}
                              onRemove={(i) => remove(i)}
                              disabled={loading}
                            />
                          ))}
                          <div className="my-3" />
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn add-more-btn"
                                onClick={() => push('')}
                                disabled={loading}
                              >
                                <span className="fa fa-plus-circle" />
                                &nbsp;{instituteWebsiteInformationTxt.addDesc}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>
              <div className="my-5" />
              <h2 className="secondary-heading mb-4">
                {instituteWebsiteInformationTxt.testimonial}
              </h2>
              <FieldArray name="testimonial">
                {({ remove, push, replace }) => (
                  <>
                    {values.testimonial.map((testimonial, index) => (
                      <Testimonial
                        // eslint-disable-next-line react/no-array-index-key
                        key={`testi${index}`}
                        index={index}
                        onRemove={(i) => remove(i)}
                        commenterPicUrl={testimonial.commenterPicUrl}
                        id={testimonial._id}
                        onImageSelect={(file) =>
                          replace(index, { ...testimonial, commenterPic: file })
                        }
                        errors={errors}
                        disabled={loading}
                        onRemoveTestiPic={(id: string) => {
                          onRemoveTestiPic(id);
                        }}
                      />
                    ))}

                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn add-more-btn"
                          onClick={() =>
                            push({
                              commenterName: '',
                              commenterContact: '',
                              commenterDescription: '',
                              comments: '',
                            })
                          }
                          disabled={loading}
                        >
                          <span className="fa fa-plus-circle" />
                          &nbsp;{instituteWebsiteInformationTxt.addTestimonial}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </FieldArray>
              <div className="my-lg-5 my-3" />
              <div className="row">
                <div className="col-12">
                  {formattedErrors.map((error) => (
                    <p
                      className="txt2"
                      style={{ fontWeight: 'bold' }}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12" />
                <div className="col-lg-6 col-12 text-right">
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {instituteWebsiteInformationTxt.btnSave}&nbsp;
                    <span className="fa fa-chevron-circle-right text-white" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default WebsiteInformationForm;

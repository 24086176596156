import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath, NotificationType } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { NotificationModel, RootState } from '../../common/types';
import {
  loadNotifications,
  markAsRead,
  resetMarkAsReadSuccess,
} from './notification.slice';

type NotificationListProps = {
  notifications: NotificationModel[];
};

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
}: NotificationListProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notification: notificationTxt } = LanguageTexts;
  const {
    markAsRead: { success, loading },
  } = useSelector((state: RootState) => state.notification);

  function readNotification(notification: NotificationModel) {
    if (!loading) {
      dispatch(markAsRead(notification));
    }
  }

  const navigateForNotification = useCallback(
    (notification: NotificationModel) => {
      const { payload } = notification;

      switch (payload.notificationType) {
        case NotificationType.FeesDue:
          history.push(
            AppRoutePath.UserInvoicePay.replace(
              ':invoiceId',
              payload.userInvoiceId,
            ),
          );
          break;
        case NotificationType.BatchScheduleChange:
          history.push(AppRoutePath.Dashboard);
          break;
        case NotificationType.NewAnnouncement:
          history.push(AppRoutePath.Announcements);
          break;
        case NotificationType.NewAssignment:
          history.push(
            AppRoutePath.AssignmentsSubmit.replace(
              ':assignmentId',
              payload.assignmentId,
            ).replace(':studentId', notification.userId),
          );
          break;
        case NotificationType.AssignmentSubmissionStatusChange:
          history.push(
            AppRoutePath.AssignmentsSubmit.replace(
              ':assignmentId',
              payload.assignmentId,
            ).replace(':studentId', notification.userId),
          );
          break;
        default:
          break;
      }
    },
    [history],
  );

  useEffect(() => {
    if (success) {
      dispatch(resetMarkAsReadSuccess());
      dispatch(loadNotifications({ readByUser: false }));
      navigateForNotification(success);
    }
  }, [dispatch, navigateForNotification, success]);

  return (
    <>
      {notifications.map((notification) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id, payload } = notification;

        switch (payload.notificationType) {
          case NotificationType.FeesDue:
            return (
              <button
                type="button"
                className="dropdown-item media"
                onClick={() => {
                  readNotification(notification);
                }}
                key={_id}
              >
                <i className="fa fa-dollar-sign" />
                <div className="media-body">
                  <h6>{payload.userInvoice.batch.name}</h6>
                  <div className="my-1" />
                  <p className="text-center small">
                    {notificationTxt.feesDue
                      .replace('{currencySymbol}', payload.currencySymbol)
                      .replace('{amountDue}', payload.amountDue.toString())}
                  </p>
                </div>
              </button>
            );
          case NotificationType.BatchScheduleChange:
            return (
              <button
                type="button"
                className="dropdown-item media"
                onClick={() => {
                  readNotification(notification);
                }}
                key={_id}
              >
                <i className="fa fa-clock" />
                <div className="media-body">
                  <h6>{payload.batch.name}</h6>
                  <div className="my-1" />
                  <p className="text-center small">
                    {notificationTxt.batchScheduleChange}
                  </p>
                </div>
              </button>
            );
          case NotificationType.NewAnnouncement:
            return (
              <button
                type="button"
                className="dropdown-item media"
                onClick={() => {
                  readNotification(notification);
                }}
                key={_id}
              >
                <i className="fa fa-bullhorn" />
                <div className="media-body">
                  <h6>
                    {payload.announcement.createdByUser.name ||
                      payload.announcement.createdByUser.domain}
                  </h6>
                  <div className="my-1" />
                  <p className="text-center small">{payload.heading}</p>
                </div>
              </button>
            );
          case NotificationType.NewAssignment:
            return (
              <button
                type="button"
                className="dropdown-item media"
                onClick={() => {
                  readNotification(notification);
                }}
                key={_id}
              >
                <i className="fa fa-edit" />
                <div className="media-body">
                  <h6>{payload.assignment.createdByUser.name}</h6>
                  <div className="my-1" />
                  <p className="text-center small">
                    {notificationTxt.newAssignment}
                  </p>
                </div>
              </button>
            );
          case NotificationType.AssignmentSubmissionStatusChange:
            return (
              <button
                type="button"
                className="dropdown-item media"
                onClick={() => {
                  readNotification(notification);
                }}
                key={_id}
              >
                <i className="fa fa-edit" />
                <div className="media-body">
                  <h6>{payload.assignment.createdByUser.name}</h6>
                  <div className="my-1" />
                  <p className="text-center small">
                    {notificationTxt.assignmentStatusChange.replace(
                      '{status}',
                      payload.submissionData.submissionStatus,
                    )}
                  </p>
                </div>
              </button>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default NotificationList;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { InstituteStatus } from '../common/constants';
import { rolesHasRole, userHasRole } from '../common/helper';
import LanguageTexts from '../common/language';
import { AppRoutePermissions, RootState } from '../common/types';
import LayoutAdmin from './layout/LayoutAdmin';

type PrivateRouteProps = {
  children: JSX.Element;
  permissions: AppRoutePermissions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  permissions: { roles, restrictOnSubscriptionCancel },
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const {
    filter: { role },
  } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.login);

  function getLayout() {
    if (!user) {
      return null;
    }

    if (
      restrictOnSubscriptionCancel &&
      user?.institute?.status === InstituteStatus.Cancelled
    ) {
      return (
        <LayoutAdmin>
          <p>{LanguageTexts.auth.renewtoaccess}</p>
        </LayoutAdmin>
      );
    }

    if (
      (role && !rolesHasRole([role], roles)) ||
      (!role && !userHasRole(user, roles))
    ) {
      return (
        <LayoutAdmin>
          <p>{LanguageTexts.auth.noaccess}</p>
        </LayoutAdmin>
      );
    }

    return <LayoutAdmin>{children}</LayoutAdmin>;
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        user ? (
          getLayout()
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;

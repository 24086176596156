import { Field, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { PreRegistrationInfoInput } from '../../common/types';

type HomePageRegistrationFormProps = {
  onSubmit: (data: PreRegistrationInfoInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: PreRegistrationInfoInput;
};

const HomePageRegistrationForm: React.FC<HomePageRegistrationFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: HomePageRegistrationFormProps): JSX.Element => {
  const { ...initVals } = initialValues;

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(
    errorKeys,
    errors,
    LanguageTexts.homePage,
  );

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <Field
          placeholder="Your Email"
          name="email"
          type="text"
          className="tolowercase-text"
        />
        {formattedErrors.map((error) => (
          <p className="text-left small" key={error}>
            {error}
          </p>
        ))}
        <button type="submit" className="btn save-btn" disabled={loading}>
          {LanguageTexts.homePage.getRegisteredBtn}
        </button>
      </Form>
    </Formik>
  );
};

export default HomePageRegistrationForm;

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import {
  announcementsSliceName,
  announcementsSliceReducer,
} from '../features/announcements/announcements.slice';
import {
  assignmentsSliceName,
  assignmentsSliceReducer,
} from '../features/assignments/assignments.slice';
import {
  attendancesSliceName,
  attendancesSliceReducer,
} from '../features/attendances/attendances.slice';
import {
  batchesSliceName,
  batchesSliceReducer,
} from '../features/batches/batches.slice';
import {
  contactSliceName,
  contactSliceReducer,
} from '../features/contact-us/contact-us.slice';
import {
  forgotPasswordSliceName,
  forgotPasswordSliceReducer,
} from '../features/forgot-password/forgot-password.slice';
import {
  preRegistrationInfoSliceName,
  preRegistrationInfoSliceReducer,
} from '../features/home/home-page.slice';
import {
  centersSliceName,
  centersSliceReducer,
} from '../features/institute-centers/institute-centers.slice';
import {
  coursesSliceName,
  coursesSliceReducer,
} from '../features/institute-courses/institute-courses.slice';
import {
  classesSliceName,
  classesSliceReducer,
} from '../features/institute-classes/institute-classes.slice';
import {
  instituteHomeSliceName,
  instituteHomeSliceReducer,
} from '../features/institute-home/institute-home.slice';
import {
  instituteSetupSliceName,
  instituteSetupSliceReducer,
} from '../features/institute-setup/institute-setup.slice';
import {
  institutesSliceName,
  institutesSliceReducer,
} from '../features/institutes/institutes.slice';
import {
  itSettingSliceName,
  itSettingSliceReducer,
} from '../features/it-setting/it-setting.slice';
import {
  loginSliceName,
  loginSliceReducer,
} from '../features/login/login.slice';
import {
  notificationSliceName,
  notificationSliceReducer,
} from '../features/notification/notification.slice';
import {
  profileSliceName,
  profileSliceReducer,
} from '../features/profile/profile.slice';
import {
  registrationSliceName,
  registrationSliceReducer,
} from '../features/registration/registration.slice';
import {
  userInvoicesSliceName,
  userInvoicesSliceReducer,
} from '../features/user-invoices/user-invoices.slice';
import {
  usersSliceName,
  usersSliceReducer,
} from '../features/users/users.slice';
import { appSliceName, appSliceReducer } from './app.slice';
import history from './history';

const router = { router: connectRouter(history) };
const itSetting = {
  [itSettingSliceName]: itSettingSliceReducer,
};
const preRegistrationInfo = {
  [preRegistrationInfoSliceName]: preRegistrationInfoSliceReducer,
};
const registration = { [registrationSliceName]: registrationSliceReducer };
const login = { [loginSliceName]: loginSliceReducer };
const forgotPassword = {
  [forgotPasswordSliceName]: forgotPasswordSliceReducer,
};
const instituteSetup = {
  [instituteSetupSliceName]: instituteSetupSliceReducer,
};

const centers = {
  [centersSliceName]: centersSliceReducer,
};

const courses = {
  [coursesSliceName]: coursesSliceReducer,
};

const classes = {
  [classesSliceName]: classesSliceReducer,
};

const users = {
  [usersSliceName]: usersSliceReducer,
};

const instituteHome = {
  [instituteHomeSliceName]: instituteHomeSliceReducer,
};

const batches = {
  [batchesSliceName]: batchesSliceReducer,
};

const profile = {
  [profileSliceName]: profileSliceReducer,
};

const contact = {
  [contactSliceName]: contactSliceReducer,
};

const userInvoices = {
  [userInvoicesSliceName]: userInvoicesSliceReducer,
};

const institutes = {
  [institutesSliceName]: institutesSliceReducer,
};

const app = {
  [appSliceName]: appSliceReducer,
};

const announcements = {
  [announcementsSliceName]: announcementsSliceReducer,
};

const attendances = {
  [attendancesSliceName]: attendancesSliceReducer,
};

const assignments = {
  [assignmentsSliceName]: assignmentsSliceReducer,
};

const notification = {
  [notificationSliceName]: notificationSliceReducer,
};

export const rootReducer = combineReducers({
  ...router,
  ...itSetting,
  ...preRegistrationInfo,
  ...registration,
  ...login,
  ...forgotPassword,
  ...instituteSetup,
  ...centers,
  ...courses,
  ...classes,
  ...users,
  ...instituteHome,
  ...batches,
  ...profile,
  ...contact,
  ...userInvoices,
  ...institutes,
  ...app,
  ...announcements,
  ...attendances,
  ...assignments,
  ...notification,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...injectedReducers,
    ...router,
    ...itSetting,
    ...preRegistrationInfo,
    ...registration,
    ...login,
    ...forgotPassword,
    ...instituteSetup,
    ...centers,
    ...courses,
    ...classes,
    ...users,
    ...instituteHome,
    ...batches,
    ...profile,
    ...contact,
    ...userInvoices,
    ...institutes,
    ...app,
    ...announcements,
    ...attendances,
    ...assignments,
    ...notification,
  });
}

export type RootState = ReturnType<typeof rootReducer>;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik';
import React from 'react';

import LanguageTexts from '../../../common/language';

type AboutUsDescriptionProps = {
  index: number;
  onRemove: (index: number) => void;
  disabled?: boolean;
};

const AboutUsDescription: React.FC<AboutUsDescriptionProps> = ({
  index,
  onRemove,
  disabled,
}: AboutUsDescriptionProps): JSX.Element => {
  const {
    instituteWebsiteInformation: instituteWebsiteInformationTxt,
  } = LanguageTexts;

  function handleClick() {
    onRemove(index);
  }

  return (
    <div className="about-desc-parent">
      {index > 0 ? (
        <>
          <div className="my-3" />
          <div className="col-12 text-right border border-bottom-0 pt-3 pr-3 pb-0 pl-3 common-border-color">
            <button
              type="button"
              className="btn cancel-btn"
              onClick={handleClick}
              disabled={disabled}
            >
              {instituteWebsiteInformationTxt.btnRemove}&nbsp;
              <span className="fa fa-times" />
            </button>
          </div>
        </>
      ) : null}
      <div
        className={
          index > 0
            ? `border border-top-0 p-3 common-border-color about-desc-child`
            : `about-desc-child`
        }
      >
        <Field
          name={`description.${index}`}
          as="textarea"
          className="form-control"
          rows={5}
        />
        <div className="row">
          <div className="col-6">
            <p className="small common-text-color short-desc mt-1">
              {instituteWebsiteInformationTxt.descTxt}
              <span>{index + 1}</span>
            </p>
          </div>
          <div className="col-6 text-right">
            <p className="small common-text-color short-desc mt-1">
              {instituteWebsiteInformationTxt.recommendedCharNote}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutUsDescription.defaultProps = {
  disabled: false,
};

export default AboutUsDescription;

import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CityModel, CreateCenterInput } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';

type InstituteCenterProps = {
  onSubmit: (data: CreateCenterInput) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateCenterInput;
  cities: CityModel[];
};

const InstituteCenterForm: React.FC<InstituteCenterProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  cities,
}: InstituteCenterProps): JSX.Element => {
  const { centers: centersTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const cityOptions = cities.map((city) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id,
      cityName,
      state: { stateName },
      country: { name: countryName },
    } = city;
    return {
      value: _id,
      label: `${cityName}, ${stateName}, ${countryName}`,
      cityObj: city,
    };
  });

  const [selectedCity, setSelectedCity] = useState(
    cityOptions.find(({ value }) => value === initialValues.address.cityId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCityChange(val: any) {
    setSelectedCity(val);
  }

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        input.address.cityId = selectedCity?.cityObj?._id || '';
        input.address.stateId = selectedCity?.cityObj?.state?._id || '';
        input.address.countryId = selectedCity?.cityObj?.country?._id || '';

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);
        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          LanguageTexts.centers,
        );
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="input.name"
                    required
                  />
                  <Field name="name" type="text" className="form-control" />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="input.email"
                    required
                  />
                  <Field
                    name="email"
                    type="text"
                    className="form-control tolowercase-text"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="address.address1"
                    required
                  />
                  <Field
                    name="address.address1"
                    type="text"
                    className="form-control"
                  />
                  <p className="small common-text-color short-desc mt-1">
                    {centersTxt.address1Note}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="address.address2"
                  />
                  <Field
                    name="address.address2"
                    type="text"
                    className="form-control"
                  />
                  <p className="small common-text-color short-desc mt-1">
                    {centersTxt.address2Note}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="address.csc"
                    errId="address.cityId"
                    required
                  />
                  <Select
                    value={selectedCity}
                    options={cityOptions}
                    onChange={onCityChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="address.zipcode"
                    required
                  />
                  <Field
                    name="address.zipcode"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={centersTxt}
                    id="input.phone"
                    required
                  />
                  <Field name="phone" type="text" className="form-control" />
                  <p className="small common-text-color short-desc mt-1">
                    {appTxt.phoneNote}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12" />
              <div className="col-lg-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {centersTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InstituteCenterForm;

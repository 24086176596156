/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { ClassModel, CreateClassInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadClass,
  loadClassDeps,
  removeClassImage,
  resetLoadClass,
  resetLoadClassDeps,
  resetRemoveClassImage,
} from './institute-classes.slice';
import InstituteClassForm from './InstituteClassForm';

type InstituteClassRecordPageParams = {
  onSubmit: (data: CreateClassInput) => void;
  errors: string[];
  loading: boolean;
};

const InstituteClassRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: InstituteClassRecordPageParams): JSX.Element => {
  const { classId } = useParams<{ classId: string }>();
  const { classes: classesTxt, app: appTxt } = LanguageTexts;

  const [classLoaded, setClassLoaded] = useState<boolean>();
  const [classes, setClasses] = useState<ClassModel>();
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [classDepsLoadRequested, setClassDepsRequested] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadClass: {
      loading: loadClassLoading,
      errors: loadClassErrors,
      success: loadClassSuccess,
    },
    loadClassDeps: {
      loading: loadClassDepsLoading,
      errors: loadClassDepsErrors,
      success: loadClassDepsSuccess,
    },
    courses,
    removeClassImage: {
      loading: removeClassImageLoading,
      success: removeClassImageSuccess,
    },
  } = useSelector((state: RootState) => state.classes);

  useEffect(() => {
    if (classId && !classLoaded) {
      dispatch(loadClass(classId));
      setClassLoaded(true);
    }
  }, [classLoaded, classId, dispatch]);

  useEffect(() => {
    if (loadClassErrors.length > 0) {
      dispatch(resetLoadClass());
      history.push(AppRoutePath.Classes);
    } else if (loadClassSuccess) {
      setClasses(loadClassSuccess);
      dispatch(resetLoadClass());
    }
  }, [loadClassErrors, loadClassSuccess, dispatch, history]);

  function onSubmit({ ...restInput }: CreateClassInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  function onRemoveClassImage(id: string) {
    dispatch(removeClassImage(id));
  }

  // load class deps
  useEffect(() => {
    if (!classDepsLoadRequested) {
      dispatch(
        loadClassDeps({
          domain: currentUser?.domain || '',
        }),
      );
      setClassDepsRequested(true);
    }
  }, [currentUser, classDepsLoadRequested, dispatch]);

  useEffect(() => {
    if (loadClassDepsErrors.length > 0) {
      dispatch(resetLoadClassDeps());
      history.push(AppRoutePath.Classes);
    } else if (loadClassDepsSuccess) {
      dispatch(resetLoadClassDeps());
    }
  }, [loadClassDepsSuccess, loadClassDepsErrors, dispatch, history]);

  useEffect(() => {
    if (removeClassImageSuccess) {
      dispatch(resetRemoveClassImage());
      if (classes) {
        setClasses({ ...classes, imageUrl: undefined });
      }
    }
  }, [classes, removeClassImageSuccess, dispatch]);

  if (
    (classId && (!classLoaded || loadClassLoading || classes === undefined)) ||
    !classDepsLoadRequested ||
    loadClassDepsLoading
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let classInfo: any;

  if (classes) {
    classInfo = classes;
  }

  const classInfoInit: CreateClassInput = classInfo || {
    courseId: '',
    name: '',
    description: [''],
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {classId ? classesTxt.updateClass : classesTxt.newClass}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <InstituteClassForm
          onSubmit={onSubmit}
          onRemoveClassPic={onRemoveClassImage}
          initialValues={classInfoInit}
          courses={courses || []}
          errors={errors}
          loading={loading || removeClassImageLoading}
        />
      </div>
    </section>
  );
};

export default InstituteClassRecordPage;

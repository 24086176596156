/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath, PaymentMethodType } from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  PaymentMethodModel,
  RootState,
  UserCreditCardInput,
} from '../../common/types';
import BankAccountDetail from '../profile/bank-account/BankAccountDetail';
import CreditCardDetail from '../profile/credit-card/CreditCardDetail';
import {
  payInvoiceUsingCard,
  resetPayInvoiceUsingCard,
} from './user-invoices.slice';

type UserInvoicePayUsingCardProps = {
  invoiceId: string;
  onPaymentMethodChange: (pm: PaymentMethodModel) => void;
  initialValues: { paymentMethodToken: string | undefined };
  paymentMethods: PaymentMethodModel[];
};

const UserInvoicePayUsingCard: React.FC<UserInvoicePayUsingCardProps> = ({
  invoiceId,
  onPaymentMethodChange,
  initialValues,
  paymentMethods,
}: UserInvoicePayUsingCardProps): JSX.Element => {
  const { userInvoices: userInvoicesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    payInvoiceUsingCard: {
      loading: payInvoiceUsingCardLoading,
      success: payInvoiceUsingCardSuccess,
      errors: payInvoiceUsingCardErrors,
    },
  } = useSelector((state: RootState) => state.userInvoices);

  function onSubmit(data: UserCreditCardInput) {
    dispatch(
      payInvoiceUsingCard({
        invoiceId,
        paymentMethodToken: data.paymentMethodToken || '',
        autoPayment: data.autoPayment,
      }),
    );
  }

  useEffect(() => {
    if (payInvoiceUsingCardSuccess) {
      dispatch(resetPayInvoiceUsingCard());
      history.push(AppRoutePath.UserInvoices);
    }
  }, [payInvoiceUsingCardSuccess, dispatch, history]);

  const errorKeys = getLangAndErrKeys({
    invoiceId,
    paymentMethodToken: '',
    autoPayment: true,
  });

  const formattedErrors = formatErrors(
    errorKeys,
    payInvoiceUsingCardErrors,
    userInvoicesTxt,
  );

  return (
    <>
      {paymentMethods.length > 0 ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit({
              autoPayment: true,
              paymentMethodToken: values.paymentMethodToken,
            });
            setSubmitting(false);
          }}
        >
          {({ values }) => {
            return (
              <>
                <Form>
                  <div className="mb-3" style={{ display: 'inline' }}>
                    <div className="row my-3">
                      <div className="col-12 text-center">
                        <h2 className="secondary-heading">
                          {userInvoicesTxt.paymentMethod}
                        </h2>
                        <div className="my-2" />
                        <p className="small common-text-color">
                          {userInvoicesTxt.paymentMethodInstruction}
                        </p>
                      </div>
                    </div>
                    <div style={{ clear: 'both' }} />
                    {paymentMethods.map((pMethod) => {
                      switch (pMethod.type) {
                        case PaymentMethodType.ACH:
                          return (
                            <React.Fragment key={pMethod.id}>
                              <Field
                                type="radio"
                                name="paymentMethodToken"
                                id={pMethod.id}
                                value={pMethod.id}
                                style={{ display: 'none' }}
                                onClick={() => onPaymentMethodChange(pMethod)}
                              />
                              <label
                                htmlFor={pMethod.id}
                                style={{ cursor: 'pointer' }}
                              >
                                <BankAccountDetail
                                  data={pMethod}
                                  selected={
                                    pMethod.id === values.paymentMethodToken
                                  }
                                />
                              </label>
                            </React.Fragment>
                          );
                        case PaymentMethodType.Card:
                          return (
                            <React.Fragment key={pMethod.id}>
                              <Field
                                type="radio"
                                name="paymentMethodToken"
                                id={pMethod.id}
                                value={pMethod.id}
                                style={{ display: 'none' }}
                                onClick={() => onPaymentMethodChange(pMethod)}
                              />
                              <label
                                htmlFor={pMethod.id}
                                style={{ cursor: 'pointer' }}
                              >
                                <CreditCardDetail
                                  data={pMethod}
                                  selected={
                                    pMethod.id === values.paymentMethodToken
                                  }
                                />
                              </label>
                            </React.Fragment>
                          );
                        default:
                          return null;
                      }
                    })}
                    <div style={{ clear: 'both' }} />
                  </div>

                  <div className="my-4" />
                  <div className="row">
                    <div className="col-12">
                      {formattedErrors.map((error) => (
                        <p
                          className="txt2"
                          style={{ fontWeight: 'bold' }}
                          key={error}
                        >
                          {error}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12" />
                    <div className="col-lg-6 col-12 text-right">
                      <button
                        type="submit"
                        className="btn save-btn"
                        disabled={payInvoiceUsingCardLoading}
                      >
                        {userInvoicesTxt.btnPay}
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      ) : null}
    </>
  );
};

UserInvoicePayUsingCard.defaultProps = {};

export default UserInvoicePayUsingCard;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ForgotPasswordInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  forgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
} from './forgot-password.slice';

export function* forgotPasswordAsync(
  action: PayloadAction<ForgotPasswordInput>,
) {
  const { data, errors } = yield call(
    ApiService.forgotPassword,
    action.payload,
  );

  if (errors) {
    yield put(forgotPasswordError(errors));
  } else {
    yield put(forgotPasswordSuccess(data));
  }
}

export function* watchForgotPasswordAsync() {
  yield takeEvery(forgotPassword.toString(), forgotPasswordAsync);
}

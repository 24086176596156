import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppRoutePath, PaymentMethodType } from '../../common/constants';
import {
  PayInvoiceOfflineInput,
  RootState,
  UserInvoiceModel,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  payInvoiceOffline,
  resetPayInvoiceOffline,
} from './user-invoices.slice';
import UserInvoicePayOfflineFormForm from './UserInvoicePayOfflineForm';

type UserInvoicePayOfflineProps = {
  infoLoaded: boolean;
  invoice: UserInvoiceModel;
};

const UserInvoicePayOffline: React.FC<UserInvoicePayOfflineProps> = ({
  infoLoaded,
  invoice,
}: UserInvoicePayOfflineProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    payInvoiceOffline: {
      loading: payInvoiceOfflineLoading,
      success: payInvoiceOfflineSuccess,
      errors: payInvoiceOfflineErrors,
    },
  } = useSelector((state: RootState) => state.userInvoices);

  function onSubmit(data: PayInvoiceOfflineInput) {
    dispatch(
      payInvoiceOffline({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (payInvoiceOfflineSuccess) {
      dispatch(resetPayInvoiceOffline());
      history.push(AppRoutePath.UserInvoices);
    }
  }, [payInvoiceOfflineSuccess, dispatch, history]);

  if (!infoLoaded) {
    return <AppLoader />;
  }

  const initialValues = {
    invoiceId: invoice._id,
    paymentMethodType: PaymentMethodType.Cash,
    feesAmount: invoice.amountDue,
    notes: '',
    chequeBankName: '',
    chequeNumber: '',
  };

  return !payInvoiceOfflineSuccess ? (
    <UserInvoicePayOfflineFormForm
      onSubmit={onSubmit}
      errors={payInvoiceOfflineErrors}
      loading={payInvoiceOfflineLoading}
      initialValues={initialValues}
      currencySymbol={
        invoice.batch.center?.address.country?.currencySymbol || ''
      }
    />
  ) : (
    <AppLoader />
  );
};

UserInvoicePayOffline.defaultProps = {};

export default UserInvoicePayOffline;

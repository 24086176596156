/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

export type SelectedImage = {
  image: File;
  imagePreview: string;
};

type MultiImageUploadProps = {
  children: JSX.Element;
  id: string;
  onFileSelect: (images: SelectedImage[] | null) => void;
  disabled?: boolean;
};

const MultiImageUpload: React.FC<MultiImageUploadProps> = ({
  id,
  onFileSelect,
  children,
  disabled,
}: MultiImageUploadProps): JSX.Element => {
  async function onFileChange({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && files.length > 0) {
      const fileListPromise = [];

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        fileListPromise.push(
          new Promise<SelectedImage | null>((resolve) => {
            const reader = new FileReader();

            reader.onload = function onLoad() {
              if (reader.result) {
                resolve({
                  image: file,
                  imagePreview: reader.result.toString(),
                });
              } else {
                resolve(null);
              }
            };

            reader.readAsDataURL(file);
          }),
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fileList: any[] = (await Promise.all(fileListPromise)).filter(
        (item) => item !== null,
      );

      onFileSelect(fileList);
    }
  }

  return (
    <label htmlFor={id}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        aria-describedby="inputGroupFileAddon04"
        onChange={onFileChange}
        multiple
        disabled={disabled}
      />
      {children}
    </label>
  );
};

MultiImageUpload.defaultProps = {
  disabled: false,
};

export default MultiImageUpload;

/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';

import {
  AppRoutePath,
  BatchScheduleType,
  BatchStatus,
  UserRole,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  BatchFeesScheduleInput,
  BatchModel,
  BatchScheduleInput,
  CreateBatchInput,
  RootState,
} from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';
import AppLoader from '../../components/AppLoader';
import {
  loadBatch,
  loadBatchDeps,
  resetAddBatch,
  resetLoadBatch,
  resetLoadBatchDeps,
} from './batches.slice';
import BatchForm, { CreateBatchProps } from './BatchForm';

type BatchRecordPageParams = {
  onSubmit: (data: CreateBatchInput) => void;
  errors: string[];
  loading: boolean;
};

const BatchRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: BatchRecordPageParams): JSX.Element => {
  const { batchId } = useParams<{ batchId: string }>();
  const { batches: batchesTxt, app: appTxt } = LanguageTexts;
  const {
    filter: { centerId: selectedCenterF },
  } = useSelector((state: RootState) => state.app);

  const [selectedCenter, setSelectedCenter] = useState<
    | { value: string; label: string; timeZone: string; currencySymbol: string }
    | undefined
  >();

  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [formResetDone, setFormResetDone] = useState<boolean>();
  const [batchLoaded, setBatchLoaded] = useState<boolean>();
  const [batch, setBatch] = useState<BatchModel>();
  const [batchDepsLoadRequested, setBatchDepsRequested] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadBatch: {
      loading: loadBatchLoading,
      errors: loadBatchErrors,
      success: loadBatchSuccess,
    },
    loadBatchDeps: {
      loading: loadBatchDepsLoading,
      errors: loadBatchDepsErrors,
      success: loadBatchDepsSuccess,
    },
    centers,
    classes,
  } = useSelector((state: RootState) => state.batches);

  useEffect(() => {
    if (!formResetDone) {
      dispatch(resetAddBatch());
      setFormResetDone(true);
    }
  }, [formResetDone, dispatch]);

  // load batch
  useEffect(() => {
    if (batchId && !batchLoaded) {
      dispatch(loadBatch(batchId));
      setBatchLoaded(true);
    }
  }, [batchLoaded, batchId, dispatch]);

  useEffect(() => {
    if (loadBatchErrors.length > 0) {
      dispatch(resetLoadBatch());
      history.push(AppRoutePath.Batches);
    } else if (loadBatchSuccess) {
      setBatch(loadBatchSuccess);
      dispatch(resetLoadBatch());
    }
  }, [loadBatchErrors, loadBatchSuccess, dispatch, history]);

  // load batch deps
  useEffect(() => {
    if (!batchDepsLoadRequested) {
      dispatch(
        loadBatchDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Teacher],
        }),
      );
      setBatchDepsRequested(true);
    }
  }, [currentUser, batchDepsLoadRequested, dispatch]);

  useEffect(() => {
    if (loadBatchDepsErrors.length > 0) {
      dispatch(resetLoadBatchDeps());
      history.push(AppRoutePath.Batches);
    } else if (loadBatchDepsSuccess) {
      dispatch(resetLoadBatchDeps());
    }
  }, [loadBatchDepsSuccess, loadBatchDepsErrors, dispatch, history]);

  const filteredCenters = useMemo(() => {
    let centerIds: string[] = [];

    if (currentUser?.role.indexOf(UserRole.CenterHead) !== -1) {
      centerIds = currentUser?.centerIds || [];
    }

    return centers?.filter(({ _id }) => {
      if (selectedCenterF) {
        return selectedCenterF === _id;
      }

      if (centerIds.length > 0) {
        return centerIds.indexOf(_id) !== -1;
      }

      return true;
    });
  }, [currentUser, centers, selectedCenterF]);

  if (
    (batchId && (!batchLoaded || loadBatchLoading || batch === undefined)) ||
    !batchDepsLoadRequested ||
    loadBatchDepsLoading
  ) {
    return <AppLoader />;
  }

  const centerOptions =
    filteredCenters?.map(({ _id, name, ...rest }) => {
      const timeZone = rest.address.city?.timeZone || '';
      const currencySymbol = rest.address.country?.currencySymbol || '';

      return {
        value: _id,
        label: `${name}`,
        timeZone,
        currencySymbol,
      };
    }) || [];

  const timezone = selectedCenter?.timeZone || undefined;
  const currencySymbol = selectedCenter?.currencySymbol || undefined;

  let batchInfo: CreateBatchProps | undefined;

  if (batch && !selectedCenter) {
    const centerObj =
      centerOptions.find(({ value }) => value === batch.centerId) || undefined;
    setSelectedCenter(centerObj);
  }

  if (batch && timezone) {
    const {
      schedule: scheduleInit,
      scheduleType,
      startDate,
      endDate,
      feesSchedule: feesScheduleInit,
      ...restBatch
    } = batch;

    const feesSchedule = feesScheduleInit.map(({ dueDate, ...rest }) => {
      return {
        ...rest,
        dueDate: moment.tz(setTimeToZero(dueDate), timezone),
      };
    });
    const scheduleWeekly =
      scheduleType === BatchScheduleType.Weekly
        ? scheduleInit.map(({ startTime, endTime, ...rest }) => {
            return {
              ...rest,
              startTime: moment.tz(startTime, moment.HTML5_FMT.TIME, timezone),
              endTime: moment.tz(endTime, moment.HTML5_FMT.TIME, timezone),
            };
          })
        : [];
    const scheduleCustom =
      scheduleType === BatchScheduleType.Custom
        ? scheduleInit.map(({ startTime, endTime, day, ...rest }) => {
            return {
              ...rest,
              day: moment.tz(setTimeToZero(day), timezone),
              startTime: moment.tz(startTime, moment.HTML5_FMT.TIME, timezone),
              endTime: moment.tz(endTime, moment.HTML5_FMT.TIME, timezone),
            };
          })
        : [];

    batchInfo = {
      ...restBatch,
      startDate: moment.tz(setTimeToZero(startDate), timezone),
      endDate: moment.tz(setTimeToZero(endDate), timezone),
      scheduleType,
      scheduleWeekly,
      scheduleCustom,
      feesSchedule,
    };
  }

  const initVals = batchInfo || {
    name: '',
    centerId: '',
    classId: '',
    startDate: undefined,
    endDate: undefined,
    scheduleType: BatchScheduleType.Weekly,
    scheduleWeekly: [],
    scheduleCustom: [],
    feesSchedule: [{ feesAmount: 0, dueDate: undefined }],
    status: BatchStatus.Active,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCenterChange(val: any) {
    setSelectedCenter(undefined);
    setTimeout(() => {
      setSelectedCenter(val);
    }, 0);
  }

  function onSubmit({
    scheduleWeekly,
    scheduleCustom,
    startDate,
    endDate,
    feesSchedule: feesScheduleInit,
    ...restInput
  }: CreateBatchProps) {
    let schedule: BatchScheduleInput[] = [];
    let feesSchedule: BatchFeesScheduleInput[] = [];

    if (restInput.scheduleType === BatchScheduleType.Custom) {
      schedule = scheduleCustom.map(({ startTime, endTime, day, ...rest }) => {
        return {
          ...rest,
          day: day.format(moment.HTML5_FMT.DATE),
          startTime: startTime ? startTime.format(moment.HTML5_FMT.TIME) : '',
          endTime: endTime ? endTime.format(moment.HTML5_FMT.TIME) : '',
        };
      });
    } else {
      schedule = scheduleWeekly.map(({ startTime, endTime, ...rest }) => {
        return {
          ...rest,
          startTime: startTime ? startTime.format(moment.HTML5_FMT.TIME) : '',
          endTime: endTime ? endTime.format(moment.HTML5_FMT.TIME) : '',
        };
      });
    }

    feesSchedule = feesScheduleInit.map(({ dueDate, ...rest }) => {
      return {
        ...rest,
        dueDate: dueDate ? dueDate.format(moment.HTML5_FMT.DATE) : '',
      };
    });

    onSubmitParent({
      ...restInput,
      centerId: selectedCenter?.value || '',
      startDate: startDate ? startDate.format(moment.HTML5_FMT.DATE) : '',
      endDate: endDate ? endDate.format(moment.HTML5_FMT.DATE) : '',
      feesSchedule,
      schedule,
    });
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {batchId ? batchesTxt.updateBatch : batchesTxt.newBatch}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <div className="row">
          <div className="col-12 mb-4">
            <AppInputLableWithError
              errors={errors}
              langTxt={batchesTxt}
              id="input.centerId"
              required
            />
            <Select
              value={selectedCenter}
              options={centerOptions}
              onChange={onCenterChange}
            />
          </div>
        </div>
        {timezone && currencySymbol ? (
          <BatchForm
            classes={classes || []}
            initialValues={initVals}
            onSubmit={onSubmit}
            loading={loading}
            errors={errors}
            timezone={timezone}
            currencySymbol={currencySymbol}
          />
        ) : null}
      </div>
    </section>
  );
};

export default BatchRecordPage;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateUserInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import UserListPage from './UserListPage';
import UserRecordPage from './UserRecordPage';
import { addUser, resetAddUserSuccess } from './users.slice';

const UsersPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addUser: {
      loading: addUserLoading,
      errors: addUserErrors,
      success: addUserSuccess,
    },
  } = useSelector((state: RootState) => state.users);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ status, ...data }: CreateUserInput) {
    dispatch(
      addUser({
        ...data,
        status: status || undefined,
      }),
    );
  }

  useEffect(() => {
    if (addUserSuccess) {
      dispatch(resetAddUserSuccess());
      history.push(AppRoutePath.Users);
    }
  }, [addUserSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.UsersUpdate}
          permissions={rPermission(AppRoutePath.UsersUpdate)}
        >
          {!addUserSuccess ? (
            <UserRecordPage
              key="updateuserform"
              errors={addUserErrors}
              loading={addUserLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.UsersCreate}
          permissions={rPermission(AppRoutePath.UsersCreate)}
        >
          {!addUserSuccess ? (
            <UserRecordPage
              key="createuserform"
              errors={addUserErrors}
              loading={addUserLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <UserListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default UsersPage;

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { PaymentMethodType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { PaymentMethodModel, RootState } from '../../../common/types';
import BankAccountDetail from '../bank-account/BankAccountDetail';
import CreditCardDetail from '../credit-card/CreditCardDetail';
import {
  deletePaymentMethod,
  loadPaymentMethods,
  resetDeletePaymentMethod,
  resetSetDefaultPaymentMethod,
  setDefaultPaymentMethod,
} from '../profile.slice';

type PaymentMethodListProps = {
  paymentMethods: PaymentMethodModel[];
};

const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  paymentMethods,
}: PaymentMethodListProps): JSX.Element => {
  const { profile: profileTxt, app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();

  const {
    setDefaultPaymentMethod: {
      errors: setDefaultPaymentMethodErrors,
      success: setDefaultPaymentMethodSuccess,
    },
    deletePaymentMethod: {
      errors: deletePaymentMethodErrors,
      success: deletePaymentMethodSuccess,
    },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (setDefaultPaymentMethodSuccess) {
      dispatch(resetSetDefaultPaymentMethod());
      dispatch(loadPaymentMethods());
      toast.success(profileTxt.setDefaultPaymentMethodSuccess);
    } else if (
      setDefaultPaymentMethodErrors &&
      setDefaultPaymentMethodErrors.length > 0
    ) {
      toast.error(setDefaultPaymentMethodErrors[0]);
      dispatch(resetSetDefaultPaymentMethod());
    }
  }, [
    setDefaultPaymentMethodSuccess,
    setDefaultPaymentMethodErrors,
    profileTxt,
    dispatch,
  ]);

  useEffect(() => {
    if (deletePaymentMethodSuccess) {
      dispatch(resetDeletePaymentMethod());
      dispatch(loadPaymentMethods());
      toast.success(profileTxt.deletePaymentMethodSuccess);
    } else if (
      deletePaymentMethodErrors &&
      deletePaymentMethodErrors.length > 0
    ) {
      toast.error(deletePaymentMethodErrors[0]);
      dispatch(resetDeletePaymentMethod());
    }
  }, [
    deletePaymentMethodSuccess,
    deletePaymentMethodErrors,
    profileTxt,
    dispatch,
  ]);

  function handleSetDefaultPaymentMethod(paymentMethodSourceId: string) {
    dispatch(setDefaultPaymentMethod({ paymentMethodSourceId }));
  }

  function handleDeletePaymentMethod(paymentMethodSourceId: string) {
    confirmAlert({
      title: profileTxt.deletePaymentMethod,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () =>
            dispatch(deletePaymentMethod({ paymentMethodSourceId })),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  return (
    <>
      <div style={{ clear: 'both' }} />
      {paymentMethods.map((pMethod) => {
        switch (pMethod.type) {
          case PaymentMethodType.ACH:
            return (
              <BankAccountDetail
                key={pMethod.id}
                data={pMethod}
                handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                handleDeletePaymentMethod={handleDeletePaymentMethod}
                showPaymentSelectedIcon
              />
            );
          case PaymentMethodType.Card:
            return (
              <CreditCardDetail
                key={pMethod.id}
                data={pMethod}
                handleSetDefaultPaymentMethod={handleSetDefaultPaymentMethod}
                handleDeletePaymentMethod={handleDeletePaymentMethod}
                showPaymentSelectedIcon
              />
            );
          default:
            return null;
        }
      })}
      <div style={{ clear: 'both' }} />
    </>
  );
};

export default PaymentMethodList;

import React from 'react';

type AccordionHeaderProps = {
  title: string;
};

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  title,
}: AccordionHeaderProps): JSX.Element => {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-12">
        <h5>
          <button
            className="btn btn-block text-left btn-accordion-header"
            type="button"
          >
            <i className="fa fa-angle-right mr-3" />
            {title}
          </button>
        </h5>
      </div>
    </div>
  );
};

export default AccordionHeader;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateBatchInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import { addBatch, resetAddBatch } from './batches.slice';
import BatchListPage from './BatchListPage';
import BatchRecordPage from './BatchRecordPage';

const BatchesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addBatch: {
      loading: addBatchLoading,
      errors: addBatchErrors,
      success: addBatchSuccess,
    },
  } = useSelector((state: RootState) => state.batches);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateBatchInput) {
    dispatch(
      addBatch({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addBatchSuccess) {
      dispatch(resetAddBatch());
      history.push(AppRoutePath.Batches);
    }
  }, [addBatchSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.BatchesUpdate}
          permissions={rPermission(AppRoutePath.BatchesUpdate)}
        >
          {!addBatchSuccess ? (
            <BatchRecordPage
              key="updatebatchform"
              errors={addBatchErrors}
              loading={addBatchLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.BatchesCreate}
          permissions={rPermission(AppRoutePath.BatchesCreate)}
        >
          {!addBatchSuccess ? (
            <BatchRecordPage
              key="createbatchform"
              errors={addBatchErrors}
              loading={addBatchLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <BatchListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default BatchesPage;

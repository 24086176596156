/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { GetUsersQuery } from '../../common/types';
import ApiService from '../../services/api';
import {
  loadCenterHeadContactInfo,
  loadCenterHeadContactInfoError,
  loadCenterHeadContactInfoSuccess,
  loadTeacherContactInfo,
  loadTeacherContactInfoError,
  loadTeacherContactInfoSuccess,
} from './contact-us.slice';

export function* loadCenterHeadContactInfoAsync(
  action: PayloadAction<GetUsersQuery>,
) {
  const { data } = yield call(
    ApiService.loadCenterHeadContactInfo,
    action.payload,
  );

  if (data) {
    yield put(loadCenterHeadContactInfoSuccess(data));
  } else {
    yield put(loadCenterHeadContactInfoError(['500']));
  }
}

export function* loadTeacherContactInfoAsync(
  action: PayloadAction<GetUsersQuery>,
) {
  const { data } = yield call(
    ApiService.loadTeacherContactInfo,
    action.payload,
  );

  if (data) {
    yield put(loadTeacherContactInfoSuccess(data));
  } else {
    yield put(loadTeacherContactInfoError(['500']));
  }
}

export function* watchContactUsAsync() {
  yield takeEvery(
    loadCenterHeadContactInfo.toString(),
    loadCenterHeadContactInfoAsync,
  );
  yield takeEvery(
    loadTeacherContactInfo.toString(),
    loadTeacherContactInfoAsync,
  );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  GetUserInvoicesQuery,
  PayInvoiceOfflineInput,
  PayInvoiceUsingCardInput,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  loadUserInvoice,
  loadUserInvoiceError,
  loadUserInvoices,
  loadUserInvoicesError,
  loadUserInvoicesSuccess,
  loadUserInvoiceSuccess,
  payInvoiceOffline,
  payInvoiceOfflineError,
  payInvoiceOfflineSuccess,
  payInvoiceUsingCard,
  payInvoiceUsingCardError,
  payInvoiceUsingCardSuccess,
} from './user-invoices.slice';

export function* payInvoiceUsingCardAsync(
  action: PayloadAction<PayInvoiceUsingCardInput>,
) {
  const { data, errors } = yield call(
    ApiService.payInvoiceUsingCard,
    action.payload,
  );

  if (errors) {
    yield put(payInvoiceUsingCardError(errors));
  } else {
    yield put(payInvoiceUsingCardSuccess(!!data));
  }
}

export function* payInvoiceOfflineAsync(
  action: PayloadAction<PayInvoiceOfflineInput>,
) {
  const { data, errors } = yield call(
    ApiService.payInvoiceOffline,
    action.payload,
  );

  if (errors) {
    yield put(payInvoiceOfflineError(errors));
  } else {
    yield put(payInvoiceOfflineSuccess(!!data));
  }
}

export function* loadUserInvoiceAsync(action: PayloadAction<string>) {
  const { data } = yield call(
    ApiService.loadUserInvoiceFormDetails,
    action.payload,
  );

  if (!data) {
    yield put(loadUserInvoiceError(['404']));
  } else {
    yield put(loadUserInvoiceSuccess(data));
  }
}

export function* loadUserInvoicesAsync(
  action: PayloadAction<GetUserInvoicesQuery>,
) {
  const { data } = yield call(
    ApiService.getUserInvoicesForTable,
    action.payload,
  );

  if (!data) {
    yield put(loadUserInvoicesError(['500']));
  } else {
    yield put(loadUserInvoicesSuccess(data));
  }
}

export function* watchUserInvoicesAsync() {
  yield takeEvery(payInvoiceUsingCard.toString(), payInvoiceUsingCardAsync);
  yield takeEvery(payInvoiceOffline.toString(), payInvoiceOfflineAsync);
  yield takeEvery(loadUserInvoice.toString(), loadUserInvoiceAsync);
  yield takeEvery(loadUserInvoices.toString(), loadUserInvoicesAsync);
}

import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';

import { InstituteStatus, UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import CancelSubscriptionPage from './cancel-subscription/CancelSubscriptionPage';
import ChangePasswordPage from './change-password/ChangePasswordPage';
import PaymentMethodPage from './payment-method/PaymentMethodPage';
import { loadPaymentMethods } from './profile.slice';
import RenewSubscriptionPage from './renew-subscription/RenewSubscriptionPage';
import UploadProfilePhotoPage from './upload-profile-photo/UploadProfilePhotoPage';

const ProfilePage: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;

  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.login);
  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadPaymentMethods());
      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded]);

  return (
    <section className="bg-white rounded shadow py-5 profile-page">
      <h1 className="primary-heading mb-4 ml-4">{profileTxt.header}</h1>
      <p className="small common-text-color text-right mb-5 mr-4">
        {appTxt.mandatoryNote}
      </p>
      <Tabs defaultActiveKey="0" className="nav-fill">
        <Tab eventKey="0" title={profileTxt.tabHeader1}>
          <div
            className="tab-pane fade show active pt-4 px-4"
            id="upload-profile-photo"
            role="tabpanel"
            aria-labelledby="upload-profile-photo-tab"
          >
            <UploadProfilePhotoPage />
          </div>
        </Tab>
        <Tab eventKey="1" title={profileTxt.tabHeader2}>
          <div
            className="tab-pane pt-4 px-4"
            id="change-password"
            role="tabpanel"
            aria-labelledby="change-password-tab"
          >
            <ChangePasswordPage />
          </div>
        </Tab>
        {user?.role.includes(UserRole.Owner) ||
        user?.role.includes(UserRole.Parent) ||
        user?.role.includes(UserRole.Student) ? (
          <Tab eventKey="2" title={profileTxt.tabHeader3}>
            <div
              className="tab-pane pt-4 px-4"
              id="payment-method"
              role="tabpanel"
              aria-labelledby="payment-method-tab"
            >
              <PaymentMethodPage infoLoaded={infoLoaded} />
            </div>
          </Tab>
        ) : null}
        {user?.institute?.status === InstituteStatus.Active &&
        user.role.includes(UserRole.Owner) ? (
          <Tab eventKey="3" title={profileTxt.tabHeader4}>
            <div
              className="tab-pane pt-4 px-4"
              id="cancel-subscription"
              role="tabpanel"
              aria-labelledby="cancel-subscription-tab"
            >
              <CancelSubscriptionPage infoLoaded={infoLoaded} />
            </div>
          </Tab>
        ) : null}
        {user?.institute?.status === InstituteStatus.Cancelled &&
        user.role.includes(UserRole.Owner) ? (
          <Tab eventKey="4" title={profileTxt.tabHeader5}>
            <div
              className="tab-pane pt-4 px-4"
              id="renew-subscription"
              role="tabpanel"
              aria-labelledby="renew-subscription-tab"
            >
              <RenewSubscriptionPage infoLoaded={infoLoaded} />
            </div>
          </Tab>
        ) : null}
      </Tabs>
    </section>
  );
};

export default ProfilePage;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import {
  AppRoutePermissions,
  CreateClassInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import { addClass, resetAddClassSuccess } from './institute-classes.slice';
import InstituteClassListPage from './InstituteClassListPage';
import InstituteClassRecordPage from './InstituteClassRecordPage';

const InstituteClassesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const {
    addClass: {
      loading: addClassLoading,
      success: addClassSuccess,
      errors: addClassErrors,
    },
  } = useSelector((state: RootState) => state.classes);

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  function onSubmit({ ...data }: CreateClassInput) {
    dispatch(
      addClass({
        ...data,
      }),
    );
  }

  useEffect(() => {
    if (addClassSuccess) {
      dispatch(resetAddClassSuccess());
      history.push(AppRoutePath.Classes);
    }
  }, [addClassSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.ClassesUpdate}
          permissions={rPermission(AppRoutePath.ClassesUpdate)}
        >
          {!addClassSuccess ? (
            <InstituteClassRecordPage
              key="updateclassform"
              errors={addClassErrors}
              loading={addClassLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute
          path={AppRoutePath.ClassesCreate}
          permissions={rPermission(AppRoutePath.ClassesCreate)}
        >
          {!addClassSuccess ? (
            <InstituteClassRecordPage
              key="createclassform"
              errors={addClassErrors}
              loading={addClassLoading}
              onSubmit={onSubmit}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <InstituteClassListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default InstituteClassesPage;

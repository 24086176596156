import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';

import { getPermissionsForRoute } from '../../common/helper';
import { AppRoutePermissions, RootState } from '../../common/types';
import PrivateRoute from '../../components/PrivateRoute';
import InstituteListPage from './InstituteListPage';

const InstitutesPage = (): JSX.Element => {
  const match = useRouteMatch();

  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  return (
    <div>
      <Switch>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <InstituteListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default InstitutesPage;

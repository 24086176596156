/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { InstituteWebsiteMediaType } from '../../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { InstituteTypeModel, WebsiteMediaInput } from '../../../common/types';
import MediaMainImages from './MediaMainImages';
import MediaPhotos from './MediaPhotos';
import MediaVideos from './MediaVideos';

type MediaUploadFormProps = {
  onSubmit: (data: WebsiteMediaInput[], defaultMainImage: string) => void;
  onRemoveMedia: (id: string) => void;
  errors: string[];
  loading: boolean;
  initialValues: WebsiteMediaInput[];
  instituteTypes: InstituteTypeModel[];
  initDefaultMainImage: string;
};

const MediaUploadForm = ({
  initialValues: websiteMedia,
  instituteTypes,
  initDefaultMainImage,
  onSubmit,
  onRemoveMedia,
  errors,
  loading,
}: MediaUploadFormProps): JSX.Element => {
  const { websiteMedia: websiteMediaTxt } = LanguageTexts;

  const [defaultMainImage, setDefaultMainImage] = useState(
    initDefaultMainImage,
  );
  const [mainImages, setMainImages] = useState<WebsiteMediaInput[]>(
    websiteMedia.filter(
      (media) => media.mediaType === InstituteWebsiteMediaType.MainImage,
    ),
  );
  const [galleryImages, setGalleryImages] = useState<WebsiteMediaInput[]>(
    websiteMedia.filter(
      (media) => media.mediaType === InstituteWebsiteMediaType.GalleryImage,
    ),
  );
  const [utubeVideos, setUtubeVideos] = useState<WebsiteMediaInput[]>(
    websiteMedia.filter(
      (media) => media.mediaType === InstituteWebsiteMediaType.YoutubeUrl,
    ),
  );

  function onMainImagesChange(input: WebsiteMediaInput[]) {
    setMainImages(
      input.map(({ _id, image, mediaUrl, mediaType }) => {
        return _id ? { _id, mediaUrl, mediaType } : { image, mediaType };
      }),
    );
  }

  function onGalleryImagesChange(input: WebsiteMediaInput[]) {
    setGalleryImages(
      input.map(({ _id, image, mediaUrl, mediaType }) => {
        return _id ? { _id, mediaUrl, mediaType } : { image, mediaType };
      }),
    );
  }

  function onUtubeVideosChange(input: WebsiteMediaInput[]) {
    setUtubeVideos(
      input.map(({ _id, mediaUrl, mediaType }) => {
        return _id ? { _id, mediaUrl, mediaType } : { mediaUrl, mediaType };
      }),
    );
  }

  function onSubmitBtn() {
    const payload = [...utubeVideos, ...mainImages, ...galleryImages];
    onSubmit(payload, defaultMainImage);
  }

  const errorKeys = getLangAndErrKeys([
    ...utubeVideos,
    ...mainImages,
    ...galleryImages,
  ]);

  const formattedErrors = formatErrors(errorKeys, errors, websiteMediaTxt);

  return (
    <div className="form">
      <div className="my-3" />
      <MediaMainImages
        initialValues={mainImages}
        initDefaultMainImage={defaultMainImage}
        instituteTypes={instituteTypes}
        onChange={onMainImagesChange}
        onDefaultImgChange={(imageUrl) => setDefaultMainImage(imageUrl)}
        disabled={loading}
      />
      <div className="my-5" />
      <MediaPhotos
        initialValues={galleryImages}
        onChange={onGalleryImagesChange}
        onRemoveMedia={onRemoveMedia}
        disabled={loading}
      />
      <div className="my-5" />
      <h2 className="secondary-heading mb-4">{websiteMediaTxt.videos}</h2>
      <div className="my-5" />
      <MediaVideos
        disabled={loading}
        initialValues={utubeVideos}
        onChange={onUtubeVideosChange}
      />

      <div className="my-5" />
      <div className="row">
        <div className="col-lg-12 col-12">
          {formattedErrors.map((error) => (
            <p className="txt2" style={{ fontWeight: 'bold' }} key={error}>
              {error}
            </p>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12" />
        <div className="col-lg-6 col-12 text-right">
          <button
            type="button"
            className="btn save-btn"
            data-toggle="collapse"
            onClick={onSubmitBtn}
            disabled={loading}
          >
            {websiteMediaTxt.btnSave}&nbsp;
            <span className="fa fa-chevron-circle-right text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MediaUploadForm;

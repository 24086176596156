/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from 'formik';
import moment from 'moment-timezone';
import React from 'react';

import { isPastDate, setTimeToZero } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import BatchDatePicker from './BatchDatePicker';

type BatchFeesScheduleProps = {
  index: number;
  onRemove: (index: number) => void;
  onDateChange: (date: moment.Moment | undefined) => void;
  dueDate: moment.Moment | undefined;
  errors: string[];
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  timezone: string;
  currencySymbol: string;
  disabled?: boolean;
  id?: string;
};

const BatchFeesSchedule: React.FC<BatchFeesScheduleProps> = ({
  index,
  onRemove,
  onDateChange,
  dueDate,
  startDate,
  endDate,
  timezone,
  currencySymbol,
  errors,
  disabled,
  id,
}: BatchFeesScheduleProps): JSX.Element => {
  const { batches: batchsTxt } = LanguageTexts;

  const today = moment(
    setTimeToZero(moment.tz(undefined, timezone).format(moment.HTML5_FMT.DATE)),
  ).toDate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let disabledDays: any[] = [
    today,
    {
      before: today,
      after: today,
    },
  ];

  if (startDate && endDate) {
    disabledDays = [
      today,
      {
        before: today,
        after: moment(
          setTimeToZero(endDate.format(moment.HTML5_FMT.DATE)),
        ).toDate(),
      },
    ];
  }

  function handleRemove() {
    onRemove(index);
  }

  return (
    <div className="row batch-fees-schedule">
      <div className="col-5">
        <AppInputLableWithError
          errors={errors}
          langTxt={batchsTxt}
          id="feesSchedule.feesAmount"
          required
          errId={`${index}.feesAmount`}
        />
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text text-secondary">
              <b>{currencySymbol}</b>
            </span>
          </div>
          <Field
            name={`feesSchedule.${index}.feesAmount`}
            type="number"
            className="form-control"
            disabled={disabled || (dueDate && isPastDate(dueDate, timezone))}
          />
          <div className="input-group-append">
            <span className="input-group-text text-secondary">
              <b>{batchsTxt.doubleDecimal}</b>
            </span>
          </div>
        </div>
      </div>
      <div className="col-5">
        <div className="form-group">
          <AppInputLableWithError
            errors={errors}
            langTxt={batchsTxt}
            id="feesSchedule.dueDate"
            required
            errId={`${index}.dueDate`}
          />
          <BatchDatePicker
            value={dueDate}
            onChange={(newValue) => {
              if (!startDate || !endDate) {
                return;
              }

              onDateChange(newValue);
            }}
            disabledDays={disabledDays}
            disabled={disabled || (dueDate && isPastDate(dueDate, timezone))}
            timezone={timezone}
          />
        </div>
      </div>
      <div className="col-2 pt-2 d-flex flex-column align-items-start justify-content-center">
        {!id && index > 0 ? (
          <>
            <button
              type="button"
              className="btn cancel-btn"
              onClick={handleRemove}
              disabled={disabled}
            >
              <i className="fa fa-times font-weight-bold" />
            </button>
          </>
        ) : (
          ' '
        )}
      </div>
    </div>
  );
};

BatchFeesSchedule.defaultProps = {
  disabled: false,
  id: undefined,
};

export default BatchFeesSchedule;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CenterModel,
  ClassModel,
  GetCentersQuery,
  GetClassesQuery,
  GetUsersQuery,
  InstituteHomeState,
  InstituteWebsiteBasicInfoDetailsResponse,
  InstituteWebsiteCourseDetailsResponse,
  InstituteWebsiteDetailsResponse,
  UserModel,
} from '../../common/types';

const initialState: InstituteHomeState = {
  loading: false,
  success: null,
  errors: [],
  website: null,
  websiteBasicInfo: null,
  websiteCourseInfo: null,
  websiteClassInfo: null,
  websiteTeacherInfo: null,
  websiteCenterInfo: null,
};

const homeSlice = createSlice({
  name: 'instituteHome',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadInstituteWebsiteDetails(state, action: PayloadAction<string>) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteDetailsSuccess(
      state,
      action: PayloadAction<InstituteWebsiteDetailsResponse>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.website = action.payload.instituteWebsite;
    },
    loadInstituteWebsiteDetailsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteDetails(state) {
      state.success = null;
      state.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadInstituteWebsiteBasicInfoDetails(state, action: PayloadAction<string>) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteBasicInfoDetailsSuccess(
      state,
      action: PayloadAction<InstituteWebsiteBasicInfoDetailsResponse>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.websiteBasicInfo = action.payload.instituteWebsiteBasicInfo;
    },
    loadInstituteWebsiteBasicInfoDetailsError(
      state,
      action: PayloadAction<string[]>,
    ) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteBasicInfoDetails(state) {
      state.success = null;
      state.errors = [];
    },
    loadInstituteWebsiteCenterDetails(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetCentersQuery>,
    ) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteCenterDetailsSuccess(
      state,
      action: PayloadAction<CenterModel[]>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.websiteCenterInfo = action.payload;
    },
    loadInstituteWebsiteCenterDetailsError(
      state,
      action: PayloadAction<string[]>,
    ) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteCenterDetails(state) {
      state.success = null;
      state.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadInstituteWebsiteCourseDetails(state, action: PayloadAction<string>) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteCourseDetailsSuccess(
      state,
      action: PayloadAction<InstituteWebsiteCourseDetailsResponse>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.websiteCourseInfo = action.payload.instituteWebsiteCourseInfo;
    },
    loadInstituteWebsiteCourseDetailsError(
      state,
      action: PayloadAction<string[]>,
    ) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteCourseDetails(state) {
      state.success = null;
      state.errors = [];
    },
    loadInstituteWebsiteClassDetails(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetClassesQuery>,
    ) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteClassDetailsSuccess(
      state,
      action: PayloadAction<ClassModel[]>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.websiteClassInfo = action.payload;
    },
    loadInstituteWebsiteClassDetailsError(
      state,
      action: PayloadAction<string[]>,
    ) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteClassDetails(state) {
      state.success = null;
      state.errors = [];
    },
    loadInstituteWebsiteTeacherDetails(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<GetUsersQuery>,
    ) {
      state.loading = true;
      state.errors = [];
    },
    loadInstituteWebsiteTeacherDetailsSuccess(
      state,
      action: PayloadAction<UserModel[]>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.websiteTeacherInfo = action.payload;
    },
    loadInstituteWebsiteTeacherDetailsError(
      state,
      action: PayloadAction<string[]>,
    ) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadInstituteWebsiteTeacherDetails(state) {
      state.success = null;
      state.errors = [];
    },
  },
});

export const {
  loadInstituteWebsiteDetails,
  loadInstituteWebsiteDetailsSuccess,
  loadInstituteWebsiteDetailsError,
  resetLoadInstituteWebsiteDetails,
  loadInstituteWebsiteBasicInfoDetails,
  loadInstituteWebsiteBasicInfoDetailsSuccess,
  loadInstituteWebsiteBasicInfoDetailsError,
  resetLoadInstituteWebsiteBasicInfoDetails,
  loadInstituteWebsiteCenterDetails,
  loadInstituteWebsiteCenterDetailsSuccess,
  loadInstituteWebsiteCenterDetailsError,
  resetLoadInstituteWebsiteCenterDetails,
  loadInstituteWebsiteCourseDetails,
  loadInstituteWebsiteCourseDetailsSuccess,
  loadInstituteWebsiteCourseDetailsError,
  resetLoadInstituteWebsiteCourseDetails,
  loadInstituteWebsiteClassDetails,
  loadInstituteWebsiteClassDetailsSuccess,
  loadInstituteWebsiteClassDetailsError,
  resetLoadInstituteWebsiteClassDetails,
  loadInstituteWebsiteTeacherDetails,
  loadInstituteWebsiteTeacherDetailsSuccess,
  loadInstituteWebsiteTeacherDetailsError,
  resetLoadInstituteWebsiteTeacherDetails,
} = homeSlice.actions;

export const {
  name: instituteHomeSliceName,
  reducer: instituteHomeSliceReducer,
} = homeSlice;

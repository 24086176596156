/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import {
  formatErrors,
  getLangAndErrKeys,
  getSubdomain,
} from '../../common/helper';
import LanguageTexts from '../../common/language';
import { LoginInput, RootState } from '../../common/types';
import InputLableWithError from '../../components/InputLableWithError';
import { loadInstituteWebsiteBasicInfoDetails } from '../institute-home/institute-home.slice';

type ILoginProps = {
  onSubmit: (data: LoginInput) => void;
  errors: string[];
  loading: boolean;
};

const LoginForm: React.FC<ILoginProps> = ({
  onSubmit,
  errors,
  loading,
}: ILoginProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { websiteBasicInfo } = useSelector(
    (state: RootState) => state.instituteHome,
  );
  const [loadRequested, setLoadRequested] = useState<boolean>();
  const { reservedSubdomain } = useSelector(
    (state: RootState) => state.itSetting,
  );

  const subdomain = getSubdomain();
  const isRootDomain = !!(
    reservedSubdomain && reservedSubdomain.indexOf(subdomain) !== -1
  );
  const initialValues: LoginInput = {
    email: '',
    password: '',
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, LanguageTexts.login);

  const handleBlur = (e: {
    target: {
      value: string;
      classList: {
        add: (arg0: string) => void;
        remove: (arg0: string) => void;
      };
    };
  }) => {
    if (e.target.value !== '') {
      e.target.classList.add('has-val');
    } else {
      e.target.classList.remove('has-val');
    }
  };

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadInstituteWebsiteBasicInfoDetails(subdomain));
      setLoadRequested(true);
    }
  }, [loadRequested, subdomain, dispatch]);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          history.push('/');
        }}
        className="btn save-btn mb-5"
      >
        <span className="fa fa-chevron-circle-left" aria-hidden="true" />{' '}
        {LanguageTexts.login.homeBtn}
      </button>
      <div className="text-center mb-4">
        {!isRootDomain ? (
          <>
            <img
              className="institute-logo"
              src={
                websiteBasicInfo?.logoUrl
                  ? websiteBasicInfo?.logoUrl
                  : `${process.env.REACT_APP_IMAGE_PATH}website/logo/default-logo.jpg`
              }
              alt="Logo"
            />
          </>
        ) : (
          <>
            <img
              className="institute-logo"
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo/main-app-logo.jpg`}
              alt="Logo"
            />
          </>
        )}
      </div>
      <span className="login-register-form-title mb-5">
        {LanguageTexts.login.header}
      </span>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <div className="wrap-input-field">
              <Field
                name="email"
                type="text"
                className="inputfield tolowercase-text"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.email"
                errors={errors}
                langTxt={LanguageTexts.login}
              />
            </div>

            <div className="wrap-input-field">
              <Field
                name="password"
                type="password"
                className="inputfield"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.password"
                errors={errors}
                langTxt={LanguageTexts.login}
              />
            </div>

            <div className="row my-3">
              <div className="col-12 text-right">
                <Link className="txt1" to="/forgot-password">
                  {LanguageTexts.login.forgotPass}
                </Link>
              </div>
            </div>

            {formattedErrors.map((error) => (
              <p className="txt2" style={{ fontWeight: 'bold' }} key={error}>
                {error}
              </p>
            ))}

            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="login100-form-btn save-btn"
                  disabled={loading}
                >
                  {LanguageTexts.login.submitBtn}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LoginForm.defaultProps = {};

export default LoginForm;

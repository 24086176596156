import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, UserCreditCardInput } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  loadPaymentMethods,
  resetSaveUserCreditCard,
  saveUserCreditCard,
} from '../profile.slice';
import CreditCardForm from './CreditCardForm';

type CreditCardPageProps = {
  infoLoaded: boolean;
  onCancel: () => void;
};

const CreditCardPage: React.FC<CreditCardPageProps> = ({
  infoLoaded,
  onCancel,
}: CreditCardPageProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    loading: infoLoading,
    saveUserCreditCard: { success, loading, errors },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(data: UserCreditCardInput) {
    dispatch(saveUserCreditCard(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetSaveUserCreditCard());
      dispatch(loadPaymentMethods());
      onCancel();
    }
  }, [success, dispatch, onCancel]);

  if (!infoLoaded || infoLoading) {
    return <AppLoader />;
  }

  return !success ? (
    <CreditCardForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      errors={errors}
      loading={loading}
      showCancel
    />
  ) : (
    <AppLoader />
  );
};

export default CreditCardPage;

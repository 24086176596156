import React from 'react';

import { hasError } from '../common/helper';

type ILableProps = {
  langTxt: Record<string, string>;
  id: string;
  errors: string[];
};

const InputLableWithError: React.FC<ILableProps> = ({
  langTxt,
  id,
  errors,
}: ILableProps) => {
  const errorStyle = { color: 'red' };

  return (
    <span
      className="label-input-field"
      style={hasError(id, errors) ? errorStyle : {}}
    >
      {langTxt[id]}
    </span>
  );
};

export default InputLableWithError;

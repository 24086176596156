import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getCurrentYear } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import SideMenu from './SideMenu';
import TopHeader from './TopHeader';

type LayoutAdminProps = {
  children: JSX.Element;
};

const LayoutAdmin: React.FC<LayoutAdminProps> = ({
  children,
}: LayoutAdminProps): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);
  const { navMenu } = useSelector((state: RootState) => state.itSetting);
  const { pathname } = useLocation();

  return (
    <>
      {user ? (
        <SideMenu user={user} menuItems={navMenu || []} pathname={pathname} />
      ) : null}
      <div id="right-panel" className="right-panel app-right-panel">
        <TopHeader />
        <div className="content app-content">{children}</div>
        <div className="clearfix" />
        <footer className="site-footer app-site-footer">
          <div className="footer-inner bg-white shadow">
            <div className="row">
              <div className="col-12">
                <p>
                  {getCurrentYear(LanguageTexts.layoutAdmin.copyrightText)}{' '}
                  <a href="/privacy-policy" target="_blank">
                    {LanguageTexts.layoutAdmin.privacyPolicyText}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LayoutAdmin;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMutation } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { CHECK_DOMAIN_AVAILIBILITY } from '../../common/gql';
import {
  extractErrors,
  formatErrors,
  getLangAndErrKeys,
} from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  RegistrationInput,
  RootState,
  SubscriptionPlanModel,
} from '../../common/types';
import InputLableWithError from '../../components/InputLableWithError';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
  hidePostalCode: true,
};

type IRegistrationProps = {
  onSubmit: (data: RegistrationInput) => void;
  errors: string[];
  loading: boolean;
  subscriptionPlan: SubscriptionPlanModel;
  currencySymbol: string;
};

const RegistrationForm: React.FC<IRegistrationProps> = ({
  onSubmit,
  errors,
  loading,
  subscriptionPlan,
  currencySymbol,
}: IRegistrationProps): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const { institutePreRegistrationInfo } = useSelector(
    (state: RootState) => state.home,
  );
  const initialValues: RegistrationInput = {
    name: '',
    email: institutePreRegistrationInfo?.email || '',
    password: '',
    phone: '',
    domain: '',
    paymentMethodToken: undefined,
    autoPayment: true,
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(
    errorKeys,
    errors,
    LanguageTexts.registration,
  );

  const [
    checkDomainAvailibility,
    { data: domainCheckResult, error: domainCheckError },
  ] = useMutation(CHECK_DOMAIN_AVAILIBILITY);

  const domainFormattedErrors =
    domainCheckError && domainCheckError.graphQLErrors
      ? formatErrors(
          errorKeys,
          extractErrors([...domainCheckError.graphQLErrors]),
          LanguageTexts.registration,
        )
      : [];

  const handleBlur = (e: {
    target: {
      value: string;
      classList: {
        add: (arg0: string) => void;
        remove: (arg0: string) => void;
      };
    };
  }) => {
    if (e.target.value !== '') {
      e.target.classList.add('has-val');
    } else {
      e.target.classList.remove('has-val');
    }
  };

  return (
    <>
      <span className="login-register-form-title my-5">
        {LanguageTexts.registration.header}
      </span>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (!stripe || !elements) {
            return null;
          }

          const cardEle = elements.getElement(CardElement) || undefined;
          let paymentMethodToken;

          if (cardEle) {
            const { error, token } = await stripe.createToken(cardEle);

            setErrors({ paymentMethodToken: error?.message || undefined });

            paymentMethodToken = token?.id || undefined;
          } else {
            setErrors({
              paymentMethodToken: LanguageTexts.app.invalidCard,
            });
          }

          if (paymentMethodToken) {
            onSubmit({ ...values, paymentMethodToken });
            setSubmitting(false);
          }

          return null;
        }}
      >
        {({ values }) => (
          <Form>
            <div className="wrap-input-field">
              <Field
                id="name"
                name="name"
                type="text"
                className="inputfield"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.name"
                errors={errors}
                langTxt={LanguageTexts.registration}
              />
            </div>
            <div className="wrap-input-field">
              <Field
                id="email"
                name="email"
                type="text"
                className="inputfield tolowercase-text"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.email"
                errors={errors}
                langTxt={LanguageTexts.registration}
              />
            </div>

            <div className="wrap-input-field">
              <Field
                name="password"
                type="password"
                className="inputfield"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.password"
                errors={errors}
                langTxt={LanguageTexts.registration}
              />
            </div>

            <div className="row p-t-20">
              <div className="col-12">
                <p className="small common-text-color short-desc">
                  {LanguageTexts.app.phoneNote}
                </p>
              </div>
            </div>
            <div className="wrap-input-field">
              <Field
                name="phone"
                type="text"
                className="inputfield"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.phone"
                errors={errors}
                langTxt={LanguageTexts.registration}
              />
            </div>

            <div className="row p-t-20">
              <div className="col-12">
                <p className="small common-text-color short-desc">
                  {LanguageTexts.registration.https}
                  <span className="subdomain tolowercase-text">
                    {values.domain
                      ? values.domain
                      : LanguageTexts.registration.defaultDomain}
                  </span>
                  {LanguageTexts.registration.domainUrl}
                </p>
              </div>
            </div>

            <div className="wrap-input-field">
              <Field
                name="domain"
                type="text"
                className="inputfield new-subdomain tolowercase-text"
                onBlur={handleBlur}
              />
              <span className="focus-input-field" />
              <InputLableWithError
                id="input.domain"
                errors={errors}
                langTxt={LanguageTexts.registration}
              />
            </div>
            <div className="row mb-2">
              <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                {domainFormattedErrors.length > 0
                  ? domainFormattedErrors.map((error) => (
                      <p
                        className="small txt2"
                        style={{ fontWeight: 'bold' }}
                        key={error}
                      >
                        <b>{error}</b>
                      </p>
                    ))
                  : null}

                {domainCheckResult ? (
                  <p className="small">
                    <b>{LanguageTexts.registration.domainAvailable}</b>
                  </p>
                ) : null}
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="login100-form-btn check-availability-btn save-btn"
                  onClick={() =>
                    checkDomainAvailibility({
                      variables: { input: { domain: values.domain } },
                    })
                  }
                >
                  {LanguageTexts.registration.checkAvailable}
                </button>
              </div>
            </div>

            <span className="login-register-form-title my-5">
              {LanguageTexts.registration.payment}
            </span>

            <div className="row" style={{ marginBottom: '10px' }}>
              <div className="col-12">
                <CardElement
                  options={cardStyle}
                  className="credit-card-element"
                />
              </div>
            </div>

            <div className="flex-sb-m w-full p-t-15 p-l-5">
              <div className="contact100-form-checkbox">
                <p>{LanguageTexts.registration.autopaymentMsg}</p>
              </div>
            </div>
            <div className="row p-t-40">
              <div className="col-12">
                <h6
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: LanguageTexts.registration.paymentInfoMsg.replace(
                      '{price}',
                      `${currencySymbol}${subscriptionPlan.pricePerActiveStudent.toString()}`,
                    ),
                  }}
                  className="common-text-color text-center mb-4"
                />
              </div>
            </div>
            <div>
              <ErrorMessage
                name="paymentMethodToken"
                render={(msg) => (
                  <p className="txt2" style={{ fontWeight: 'bold' }}>
                    {msg}
                  </p>
                )}
              />
              {formattedErrors.map((error) => (
                <p className="txt2" style={{ fontWeight: 'bold' }} key={error}>
                  {error}
                </p>
              ))}
            </div>
            <div className="row p-t-32">
              <div className="col-12">
                <button
                  type="submit"
                  className="login100-form-btn save-btn"
                  disabled={loading}
                >
                  {LanguageTexts.registration.submitBtn}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationForm.defaultProps = {};

export default RegistrationForm;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  CreateAttendanceInput,
  GetAttendanceDepsQuery,
  GetAttendancesQuery,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addAttendance,
  addAttendanceError,
  addAttendanceSuccess,
  loadAttendance,
  loadAttendanceDeps,
  loadAttendanceDepsError,
  loadAttendanceDepsSuccess,
  loadAttendanceError,
  loadAttendances,
  loadAttendancesError,
  loadAttendancesSuccess,
  loadAttendanceSuccess,
} from './attendances.slice';

export function* addAttendanceAsync(
  action: PayloadAction<CreateAttendanceInput>,
) {
  const { data, errors } = yield call(ApiService.addAttendance, action.payload);

  if (errors) {
    yield put(addAttendanceError(errors));
  } else {
    yield put(addAttendanceSuccess(!!data));
  }
}

export function* loadAttendanceAsync(action: PayloadAction<string>) {
  const { data } = yield call(
    ApiService.loadAttendanceFormDetails,
    action.payload,
  );

  if (!data) {
    yield put(loadAttendanceError(['404']));
  } else {
    yield put(loadAttendanceSuccess(data));
  }
}

export function* loadAttendancesAsync(
  action: PayloadAction<GetAttendancesQuery>,
) {
  const { data } = yield call(
    ApiService.getAttendancesForTable,
    action.payload,
  );

  if (!data) {
    yield put(loadAttendancesError(['500']));
  } else {
    yield put(loadAttendancesSuccess(data));
  }
}

export function* loadAttendanceDepsAsync(
  action: PayloadAction<GetAttendanceDepsQuery>,
) {
  const { data } = yield call(ApiService.getAttendanceDeps, action.payload);

  if (!data) {
    yield put(loadAttendanceDepsError(['500']));
  } else {
    yield put(loadAttendanceDepsSuccess(data));
  }
}

export function* watchAttendancesAsync() {
  yield takeEvery(addAttendance.toString(), addAttendanceAsync);
  yield takeEvery(loadAttendance.toString(), loadAttendanceAsync);
  yield takeEvery(loadAttendances.toString(), loadAttendancesAsync);
  yield takeEvery(loadAttendanceDeps.toString(), loadAttendanceDepsAsync);
}

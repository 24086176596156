/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetUserInvoicesQuery,
  PayInvoiceOfflineInput,
  PayInvoiceUsingCardInput,
  UserInvoiceModel,
  UserInvoicesState,
} from '../../common/types';

const initialState: UserInvoicesState = {
  loading: false,
  success: null,
  errors: [],
  userInvoices: null,
  loadUserInvoice: {
    loading: false,
    success: null,
    errors: [],
  },
  payInvoiceUsingCard: {
    loading: false,
    success: null,
    errors: [],
  },
  payInvoiceOffline: {
    loading: false,
    success: null,
    errors: [],
  },
};

const userInvoicesSlice = createSlice({
  name: 'userInvoices',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUserInvoices(state, action: PayloadAction<GetUserInvoicesQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadUserInvoicesSuccess(state, action: PayloadAction<UserInvoiceModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.userInvoices = action.payload;
    },
    loadUserInvoicesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadUserInvoices(state) {
      state.loadUserInvoice.success = null;
      state.loadUserInvoice.errors = [];
    },
    payInvoiceUsingCard(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<PayInvoiceUsingCardInput>,
    ) {
      state.payInvoiceUsingCard.loading = true;
      state.payInvoiceUsingCard.errors = [];
    },
    payInvoiceUsingCardSuccess(state, action: PayloadAction<boolean>) {
      state.payInvoiceUsingCard.loading = false;
      state.payInvoiceUsingCard.success = action.payload;
    },
    payInvoiceUsingCardError(state, action: PayloadAction<string[]>) {
      state.payInvoiceUsingCard.loading = false;
      state.payInvoiceUsingCard.errors = action.payload;
    },
    resetPayInvoiceUsingCard(state) {
      state.payInvoiceUsingCard.success = null;
    },
    payInvoiceOffline(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<PayInvoiceOfflineInput>,
    ) {
      state.payInvoiceOffline.loading = true;
      state.payInvoiceOffline.errors = [];
    },
    payInvoiceOfflineSuccess(state, action: PayloadAction<boolean>) {
      state.payInvoiceOffline.loading = false;
      state.payInvoiceOffline.success = action.payload;
    },
    payInvoiceOfflineError(state, action: PayloadAction<string[]>) {
      state.payInvoiceOffline.loading = false;
      state.payInvoiceOffline.errors = action.payload;
    },
    resetPayInvoiceOffline(state) {
      state.payInvoiceOffline.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUserInvoice(state, action: PayloadAction<string>) {
      state.loadUserInvoice.loading = true;
      state.loadUserInvoice.errors = [];
    },
    loadUserInvoiceSuccess(state, action: PayloadAction<UserInvoiceModel>) {
      state.loadUserInvoice.loading = false;
      state.loadUserInvoice.success = action.payload;
    },
    loadUserInvoiceError(state, action: PayloadAction<string[]>) {
      state.loadUserInvoice.loading = false;
      state.loadUserInvoice.errors = action.payload;
    },
    resetLoadUserInvoice(state) {
      state.loadUserInvoice.success = null;
      state.loadUserInvoice.errors = [];
    },
  },
});

export const {
  loadUserInvoice,
  loadUserInvoiceError,
  loadUserInvoiceSuccess,
  resetLoadUserInvoice,
  loadUserInvoices,
  loadUserInvoicesError,
  loadUserInvoicesSuccess,
  resetLoadUserInvoices,
  payInvoiceUsingCard,
  payInvoiceUsingCardSuccess,
  payInvoiceUsingCardError,
  resetPayInvoiceUsingCard,
  payInvoiceOffline,
  payInvoiceOfflineSuccess,
  payInvoiceOfflineError,
  resetPayInvoiceOffline,
} = userInvoicesSlice.actions;

export const {
  name: userInvoicesSliceName,
  reducer: userInvoicesSliceReducer,
} = userInvoicesSlice;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { CreateCourseInput, GetCoursesQuery } from '../../common/types';
import ApiService from '../../services/api';
import {
  loadCourses,
  loadCoursesSuccess,
  loadCoursesError,
  addCourse,
  addCourseSuccess,
  addCourseError,
  loadCourse,
  loadCourseSuccess,
  loadCourseError,
  removeCourseImage,
  removeCourseImageSuccess,
  removeCourseImageError,
} from './institute-courses.slice';

export function* addCourseAsync(action: PayloadAction<CreateCourseInput>) {
  const { data, errors } = yield call(ApiService.addCourse, action.payload);

  if (errors) {
    yield put(addCourseError(errors));
  } else {
    yield put(addCourseSuccess(!!data));
  }
}

export function* loadCourseAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadCourseFormDetails, action.payload);

  if (!data) {
    yield put(loadCourseError(['404']));
  } else {
    yield put(loadCourseSuccess(data));
  }
}

export function* loadCoursesAsync(action: PayloadAction<GetCoursesQuery>) {
  const { data } = yield call(ApiService.getCoursesForTable, action.payload);

  if (!data) {
    yield put(loadCoursesError(['500']));
  } else {
    yield put(loadCoursesSuccess(data));
  }
}

export function* removeCourseImageAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.removeCourseImage,
    action.payload,
  );

  if (errors) {
    yield put(removeCourseImageError(errors));
  } else {
    yield put(removeCourseImageSuccess({ success: data, id: action.payload }));
  }
}

export function* watchCoursesAsync() {
  yield takeEvery(addCourse.toString(), addCourseAsync);
  yield takeEvery(loadCourse.toString(), loadCourseAsync);
  yield takeEvery(loadCourses.toString(), loadCoursesAsync);
  yield takeEvery(removeCourseImage.toString(), removeCourseImageAsync);
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  InstitutesState,
  ReceivePaymentForInstituteInput,
  SendPaymentForInstituteInput,
  UserModel,
} from '../../common/types';

const initialState: InstitutesState = {
  loading: false,
  success: null,
  errors: [],
  owners: null,
  receivePaymentForInstitute: {
    loading: false,
    success: null,
    errors: [],
  },
  sendPaymentForInstitute: {
    loading: false,
    success: null,
    errors: [],
  },
};

const institutesSlice = createSlice({
  name: 'institutes',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadOwners(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadOwnersSuccess(state, action: PayloadAction<UserModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.owners = action.payload;
    },
    loadOwnersError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadOwners(state) {
      state.success = null;
      state.errors = [];
    },
    receivePaymentForInstitute(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ReceivePaymentForInstituteInput>,
    ) {
      state.receivePaymentForInstitute.loading = true;
      state.receivePaymentForInstitute.errors = [];
    },
    receivePaymentForInstituteSuccess(state, action: PayloadAction<boolean>) {
      state.receivePaymentForInstitute.loading = false;
      state.receivePaymentForInstitute.success = action.payload;
    },
    receivePaymentForInstituteError(state, action: PayloadAction<string[]>) {
      state.receivePaymentForInstitute.loading = false;
      state.receivePaymentForInstitute.errors = action.payload;
    },
    resetReceivePaymentForInstitute(state) {
      state.receivePaymentForInstitute.success = null;
      state.receivePaymentForInstitute.errors = [];
    },
    sendPaymentForInstitute(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<SendPaymentForInstituteInput>,
    ) {
      state.sendPaymentForInstitute.loading = true;
      state.sendPaymentForInstitute.errors = [];
    },
    sendPaymentForInstituteSuccess(state, action: PayloadAction<boolean>) {
      state.sendPaymentForInstitute.loading = false;
      state.sendPaymentForInstitute.success = action.payload;
    },
    sendPaymentForInstituteError(state, action: PayloadAction<string[]>) {
      state.sendPaymentForInstitute.loading = false;
      state.sendPaymentForInstitute.errors = action.payload;
    },
    resetSendPaymentForInstitute(state) {
      state.sendPaymentForInstitute.success = null;
      state.sendPaymentForInstitute.errors = [];
    },
  },
});

export const {
  loadOwners,
  loadOwnersSuccess,
  loadOwnersError,
  resetLoadOwners,
  receivePaymentForInstitute,
  receivePaymentForInstituteSuccess,
  receivePaymentForInstituteError,
  resetReceivePaymentForInstitute,
  sendPaymentForInstitute,
  sendPaymentForInstituteSuccess,
  sendPaymentForInstituteError,
  resetSendPaymentForInstitute,
} = institutesSlice.actions;

export const {
  name: institutesSliceName,
  reducer: institutesSliceReducer,
} = institutesSlice;

import { IsNotEmpty, MaxLength, MinLength } from 'class-validator';

import { IsValidMomentDate } from '../../../common/decoraters';
import LanguageTexts from '../../../common/language';

export default class InstitutePaymentInfoDto {
  @IsNotEmpty()
  firstName: string | undefined;

  @IsNotEmpty()
  lastName: string | undefined;

  @MinLength(4, { message: LanguageTexts.app.InvalidValue })
  @MaxLength(4, { message: LanguageTexts.app.InvalidValue })
  ssn: string | undefined;

  @IsValidMomentDate(undefined, { message: LanguageTexts.app.InvalidValue })
  dob: string | undefined;

  @IsNotEmpty()
  accountNumber: string | undefined;

  @IsNotEmpty()
  routingNumber: string | undefined;
}

import React from 'react';

import LanguageTexts from '../../../common/language';
import { InstitutePaymentInfo } from '../../../common/types';

type PaymentInformationDetailProps = {
  onEdit: () => void;
  loading: boolean;
  data: InstitutePaymentInfo;
};

const PaymentInformationDetail: React.FC<PaymentInformationDetailProps> = ({
  onEdit,
  data,
  loading,
}: PaymentInformationDetailProps): JSX.Element => {
  const { institutePaymentInfo: institutePaymentInfoTxt } = LanguageTexts;

  return (
    <div className="form payment-info-detail">
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">
            {institutePaymentInfoTxt.accountHolderName}
          </p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{data.name}</p>
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">
            {institutePaymentInfoTxt.dateOfBirth}
          </p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{data.dob}</p>
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">{institutePaymentInfoTxt.bankName}</p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{data.bankName}</p>
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">
            {institutePaymentInfoTxt.accountNumber}
          </p>
        </div>
        <div className="col-lg-9 col-12">
          <p>**** {data.accountNumber}</p>
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-3 col-12 mb-lg-0 mb-2">
          <p className="font-weight-bold">
            {institutePaymentInfoTxt.routingNumber}
          </p>
        </div>
        <div className="col-lg-9 col-12">
          <p>{data.routingNumber}</p>
        </div>
      </div>
      <div className="my-4" />
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn save-btn"
            disabled={loading}
            onClick={onEdit}
          >
            {institutePaymentInfoTxt.btnEdit}&nbsp;
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformationDetail;

import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';

import { formatErrors } from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import AppLoader from '../../../components/AppLoader';
import {
  cancelInstituteSubscription,
  resetCancelInstituteSubscription,
} from '../profile.slice';

type CancelSubscriptionPageProps = {
  infoLoaded: boolean;
};

const CancelSubscriptionPage: React.FC<CancelSubscriptionPageProps> = ({
  infoLoaded,
}: CancelSubscriptionPageProps): JSX.Element => {
  const { profile: profileTxt, app: appTxt } = LanguageTexts;

  const dispatch = useDispatch();
  const {
    loading: infoLoading,
    cancelInstituteSubscription: { success, loading, errors },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit() {
    confirmAlert({
      title: profileTxt.tabHeader4,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(cancelInstituteSubscription()),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  useEffect(() => {
    if (success) {
      dispatch(resetCancelInstituteSubscription());
    }
  }, [success, dispatch]);

  if (!infoLoaded || infoLoading) {
    return <AppLoader />;
  }

  const formattedErrors = formatErrors([], errors, profileTxt);

  return (
    <div className="form">
      <div className="row">
        <div className="col-lg-6 col-12">
          <p className="mb-2">
            {profileTxt.cancelSubscriptionMsg1}{' '}
            <a
              href={`mailto:${LanguageTexts.app.contactEmail}`}
              className="underline-link"
            >
              {LanguageTexts.app.contactEmail}
            </a>
          </p>
          <p className="mb-2">{profileTxt.cancelSubscriptionMsg2}</p>
          <p className="font-weight-bold">
            {profileTxt.cancelSubscriptionMsg3}
          </p>
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-12">
          {formattedErrors.map((error) => (
            <p className="txt2" style={{ fontWeight: 'bold' }} key={error}>
              {error}
            </p>
          ))}
        </div>
      </div>
      <div className="my-3" />
      <div className="row">
        <div className="col-lg-6 col-12">
          <button
            type="button"
            className="btn save-btn"
            onClick={() => onSubmit()}
            disabled={loading}
          >
            {profileTxt.cancelSubscriptionBtn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionPage;

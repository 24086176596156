import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReadMoreReact from 'read-more-react';

import {
  AppRoutePath,
  AssignmentSubmissionStatus,
  UserRole,
} from '../../common/constants';
import { setTimeToZero } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { GetAssignmentSubmissionsQuery, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import {
  loadAssignmentListDeps,
  loadAssignments,
  loadAssignmentSubmissions,
  resetLoadAssignmentDeps,
} from './assignments.slice';

type AssignmentTableProps = {
  disableFilters?: boolean;
  miniMode?: boolean;
  latest?: number;
  sortBy?: string;
};

const AssignmentTable: React.FC<AssignmentTableProps> = ({
  disableFilters,
  miniMode,
  latest,
  sortBy,
}: AssignmentTableProps): JSX.Element | null => {
  const {
    assignments: assignmentsTxt,
    app: appTxt,
    dashboard: dashboardTxt,
  } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    assignments,
    loading,
    loadAssignmentListDeps: {
      loading: loadAssignmentListDepsLoading,
      errors: loadAssignmentListDepsErrors,
      success: loadAssignmentListDepsSuccess,
    },
    loadAssignmentSubmissions: { loading: loadAssignmentSubmissionsLoading },
    students: allStudents,
    assignmentSubmissions,
  } = useSelector((state: RootState) => state.assignments);
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);
  const [assignmentDepsLoadRequested, setAssignmentDepsRequested] = useState<
    boolean
  >();

  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const columns = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conColumns: any = [];

    if (!userRoles.includes(UserRole.Student)) {
      conColumns.push({
        Header: assignmentsTxt.studentName,
        accessor: 'student.name',
      });
    } else {
      conColumns.push({
        Header: assignmentsTxt.teacherName,
        accessor: 'createdByUser.name',
      });
    }

    conColumns.push({
      Header: assignmentsTxt.description,
      accessor: 'description',
      Cell: ({
        row: {
          original: { description },
        },
      }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any) => {
        return (
          <ReadMoreReact
            text={description}
            min={80}
            ideal={100}
            max={150}
            readMoreText={appTxt.readMore}
          />
        );
      },
    });

    if (userRoles.includes(UserRole.Owner)) {
      conColumns.push({
        Header: assignmentsTxt.center,
        accessor: 'batch.center.name',
      });
    }

    if (
      userRoles.includes(UserRole.Owner) ||
      userRoles.includes(UserRole.CenterHead)
    ) {
      conColumns.push({
        Header: assignmentsTxt.class,
        accessor: 'batch.name',
      });
    }

    conColumns.push({
      Header: assignmentsTxt.dueDate,
      accessor: 'dueDateTxt',
    });

    conColumns.push({
      Header: assignmentsTxt.submissionStatus,
      accessor: 'submissionStatusTxt',
      Cell: ({
        row: {
          original: { _id, student, submissionStatusTxt },
        },
      }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any) => {
        return (
          <Link
            to={`${AppRoutePath.AssignmentsSubmit.replace(
              ':assignmentId',
              _id,
            ).replace(':studentId', student._id)}`}
          >
            {submissionStatusTxt}
          </Link>
        );
      },
    });

    return conColumns;
  }, [assignmentsTxt, appTxt, userRoles]);

  // filter data
  const data = React.useMemo(() => {
    const sortedAssignment = matchSorter(assignments || [], '', {
      keys: [sortBy || ''],
    }).reverse();

    // filter students by selected filter/role
    const allFilteredStudents =
      allStudents?.filter(({ _id, batches: sBatches }) => {
        const aCenterIds = sBatches?.map(({ batch }) => batch.centerId) || [];
        const aBatchIds = sBatches?.map(({ batchId }) => batchId) || [];

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (currentUser?.role.includes(UserRole.Student)) {
          return currentUser._id === _id;
        }

        if (selectedChildren) {
          childrenCheck = !!(_id === selectedChildren);

          return childrenCheck;
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      }) || [];

    // filter assignment
    const filteredAssignment = sortedAssignment
      // map assignment to populate student data
      ?.map((assignment) => {
        const { students, studentsExcluded } = assignment;

        // get students for given assignment
        const filteredStudent =
          allFilteredStudents?.filter(({ _id, batches }) => {
            const batchCheck =
              !!batches?.find(
                ({ batchId }) => batchId === assignment.batchId,
              ) || false;

            if (students.length === 0) {
              return batchCheck && !studentsExcluded.includes(_id);
            }

            return batchCheck && !!students.includes(_id);
          }) || [];

        return {
          ...assignment,
          filteredStudent,
        };
      })
      // filter assignment for selected filters
      .filter(({ filteredStudent, batch }) => {
        const aCenterIds = [batch.center?._id];
        const aBatchIds = [batch._id];

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (selectedChildren) {
          childrenCheck = !!filteredStudent.find(
            ({ _id }) => _id === selectedChildren,
          );
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (selectedChildren) {
            return batchCheck && childrenCheck;
          }

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredAssignmentByStudent: any[] = [];

    // iterate assignment to flatten by student
    filteredAssignment.forEach((assignment) => {
      assignment.filteredStudent.forEach((student) => {
        filteredAssignmentByStudent.push({
          ...assignment,
          student,
        });
      });
    });

    const assignmentData =
      latest && latest > 0
        ? filteredAssignmentByStudent.slice(0, latest)
        : filteredAssignmentByStudent;

    return assignmentData.map(({ assignmentDueDate, ...restAssignment }) => {
      const dueDateTxt = moment(setTimeToZero(assignmentDueDate)).format('L');
      const assignmentSubmission = assignmentSubmissions?.find(
        ({ studentId, assignmentId }) =>
          studentId === restAssignment.student._id &&
          assignmentId === restAssignment._id,
      );
      const submissionStatusTxt =
        assignmentSubmission?.submissionStatus ||
        AssignmentSubmissionStatus.Pending;

      return {
        ...restAssignment,
        dueDateTxt,
        submissionStatusTxt,
      };
    });
  }, [
    assignments,
    selectedBatch,
    selectedCenter,
    selectedChildren,
    allStudents,
    latest,
    sortBy,
    currentUser,
    assignmentSubmissions,
  ]);

  // load assignments
  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadAssignments({}));
      const query: GetAssignmentSubmissionsQuery = {};

      if (currentUser?.role.includes(UserRole.Student)) {
        query.studentId = currentUser._id;
      } else if (currentUser?.role.includes(UserRole.Parent)) {
        query.studentId = selectedChildren || undefined;
      }

      dispatch(loadAssignmentSubmissions(query));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, selectedChildren, dispatch]);

  // load assignment deps
  useEffect(() => {
    if (!assignmentDepsLoadRequested) {
      dispatch(
        loadAssignmentListDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Student],
        }),
      );
      setAssignmentDepsRequested(true);
    }
  }, [currentUser, assignmentDepsLoadRequested, selectedBatch, dispatch]);

  useEffect(() => {
    if (loadAssignmentListDepsErrors.length > 0) {
      dispatch(resetLoadAssignmentDeps());
    } else if (loadAssignmentListDepsSuccess) {
      dispatch(resetLoadAssignmentDeps());
    }
  }, [loadAssignmentListDepsSuccess, loadAssignmentListDepsErrors, dispatch]);

  if (
    !miniMode &&
    (loading ||
      !loadRequested ||
      !assignmentDepsLoadRequested ||
      loadAssignmentListDepsLoading ||
      loadAssignmentSubmissionsLoading)
  ) {
    return <AppLoader />;
  }

  if (miniMode) {
    if (data.length === 0) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card dashboard-table list-table">
            <div className="row justify-content-center align-items-center">
              <div className="col-6">
                <h1 className="primary-heading mt-5 mb-4 ml-4">
                  {dashboardTxt.assignments}
                </h1>
              </div>
              <div className="col-6 text-right">
                <h3 className="tertiary-heading mt-5 mb-4 mr-4">
                  <Link to={AppRoutePath.Assignments} className="view-details">
                    {dashboardTxt.viewAll}
                  </Link>
                </h3>
              </div>
            </div>
            <div className="card-body">
              <AppTable
                columns={columns}
                data={data || []}
                disableFilters={disableFilters}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AppTable
      columns={columns}
      data={data || []}
      disableFilters={disableFilters}
    />
  );
};

AssignmentTable.defaultProps = {
  disableFilters: false,
  miniMode: false,
  latest: 0,
  sortBy: 'createdAt',
};

export default AssignmentTable;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useStripe } from '@stripe/react-stripe-js';
import { plainToClass } from 'class-transformer';
import { validate } from 'class-validator';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import {
  extractClassValidatorErr,
  formatErrors,
  getLangAndErrKeys,
} from '../../../common/helper';
import LanguageTexts from '../../../common/language';
import { BankAccountInput } from '../../../common/types';
import AppInputLableWithError from '../../../components/AppInputLableWithError';
import BankAccountDto from './bank-account.dto';

type BankAccountProps = {
  onSubmit: (data: BankAccountInput) => void;
  onCancel: () => void;
  errors: string[];
  loading: boolean;
  showCancel: boolean;
};

const BankAccountForm: React.FC<BankAccountProps> = ({
  onSubmit,
  onCancel,
  errors,
  loading,
  showCancel,
}: BankAccountProps): JSX.Element => {
  const stripe = useStripe();
  const { profile: profileTxt } = LanguageTexts;
  const initialValues = {
    name: '',
    accountNumber: '',
    routingNumber: '',
    bankToken: '',
  };
  const [cErrors, setCErrors] = useState<string[] | undefined>();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        if (!stripe) {
          return null;
        }

        const valuesClass = plainToClass(BankAccountDto, values);

        const err = await validate(valuesClass, {
          validationError: { target: false, value: false },
        });

        if (err.length > 0) {
          setCErrors(extractClassValidatorErr(err));
          return null;
        }

        setCErrors(undefined);

        const { accountNumber, routingNumber, name } = values;

        const { error, token } = await stripe.createToken('bank_account', {
          account_holder_type: 'individual',
          country: 'US',
          currency: 'usd',
          account_holder_name: name,
          account_number: accountNumber,
          routing_number: routingNumber,
        });

        setErrors({ bankToken: error?.message || undefined });

        if (token) {
          onSubmit({
            bankToken: token.id,
          });
          setSubmitting(false);
        }

        return null;
      }}
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);

        const formattedErrors = formatErrors(
          errorKeys,
          cErrors || errors,
          profileTxt,
        );

        return (
          <Form>
            <div className="form">
              <div className="row">
                <div className="col-lg-4 col-12 mb-lg-0 mb-3">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={profileTxt}
                    id="input.name"
                    required
                  />
                  <Field name="name" type="text" className="form-control" />
                </div>
                <div className="col-lg-4 col-12 mb-lg-0 mb-3">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={profileTxt}
                    id="input.accountNumber"
                    required
                  />
                  <Field
                    name="accountNumber"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <div className="form-group">
                    <AppInputLableWithError
                      errors={errors}
                      langTxt={profileTxt}
                      id="input.routingNumber"
                      required
                    />
                    <Field
                      name="routingNumber"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="my-3" />

              <div className="row">
                <div className="col-lg-12 col-12">
                  <ErrorMessage
                    name="bankToken"
                    render={(msg) => (
                      <p className="txt2" style={{ fontWeight: 'bold' }}>
                        {msg}
                      </p>
                    )}
                  />
                  {formattedErrors.map((error) => (
                    <p
                      className="txt2"
                      style={{ fontWeight: 'bold' }}
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12" />
                <div className="col-md-6 col-12 text-right">
                  {showCancel ? (
                    <button
                      type="button"
                      className="btn cancel-btn mr-2"
                      disabled={loading}
                      onClick={() => onCancel()}
                    >
                      {profileTxt.btnCancel}&nbsp;
                    </button>
                  ) : null}
                  &nbsp;
                  <button
                    type="submit"
                    className="btn save-btn"
                    disabled={loading}
                  >
                    {profileTxt.btnSave}&nbsp;
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankAccountForm;

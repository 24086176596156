/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath, UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import {
  AnnouncementModel,
  CreateAnnouncementInput,
  RootState,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AnnouncementForm from './AnnouncementForm';
import {
  loadAnnouncement,
  loadAnnouncementDeps,
  resetLoadAnnouncement,
  resetLoadAnnouncementDeps,
} from './announcements.slice';

type AnnouncementRecordPageParams = {
  onSubmit: (data: CreateAnnouncementInput) => void;
  errors: string[];
  loading: boolean;
};

const AnnouncementRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: AnnouncementRecordPageParams): JSX.Element => {
  const { announcementId } = useParams<{ announcementId: string }>();
  const { announcements: announcementsTxt, app: appTxt } = LanguageTexts;

  const [announcementLoaded, setAnnouncementLoaded] = useState<boolean>();
  const [announcements, setAnnouncements] = useState<AnnouncementModel>();
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [
    announcementDepsLoadRequested,
    setAnnouncementDepsRequested,
  ] = useState<boolean>();

  const dispatch = useDispatch();
  const history = useHistory();

  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];
  let uCenterIds: string[] | undefined;
  let uBatchIds: string[] | undefined;

  const {
    loadAnnouncement: {
      loading: loadAnnouncementLoading,
      errors: loadAnnouncementErrors,
      success: loadAnnouncementSuccess,
    },
    loadAnnouncementDeps: {
      loading: loadAnnouncementDepsLoading,
      errors: loadAnnouncementDepsErrors,
      success: loadAnnouncementDepsSuccess,
    },
    centers,
    batches,
    students,
  } = useSelector((state: RootState) => state.announcements);

  useEffect(() => {
    if (announcementId && !announcementLoaded) {
      dispatch(loadAnnouncement(announcementId));
      setAnnouncementLoaded(true);
    }
  }, [announcementLoaded, announcementId, dispatch]);

  useEffect(() => {
    if (loadAnnouncementErrors.length > 0) {
      dispatch(resetLoadAnnouncement());
      history.push(AppRoutePath.Announcements);
    } else if (loadAnnouncementSuccess) {
      setAnnouncements(loadAnnouncementSuccess);
      dispatch(resetLoadAnnouncement());
    }
  }, [loadAnnouncementErrors, loadAnnouncementSuccess, dispatch, history]);

  function onSubmit({ ...restInput }: CreateAnnouncementInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  // load announcement deps
  useEffect(() => {
    if (!announcementDepsLoadRequested) {
      dispatch(
        loadAnnouncementDeps({
          domain: currentUser?.domain || '',
          role: [UserRole.Student],
        }),
      );
      setAnnouncementDepsRequested(true);
    }
  }, [currentUser, announcementDepsLoadRequested, dispatch]);

  useEffect(() => {
    if (loadAnnouncementDepsErrors.length > 0) {
      dispatch(resetLoadAnnouncementDeps());
      history.push(AppRoutePath.Announcements);
    } else if (loadAnnouncementDepsSuccess) {
      dispatch(resetLoadAnnouncementDeps());
    }
  }, [
    loadAnnouncementDepsSuccess,
    loadAnnouncementDepsErrors,
    dispatch,
    history,
  ]);

  if (
    (announcementId &&
      (!announcementLoaded ||
        loadAnnouncementLoading ||
        announcements === undefined)) ||
    !announcementDepsLoadRequested ||
    loadAnnouncementDepsLoading
  ) {
    return <AppLoader />;
  }

  if (userRoles.includes(UserRole.CenterHead)) {
    uCenterIds = currentUser?.centerIds || [];

    if (uCenterIds.length === 0 && selectedCenter) {
      uCenterIds = [selectedCenter];
    } else if (userRoles.includes(UserRole.Teacher)) {
      uBatchIds = currentUser?.batches?.map(({ batch }) => batch._id) || [];
      uCenterIds = [
        ...uCenterIds,
        ...(currentUser?.batches?.map(
          ({ batch: { center } }) => center?._id || '',
        ) || []),
      ];
    }
  } else if (userRoles.includes(UserRole.Teacher)) {
    uBatchIds = currentUser?.batches?.map(({ batch }) => batch._id) || [];

    if (uBatchIds.length === 0 && selectedBatch) {
      uBatchIds = [selectedBatch];
      const selectedBatchObj =
        batches?.find(({ _id }) => _id === selectedBatch) || null;

      if (selectedBatchObj) {
        uCenterIds = [selectedBatchObj.centerId];
      }
    } else {
      uCenterIds =
        currentUser?.batches?.map(
          ({ batch: { center } }) => center?._id || '',
        ) || [];
    }
  }

  const filteredCenters =
    uCenterIds && centers
      ? centers.filter(({ _id }) => uCenterIds && uCenterIds.includes(_id))
      : centers || [];
  const filteredBatches =
    uBatchIds && batches
      ? batches.filter(({ _id }) => uBatchIds && uBatchIds.includes(_id))
      : batches || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let announcementInfo: any;

  if (announcements) {
    announcementInfo = { ...announcements };
    announcementInfo.centerIds =
      batches
        ?.filter(({ _id }) => announcementInfo.batchIds.includes(_id))
        .map(({ centerId }) => centerId) || [];
  }

  const announcementInfoInit: CreateAnnouncementInput = announcementInfo || {
    heading: '',
    description: '',
    batchIds: [],
    centerIds: [],
    userIds: [],
    expiresAt: undefined,
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {announcementId
          ? announcementsTxt.updateAnnouncement
          : announcementsTxt.newAnnouncement}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <AnnouncementForm
          onSubmit={onSubmit}
          initialValues={announcementInfoInit}
          centers={filteredCenters}
          batches={filteredBatches}
          students={students || []}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default AnnouncementRecordPage;

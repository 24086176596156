/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppRoutePath,
  InvoiceStatus,
  PaymentMethodType,
  PaymentMode,
  UserRole,
} from '../../common/constants';
import LanguageTexts from '../../common/language';
import {
  PaymentMethodModel,
  RootState,
  UserInvoiceModel,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PaymentMethodForm from '../profile/payment-method/PaymentMethodForm';
import {
  loadPaymentMethods,
  resetLoadPaymentMethods,
} from '../profile/profile.slice';
import { loadUserInvoice, resetLoadUserInvoice } from './user-invoices.slice';
import UserInvoiceDetail from './UserInvoiceDetail';
import UserInvoicePayOffline from './UserInvoicePayOffline';
import UserInvoicePayUsingCard from './UserInvoicePayUsingCard';

const UserInvoiceRecordPage = (): JSX.Element => {
  const { invoiceId: userInvoiceId } = useParams<{ invoiceId: string }>();
  const {
    userInvoices: userInvoicesTxt,
    profile: profileTxt,
    app: appTxt,
  } = LanguageTexts;

  const [userInvoiceLoaded, setUserInvoiceLoaded] = useState<boolean>();
  const [userInvoice, setUserInvoice] = useState<UserInvoiceModel>();
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [profileInfoLoaded, setProfileInfoLoaded] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethodModel
  >();
  const [showForm, setShowForm] = useState(false);
  const {
    filter: { role: selectedRole },
  } = useSelector((state: RootState) => state.app);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadUserInvoice: {
      loading: loadUserInvoiceLoading,
      errors: loadUserInvoiceErrors,
      success: loadUserInvoiceSuccess,
    },
  } = useSelector((state: RootState) => state.userInvoices);
  const {
    paymentMethods,
    loadPaymentMethods: {
      loading: loadPaymentMethodsLoading,
      success: loadPaymentMethodsSuccess,
    },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (userInvoiceId && !userInvoiceLoaded) {
      dispatch(loadUserInvoice(userInvoiceId));
      setUserInvoiceLoaded(true);
    }
  }, [userInvoiceLoaded, userInvoiceId, dispatch]);

  useEffect(() => {
    if (loadUserInvoiceErrors.length > 0) {
      dispatch(resetLoadUserInvoice());
      history.push(AppRoutePath.UserInvoices);
    } else if (loadUserInvoiceSuccess) {
      setUserInvoice(loadUserInvoiceSuccess);
      dispatch(resetLoadUserInvoice());
    }
  }, [loadUserInvoiceErrors, loadUserInvoiceSuccess, dispatch, history]);

  useEffect(() => {
    if (loadPaymentMethodsSuccess && paymentMethods) {
      setSelectedPaymentMethod(undefined);
      setTimeout(() => {
        setSelectedPaymentMethod(
          paymentMethods.find(({ isDefault }) => isDefault),
        );
      }, 0);
      dispatch(resetLoadPaymentMethods());
    }
  }, [loadPaymentMethodsSuccess, paymentMethods, dispatch]);

  useEffect(() => {
    if (!profileInfoLoaded) {
      dispatch(loadPaymentMethods());
      setProfileInfoLoaded(true);
    }
  }, [dispatch, profileInfoLoaded]);

  if (
    !userInvoiceId ||
    !userInvoiceLoaded ||
    loadUserInvoiceLoading ||
    !profileInfoLoaded ||
    loadPaymentMethodsLoading ||
    userInvoice === undefined ||
    !currentUser
  ) {
    return <AppLoader />;
  }

  let paymentMode;

  if (
    userRoles.indexOf(UserRole.Student) !== -1 ||
    userRoles.indexOf(UserRole.Parent) !== -1
  ) {
    paymentMode = PaymentMode.Online;
  } else if (
    userRoles.indexOf(UserRole.Owner) !== -1 ||
    userRoles.indexOf(UserRole.CenterHead) !== -1 ||
    userRoles.indexOf(UserRole.Teacher) !== -1
  ) {
    paymentMode = PaymentMode.Offline;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let userInvoiceInfo: any;

  if (userInvoice) {
    userInvoiceInfo = userInvoice;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {paymentMode === PaymentMode.Online
          ? userInvoicesTxt.newFees
          : userInvoicesTxt.offlineFees}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      {userInvoice && paymentMode !== undefined ? (
        <UserInvoiceDetail
          invoice={userInvoice}
          paymentMode={paymentMode}
          paymentMethod={selectedPaymentMethod?.type}
        />
      ) : null}

      <div className="my-4" />

      {[InvoiceStatus.Paid, InvoiceStatus.Void].indexOf(
        userInvoiceInfo.invoiceStatus,
      ) === -1 ? (
        <div className="form">
          {paymentMode === PaymentMode.Online ? (
            <>
              {showForm ? (
                <PaymentMethodForm
                  initialValues={{ paymentMethodType: PaymentMethodType.Card }}
                  infoLoaded={profileInfoLoaded}
                  onCancel={() => setShowForm(false)}
                />
              ) : (
                <button
                  type="button"
                  className="btn add-more-btn"
                  onClick={() => setShowForm(true)}
                >
                  {profileTxt.btnAddPaymentMethod}&nbsp;
                </button>
              )}
              {selectedPaymentMethod &&
              paymentMethods &&
              paymentMethods.length > 0 ? (
                <UserInvoicePayUsingCard
                  initialValues={{
                    paymentMethodToken: selectedPaymentMethod.id,
                  }}
                  invoiceId={userInvoiceInfo._id}
                  onPaymentMethodChange={(pm) => setSelectedPaymentMethod(pm)}
                  paymentMethods={paymentMethods}
                />
              ) : null}
            </>
          ) : null}
          {paymentMode === PaymentMode.Offline ? (
            <UserInvoicePayOffline
              infoLoaded={profileInfoLoaded}
              invoice={userInvoiceInfo}
            />
          ) : null}
        </div>
      ) : null}
    </section>
  );
};

export default UserInvoiceRecordPage;

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { InstituteWebsiteMediaType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { WebsiteMediaInput } from '../../../common/types';
import MultiImageUpload, {
  SelectedImage,
} from '../../../components/MultiImageUpload';

type MediaPhotosProps = {
  initialValues: WebsiteMediaInput[];
  onChange: (data: WebsiteMediaInput[]) => void;
  onRemoveMedia: (id: string) => void;
  disabled?: boolean;
};

const MediaPhotos: React.FC<MediaPhotosProps> = ({
  initialValues,
  onChange,
  onRemoveMedia,
  disabled,
}: MediaPhotosProps): JSX.Element => {
  const { websiteMedia: websiteMediaTxt } = LanguageTexts;
  const [photos, setPhotos] = useState<WebsiteMediaInput[]>(initialValues);

  function removePhoto(index: number) {
    const newPhotos = photos.filter((vid, i) => index !== i);
    setPhotos(newPhotos);
    onChange(newPhotos);
  }

  function onFileSelect(files: SelectedImage[] | null) {
    if (files) {
      if (files) {
        const newImages = [
          ...photos,
          ...files.map(({ image, imagePreview: mediaUrl }) => {
            return {
              image,
              mediaUrl,
              mediaType: InstituteWebsiteMediaType.GalleryImage,
            };
          }),
        ];
        setPhotos(newImages);
        onChange(newImages);
      }
    }
  }

  return (
    <>
      <h2 className="secondary-heading mb-4">{websiteMediaTxt.photos}</h2>
      <div className="row">
        <div className="col-12">
          <div className="row">
            {photos.map(({ _id, mediaUrl }, index) => {
              return (
                <div key={`photo-img${index}`} className="col-sm-3 col-6 mb-3">
                  <div className="uploaded-gallery-photo">
                    <img src={mediaUrl} alt="" />
                    <Link
                      className="remove-pic-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        if (disabled) return;
                        if (_id) {
                          onRemoveMedia(_id);
                        } else {
                          removePhoto(index);
                        }
                      }}
                      to="/"
                      aria-disabled={disabled}
                    >
                      x
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <MultiImageUpload
            disabled={disabled}
            id="photos-select"
            onFileSelect={onFileSelect}
          >
            <div className="d-flex justify-content-center align-items-center rounded-lg py-2 px-0 add-photos-btn add-more-btn">
              <span className="fa fa-plus-circle mr-2" />
              {websiteMediaTxt.addPhotos}
            </div>
          </MultiImageUpload>
        </div>
      </div>
    </>
  );
};

MediaPhotos.defaultProps = {
  disabled: false,
};

export default MediaPhotos;

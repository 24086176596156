/* eslint-disable no-console */
import 'react-confirm-alert/src/react-confirm-alert.css';

import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { InvoiceStatus, PaymentStatus } from '../../common/constants';
import LanguageTexts from '../../common/language';
import {
  ReceivePaymentForInstituteInput,
  RootState,
  SendPaymentForInstituteInput,
} from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import {
  loadOwners,
  receivePaymentForInstitute,
  resetReceivePaymentForInstitute,
  resetSendPaymentForInstitute,
  sendPaymentForInstitute,
} from './institutes.slice';

const InstituteListPage = (): JSX.Element => {
  const {
    institutes: institutesTxt,
    userInvoices: userInvoicesTxt,
    app: appTxt,
  } = LanguageTexts;
  const dispatch = useDispatch();
  const { owners, loading } = useSelector(
    (state: RootState) => state.institutes,
  );
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const { subscriptionPlanFirst } = useSelector(
    (state: RootState) => state.itSetting,
  );
  const {
    receivePaymentForInstitute: {
      loading: receivePaymentForInstituteLoading,
      success: receivePaymentForInstituteSuccess,
      errors: receivePaymentForInstituteErrors,
    },
    sendPaymentForInstitute: {
      loading: sendPaymentForInstituteLoading,
      success: sendPaymentForInstituteSuccess,
      errors: sendPaymentForInstituteErrors,
    },
  } = useSelector((state: RootState) => state.institutes);
  const [loadRequested, setLoadRequested] = useState(false);

  const subscriptionFeesPerStudent =
    subscriptionPlanFirst?.pricePerActiveStudent || 0;

  const onReceiveSubmit = useCallback(
    (data: ReceivePaymentForInstituteInput) => {
      confirmAlert({
        title: institutesTxt.paymentReceive,
        message: appTxt.confirmText,
        buttons: [
          {
            label: appTxt.yes,
            onClick: () =>
              dispatch(
                receivePaymentForInstitute({
                  ...data,
                }),
              ),
          },
          {
            label: appTxt.no,
            onClick: () => null,
          },
        ],
      });
    },
    [dispatch, appTxt, institutesTxt],
  );

  const onSendSubmit = useCallback(
    (data: SendPaymentForInstituteInput) => {
      confirmAlert({
        title: institutesTxt.paymentSend,
        message: appTxt.confirmText,
        buttons: [
          {
            label: appTxt.yes,
            onClick: () =>
              dispatch(
                sendPaymentForInstitute({
                  ...data,
                }),
              ),
          },
          {
            label: appTxt.no,
            onClick: () => null,
          },
        ],
      });
    },
    [dispatch, appTxt, institutesTxt],
  );

  useEffect(() => {
    if (receivePaymentForInstituteSuccess) {
      toast.success(institutesTxt.paymentReceiveSuccess);
      dispatch(resetReceivePaymentForInstitute());
      dispatch(loadOwners());
    } else if (
      receivePaymentForInstituteErrors &&
      receivePaymentForInstituteErrors.length > 0
    ) {
      toast.error(receivePaymentForInstituteErrors[0]);
      dispatch(resetReceivePaymentForInstitute());
      dispatch(loadOwners());
    }
  }, [
    receivePaymentForInstituteSuccess,
    receivePaymentForInstituteErrors,
    dispatch,
    institutesTxt,
  ]);

  useEffect(() => {
    if (sendPaymentForInstituteSuccess) {
      toast.success(institutesTxt.paymentSendSuccess);
      dispatch(resetSendPaymentForInstitute());
      dispatch(loadOwners());
    } else if (
      sendPaymentForInstituteErrors &&
      sendPaymentForInstituteErrors.length > 0
    ) {
      toast.error(sendPaymentForInstituteErrors[0]);
      dispatch(resetSendPaymentForInstitute());
      dispatch(loadOwners());
    }
  }, [
    sendPaymentForInstituteSuccess,
    sendPaymentForInstituteErrors,
    dispatch,
    institutesTxt,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: institutesTxt.instituteName,
        accessor: 'institute.name',
      },
      {
        Header: institutesTxt.subscriptionAmountDue,
        accessor: 'subscriptionAmountDue',
        Cell: ({
          row: {
            original: { subscriptionAmountDue, currencySymbol },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return subscriptionAmountDue
            ? `${currencySymbol}${subscriptionAmountDue}`
            : '';
        },
      },
      {
        Header: institutesTxt.subscriptionInvoiceDate,
        accessor: 'subscriptionInvoiceDate',
        Cell: ({
          row: {
            original: {
              subscriptionInvoiceDate,
              lastSubscriptionInvoice: invoice,
            },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          if (!subscriptionInvoiceDate) {
            return '';
          }

          return (
            <button
              type="button"
              name="downloadInvoice"
              onClick={() => {
                if (invoice)
                  window.open(invoice.invoicePdfDownloadLink, '_blank');
              }}
              disabled={
                sendPaymentForInstituteLoading ||
                (invoice && !invoice.invoicePdfDownloadLink)
              }
            >
              {subscriptionInvoiceDate}
            </button>
          );
        },
      },
      {
        Header: institutesTxt.subscriptionPaymentStatus,
        accessor: 'subscriptionInvoiceStatusTxt',
        Cell: ({
          row: {
            original: {
              _id,
              subscriptionInvoiceStatusTxt,
              canReceiveSubscriptionInvoice,
            },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          if (!canReceiveSubscriptionInvoice) {
            return subscriptionInvoiceStatusTxt;
          }

          return (
            <button
              type="button"
              name="receivePayemnt"
              onClick={() => {
                onReceiveSubmit({ userId: _id });
              }}
              disabled={receivePaymentForInstituteLoading}
            >
              {subscriptionInvoiceStatusTxt}
            </button>
          );
        },
      },
      {
        Header: institutesTxt.payoutAmountDue,
        accessor: 'payoutAmountDue',
        Cell: ({
          row: {
            original: { payoutAmountDue, currencySymbol },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return payoutAmountDue ? `${currencySymbol}${payoutAmountDue}` : '';
        },
      },
      {
        Header: institutesTxt.payoutInvoiceDate,
        accessor: 'payoutInvoiceDate',
        Cell: ({
          row: {
            original: { payoutInvoiceDate, lastPayoutInvoice: invoice },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          if (!payoutInvoiceDate) {
            return '';
          }

          return (
            <button
              type="button"
              name="downloadInvoice"
              onClick={() => {
                if (invoice) {
                  window.open(invoice.invoicePdfDownloadLink, '_blank');
                }
              }}
              disabled={sendPaymentForInstituteLoading}
            >
              {payoutInvoiceDate}
            </button>
          );
        },
      },
      {
        Header: institutesTxt.payoutPaymentStatus,
        accessor: 'payoutInvoiceStatusTxt',
        Cell: ({
          row: {
            original: {
              payoutInvoiceStatusTxt,
              payoutPending,
              instituteId,
              payoutAmountDue,
            },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          if (!payoutPending || payoutAmountDue === 0) {
            return payoutInvoiceStatusTxt;
          }

          return (
            <button
              type="button"
              name="sendPayemnt"
              onClick={() => {
                onSendSubmit({ instituteId });
              }}
              disabled={sendPaymentForInstituteLoading}
            >
              {payoutInvoiceStatusTxt}
            </button>
          );
        },
      },
    ],
    [
      onReceiveSubmit,
      onSendSubmit,
      institutesTxt,
      receivePaymentForInstituteLoading,
      sendPaymentForInstituteLoading,
    ],
  );

  const data = React.useMemo(
    () =>
      owners?.map(
        ({
          activeStudentsCount,
          lastSubscriptionInvoice,
          lastPayoutInvoice,
          pendingPayoutAmount,
          subscriptionPlan,
          ...rest
        }) => {
          let subscriptionInvoiceDate;
          let payoutInvoiceDate;

          let subscriptionAmountDue = 0;
          let payoutAmountDue = 0;

          if (lastSubscriptionInvoice) {
            subscriptionInvoiceDate = lastSubscriptionInvoice.createdAt
              ? moment(lastSubscriptionInvoice.createdAt).format('L')
              : '';

            if (
              lastSubscriptionInvoice.invoiceStatus === InvoiceStatus.Paid ||
              lastSubscriptionInvoice.paymentStatus === PaymentStatus.Paid
            ) {
              subscriptionAmountDue = lastSubscriptionInvoice.amountPaid / 100;
            } else {
              subscriptionAmountDue = lastSubscriptionInvoice.amountDue;
            }
          } else if (subscriptionPlan) {
            subscriptionAmountDue = activeStudentsCount
              ? activeStudentsCount * subscriptionPlan.pricePerActiveStudent
              : 0;
          } else {
            subscriptionAmountDue = activeStudentsCount
              ? activeStudentsCount * subscriptionFeesPerStudent
              : 0;
          }

          if (lastPayoutInvoice) {
            payoutInvoiceDate = lastPayoutInvoice.createdAt
              ? moment(lastPayoutInvoice.createdAt).format('L')
              : '';
            payoutAmountDue =
              pendingPayoutAmount && pendingPayoutAmount > 0
                ? pendingPayoutAmount
                : lastPayoutInvoice.amountDue;
          } else {
            payoutAmountDue =
              pendingPayoutAmount && pendingPayoutAmount > 0
                ? pendingPayoutAmount
                : 0;
          }

          const payoutPending = !(
            lastPayoutInvoice &&
            lastPayoutInvoice.invoiceStatus === InvoiceStatus.Paid &&
            pendingPayoutAmount === 0
          );

          let subscriptionInvoiceStatusTxt = institutesTxt.subscriptionNA;
          let canReceiveSubscriptionInvoice = false;

          if (lastSubscriptionInvoice) {
            if (
              lastSubscriptionInvoice.invoiceStatus === InvoiceStatus.Paid ||
              lastSubscriptionInvoice.paymentStatus === PaymentStatus.Paid
            ) {
              subscriptionInvoiceStatusTxt = userInvoicesTxt.received;
            } else if (
              lastSubscriptionInvoice.paymentStatus === PaymentStatus.Failed
            ) {
              subscriptionInvoiceStatusTxt = userInvoicesTxt.failed;
              canReceiveSubscriptionInvoice = true;
            } else if (
              lastSubscriptionInvoice.paymentStatus === PaymentStatus.Pending
            ) {
              subscriptionInvoiceStatusTxt = userInvoicesTxt.processing;
            } else if (subscriptionAmountDue > 0) {
              subscriptionInvoiceStatusTxt = institutesTxt.subscriptionPending;
              canReceiveSubscriptionInvoice = true;
            }
          } else if (subscriptionAmountDue > 0) {
            subscriptionInvoiceStatusTxt = institutesTxt.subscriptionPending;
            canReceiveSubscriptionInvoice = true;
          }

          return {
            ...rest,
            lastSubscriptionInvoice,
            subscriptionInvoiceDate,
            subscriptionAmountDue:
              subscriptionAmountDue > 0 ? subscriptionAmountDue.toFixed(2) : 0,
            subscriptionInvoiceStatusTxt,
            canReceiveSubscriptionInvoice,
            lastPayoutInvoice,
            payoutInvoiceDate: !payoutPending ? payoutInvoiceDate : '',
            payoutAmountDue,
            payoutPending,
            // eslint-disable-next-line no-nested-ternary
            payoutInvoiceStatusTxt: payoutPending
              ? payoutAmountDue > 0
                ? institutesTxt.payoutPending
                : institutesTxt.payoutNA
              : userInvoicesTxt.paid,
            currencySymbol:
              rest.institute?.address.country?.currencySymbol || '',
          };
        },
      ),
    [owners, userInvoicesTxt, subscriptionFeesPerStudent, institutesTxt],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadOwners());
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section
      className="bg-white rounded shadow px-4 py-5 list-table"
      style={
        receivePaymentForInstituteLoading || sendPaymentForInstituteLoading
          ? { opacity: 0.5 }
          : {}
      }
    >
      <h1 className="primary-heading mb-4">{institutesTxt.institutes}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default InstituteListPage;

import React from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '../../common/constants';
import { RootState } from '../../common/types';
import AnnouncementTable from '../announcements/AnnouncementTable';
import AssignmentTable from '../assignments/AssignmentTable';
import BatchSchedule from './BatchSchedule';

const DashboardPage: React.FC = (): JSX.Element => {
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: { role: selectedRole },
  } = useSelector((state: RootState) => state.app);
  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  return (
    <>
      {userRoles.includes(UserRole.Teacher) ||
      userRoles.includes(UserRole.Student) ||
      userRoles.includes(UserRole.Parent) ? (
        <AssignmentTable disableFilters latest={5} miniMode />
      ) : null}
      {!userRoles.includes(UserRole.Admin) ? (
        <AnnouncementTable disableFilters latest={5} miniMode />
      ) : null}
      {!userRoles.includes(UserRole.Admin) ? (
        <div className="row batch-schedule-calendar">
          <div className="col-lg-6 col-12">
            <BatchSchedule />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DashboardPage;

import { Field, Form, Formik } from 'formik';
import React from 'react';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { ContactUsInput } from '../../common/types';

type ContactUsFormProps = {
  onSubmit: (data: ContactUsInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: ContactUsInput;
};

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: ContactUsFormProps): JSX.Element => {
  const { contactUs: contactUsTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, contactUsTxt);

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <div className="w3_agileits_contact_left">
          <div className="row">
            <div className="col-12">
              <Field placeholder={contactUsTxt.name} name="name" type="text" />
            </div>
          </div>
          <div className="my-3" />
          <div className="row">
            <div className="col-12">
              <Field
                placeholder={contactUsTxt.email}
                name="email"
                type="text"
                className="tolowercase-text"
              />
            </div>
          </div>
          <div className="my-3" />
          <div className="row">
            <div className="col-12">
              <Field
                placeholder={contactUsTxt.phone}
                name="phone"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                placeholder={contactUsTxt.message}
                name="message"
                as="textarea"
              />
            </div>
          </div>
        </div>

        {formattedErrors.map((error) => (
          <p className="text-left font-weight-bold small" key={error}>
            {error}
          </p>
        ))}
        <div className="w3_agileits_contact_right ">
          <button type="submit" className="btn save-btn" disabled={loading}>
            {contactUsTxt.btnSubmit}
          </button>
        </div>
        <div className="clearfix" />
      </Form>
    </Formik>
  );
};

export default ContactUsForm;

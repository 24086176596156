/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CourseModel, CreateCourseInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import {
  loadCourse,
  removeCourseImage,
  resetLoadCourse,
  resetRemoveCourseImage,
} from './institute-courses.slice';
import InstituteCourseForm from './InstituteCourseForm';

type InstituteCourseRecordPageParams = {
  onSubmit: (data: CreateCourseInput) => void;
  errors: string[];
  loading: boolean;
};

const InstituteCourseRecordPage = ({
  onSubmit: onSubmitParent,
  errors,
  loading,
}: InstituteCourseRecordPageParams): JSX.Element => {
  const { courseId } = useParams<{ courseId: string }>();
  const { courses: coursesTxt, app: appTxt } = LanguageTexts;

  const [courseLoaded, setCourseLoaded] = useState<boolean>();
  const [courses, setCourses] = useState<CourseModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadCourse: {
      loading: loadCourseLoading,
      errors: loadCourseErrors,
      success: loadCourseSuccess,
    },
    removeCourseImage: {
      loading: removeCourseImageLoading,
      success: removeCourseImageSuccess,
    },
  } = useSelector((state: RootState) => state.courses);

  useEffect(() => {
    if (courseId && !courseLoaded) {
      dispatch(loadCourse(courseId));
      setCourseLoaded(true);
    }
  }, [courseLoaded, courseId, dispatch]);

  useEffect(() => {
    if (loadCourseErrors.length > 0) {
      dispatch(resetLoadCourse());
      history.push(AppRoutePath.Courses);
    } else if (loadCourseSuccess) {
      setCourses(loadCourseSuccess);
      dispatch(resetLoadCourse());
    }
  }, [loadCourseErrors, loadCourseSuccess, dispatch, history]);

  function onSubmit({ ...restInput }: CreateCourseInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  function onRemoveCourseImage(id: string) {
    dispatch(removeCourseImage(id));
  }

  useEffect(() => {
    if (removeCourseImageSuccess) {
      dispatch(resetRemoveCourseImage());
      if (courses) {
        setCourses({ ...courses, imageUrl: undefined });
      }
    }
  }, [courses, removeCourseImageSuccess, dispatch]);

  if (
    courseId &&
    (!courseLoaded || loadCourseLoading || courses === undefined)
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let courseInfo: any;

  if (courses) {
    courseInfo = courses;
  }

  const courseInfoInit: CreateCourseInput = courseInfo || {
    name: '',
    description: [''],
  };

  return (
    <section className="bg-white rounded shadow px-4 py-5">
      <h1 className="primary-heading mb-4">
        {courseId ? coursesTxt.updateCourse : coursesTxt.newCourse}
      </h1>

      <p className="small common-text-color text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <InstituteCourseForm
          onSubmit={onSubmit}
          onRemoveCoursePic={onRemoveCourseImage}
          initialValues={courseInfoInit}
          errors={errors}
          loading={loading || removeCourseImageLoading}
        />
      </div>
    </section>
  );
};

export default InstituteCourseRecordPage;

import matchSorter from 'match-sorter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReadMoreReact from 'read-more-react';

import { AppRoutePath, UserRole } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadAnnouncements } from './announcements.slice';

type AnnouncementTableProps = {
  disableFilters?: boolean;
  miniMode?: boolean;
  latest?: number;
  sortBy?: string;
};

const AnnouncementTable: React.FC<AnnouncementTableProps> = ({
  disableFilters,
  miniMode,
  latest,
  sortBy,
}: AnnouncementTableProps): JSX.Element | null => {
  const {
    announcements: announcementsTxt,
    app: appTxt,
    dashboard: dashboardTxt,
  } = LanguageTexts;
  const dispatch = useDispatch();
  const { announcements, loading } = useSelector(
    (state: RootState) => state.announcements,
  );
  const {
    filter: {
      role: selectedRole,
      centerId: selectedCenter,
      batchId: selectedBatch,
      childrenId: selectedChildren,
    },
  } = useSelector((state: RootState) => state.app);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const userRoles = selectedRole ? [selectedRole] : currentUser?.role || [];

  const columns = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const conColumns: any = [
      {
        Header: announcementsTxt.heading,
        accessor: 'heading',
        Cell: ({
          row: {
            original: { _id, heading },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          if (
            userRoles.includes(UserRole.Student) ||
            userRoles.includes(UserRole.Parent)
          ) {
            return heading;
          }

          return (
            <Link
              to={`${AppRoutePath.AnnouncementsUpdate.replace(
                ':announcementId',
                _id,
              )}`}
            >
              {heading}
            </Link>
          );
        },
      },
      {
        Header: announcementsTxt.description,
        accessor: 'description',
        Cell: ({
          row: {
            original: { description },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <ReadMoreReact
              text={description}
              min={80}
              ideal={100}
              max={150}
              readMoreText={appTxt.readMore}
            />
          );
        },
      },
    ];

    if (userRoles.includes(UserRole.Owner)) {
      conColumns.push({
        Header: announcementsTxt.center,
        accessor: 'centerTxt',
      });
    }

    if (userRoles.includes(UserRole.Owner)) {
      conColumns.push({
        Header: announcementsTxt.class,
        accessor: 'batchTxt',
      });
    }

    if (
      userRoles.includes(UserRole.Owner) ||
      userRoles.includes(UserRole.CenterHead) ||
      userRoles.includes(UserRole.Teacher)
    ) {
      conColumns.push({
        Header: announcementsTxt.student,
        accessor: 'studentTxt',
        Cell: ({
          row: {
            original: { studentTxt },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <ReadMoreReact
              text={studentTxt}
              min={80}
              ideal={100}
              max={150}
              readMoreText={appTxt.readMore}
            />
          );
        },
      });
    }

    conColumns.push({
      Header: announcementsTxt.date,
      accessor: 'dateTxt',
    });

    return conColumns;
  }, [announcementsTxt, appTxt, userRoles]);

  const data = React.useMemo(() => {
    const sortedAnnouncement = matchSorter(announcements || [], '', {
      keys: [sortBy || ''],
    }).reverse();

    const filteredAnnouncement = sortedAnnouncement?.filter(
      ({ userIds, batches }) => {
        const aCenterIds = batches.map(({ centerId }) => {
          return centerId;
        });
        const aBatchIds = batches.map(({ _id }) => {
          return _id;
        });

        let centerCheck = true;
        let batchCheck = true;
        let childrenCheck = true;

        if (selectedChildren) {
          childrenCheck =
            userIds && userIds.length > 0
              ? userIds.includes(selectedChildren)
              : true;
        }

        if (selectedCenter) {
          centerCheck = aCenterIds.includes(selectedCenter);

          if (!selectedBatch) {
            return centerCheck;
          }
        }

        if (selectedBatch) {
          batchCheck = aBatchIds.includes(selectedBatch);

          if (selectedChildren) {
            return batchCheck && childrenCheck;
          }

          if (!selectedCenter) {
            return batchCheck;
          }
        }

        return centerCheck || batchCheck;
      },
    );

    const announcementData =
      latest && latest > 0
        ? filteredAnnouncement.slice(0, latest)
        : filteredAnnouncement;

    return announcementData.map(
      ({ users, batches, createdAt, ...restAnnouncement }) => {
        const centerTxt = batches
          .map(({ center }) => {
            return center?.name || '';
          })
          .join(', ');
        const batchTxt = batches
          .map(({ name, class: classObj }) => {
            return `${classObj?.name || ''} - ${name}`;
          })
          .join(', ');
        const studentTxt =
          users && users.length > 0
            ? users
                .map(({ name }) => {
                  return name;
                })
                .join(', ')
            : announcementsTxt.allStudents;
        const dateTxt = moment(createdAt).format('L');

        return {
          ...restAnnouncement,
          centerTxt,
          batchTxt,
          studentTxt,
          dateTxt,
        };
      },
    );
  }, [
    announcements,
    announcementsTxt,
    selectedBatch,
    selectedCenter,
    selectedChildren,
    latest,
    sortBy,
  ]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadAnnouncements({}));
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (!miniMode && (loading || !loadRequested)) {
    return <AppLoader />;
  }

  if (miniMode) {
    if (data.length === 0) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card dashboard-table list-table">
            <div className="row justify-content-center align-items-center">
              <div className="col-6">
                <h1 className="primary-heading mt-5 mb-4 ml-4">
                  {dashboardTxt.announcements}
                </h1>
              </div>
              <div className="col-6 text-right">
                <h3 className="tertiary-heading mt-5 mb-4 mr-4">
                  <Link
                    to={AppRoutePath.Announcements}
                    className="view-details"
                  >
                    {dashboardTxt.viewAll}
                  </Link>
                </h3>
              </div>
            </div>
            <div className="card-body">
              <AppTable
                columns={columns}
                data={data || []}
                disableFilters={disableFilters}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AppTable
      columns={columns}
      data={data || []}
      disableFilters={disableFilters}
    />
  );
};

AnnouncementTable.defaultProps = {
  disableFilters: false,
  miniMode: false,
  latest: 0,
  sortBy: 'createdAt',
};

export default AnnouncementTable;

import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import { getPermissionsForRoute } from '../../common/helper';
import { AppRoutePermissions, RootState } from '../../common/types';
import PrivateRoute from '../../components/PrivateRoute';
import UserInvoiceListPage from './UserInvoiceListPage';
import UserInvoiceRecordPage from './UserInvoiceRecordPage';

const UserInvoicesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const { routePermissions } = useSelector(
    (state: RootState) => state.itSetting,
  );

  function rPermission(path: string): AppRoutePermissions {
    return getPermissionsForRoute(path, routePermissions || []);
  }

  return (
    <div>
      <Switch>
        <PrivateRoute
          path={AppRoutePath.UserInvoicePay}
          permissions={rPermission(AppRoutePath.UserInvoicePay)}
        >
          <UserInvoiceRecordPage key="userInvoiceRecord" />
        </PrivateRoute>
        <PrivateRoute path={match.path} permissions={rPermission(match.path)}>
          <UserInvoiceListPage key="userInvoiceListPage" />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default UserInvoicesPage;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ContactUsInput,
  HomeState,
  InstitutePreRegistrationInfoModel,
  PreRegistrationInfoInput,
} from '../../common/types';

const initialState: HomeState = {
  loading: false,
  success: null,
  errors: [],
  institutePreRegistrationInfo: null,
  addPreRegistrationInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  addContactUs: {
    loading: false,
    success: null,
    errors: [],
  },
};

const preRegistrationInfoSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    addPreRegistrationInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<PreRegistrationInfoInput>,
    ) {
      state.addPreRegistrationInfo.loading = true;
      state.addPreRegistrationInfo.errors = [];
    },
    addPreRegistrationInfoSuccess(
      state,
      action: PayloadAction<InstitutePreRegistrationInfoModel>,
    ) {
      state.addPreRegistrationInfo.loading = false;
      state.addPreRegistrationInfo.success = !!action.payload;
      state.institutePreRegistrationInfo = action.payload;
    },
    addPreRegistrationInfoError(state, action: PayloadAction<string[]>) {
      state.addPreRegistrationInfo.loading = false;
      state.addPreRegistrationInfo.errors = action.payload;
    },
    resetAddPreRegistrationInfoSuccess(state) {
      state.addPreRegistrationInfo.success = null;
    },
    addContactUs(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ContactUsInput>,
    ) {
      state.addContactUs.loading = true;
      state.addContactUs.errors = [];
    },
    addContactUsSuccess(state, action: PayloadAction<boolean>) {
      state.addContactUs.loading = false;
      state.addContactUs.success = action.payload;
    },
    addContactUsError(state, action: PayloadAction<string[]>) {
      state.addContactUs.loading = false;
      state.addContactUs.errors = action.payload;
    },
    resetAddContactUsSuccess(state) {
      state.addContactUs.success = null;
    },
  },
});

export const {
  addPreRegistrationInfo,
  addPreRegistrationInfoSuccess,
  addPreRegistrationInfoError,
  resetAddPreRegistrationInfoSuccess,
  addContactUs,
  addContactUsSuccess,
  addContactUsError,
  resetAddContactUsSuccess,
} = preRegistrationInfoSlice.actions;

export const {
  name: preRegistrationInfoSliceName,
  reducer: preRegistrationInfoSliceReducer,
} = preRegistrationInfoSlice;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseState, ForgotPasswordInput } from '../../common/types';

const initialState: BaseState = {
  loading: false,
  success: null,
  errors: [],
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forgotPassword(state, action: PayloadAction<ForgotPasswordInput>) {
      state.loading = true;
    },
    forgotPasswordSuccess(state, action: PayloadAction<boolean>) {
      state.loading = false;
      state.success = action.payload;
    },
    forgotPasswordError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
} = forgotPasswordSlice.actions;

export const {
  name: forgotPasswordSliceName,
  reducer: forgotPasswordSliceReducer,
} = forgotPasswordSlice;

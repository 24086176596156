/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageTexts from '../common/language';

type FileUploadProps = {
  id: string;
  label: string;
  onFileSelect: (image: File | null) => void;
  onRemoveUrl?: undefined | (() => void);
  url?: string | null;
  required?: boolean;
  maxFileSize?: number | null;
  disabled?: boolean;
};

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  label,
  onFileSelect,
  onRemoveUrl,
  url,
  required,
  maxFileSize,
  disabled,
}: FileUploadProps): JSX.Element => {
  const [previewFile, setPreviewFile] = useState<File | null>(null);
  const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

  function removePreviewFile(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    setPreviewFile(null);
    onFileSelect(null);
  }

  function removeFileUrl(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    if (onRemoveUrl) {
      onRemoveUrl();
    }
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { files },
    } = e;

    if (files && files.length > 0) {
      setMaxSizeErr(null);

      const file = files[0];
      const sizeInKB = Math.round(file.size / 1024);

      if (maxFileSize && sizeInKB > maxFileSize) {
        setMaxSizeErr(
          LanguageTexts.app.maxSizeErr.replace(
            '{size}',
            `${maxFileSize / 1024}MB`,
          ),
        );
        return;
      }

      setPreviewFile(file);
      onFileSelect(file);
      e.target.value = '';
    }
  }

  return (
    <>
      {previewFile || url ? (
        <div className="row">
          <div className="col-lg-12 col-12">
            <span>{previewFile ? previewFile.name : ''}</span>
            {previewFile ? (
              <Link
                className="font-weight-bold"
                style={{ display: 'inline', marginLeft: '5px' }}
                onClick={removePreviewFile}
                to="/"
              >
                &nbsp;
                <span className="fa fa-times-circle common-text-color" />
              </Link>
            ) : null}
            {!previewFile && url ? (
              <a
                href={url}
                title="View"
                rel="noopener noreferrer"
                target="_blank"
                className="font-weight-bold"
              >
                View <span className="fa fa-download" />
              </a>
            ) : null}
            {onRemoveUrl && !previewFile && url ? (
              <Link
                className="font-weight-bold"
                style={{ display: 'inline', marginLeft: '5px' }}
                onClick={removeFileUrl}
                to="/"
              >
                &nbsp;
                <span className="fa fa-times-circle common-text-color" />
              </Link>
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="upload-image-field">
        <div className="form-group">
          <label
            className={maxSizeErr ? '' : 'text-secondary small'}
            style={maxSizeErr ? { color: 'red' } : {}}
          >
            {label}
            {required ? (
              <sup className="common-text-color require-field">
                <span className="fa fa-star-of-life" />
              </sup>
            ) : null}
          </label>
          {maxSizeErr ? <p>{maxSizeErr}</p> : null}
          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id={id}
                aria-describedby="inputGroupFileAddon04"
                onChange={onFileChange}
                disabled={disabled}
              />
              <label htmlFor={id} className="custom-file-label" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right">
              {maxFileSize ? (
                <p className="small common-text-color short-desc mt-1">
                  {LanguageTexts.app.maxSize} {maxFileSize / 1024}MB
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FileUpload.defaultProps = {
  url: null,
  maxFileSize: null,
  required: false,
  disabled: false,
  onRemoveUrl: undefined,
};

export default FileUpload;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ClassDepsResponse,
  ClassesState,
  ClassModel,
  CreateClassInput,
  GetClassDepsQuery,
  GetClassesQuery,
} from '../../common/types';

const initialState: ClassesState = {
  loading: false,
  success: null,
  errors: [],
  classes: null,
  addClass: {
    loading: false,
    success: null,
    errors: [],
  },
  loadClass: {
    loading: false,
    success: null,
    errors: [],
  },
  loadClassDeps: {
    loading: false,
    success: null,
    errors: [],
  },
  courses: null,
  removeClassImage: {
    loading: false,
    success: null,
    errors: [],
  },
};

const classesSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadClasses(state, action: PayloadAction<GetClassesQuery>) {
      state.loading = true;
      state.errors = [];
    },
    loadClassesSuccess(state, action: PayloadAction<ClassModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.classes = action.payload;
    },
    loadClassesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadClasses(state) {
      state.loadClass.success = null;
      state.loadClass.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addClass(state, action: PayloadAction<CreateClassInput>) {
      state.addClass.loading = true;
      state.addClass.errors = [];
    },
    addClassSuccess(state, action: PayloadAction<boolean>) {
      state.addClass.loading = false;
      state.addClass.success = action.payload;
    },
    addClassError(state, action: PayloadAction<string[]>) {
      state.addClass.loading = false;
      state.addClass.errors = action.payload;
    },
    resetAddClassSuccess(state) {
      state.addClass.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadClass(state, action: PayloadAction<string>) {
      state.loadClass.loading = true;
      state.loadClass.errors = [];
    },
    loadClassSuccess(state, action: PayloadAction<ClassModel>) {
      state.loadClass.loading = false;
      state.loadClass.success = action.payload;
    },
    loadClassError(state, action: PayloadAction<string[]>) {
      state.loadClass.loading = false;
      state.loadClass.errors = action.payload;
    },
    resetLoadClass(state) {
      state.loadClass.success = null;
      state.loadClass.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadClassDeps(state, action: PayloadAction<GetClassDepsQuery>) {
      state.loadClassDeps.loading = true;
      state.loadClassDeps.errors = [];
    },
    loadClassDepsSuccess(state, action: PayloadAction<ClassDepsResponse>) {
      state.loadClassDeps.loading = false;
      state.loadClassDeps.success = !!action.payload;
      state.courses = action.payload.courses;
    },
    loadClassDepsError(state, action: PayloadAction<string[]>) {
      state.loadClassDeps.loading = false;
      state.loadClassDeps.errors = action.payload;
    },
    resetLoadClassDeps(state) {
      state.loadClassDeps.success = null;
      state.loadClassDeps.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeClassImage(state, action: PayloadAction<string>) {
      state.removeClassImage.loading = true;
      state.removeClassImage.errors = [];
    },
    removeClassImageSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeClassImage.loading = false;
      state.removeClassImage.success = action.payload.success;
    },
    removeClassImageError(state, action: PayloadAction<string[]>) {
      state.removeClassImage.loading = false;
      state.removeClassImage.errors = action.payload;
    },
    resetRemoveClassImage(state) {
      state.removeClassImage.success = null;
      state.removeClassImage.errors = [];
    },
  },
});

export const {
  loadClass,
  loadClassError,
  loadClassSuccess,
  resetLoadClass,
  loadClasses,
  loadClassesError,
  loadClassesSuccess,
  resetLoadClasses,
  addClass,
  addClassSuccess,
  addClassError,
  resetAddClassSuccess,
  loadClassDeps,
  loadClassDepsSuccess,
  loadClassDepsError,
  resetLoadClassDeps,
  removeClassImage,
  removeClassImageSuccess,
  removeClassImageError,
  resetRemoveClassImage,
} = classesSlice.actions;

export const {
  name: classesSliceName,
  reducer: classesSliceReducer,
} = classesSlice;

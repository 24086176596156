/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { UserRole } from '../../common/constants';
import { rolesHasRole, userHasRole } from '../../common/helper';
import { MenuItemModel, RootState, UserModel } from '../../common/types';
import FilterBox from './FilterBox';

type SideMenuProps = {
  user: UserModel;
  menuItems: MenuItemModel[];
  pathname: string;
};

const SideMenu: React.FC<SideMenuProps> = ({
  user,
  menuItems,
  pathname,
}: SideMenuProps): JSX.Element => {
  const {
    filter: { role },
  } = useSelector((state: RootState) => state.app);

  const links = menuItems
    .filter(({ roles }) =>
      role ? rolesHasRole([role], roles) : userHasRole(user, roles),
    )
    .map(({ children, ...rest }) => {
      return {
        ...rest,
        children: children
          ? children.filter(({ roles }) =>
              role ? rolesHasRole([role], roles) : userHasRole(user, roles),
            )
          : [],
      };
    });
  const [activeDropDown, setActiveDropDown] = useState<string>();

  function getClassNameForParent(path: string, hasChildren = false) {
    if (hasChildren) {
      let className = 'menu-item-has-children dropdown';

      const hasActiveRoute = pathname.startsWith(path);

      if (hasActiveRoute) {
        className += ' active';
      }

      if (activeDropDown === path) {
        className += ' show';
      }

      return className;
    }

    return path === pathname ? 'active' : '';
  }

  function closeSideMenu(e: React.MouseEvent) {
    e.preventDefault();
    const leftPanel = document.getElementById('left-panel');
    if (window.innerWidth <= 992) {
      if (leftPanel) {
        if (!leftPanel.classList.contains('left-panel-d-block')) {
          leftPanel.classList.add('left-panel-d-block');
        } else {
          leftPanel.classList.remove('left-panel-d-block');
        }
      }
    }
  }

  return (
    <aside id="left-panel" className="left-panel app-left-panel open-menu">
      <div className="row close-side-menu py-2 pr-2 d-lg-none d-block">
        <div className="col-12 text-right">
          <i
            className="fa fa-times-circle fa-1x text-white"
            onClick={closeSideMenu}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <nav className="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li className="py-3">
              {user.role.indexOf(UserRole.Admin) !== -1 ? null : <FilterBox />}
            </li>
            {links.map(({ path, title, icon, children }) => {
              return children && children.length > 0 ? (
                <li key={path} className={getClassNameForParent(path, true)}>
                  <Link
                    to={path}
                    className="dropdown-toggle"
                    onClick={(e) => {
                      e.preventDefault();

                      if (activeDropDown === path) {
                        setActiveDropDown(undefined);
                      } else {
                        setActiveDropDown(path);
                      }
                    }}
                  >
                    <i className={`menu-icon fa ${icon}`} />
                    <span>{title}</span>
                  </Link>
                  <ul
                    className={`sub-menu children dropdown-menu ${
                      activeDropDown === path ? 'show' : ''
                    }`}
                  >
                    <li className="subtitle">
                      <i className={`menu-icon fa ${icon}`} />
                      <span>{title}</span>
                    </li>
                    {children.map(
                      ({
                        path: childPath,
                        title: childTitle,
                        icon: childIcon,
                      }) => {
                        return (
                          <li key={childPath}>
                            <Link to={childPath} className="dropdown-toggle">
                              <i className={`menu-icon fa ${childIcon}`} />
                              <span>{childTitle}</span>
                            </Link>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </li>
              ) : (
                <li key={path} className={getClassNameForParent(path)}>
                  <Link to={path}>
                    <i className={`menu-icon fa ${icon}`} />
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </aside>
  );
};

export default SideMenu;

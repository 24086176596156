import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadBatches } from './batches.slice';

const BatchListPage = (): JSX.Element => {
  const { batches: batchesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    filter: { centerId: selectedCenter },
  } = useSelector((state: RootState) => state.app);
  const { loading, batches } = useSelector((state: RootState) => state.batches);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: batchesTxt.batch,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link to={`${AppRoutePath.BatchesUpdate.replace(':batchId', _id)}`}>
              {name}
            </Link>
          );
        },
      },
      {
        Header: batchesTxt.class,
        accessor: 'classTitle',
      },
      {
        Header: batchesTxt.center,
        accessor: 'centerTitle',
      },
      {
        Header: batchesTxt.status,
        accessor: 'status',
      },
    ],
    [batchesTxt],
  );

  const data = useMemo(
    () =>
      batches
        ?.filter(({ centerId }) => {
          let centerCheck = true;

          if (selectedCenter) {
            centerCheck = selectedCenter === centerId;
          }

          return centerCheck;
        })
        .map(({ class: classInfo, center: centerInfo, ...restBatch }) => {
          return {
            classTitle: classInfo?.name || '',
            centerTitle: centerInfo?.name || '',
            ...restBatch,
          };
        }),
    [batches, selectedCenter],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadBatches({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="bg-white rounded shadow px-4 py-5 list-table">
      <h1 className="primary-heading mb-4">{batchesTxt.batches}</h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default BatchListPage;

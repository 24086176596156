/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AddressModel,
  CityModel,
  InstituteBasicInfoInput,
  InstituteBasicInfoModel,
  InstituteCourseInput,
  InstituteCourseModel,
  InstitutePaymentInfo,
  InstitutePaymentInfoInput,
  InstituteSetupState,
  InstituteTypeModel,
  InstituteWebsiteInfoInput,
  InstituteWebsiteInfoModel,
  WebsiteMediaInput,
  WebsiteMediaModel,
} from '../../common/types';

const initialState: InstituteSetupState = {
  loading: false,
  success: null,
  errors: [],
  activeTab: '0',
  instituteBasicInfo: null,
  saveInstituteBasicInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  instituteWebsiteInfo: null,
  saveInstituteWebsiteInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  instituteCourses: [],
  saveInstituteCourses: {
    loading: false,
    success: null,
    errors: [],
  },
  websiteMedia: [],
  websiteTestimonial: [],
  saveWebsiteMedia: {
    loading: false,
    success: null,
    errors: [],
  },
  instituteTypes: [],
  websiteDefaultMainImageUrl: null,
  removeInstituteLogo: {
    loading: false,
    success: null,
    errors: [],
  },
  removeInstituteAboutImage: {
    loading: false,
    success: null,
    errors: [],
  },
  removeTestimonialImage: {
    loading: false,
    success: null,
    errors: [],
  },
  removeCourseImage: {
    loading: false,
    success: null,
    errors: [],
  },
  removeInstituteWebsiteMedia: {
    loading: false,
    success: null,
    errors: [],
  },
  institutePaymentInfo: null,
  saveInstitutePaymentInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  cities: null,
  instituteAddress: null,
};

const instituteSetupSlice = createSlice({
  name: 'instituteSetup',
  initialState,
  reducers: {
    loadInstituteSetupInfo(state) {
      state.loading = true;
      state.success = null;
      state.errors = [];
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    loadInstituteSetupInfoSuccess(
      state,
      action: PayloadAction<{
        instituteBasicInfo: InstituteBasicInfoModel;
        instituteWebsiteInfo: InstituteWebsiteInfoModel;
        instituteCourses: InstituteCourseModel[];
        websiteMedia: WebsiteMediaModel[];
        instituteTypes: InstituteTypeModel[];
        websiteDefaultMainImageUrl: string;
        institutePaymentInfo: InstitutePaymentInfo;
        cities: CityModel[];
        instituteAddress: AddressModel;
      }>,
    ) {
      state.loading = false;
      state.success = !!action.payload;
      state.instituteBasicInfo = action.payload.instituteBasicInfo;
      state.instituteWebsiteInfo = action.payload.instituteWebsiteInfo;
      state.instituteCourses = action.payload.instituteCourses;
      state.websiteMedia = action.payload.websiteMedia;
      state.instituteTypes = action.payload.instituteTypes;
      state.websiteDefaultMainImageUrl =
        action.payload.websiteDefaultMainImageUrl;
      state.institutePaymentInfo = action.payload.institutePaymentInfo;
      state.cities = action.payload.cities;
      state.instituteAddress = action.payload.instituteAddress;
    },
    loadInstituteSetupInfoError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    saveInstituteBasicInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<InstituteBasicInfoInput>,
    ) {
      state.saveInstituteBasicInfo.loading = true;
      state.saveInstituteBasicInfo.success = null;
      state.saveInstituteBasicInfo.errors = [];
    },
    saveInstituteBasicInfoSuccess(
      state,
      action: PayloadAction<InstituteBasicInfoModel>,
    ) {
      state.saveInstituteBasicInfo.loading = false;
      state.saveInstituteBasicInfo.success = !!action.payload;

      const {
        address: { country, ...restAddress },
        ...restBasicInfo
      } = action.payload;
      state.instituteBasicInfo = { ...restBasicInfo, address: restAddress };
      state.instituteAddress = { ...restAddress, country };
    },
    saveInstituteBasicInfoError(state, action: PayloadAction<string[]>) {
      state.saveInstituteBasicInfo.loading = false;
      state.saveInstituteBasicInfo.errors = action.payload;
    },
    resetInstituteBasicInfoSuccess(state) {
      state.saveInstituteBasicInfo.success = null;
    },
    saveInstituteWebsiteInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<InstituteWebsiteInfoInput>,
    ) {
      state.saveInstituteWebsiteInfo.loading = true;
      state.saveInstituteWebsiteInfo.success = null;
      state.saveInstituteWebsiteInfo.errors = [];
    },
    saveInstituteWebsiteInfoSuccess(
      state,
      action: PayloadAction<InstituteWebsiteInfoModel>,
    ) {
      state.saveInstituteWebsiteInfo.loading = false;
      state.saveInstituteWebsiteInfo.success = !!action.payload;
      state.instituteWebsiteInfo = action.payload;
    },
    saveInstituteWebsiteInfoError(state, action: PayloadAction<string[]>) {
      state.saveInstituteWebsiteInfo.loading = false;
      state.saveInstituteWebsiteInfo.errors = action.payload;
    },
    resetInstituteWebsiteInfoSuccess(state) {
      state.saveInstituteWebsiteInfo.success = null;
    },
    saveInstituteCourses(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<InstituteCourseInput[]>,
    ) {
      state.saveInstituteCourses.loading = true;
      state.saveInstituteCourses.success = null;
      state.saveInstituteCourses.errors = [];
    },
    saveInstituteCoursesSuccess(
      state,
      action: PayloadAction<InstituteCourseModel[]>,
    ) {
      state.saveInstituteCourses.loading = false;
      state.saveInstituteCourses.success = !!action.payload;
      state.instituteCourses = action.payload;
    },
    saveInstituteCoursesError(state, action: PayloadAction<string[]>) {
      state.saveInstituteCourses.loading = false;
      state.saveInstituteCourses.errors = action.payload;
    },
    resetInstituteCoursesSuccess(state) {
      state.saveInstituteCourses.success = null;
    },
    saveWebsiteMedia(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        input: WebsiteMediaInput[];
        defaultMainImage: string;
      }>,
    ) {
      state.saveWebsiteMedia.loading = true;
      state.saveWebsiteMedia.success = null;
      state.saveWebsiteMedia.errors = [];
    },
    saveWebsiteMediaSuccess(
      state,
      action: PayloadAction<{
        media: WebsiteMediaModel[];
        defaultMainImageUrl: string;
      }>,
    ) {
      state.saveWebsiteMedia.loading = false;
      state.saveWebsiteMedia.success = !!action.payload;
      state.websiteMedia = action.payload.media;
      state.websiteDefaultMainImageUrl = action.payload.defaultMainImageUrl;
    },
    saveWebsiteMediaError(state, action: PayloadAction<string[]>) {
      state.saveWebsiteMedia.loading = false;
      state.saveWebsiteMedia.errors = action.payload;
    },
    resetSaveWebsiteMediaSuccess(state) {
      state.saveWebsiteMedia.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeInstituteLogo(state, action: PayloadAction<undefined>) {
      state.removeInstituteLogo.loading = true;
      state.removeInstituteLogo.errors = [];
    },
    removeInstituteLogoSuccess(state, action: PayloadAction<boolean>) {
      state.removeInstituteLogo.loading = false;
      state.removeInstituteLogo.success = action.payload;

      if (state.instituteBasicInfo?.logoUrl) {
        state.instituteBasicInfo.logoUrl = undefined;
      }
    },
    removeInstituteLogoError(state, action: PayloadAction<string[]>) {
      state.removeInstituteLogo.loading = false;
      state.removeInstituteLogo.errors = action.payload;
    },
    resetRemoveInstituteLogo(state) {
      state.removeInstituteLogo.success = null;
      state.removeInstituteLogo.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeInstituteAboutImage(state, action: PayloadAction<undefined>) {
      state.removeInstituteAboutImage.loading = true;
      state.removeInstituteAboutImage.errors = [];
    },
    removeInstituteAboutImageSuccess(state, action: PayloadAction<boolean>) {
      state.removeInstituteAboutImage.loading = false;
      state.removeInstituteAboutImage.success = action.payload;

      if (state.instituteWebsiteInfo?.aboutImageUrl) {
        state.instituteWebsiteInfo.aboutImageUrl = undefined;
      }
    },
    removeInstituteAboutImageError(state, action: PayloadAction<string[]>) {
      state.removeInstituteAboutImage.loading = false;
      state.removeInstituteAboutImage.errors = action.payload;
    },
    resetRemoveInstituteAboutImage(state) {
      state.removeInstituteAboutImage.success = null;
      state.removeInstituteAboutImage.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeTestimonialImage(state, action: PayloadAction<string>) {
      state.removeTestimonialImage.loading = true;
      state.removeTestimonialImage.errors = [];
    },
    removeTestimonialImageSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeTestimonialImage.loading = false;
      state.removeTestimonialImage.success = action.payload.success;

      if (state.instituteWebsiteInfo) {
        state.instituteWebsiteInfo.testimonial = state.instituteWebsiteInfo.testimonial.map(
          ({ _id, commenterPicUrl, ...restWT }) => {
            return {
              _id,
              ...restWT,
              commenterPicUrl:
                action.payload.id === _id ? undefined : commenterPicUrl,
            };
          },
        );
      }
    },
    removeTestimonialImageError(state, action: PayloadAction<string[]>) {
      state.removeTestimonialImage.loading = false;
      state.removeTestimonialImage.errors = action.payload;
    },
    resetRemoveTestimonialImage(state) {
      state.removeTestimonialImage.success = null;
      state.removeTestimonialImage.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeCourseImage(state, action: PayloadAction<string>) {
      state.removeCourseImage.loading = true;
      state.removeCourseImage.errors = [];
    },
    removeCourseImageSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeCourseImage.loading = false;
      state.removeCourseImage.success = action.payload.success;

      if (state.instituteCourses) {
        state.instituteCourses = state.instituteCourses.map(
          ({ _id, imageUrl, ...restCourse }) => {
            return {
              _id,
              ...restCourse,
              imageUrl: action.payload.id === _id ? undefined : imageUrl,
            };
          },
        );
      }
    },
    removeCourseImageError(state, action: PayloadAction<string[]>) {
      state.removeCourseImage.loading = false;
      state.removeCourseImage.errors = action.payload;
    },
    resetRemoveCourseImage(state) {
      state.removeCourseImage.success = null;
      state.removeCourseImage.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeInstituteWebsiteMedia(state, action: PayloadAction<string>) {
      state.removeInstituteWebsiteMedia.loading = true;
      state.removeInstituteWebsiteMedia.errors = [];
    },
    removeInstituteWebsiteMediaSuccess(
      state,
      action: PayloadAction<{ success: boolean; id: string }>,
    ) {
      state.removeInstituteWebsiteMedia.loading = false;
      state.removeInstituteWebsiteMedia.success = action.payload.success;

      if (state.websiteMedia) {
        state.websiteMedia = state.websiteMedia.filter(
          ({ _id }) => _id !== action.payload.id,
        );
      }
    },
    removeInstituteWebsiteMediaError(state, action: PayloadAction<string[]>) {
      state.removeInstituteWebsiteMedia.loading = false;
      state.removeInstituteWebsiteMedia.errors = action.payload;
    },
    resetRemoveInstituteWebsiteMedia(state) {
      state.removeInstituteWebsiteMedia.success = null;
      state.removeInstituteWebsiteMedia.errors = [];
    },
    saveInstitutePaymentInfo(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<InstitutePaymentInfoInput>,
    ) {
      state.saveInstitutePaymentInfo.loading = true;
      state.saveInstitutePaymentInfo.errors = [];
    },
    saveInstitutePaymentInfoSuccess(
      state,
      action: PayloadAction<InstitutePaymentInfo>,
    ) {
      state.saveInstitutePaymentInfo.loading = false;
      state.saveInstitutePaymentInfo.success = !!action.payload;
      state.institutePaymentInfo = action.payload;
    },
    saveInstitutePaymentInfoError(state, action: PayloadAction<string[]>) {
      state.saveInstitutePaymentInfo.loading = false;
      state.saveInstitutePaymentInfo.errors = action.payload;
    },
    resetSaveInstitutePaymentInfo(state) {
      state.saveInstitutePaymentInfo.success = null;
      state.saveInstitutePaymentInfo.errors = [];
    },
  },
});

export const {
  loadInstituteSetupInfo,
  setActiveTab,
  loadInstituteSetupInfoSuccess,
  loadInstituteSetupInfoError,
  saveInstituteBasicInfo,
  saveInstituteBasicInfoSuccess,
  saveInstituteBasicInfoError,
  resetInstituteBasicInfoSuccess,
  saveInstituteWebsiteInfo,
  saveInstituteWebsiteInfoSuccess,
  saveInstituteWebsiteInfoError,
  resetInstituteWebsiteInfoSuccess,
  saveInstituteCourses,
  saveInstituteCoursesSuccess,
  saveInstituteCoursesError,
  resetInstituteCoursesSuccess,
  saveWebsiteMedia,
  saveWebsiteMediaSuccess,
  saveWebsiteMediaError,
  resetSaveWebsiteMediaSuccess,
  removeInstituteLogo,
  removeInstituteLogoSuccess,
  removeInstituteLogoError,
  resetRemoveInstituteLogo,
  removeInstituteAboutImage,
  removeInstituteAboutImageSuccess,
  removeInstituteAboutImageError,
  resetRemoveInstituteAboutImage,
  removeTestimonialImage,
  removeTestimonialImageSuccess,
  removeTestimonialImageError,
  resetRemoveTestimonialImage,
  removeCourseImage,
  removeCourseImageSuccess,
  removeCourseImageError,
  resetRemoveCourseImage,
  removeInstituteWebsiteMedia,
  removeInstituteWebsiteMediaSuccess,
  removeInstituteWebsiteMediaError,
  resetRemoveInstituteWebsiteMedia,
  saveInstitutePaymentInfo,
  saveInstitutePaymentInfoSuccess,
  saveInstitutePaymentInfoError,
  resetSaveInstitutePaymentInfo,
} = instituteSetupSlice.actions;

export const {
  name: instituteSetupSliceName,
  reducer: instituteSetupSliceReducer,
} = instituteSetupSlice;

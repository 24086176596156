import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LanguageTexts from '../../../common/language';
import { ChangePasswordInput, RootState } from '../../../common/types';
import { changePassword, resetChangePassword } from '../profile.slice';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordPage: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    changePassword: { success, errors, loading },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(data: ChangePasswordInput) {
    dispatch(changePassword(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetChangePassword());
      toast.success(profileTxt.passwordSuccess);
    }
  }, [success, profileTxt, dispatch]);

  const changePasswordInit: ChangePasswordInput = {
    newPassword: '',
    oldPassword: '',
  };

  return (
    <>
      {!success ? (
        <ChangePasswordForm
          onSubmit={onSubmit}
          errors={errors}
          loading={loading}
          initialValues={changePasswordInit}
        />
      ) : null}
    </>
  );
};

export default ChangePasswordPage;

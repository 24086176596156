/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { PaymentMethodType } from '../../common/constants';
import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { PayInvoiceOfflineInput } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';

type UserInvoicePayOfflineFormProps = {
  onSubmit: (data: PayInvoiceOfflineInput) => void;
  loading: boolean;
  errors: string[];
  initialValues: PayInvoiceOfflineInput;
  currencySymbol: string;
};

const UserInvoicePayOfflineFormForm: React.FC<UserInvoicePayOfflineFormProps> = ({
  onSubmit,
  loading,
  errors,
  initialValues,
  currencySymbol,
}: UserInvoicePayOfflineFormProps): JSX.Element => {
  const { userInvoices: userInvoicesTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  return (
    <Formik
      initialValues={initVals}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values }) => {
        const errorKeys = getLangAndErrKeys(values);
        const formattedErrors = formatErrors(
          errorKeys,
          errors,
          userInvoicesTxt,
        );
        return (
          <Form>
            {values.paymentMethodType !== PaymentMethodType.None ? (
              <div className="row pending-fee-amount">
                <div className="col-lg-3 col-12 mb-lg-0 mb-2 d-flex flex-column justify-content-center">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={userInvoicesTxt}
                    id="input.feesAmount"
                    required
                  />
                </div>
                <div className="col-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text text-secondary">
                        <b>{currencySymbol}</b>
                      </span>
                    </div>
                    <Field
                      name="feesAmount"
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="my-4">
              <div className="row">
                <div className="col-lg-3 col-12">
                  <p className="font-weight-bold">
                    {userInvoicesTxt['input.paymentMethodType']} :
                  </p>
                </div>
                <div className="col-lg-9 col-12">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="paymentMethodType"
                      id={PaymentMethodType.Cash}
                      className="custom-control-input cash-mode"
                      value={PaymentMethodType.Cash}
                    />
                    <label
                      className="custom-control-label text-secondary"
                      htmlFor={PaymentMethodType.Cash}
                    >
                      {PaymentMethodType.Cash}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="paymentMethodType"
                      id={PaymentMethodType.Cheque}
                      className="custom-control-input cash-mode"
                      value={PaymentMethodType.Cheque}
                    />
                    <label
                      className="custom-control-label text-secondary"
                      htmlFor={PaymentMethodType.Cheque}
                    >
                      {PaymentMethodType.Cheque}
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="paymentMethodType"
                      id={PaymentMethodType.Online}
                      className="custom-control-input cash-mode"
                      value={PaymentMethodType.Online}
                    />
                    <label
                      className="custom-control-label text-secondary"
                      htmlFor={PaymentMethodType.Online}
                    >
                      {userInvoicesTxt.onlinePaymentService}
                      <br />
                      <p className="small common-text-color short-desc mt-1">
                        {userInvoicesTxt.onlinePaymentServiceExp}
                      </p>
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      name="paymentMethodType"
                      id={PaymentMethodType.None}
                      className="custom-control-input cash-mode"
                      value={PaymentMethodType.None}
                    />
                    <label
                      className="custom-control-label text-secondary"
                      htmlFor={PaymentMethodType.None}
                    >
                      {userInvoicesTxt.notPaid}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <AppInputLableWithError
                    errors={errors}
                    langTxt={userInvoicesTxt}
                    id="input.notes"
                    required={
                      values.paymentMethodType === PaymentMethodType.None
                    }
                  />
                  <Field
                    name="notes"
                    as="textarea"
                    rows={5}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="my-md-3" />

            {values.paymentMethodType === PaymentMethodType.Cheque ? (
              <>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={userInvoicesTxt}
                        id="input.chequeBankName"
                        required
                      />
                      <Field
                        name="chequeBankName"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-md-3" />

                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <AppInputLableWithError
                        errors={errors}
                        langTxt={userInvoicesTxt}
                        id="input.chequeNumber"
                        required
                      />
                      <Field
                        name="chequeNumber"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-md-3" />
              </>
            ) : null}

            <div className="row">
              <div className="col-12">
                {formattedErrors.map((error) => (
                  <p
                    className="txt2"
                    style={{ fontWeight: 'bold' }}
                    key={error}
                  >
                    {error}
                  </p>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12" />
              <div className="col-md-6 col-12 text-right">
                <button
                  type="submit"
                  className="btn save-btn"
                  disabled={loading}
                >
                  {userInvoicesTxt.btnSubmit}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserInvoicePayOfflineFormForm;

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AppRoutePath,
  NotificationType,
  UserRole,
} from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import { loadInstituteWebsiteBasicInfoDetails } from '../../features/institute-home/institute-home.slice';
import { logout } from '../../features/login/login.slice';
import { loadNotifications } from '../../features/notification/notification.slice';
import NotificationList from '../../features/notification/NotificationList';
import { loadProfileInfo } from '../../features/profile/profile.slice';
import SelectedFilter from './SelectedFilter';

const TopHeader: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { websiteBasicInfo } = useSelector(
    (state: RootState) => state.instituteHome,
  );
  const { user } = useSelector((state: RootState) => state.login);
  const { notifications } = useSelector(
    (state: RootState) => state.notification,
  );
  const {
    filter: { childrenId: selectedChildren, batchId: selectedBatch },
  } = useSelector((state: RootState) => state.app);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const { topHeader: topHeaderText } = LanguageTexts;

  const showNotifications =
    user?.role.includes(UserRole.Student) ||
    user?.role.includes(UserRole.Parent);
  const filteredNotifications = showNotifications
    ? notifications?.filter(({ userId, payload }) => {
        const userCheck = user?.role.includes(UserRole.Parent)
          ? userId === selectedChildren
          : true;

        switch (payload.notificationType) {
          case NotificationType.FeesDue:
            return userCheck && payload.userInvoice.batchId === selectedBatch;
          case NotificationType.BatchScheduleChange:
            return userCheck && payload.batchId === selectedBatch;
          case NotificationType.NewAnnouncement:
            return (
              userCheck &&
              selectedBatch &&
              payload.announcement.batchIds.includes(selectedBatch)
            );
          case NotificationType.NewAssignment:
            return userCheck && payload.assignment.batchId === selectedBatch;
          case NotificationType.AssignmentSubmissionStatusChange:
            return userCheck && payload.assignment.batchId === selectedBatch;
          default:
            return userCheck;
        }
      })
    : [];

  useEffect(() => {
    if (!infoLoaded) {
      dispatch(loadProfileInfo());

      if (showNotifications) {
        dispatch(loadNotifications({ readByUser: false }));
      }

      if (user) {
        dispatch(loadInstituteWebsiteBasicInfoDetails(user.domain));
      }

      setInfoLoaded(true);
    }
  }, [dispatch, infoLoaded, user, showNotifications]);

  function doLogout() {
    dispatch(logout());
  }

  function handleToggleMenu(e: React.MouseEvent) {
    e.preventDefault();
    const leftPanel = document.getElementById('left-panel');
    if (window.innerWidth < 1024) {
      document.body.classList.remove('open');
      if (window.innerWidth <= 992) {
        if (leftPanel) {
          if (!leftPanel.classList.contains('left-panel-d-block')) {
            leftPanel.classList.add('left-panel-d-block');
          } else {
            leftPanel.classList.remove('left-panel-d-block');
          }
        }
      } else if (leftPanel) {
        if (leftPanel.classList.contains('open-menu')) {
          document.body.classList.remove('open-menu');
        } else {
          leftPanel.classList.add('open-menu');
        }
      }
    } else if (document.body.classList.contains('open')) {
      document.body.classList.remove('open');
    } else {
      document.body.classList.add('open');
    }
  }

  return (
    <header id="header" className="header">
      <div className="top-left">
        <div className="navbar-header">
          <Link className="navbar-brand" to={AppRoutePath.Dashboard}>
            <img
              src={
                websiteBasicInfo?.logoUrl
                  ? websiteBasicInfo?.logoUrl
                  : `${process.env.REACT_APP_IMAGE_PATH}website/logo/default-logo.jpg`
              }
              alt="Logo"
            />
          </Link>
          <Link
            id="menuToggle"
            className="menuToggle"
            onClick={handleToggleMenu}
            to="/"
          >
            <i className="fa fa-bars common-text-color" />
          </Link>
        </div>
      </div>
      <div className="top-right">
        <div className="float-left py-lg-2 py-3">
          <SelectedFilter />
        </div>
        <div className="header-menu">
          <div className="header-left">
            {showNotifications ? (
              <Dropdown className="dropdown for-notification">
                <Dropdown.Toggle id="notifications-dp">
                  <i className="fa fa-bell" />
                  {filteredNotifications && filteredNotifications.length > 0 ? (
                    <>
                      <span className="count bg-danger">
                        {filteredNotifications.length}
                      </span>
                    </>
                  ) : null}
                </Dropdown.Toggle>
                {filteredNotifications && filteredNotifications.length > 0 ? (
                  <Dropdown.Menu className="dropdown-menu">
                    <NotificationList
                      notifications={filteredNotifications || []}
                    />
                  </Dropdown.Menu>
                ) : null}
              </Dropdown>
            ) : null}
            <Dropdown className="user-area float-right">
              <Dropdown.Toggle id="dropdown-basic d-flex align-items-center">
                <div className="user-avatar-img">
                  <img
                    className="user-avatar"
                    src={
                      user?.profilePicUrl !== null
                        ? user?.profilePicUrl
                        : user?.gender === null
                        ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/user.png`
                        : user?.gender === 'Male'
                        ? `${process.env.REACT_APP_IMAGE_PATH}website/profile/man.png`
                        : `${process.env.REACT_APP_IMAGE_PATH}website/profile/woman.png`
                    }
                    alt={user?.name}
                  />
                  <span className="availability-status" />
                </div>
                {user?.name ? (
                  <>
                    <span className="user-name ml-2">
                      {user?.name.split(' ')[0]}
                    </span>
                  </>
                ) : null}
                <i className="fa fa-angle-down ml-2" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="user-menu">
                <Link className="nav-link" to={AppRoutePath.Profile}>
                  <i className="fa fa-user" />
                  {topHeaderText.myProfile}
                </Link>

                <Link className="nav-link" to="/" onClick={doLogout}>
                  <i className="fa fa-power-off" />
                  {topHeaderText.logout}
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopHeader;

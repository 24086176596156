/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import FsLightbox from 'fslightbox-react';
import React, { useMemo, useState } from 'react';

import { InstituteWebsiteMediaType } from '../../../common/constants';
import LanguageTexts from '../../../common/language';
import { InstituteTypeModel, WebsiteMediaInput } from '../../../common/types';
import MultiImageUpload, {
  SelectedImage,
} from '../../../components/MultiImageUpload';
import MediaMainImageRadio from './MediaMainImageRadio';

type MediaMainImagesProps = {
  initialValues: WebsiteMediaInput[];
  initDefaultMainImage: string;
  instituteTypes: InstituteTypeModel[];
  onChange: (data: WebsiteMediaInput[]) => void;
  onDefaultImgChange: (imageUrl: string) => void;
  disabled?: boolean;
};

const MediaMainImages: React.FC<MediaMainImagesProps> = ({
  initialValues,
  initDefaultMainImage,
  instituteTypes,
  onChange,
  onDefaultImgChange,
  disabled,
}: MediaMainImagesProps): JSX.Element => {
  const { websiteMedia: websiteMediaTxt } = LanguageTexts;
  const [defaultImage, setDefaultImage] = useState(initDefaultMainImage);
  const [mainImages, setMainImages] = useState<WebsiteMediaInput[]>(
    initialValues,
  );

  function onFileSelect(files: SelectedImage[] | null) {
    if (files) {
      const newImages = [
        ...mainImages,
        ...files.map(({ image, imagePreview: mediaUrl }) => {
          return {
            image,
            mediaUrl,
            mediaType: InstituteWebsiteMediaType.MainImage,
          };
        }),
      ];

      setMainImages(newImages);
      onChange(newImages);
    }
  }

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const { imgSources: imageSources, imgTypes: imageTypes } = useMemo(() => {
    const imgSources: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const imgTypes: any[] = [];

    instituteTypes.forEach(({ imageUrl }) => {
      imgSources.push(imageUrl || '');
      imgTypes.push('image');
    });
    mainImages.forEach(({ mediaUrl }) => {
      imgSources.push(mediaUrl || '');
      imgTypes.push('image');
    });

    return { imgSources, imgTypes };
  }, [instituteTypes, mainImages]);

  return (
    <>
      <h2 className="secondary-heading mb-2">{websiteMediaTxt.setMainImg}</h2>
      <p className="small common-text-color mb-4">
        {websiteMediaTxt.resolutionMainImage}
      </p>
      <div className="row">
        <div className="col-12">
          <p className="text-secondary small text-center mb-3">
            {websiteMediaTxt.chooseImg}
          </p>

          <div className="row">
            {instituteTypes.map(({ imageUrl: mediaUrl, _id }, index) => {
              return (
                <MediaMainImageRadio
                  key={`itimg${_id}`}
                  _id={_id}
                  defaultImage={defaultImage}
                  mediaUrl={mediaUrl || ''}
                  onDefaultImgChange={(imageUrl) => {
                    setDefaultImage(imageUrl);
                    onDefaultImgChange(imageUrl);
                  }}
                  disabled={disabled}
                  onImageClick={() => {
                    openLightboxOnSlide(index + 1);
                  }}
                />
              );
            })}
            {mainImages.map(({ mediaUrl, _id }, index) => {
              return (
                <MediaMainImageRadio
                  key={`mainimg${_id || index}`}
                  _id={_id || `${index}`}
                  defaultImage={defaultImage}
                  mediaUrl={mediaUrl || ''}
                  onDefaultImgChange={(imageUrl) => {
                    setDefaultImage(imageUrl);
                    onDefaultImgChange(imageUrl);
                  }}
                  disabled={disabled}
                  onImageClick={() => {
                    openLightboxOnSlide(instituteTypes.length + index + 1);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <FsLightbox
          toggler={lightboxController.toggler}
          slide={lightboxController.slide}
          sources={imageSources || []}
          types={imageTypes || []}
        />
      </div>
      <MultiImageUpload
        disabled={disabled}
        id="main-image-select"
        onFileSelect={onFileSelect}
      >
        <div className="d-flex justify-content-center align-items-center rounded-lg py-2 px-0 upload-own-img-btn add-more-btn">
          <span className="fa fa-upload mr-2" />
          {websiteMediaTxt.uploadOwn}
        </div>
      </MultiImageUpload>
    </>
  );
};

MediaMainImages.defaultProps = {
  disabled: false,
};

export default MediaMainImages;

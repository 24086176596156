/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import { ContactUsInput, PreRegistrationInfoInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  addContactUs,
  addContactUsError,
  addContactUsSuccess,
  addPreRegistrationInfo,
  addPreRegistrationInfoError,
  addPreRegistrationInfoSuccess,
} from './home-page.slice';

export function* addPreRegistrationInfoAsync(
  action: PayloadAction<PreRegistrationInfoInput>,
) {
  const { data, errors } = yield call(
    ApiService.addPreRegistrationInfo,
    action.payload,
  );

  if (errors) {
    yield put(addPreRegistrationInfoError(errors));
  } else {
    yield put(addPreRegistrationInfoSuccess(data));
  }
}

export function* addContactUsAsync(action: PayloadAction<ContactUsInput>) {
  const { data, errors } = yield call(ApiService.addContactUs, action.payload);

  if (errors) {
    yield put(addContactUsError(errors));
  } else {
    yield put(addContactUsSuccess(data));
  }
}

export function* watchPreRegistrationInfoAsync() {
  yield takeEvery(
    addPreRegistrationInfo.toString(),
    addPreRegistrationInfoAsync,
  );
  yield takeEvery(addContactUs.toString(), addContactUsAsync);
}

import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { BatchScheduleType } from '../../common/constants';
import { setTimeToEnd, setTimeToStart } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { BatchModel, BatchScheduleModel, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import { loadBatches } from '../batches/batches.slice';
import BatchScheduleCalendar from './components/BatchScheduleCalendar';

const BatchSchedule: React.FC = (): JSX.Element => {
  const { dashboard: dashboardTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const {
    filter: { centerId: selectedCenter, batchId: selectedBatch },
  } = useSelector((state: RootState) => state.app);

  const [scheduleDate, setScheduleDate] = useState<moment.Moment>();
  const [scheduleData, setScheduleData] = useState<
    {
      schedule: BatchScheduleModel;
      batch: BatchModel;
    }[]
  >();
  const [showModel, setShowModel] = useState(false);
  const [loadRequested, setLoadRequested] = useState(false);
  const { loading, batches } = useSelector((state: RootState) => state.batches);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(
        loadBatches({
          domain: currentUser?.domain || '',
        }),
      );
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  const filteredBatches = useMemo(() => {
    if (!batches) {
      return [];
    }

    return batches.filter(({ _id: batchId, centerId }) => {
      const aCenterIds = [centerId];
      const aBatchIds = [batchId];

      let centerCheck = true;
      let batchCheck = true;

      if (selectedCenter) {
        centerCheck = aCenterIds.includes(selectedCenter);

        if (!selectedBatch) {
          return centerCheck;
        }
      }

      if (selectedBatch) {
        batchCheck = aBatchIds.includes(selectedBatch);

        if (!selectedCenter) {
          return batchCheck;
        }
      }

      return centerCheck || batchCheck;
    });
  }, [batches, selectedCenter, selectedBatch]);

  const scheduleDatesMap = useMemo(() => {
    const datesMap = new Map();

    function setDatesMap(
      date: string,
      schedule: BatchScheduleModel,
      batch: BatchModel,
    ) {
      if (datesMap.has(date)) {
        datesMap.set(date, [...datesMap.get(date), { batch, schedule }]);
      } else {
        datesMap.set(date, [{ batch, schedule }]);
      }
    }

    for (let i = 0; i < filteredBatches.length; i += 1) {
      const batch = filteredBatches[i];
      const startDate = moment(setTimeToStart(batch.startDate));
      const endDate = moment(setTimeToEnd(batch.endDate));

      if (batch.scheduleType === BatchScheduleType.Weekly) {
        const daysOfWeek = batch.schedule.map(({ day }) => parseInt(day, 10));

        const initDate = startDate.clone();

        while (initDate.isSameOrBefore(endDate)) {
          const curDate = initDate.clone();

          if (daysOfWeek.includes(curDate.weekday())) {
            const wSchedule = batch.schedule.find(
              ({ day }) => parseInt(day, 10) === curDate.weekday(),
            );

            if (wSchedule) {
              setDatesMap(curDate.toISOString(), wSchedule, batch);
            }
          }

          initDate.add(1, 'day');
        }
      } else if (batch.scheduleType === BatchScheduleType.Custom) {
        batch.schedule.forEach((dSchedule) => {
          const { day } = dSchedule;
          const curDate = moment(setTimeToStart(day));
          setDatesMap(curDate.toISOString(), dSchedule, batch);
        });
      }
    }

    return datesMap;
  }, [filteredBatches]);

  const scheduleDates: Date[] = useMemo(() => {
    const dates: Date[] = [];

    scheduleDatesMap.forEach((val, key) => {
      dates.push(new Date(key));
    });

    return dates;
  }, [scheduleDatesMap]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabledDays: any[] = [];
  const initialMonth = moment();

  function handleDateChange(newVal: moment.Moment) {
    const dateScheduleData = scheduleDatesMap.get(newVal.toISOString());

    if (dateScheduleData) {
      setScheduleDate(newVal);
      setScheduleData(dateScheduleData);
      setShowModel(true);
    }
  }

  function handleModelClose() {
    setShowModel(false);
    setScheduleDate(undefined);
    setScheduleData(undefined);
  }

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <>
      <BatchScheduleCalendar
        disabledDays={disabledDays}
        selectedDay={scheduleDate}
        initialMonth={initialMonth}
        scheduleDates={scheduleDates}
        onChange={handleDateChange}
      />
      <Modal show={showModel} size="lg" onHide={handleModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {dashboardTxt.scheduleDetails} {scheduleDate?.format('L')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-condensed table-bordered">
            <thead>
              <tr>
                <th>
                  <p>
                    <b>{dashboardTxt.center}</b>
                  </p>
                </th>
                <th>
                  <p>
                    <b>{dashboardTxt.class}</b>
                  </p>
                </th>
                <th>
                  <p>
                    <b>{dashboardTxt.timing}</b>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {scheduleData &&
                scheduleData.map(({ schedule, batch }) => {
                  return (
                    <tr key={`batch${batch._id}`} className="text-center">
                      <td>{batch.center?.name}</td>
                      <td>
                        {batch.class?.name} ({batch.name})
                      </td>
                      <td>
                        {moment(
                          schedule.startTime,
                          moment.HTML5_FMT.TIME,
                        ).format('hh:mm A')}
                        &nbsp;{dashboardTxt.to}&nbsp;
                        {moment(schedule.endTime, moment.HTML5_FMT.TIME).format(
                          'hh:mm A',
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BatchSchedule;

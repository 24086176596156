/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState, SelectedFilterInput } from '../common/types';

const initialState: AppState = {
  loading: false,
  success: null,
  errors: [],
  filter: {
    role: null,
    centerId: null,
    batchId: null,
    childrenId: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSelectedFilter(state, action: PayloadAction<SelectedFilterInput>) {
      state.loading = true;
      state.success = null;
      state.errors = [];
    },
    setSelectedFilterSuccess(
      state,
      action: PayloadAction<SelectedFilterInput>,
    ) {
      state.loading = false;
      state.success = true;

      state.filter = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetSelectedFilter(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.success = null;
      state.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetSelectedFilterSuccess(state, action: PayloadAction<undefined>) {
      state.loading = false;
      state.success = true;

      state.filter = {
        role: null,
        centerId: null,
        batchId: null,
        childrenId: null,
      };
    },
  },
});

export const {
  setSelectedFilter,
  setSelectedFilterSuccess,
  resetSelectedFilter,
  resetSelectedFilterSuccess,
} = appSlice.actions;

export const { name: appSliceName, reducer: appSliceReducer } = appSlice;
